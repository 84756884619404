import * as React from 'react';

export function LoadingOverlay(props: { text: string; isOpen: boolean }) {
  return (
    <>
      {props.isOpen && (
        <div className="overlay">
          <div className="overlay-background">
            <div className="overlay-container">
              <div className="loading loading-full-width">
                <div className="loading_text">{props.text}</div>
                <div className="loading_icon">
                  <div className="css-icon"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
