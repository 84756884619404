import { Button } from '@progress/kendo-react-buttons';
import { ill_delete_equip as DeleteIcon } from 'app/widgets/SVGs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getDefaultThumbnail } from '../utils';

interface IDeleteEquipmentDialogProps {
  isLoading: boolean;
  modelIconUrl: string;
  type?: string;
  subcategory: string;
  nickName?: string;
  model: string;
  close: () => void;
  deleteEquipment: () => void;
}

export function DeleteEqupmentDialog(props: IDeleteEquipmentDialogProps) {
  const { t } = useTranslation();

  React.useEffect(() => {
    const handlePopState = () => props.close();

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [props.nickName, props.modelIconUrl, props.subcategory]);

  return (
    <div className="remove-item-dialog-content">
      <div className="image-to-remove">
        <img
          src={
            props.modelIconUrl ||
            getDefaultThumbnail(props.type, props.subcategory)
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = getDefaultThumbnail(
              props.type,
              props.subcategory,
            );
          }}
          alt=""
        />
        <span className="delete-image-icon">
          <DeleteIcon />
        </span>
      </div>
      <h3 className="text-center">
        {t('equipment_delete_dialog_message', {
          nickname: props?.nickName ?? props?.model,
        })}
      </h3>
      <div className="row">
        <div className="col-xxs-6">
          <Button disabled={props.isLoading} onClick={props.close}>
            {t('cancel')}
          </Button>
        </div>
        <div className="col-xxs-6">
          <Button
            disabled={props.isLoading}
            onClick={props.deleteEquipment}
            themeColor="error"
          >
            {props.isLoading ? (
              <div className="loading_icon loading_icon-btn">
                <div className="css-icon"></div>
              </div>
            ) : (
              t('menu_delete')
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
