import { DateTimePicker, RadioGroup } from 'app/components/form-fields';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAccessSharingPeriodType } from 'types/IAccessSharingUser';
import './AccessPeriodRadioGroup.less';

interface Props {
  className?: string;
  initialValue?: IAccessSharingPeriodType;
  initialStartDate?: Date | null;
  initialEndDate?: Date | null;
  onChange: (value: IAccessSharingPeriodType) => void;
  onChangeStartDate: (value: Date | null) => void;
  onChangeEndDate: (value: Date | null) => void;
}

export default function AccessPeriodSelect({
  className,
  initialValue = IAccessSharingPeriodType.Continuous,
  initialStartDate = null,
  initialEndDate = null,
  onChange,
  onChangeStartDate,
  onChangeEndDate,
}: Props) {
  const { t } = useTranslation();

  const [value, setValue] = useState(initialValue);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const options = useMemo(
    () => [
      {
        label: t('continous_access_period'),
        value: IAccessSharingPeriodType.Continuous,
      },
      {
        label: t('temporary_access_period'),
        value: IAccessSharingPeriodType.Temporary,
      },
    ],
    [t],
  );

  return (
    <div className={clsx('access-period-radio-group', className)}>
      <RadioGroup
        name="access_period"
        label={t('access_period')}
        value={value}
        onChange={value => {
          setValue(value);
          onChange(value);
        }}
        options={options}
      />

      {value === IAccessSharingPeriodType.Temporary && (
        <div className="access-period-radio-group__date-time-pickers">
          <DateTimePicker
            label={t('start_time')}
            value={startDate}
            onChange={date => {
              setStartDate(date);
              onChangeStartDate(date);
            }}
            dateFormat="MMMM dd, yyyy"
            datePlaceholder={t('select_start_time')}
            timePlaceholder="00:00 AM"
          />

          <DateTimePicker
            label={t('end_time')}
            value={endDate}
            onChange={date => {
              setEndDate(date);
              onChangeEndDate(date);
            }}
            dateFormat="MMMM dd, yyyy"
            datePlaceholder={t('select_end_time')}
            timePlaceholder="00:00 AM"
          />
        </div>
      )}
    </div>
  );
}
