import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IMaintenanceSchedule } from 'types/IMaintenanceSchedule';
import { getMaintenanceScheduleIntervalTitle } from '../TrackMaintenencePage/maintenanceUtils';
import ChevronRightIcon from 'assets/images/chevron_right.svg';
import { MaintenanceHistoryContext } from 'app/components/ReactContexts/maintenanceHistoryContext';
import { useLocation, useNavigate } from 'react-router';

export function ArrowLinkComponent(props) {
  const { t } = useTranslation();
  const { maintenanceHistoryData, setMaintenanceHistoryData } =
    React.useContext(MaintenanceHistoryContext);

  const navigate = useNavigate();
  const location = useLocation();

  const schedule = props.scheduleItem as IMaintenanceSchedule;
  const title = getMaintenanceScheduleIntervalTitle(
    schedule.intervalType,
    schedule.intervalValue,
    t,
  );

  const handleClick = () => {
    setMaintenanceHistoryData({
      ...maintenanceHistoryData,
      selectedInterval: schedule,
    });

    navigate(
      location.pathname +
        `/${schedule.intervalValue
          .toLocaleString()
          .concat('-', schedule.intervalType)
          .replace(/\s/g, '')}`,
    );
  };

  return (
    <div className="clickable-row" onClick={handleClick}>
      <span className="schedule-item">{title}</span>
      <img
        className="arrow-links_icon arrow-links_icon-right"
        src={ChevronRightIcon}
      />
    </div>
  );
}
