import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface ILocationUnavailableGeofenceDialog {
  close: () => void;
}

export function LocationUnavailableGeofenceDialog(
  props: ILocationUnavailableGeofenceDialog,
) {
  const { t } = useTranslation();

  return (
    <Dialog className="modal-center modal-unavailable-location modal-remove-item modal-fulltext">
      <div className="remove-item-dialog-content">
        <h3>{t('location_unavailable1')}</h3>
        <p>{t('location_unavailable_message')}</p>
        <div className="row buttons-wrapper">
          <div className="col-xxs-12">
            <Button onClick={props.close} themeColor="primary">
              {t('ok')}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
