import { useEffect } from 'react';
import { useParams, useLocation, Location, Params } from 'react-router-dom';

const getRoutePath = (location: Location, params: Params): string => {
  const { pathname } = location;

  if (!Object.keys(params).length) {
    return pathname; // we don't need to replace anything
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};

export const usePageTracking = (): void => {
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path:
        getRoutePath(location, params) + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);
};

export const tagEvent = (eventName: string, params: any = {}): void => {
  window.gtag('event', eventName, params);
};
