import { Dialog } from '@progress/kendo-react-dialogs';
import { MaintenanceHistoryContext } from 'app/components/ReactContexts/maintenanceHistoryContext';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { IMaintenanceSchedule } from 'types/IMaintenanceSchedule';
import { EquipmentDetails } from '../../EquipmentDetailsBox';
import * as utils from '../../maintenanceUtils';
import { MaintenanceIntervalCard } from './MaintenanceIntervalCard';

interface IProps {
  isVisible: boolean;
  setVisible: any;
  equipmentDetails: IEquipmentEntry;
  openEngineHoursUpdateDialog: boolean;
  setOpenEngineHoursUpdateDialog: any;
}

export function NewMaintenanceDialog(props: IProps) {
  const { t } = useTranslation();
  const { maintenanceHistoryData } = React.useContext(
    MaintenanceHistoryContext,
  );

  const [maintenanceIntervals, setMaintenanceIntervals] =
    React.useState<IMaintenanceSchedule[]>();

  const [visibleDialog, setVisibleDialog] = React.useState(false);

  const curentMaintenanceHistory =
    maintenanceHistoryData.selectedEquipmentMaintenanceHistory;

  const getEveryHoursNextIntervals = (everyHoursData: any) => {
    const filteredHystory = curentMaintenanceHistory
      ?.filter(item => item.intervalType === 'Every X Hours')
      .map(item => ({ intervalValue: item.intervalValue }));

    const minInterval = everyHoursData?.reduce(
      (prev, crt) => (crt.intervalValue < prev.intervalValue ? crt : prev),
      { intervalValue: Infinity },
    );
    const minIntervalValue =
      minInterval == Infinity ? 0 : minInterval?.intervalValue;

    const lastHistory = utils.getLastHistoryInterval(curentMaintenanceHistory);
    const lastHistoryEngineHours = lastHistory
      ? lastHistory.completedEngineHours
      : 0;
    const equipmentEngineHours =
      utils.getCurrentEngineHours(props.equipmentDetails) ?? 0;
    const currentMaintenanceHours = Math.max(
      lastHistoryEngineHours,
      equipmentEngineHours,
    );

    const intervalDifference =
      currentMaintenanceHours % (minIntervalValue ?? 1);

    let lastInterval = currentMaintenanceHours - intervalDifference;

    if (
      equipmentEngineHours > lastHistoryEngineHours &&
      intervalDifference == 0
    )
      lastInterval -= minIntervalValue ?? 0;

    let equipmentLastInterval;
    curentMaintenanceHistory && lastHistoryEngineHours >= lastInterval
      ? (equipmentLastInterval = lastInterval + minIntervalValue)
      : (equipmentLastInterval = lastInterval);

    let nextIntervals: any[] = [];

    for (let i = 0; i < 3; i++) {
      const allIntervals = filteredHystory?.concat(nextIntervals);
      const lastHistoryInterval =
        allIntervals && allIntervals.length > 0
          ? allIntervals?.sort(
              (crt, next) => crt.intervalValue - next.intervalValue,
            )[allIntervals.length - 1].intervalValue
          : 0;

      let nextInterval = equipmentLastInterval;

      while (lastHistoryInterval >= nextInterval) {
        nextInterval += minIntervalValue;
      }

      let intervals = everyHoursData.filter(
        item => nextInterval % item.intervalValue == 0,
      );

      const checkList = intervals
        .map(item =>
          item.maintenanceCheckList.filter(
            e => !e.isFirstTimeCheck || item.intervalValue === nextInterval,
          ),
        )
        .flat();

      intervals &&
        nextIntervals.push(
          intervals.map(
            (item, index) =>
              index == 0 && {
                hierarchyPosition: item.hierarchyPosition,
                intervalType: item.intervalType,
                intervalValue: nextInterval,
                maintenanceCheckList: checkList,
                sortOrder: item.sortOrder,
              },
          )[0],
        );
    }

    return nextIntervals;
  };

  const toggleDialog = () => props.setVisible(!props.isVisible);

  React.useEffect(() => {
    try {
      let everyHoursData = maintenanceHistoryData.maintenanceIntervals?.filter(
        item => item.intervalType === 'Every X Hours',
      );

      const nextIntervals = getEveryHoursNextIntervals(everyHoursData);

      const filteredData = maintenanceHistoryData.maintenanceIntervals?.filter(
        item => item.intervalType !== 'Every X Hours',
      );

      const finalData = [...nextIntervals, ...(filteredData ?? [])];
      setMaintenanceIntervals(finalData);
    } catch (err) {
      console.log(err);
    }
  }, [props.equipmentDetails]);

  return (
    <Dialog className="modal-form">
      <div className="modal-container relative">
        <span className="back-button absolute" onClick={toggleDialog}>
          <BackIcon />
        </span>
        <div className="m-b-8">
          {props.equipmentDetails.nickName || props.equipmentDetails.model}
        </div>
        <h1>{t('maintenance_history_track')}</h1>
        <EquipmentDetails
          eqipmentDetails={props.equipmentDetails}
          openEngineHoursUpdateDialog={props.openEngineHoursUpdateDialog}
          setOpenEngineHoursUpdateDialog={props.setOpenEngineHoursUpdateDialog}
        />
        <h3 className="info-panel-list-header">
          {t('maintenance_select_interval')}
        </h3>
        <div className="info-panel-list">
          <div className="info-panel-list-items scroll-area">
            {maintenanceIntervals &&
              maintenanceIntervals.map((maintenanceInterval, index) => (
                <MaintenanceIntervalCard
                  maintenanceInterval={maintenanceInterval}
                  index={index}
                  key={index}
                  visible={visibleDialog}
                  setVisible={setVisibleDialog}
                  closeTrackMaintenanceDialog={toggleDialog}
                  openEngineHoursUpdateDialog={
                    props.openEngineHoursUpdateDialog
                  }
                  setOpenEngineHoursUpdateDialog={
                    props.setOpenEngineHoursUpdateDialog
                  }
                />
              ))}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
