import { createContext } from 'react';
import { IAccessSharingUserData } from 'types/IAccessSharingUser';

export interface IShareAccessData {
  selectedEquipments: string[];
  fleetName: string;
  selectedUsers: IAccessSharingUserData[];
}

export interface IShareAccessContext {
  setShareAccessData: (newValue: IShareAccessData) => void;
  shareAccessData: IShareAccessData;
}

export const ShareAccessContext = createContext({} as IShareAccessContext);
