import React, { createContext, useContext, useEffect, useState } from 'react';
import { Context } from './types';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';
import { MobileSettingsService } from 'ajax/services';
import { AdSettings, FeatureName } from 'types/entities/MobileSettings';

const MobileSettingsContext = createContext<Context>({} as Context);

interface Props {
  children: React.ReactNode;
}
export function MobileSettingsProvider({ children }: Props) {
  const { addErrorModal } = React.useContext(GlobalErrorContext);

  const [isLoading, setIsLoading] = useState(true);
  const [ad, setAd] = useState<AdSettings>();
  const [disabledFeatureFlags, setDisabledFeatureFlags] = useState<
    FeatureName[]
  >([]);
  const [minVersionAndroid, setMinVersionAndroid] = useState<string>();
  const [minVersionIOS, setMinVersionIOS] = useState<string>();

  useEffect(() => {
    async function fetchData() {
      try {
        const { data: settings } = await MobileSettingsService.getSettings();
        const { ad, disabledFeatureFlags, minVersionAndroid, minVersionIOS } =
          settings;

        setAd(ad);
        setDisabledFeatureFlags(disabledFeatureFlags);
        setMinVersionAndroid(minVersionAndroid);
        setMinVersionIOS(minVersionIOS);
      } catch (e) {
        addErrorModal();
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  const value = {
    isLoading,
    ad,
    disabledFeatureFlags,
    minVersionAndroid,
    minVersionIOS,
  };

  return (
    <MobileSettingsContext.Provider value={value}>
      {children}
    </MobileSettingsContext.Provider>
  );
}

const useMobileSettingsContext = () => useContext(MobileSettingsContext);
export default useMobileSettingsContext;
