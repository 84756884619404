import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import {
  ill_inhibit_disabled as StarterDisabledIcon,
  ill_inhibit_enabled as StarterEnabledIcon,
} from 'app/widgets/SVGs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InhibitRestartConfirmDialog } from '../Dialog/InhibitRestartConfirmDialog';

interface InhibitRestartStatusProps {
  isEnabled?: boolean;
  isConfirmDialogVisible: boolean;
  isLoading: boolean;
  setIsConfirmDialogVisible: (v: boolean) => void;
  equipmentNickName?: string;
  equipmentModelIconUrl?: string;
  onSendCommand: (isCancel: boolean) => void;
  equipmentModel?: string;
  equipmentIdentifierType?: string;
  equipmentPinOrSerial?: string;
}
export function InhibitRestartStatus(props: InhibitRestartStatusProps) {
  const { t } = useTranslation();
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  // mount
  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  const {
    isEnabled,
    isConfirmDialogVisible,
    isLoading,
    setIsConfirmDialogVisible,
    equipmentNickName,
    equipmentModelIconUrl,
    onSendCommand,
    equipmentModel,
    equipmentIdentifierType,
    equipmentPinOrSerial,
  } = props;

  return (
    <>
      <h1>{t('inhibit_restart_title')}</h1>
      <div className="inline-label-text">
        <span className="label">{t('equipment_model')}</span>
        <span className="text">{equipmentModel}</span>
      </div>
      <div className="inline-label-text">
        <span className="label">
          {equipmentIdentifierType === 'Pin'
            ? t('equipment_pin')
            : t('equipment_serial')}{' '}
        </span>
        <span className="text">{equipmentPinOrSerial}</span>
      </div>
      <div className="separator-xl"></div>
      <div>
        <div className="inhebit-svg">
          {isEnabled ? <StarterEnabledIcon /> : <StarterDisabledIcon />}
        </div>
      </div>
      <div>
        <div>
          <div className="separator-l"></div>
          <h3>
            {t(
              `${
                isEnabled
                  ? 'inhibit_restart_enabled_status_message'
                  : 'inhibit_restart_disabled_status_message'
              }`,
            )}
          </h3>
        </div>
        <div className="separator-l"></div>
        <div>
          <Button
            onClick={() => {
              setIsConfirmDialogVisible(true);
            }}
            themeColor={`${isEnabled ? 'error' : 'success'}`}
            className="inhibit-restart-button"
          >
            {t(
              `${
                isEnabled
                  ? 'inhibit_restart_btn_disable_starting'
                  : 'inhibit_restart_btn_enable_starting'
              }`,
            )}
          </Button>
        </div>
        <div className="separator-m"></div>
        <div>
          <p>
            {t(
              `${
                isEnabled
                  ? 'inhibit_restart_enabled_description_message'
                  : 'inhibit_restart_disabled_description_message'
              }`,
            )}
          </p>
        </div>
      </div>
      {isConfirmDialogVisible && (
        <Dialog className="modal-center modal-remove-item modal-fulltext">
          <InhibitRestartConfirmDialog
            isEnable={!isEnabled}
            isLoading={isLoading}
            modelIconUrl={equipmentModelIconUrl}
            nickName={equipmentNickName}
            close={() => setIsConfirmDialogVisible(false)}
            onSendCommand={onSendCommand}
          />
        </Dialog>
      )}
    </>
  );
}
