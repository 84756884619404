import React, { useId } from 'react';
import './TextField.less';
import { Input, InputProps } from '@progress/kendo-react-inputs';
import { Label, Hint, Error } from '@progress/kendo-react-labels';
import clsx from 'clsx';

interface Props extends InputProps {
  error?: string;
  helperText?: string;
}

export default function TextField({
  label,
  required,
  error,
  helperText,
  className,
  autoComplete = 'off',
  ...props
}: Props) {
  const inputId = useId();

  return (
    <div className={clsx('text-field', className)}>
      {label && (
        <Label
          editorId={inputId}
          className={clsx('text-field__label', {
            'text-field__label_required': required,
          })}
        >
          {label}
        </Label>
      )}

      <Input
        {...props}
        id={inputId}
        // it highlights it red event if the field isn't touched. I have no idea why.
        required={false}
        defaultValue=""
        className={clsx('text-field__input', {
          'text-field__input_error': error,
        })}
        autoComplete={autoComplete}
      />

      {helperText && (
        <Hint className="text-field__helper-text">{helperText}</Hint>
      )}

      {error && <Error className="text-field__error">{error}</Error>}
    </div>
  );
}
