import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import ChevronRightIcon from 'assets/images/chevron_right.svg';
import LegalListingIcon from 'assets/images/ic_listing_legal.svg';
import { getLocale } from 'locales/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getPrivacyPolicyUrl, gettermsAndConditionsUrl } from 'utils/link-urls';

export function LegalTermsPage() {
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  const { t } = useTranslation();
  const locale = getLocale();

  // mount
  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  const handleTermsClick = () => {
    var href = gettermsAndConditionsUrl(locale);
    window.open(href, '_blank');
  };

  const handlePolicyClick = () => {
    var href = getPrivacyPolicyUrl(locale);
    window.open(href, '_blank');
  };

  return (
    <div className="box-content no-left-right-paddings legal-terms box-content-full-height">
      <h2 className="legal-terms-headers">
        {t('legal_terms_preference_title')}
      </h2>
      <div className="arrow-links arrow-links-no-border">
        <div className="arrow-links_item" onClick={() => handleTermsClick()}>
          <img
            className="arrow-links_icon item-left"
            src={LegalListingIcon}
          ></img>
          <span className="arrow-links_title">{t('terms_of_use')}</span>
          <img
            className="arrow-links_icon arrow-links_icon-right"
            src={ChevronRightIcon}
          />
        </div>
        <div className="arrow-links_item" onClick={() => handlePolicyClick()}>
          <img
            className="arrow-links_icon item-left"
            src={LegalListingIcon}
          ></img>
          <span className="arrow-links_title">{t('privacy_policy')}</span>
          <img
            className="arrow-links_icon arrow-links_icon-right"
            src={ChevronRightIcon}
          />
        </div>
      </div>
    </div>
  );
}
