import { IAccessSharingPermissionType } from 'types/IAccessSharingUser';

export const getUserRoleString = role => {
  switch (role) {
    case IAccessSharingPermissionType.Viewer:
      return 'Viewer';
    case IAccessSharingPermissionType.Editor:
      return 'Editor';
    case IAccessSharingPermissionType.Admin:
      return 'Admin';
    default:
      return 'Unknown';
  }
};
