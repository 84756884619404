import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { ajax } from 'ajax/ajax';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';
import { ill_delete as DeleteIcon } from 'app/widgets/SVGs';
import IcExternalLink from 'assets/images/ic_external_link.svg';
import { getLocale } from 'locales/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { tagEvent } from 'utils/ga';
import { getPrivacyPolicyUrl } from 'utils/link-urls';
import { RequestSubmitedDialog } from './SubmitedRequestDialog';

interface ICloseDialogProps {
  close: () => void;
}

export function DeleteAccountConfirmationDialog(props: ICloseDialogProps) {
  const { addErrorModal } = React.useContext(GlobalErrorContext);
  const [showDialog, setSowDialog] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('');
  const [message, setMessage] = React.useState<string>('');

  const { t } = useTranslation();

  const handleClick = async () => {
    await ajax
      .delete(`${ajax.appBaseUrl}/oauth/user`)
      .then(() => {
        setTitle(() => t('request_submited_title'));
        setMessage(() => t('request_submited_message'));
        tagEvent('delete_account');
        setSowDialog(true);
      })
      .catch(error => {
        if (error.response.data.errorCode == 'DeletionAlreadyRequested') {
          setTitle(t('request_already_submited_title'));
          setMessage(t('request_already_submited_message'));
          setSowDialog(true);
        } else {
          addErrorModal();
        }
      });
  };

  const locale = getLocale();
  var href = getPrivacyPolicyUrl(locale);

  return (
    <>
      <Dialog className="modal-center modal-remove-item modal-fulltext">
        <div className="remove-item-dialog-content">
          <DeleteIcon />
          <h3>{t('delete_account_title')}</h3>

          <span className="message">
            <span>{t('delete_account_message')}</span>{' '}
            <a href={href} target="_blank" rel="noreferrer">
              {t('click_here')}
            </a>
            <img src={IcExternalLink}></img>
          </span>
          <div className="row">
            <div className="col-xxs-6">
              <Button onClick={props.close}>{t('cancel')}</Button>
            </div>
            <div className="col-xxs-6">
              <Button onClick={() => handleClick()} themeColor="error">
                {t('menu_delete')}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      {showDialog && (
        <RequestSubmitedDialog
          closeRequestSubmittedDialog={() => setSowDialog(false)}
          closeConfirmationDialog={() => props.close()}
          title={title}
          message={message}
        />
      )}
    </>
  );
}
