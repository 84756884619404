import { KubotaAppBar } from 'app/components/AppBar';
import { KubotaMenu } from 'app/components/KubotaMenu';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import _ from 'lodash';
import * as React from 'react';

interface ICardAndImage {
  children: JSX.Element;
  useTheEntireSpace?: boolean;
}

export function MenuAndContent(props: ICardAndImage) {
  const { classNames } = React.useContext(LayoutContext);
  const classString = _.uniq(
    _.map(_.orderBy(classNames, ['index'], ['asc']), cls => cls.class),
  ).join(' ');

  return (
    <>
      <div className={`layout layout-menu-and-content ${classString}`}>
        <div className="layout_menu">
          <KubotaMenu />
        </div>
        <div className="layout_container">
          <div className="site-header">
            <KubotaAppBar />
          </div>
          {props.useTheEntireSpace ? (
            <>{props.children}</>
          ) : (
            <div className="site-content">
              <div className="container">{props.children}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
