import { Action } from './types';
import { useCallback } from 'react';
import { accessSharingPermissions } from './constants';
import { AccessSharingRole } from 'types/entities/AccessSharingRole';

export default function useAccessSharingPermissions(
  accessSharingRole?: AccessSharingRole,
) {
  return useCallback(
    <T extends Action>(
      action: T,
      role: AccessSharingRole | undefined = accessSharingRole,
    ) => {
      if (!role) {
        return false;
      }

      return !!accessSharingPermissions[role][action];
    },
    [],
  );
}
