import { createContext } from 'react';

export interface INotificationContext {
  addNotification: any;
  addSnackbarNotification: any;
  removeNotification: any;
  removeSnackbarNotification: any;
  notifications: any[];
  snackbarNotification: any;
}

export const NotificationContext = createContext({} as INotificationContext);
