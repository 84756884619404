import React from 'react';

export default function ArrowBackIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="ic_nav_back"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={props.width ?? '32'}
      height={props.height ?? '32'}
      {...props}
    >
      <rect id="Box" width="32" height="32" fill="none" />
      <path
        id="ic_nav_up"
        d="M16.465,8.482H1.483"
        transform="translate(7.518 7.518)"
        fill="none"
        stroke={props.stroke ?? '#1a1a1a'}
        strokeLinecap="round"
        strokeWidth={props.strokeWidth ?? '2'}
      />
      <path
        id="Path_470"
        data-name="Path 470"
        d="M23.676,16.018,16.2,23.748l7.477,7.73"
        transform="translate(-7.676 -7.748)"
        fill="none"
        stroke={props.stroke ?? '#1a1a1a'}
        strokeLinecap="round"
        strokeWidth={props.strokeWidth ?? '2'}
      />
    </svg>
  );
}
