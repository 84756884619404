import { createContext } from 'react';

export interface ILayoutContext {
  setClassNames: (classNames: string) => number;
  classNames: { class: string; index: number }[];
  removeClassNames: (index: number) => void;
  removeSpecificClass: (className: string) => void;
}

export const LayoutContext = createContext<ILayoutContext>(
  {} as ILayoutContext,
);
