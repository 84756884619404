import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { isCanada } from 'app/common/websiteCountry';
import { Card, Menu, MenuItem } from '@progress/kendo-react-layout';
import { ic_account_language as IconLanguage } from 'app/widgets/SVGs';
import ArrowDownImage from '../../../assets/images/chevron_down.svg';
import { useNavigate } from 'react-router-dom';
import ClickOutside from 'app/widgets/ClickOutside';

export function LoginHeader() {
  const { t, i18n } = useTranslation();
  const [isLanguageExpanded, setIsLanguageExpanded] = React.useState(false);
  const [language, setLanguage] = React.useState('');

  const navigate = useNavigate();

  const changeLanguage = (selectedValue: string) => {
    const selectedLanguage = selectedValue;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage);
  };

  const onNavigateClicked = item => {
    setIsLanguageExpanded(false);
    switch (item.item.data) {
      case 'language':
        setIsLanguageExpanded(() => !isLanguageExpanded);
        break;
    }
  };

  React.useEffect(() => {
    const storedLanguage = localStorage.getItem('language');

    if (storedLanguage == null) {
      setLanguage(navigator?.languages?.[0]?.includes('fr') ? 'fr' : 'en');
      i18n.changeLanguage('en');
      localStorage.setItem('language', 'en');
    } else {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  return (
    <>
      <span
        className={`site-logo ${isCanada ? 'website-CA' : ''}`}
        onClick={() => {
          navigate('/login', {
            state: { showLandingPage: true },
          });
        }}
      >
        <img src="/logo.png" alt="logo" />
      </span>
      <div className="landing-header">
        <ClickOutside onClickOutside={() => setIsLanguageExpanded(false)}>
          <Menu vertical onSelect={onNavigateClicked} className="btn-display">
            <MenuItem
              data="language"
              render={() => (
                <div className="align-inline margin">
                  <span className="align-inline">
                    <span className="link-icon">{<IconLanguage />}</span>
                    <span className="link-text">
                      <div className="language-component language-menu-dropdown">
                        <span>
                          <span className="selected-language">
                            {language.toLocaleUpperCase().slice(0, 2)}
                          </span>
                        </span>
                        {isLanguageExpanded && (
                          <Card className="top-card top-card-language-setting language-menu-dropdown-options">
                            <div className="">
                              <span
                                onClick={() => {
                                  changeLanguage('en');
                                }}
                              >
                                {t('language_EN')}
                              </span>
                              <span
                                onClick={() => {
                                  changeLanguage('fr');
                                }}
                              >
                                {t('language_FR')}
                              </span>
                            </div>
                          </Card>
                        )}
                      </div>
                    </span>
                  </span>
                  <span className="link-arrow">
                    <img src={ArrowDownImage} alt="right arrow"></img>
                  </span>
                </div>
              )}
            />
          </Menu>
        </ClickOutside>
      </div>
    </>
  );
}
