import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import * as React from 'react';
import { useLocation } from 'react-router';

interface IKeepDataClean {}

export function KeepDataClean(props: IKeepDataClean) {
  const { equipmentListData: data, setEquipmentListData: setData } =
    React.useContext(EquipmentListContext);
  const location = useLocation();

  React.useEffect(() => {
    if (
      !location.pathname.includes('/equipment') &&
      (data.selectedIndex || data.selectedIndex === 0)
    ) {
      setData(oldValue => ({
        ...oldValue,
        searchValue: '',
        selectedEquipment: undefined,
        selectedGeofence: undefined,
      }));
    }
  }, [location.pathname]);
  return <></>;
}
