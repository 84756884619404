import { Dialog } from '@progress/kendo-react-dialogs';
import CloseIcon from 'assets/images/ic_close_small.svg';
import React from 'react';
import { tagEvent } from 'utils/ga';
import { AdContext } from '../ReactContexts/adContext';

export function LargeBanner() {
  const { adData } = React.useContext(AdContext);
  const [shouldShowLargeAdBanner, setShouldShowLargeAdBanner] =
    React.useState(false);

  const [bannerImageSize, setBannerImageSize] = React.useState({
    width: 950,
    height: 600,
    right: 0,
  });
  const [bannerImageAspectRatio, setBannerImageAspectRatio] = React.useState(
    3 / 2,
  );

  const updateBannerImageSizes = () => {
    var usedHeight = 0,
      usedWidth = 0,
      right = 0;
    if (window.innerHeight < window.innerWidth) {
      usedHeight = (window.innerHeight * 2) / 3 - 100;
      usedWidth = bannerImageAspectRatio * usedHeight;
      if (usedWidth > 0.98 * window.innerWidth) {
        usedWidth = 0.98 * window.innerWidth;
        usedHeight = usedWidth / bannerImageAspectRatio;
      }
      right = 0.02 * window.innerWidth;
    } else {
      usedWidth = window.innerWidth;
      usedHeight = usedWidth / bannerImageAspectRatio;
    }
    if (usedHeight > window.innerHeight / 2 && window.innerHeight < 650) {
      usedHeight = window.innerHeight / 2;
    }
    setBannerImageSize({
      width: usedWidth,
      height: usedHeight,
      right,
    });
  };

  React.useEffect(() => {
    const handleResize = () => {
      updateBannerImageSizes();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [bannerImageAspectRatio]);

  React.useEffect(() => {
    updateBannerImageSizes();
  }, [bannerImageAspectRatio]);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        localStorage.setItem('justLoggedOn', 'false');
        setShouldShowLargeAdBanner(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  const isAdLoaded = () => {
    return (
      !!adData?.largeBannerAd?.buttonLabel &&
      !!adData?.largeBannerAd?.buttonUrl &&
      !!adData?.largeBannerAd?.description &&
      !!adData?.largeBannerAd?.imageUrl &&
      !!adData?.largeBannerAd?.title
    );
  };

  React.useEffect(() => {
    if (isAdLoaded()) {
      var img = new Image();
      img.src = adData!.largeBannerAd!.imageUrl;
      img.onload = () => {
        var width = img.naturalWidth;
        var height = img.naturalHeight;
        var aspectRatio = width / height;
        setBannerImageAspectRatio(aspectRatio);
        setShouldShowLargeAdBanner(true);
      };
    }
  }, [adData?.largeBannerAd]);

  return (
    <div>
      {shouldShowLargeAdBanner && (
        <>
          <Dialog
            className="large-banner"
            style={{
              width: `${bannerImageSize.width}px`,
              maxHeight: `${window.innerHeight}px`,
              minWidth: '300px',
              marginRight: `${bannerImageSize.right}px`,
            }}
          >
            <div
              className="close-button"
              onClick={() => {
                localStorage.setItem('justLoggedOn', 'false');
                setShouldShowLargeAdBanner(false);
              }}
            >
              <img src={CloseIcon} alt="" />
            </div>
            <img
              src={adData?.largeBannerAd?.imageUrl}
              style={{
                width: `${bannerImageSize.width}px`,
                height: `${bannerImageSize.height}px`,
                maxHeight: `${bannerImageSize.height}px`,
                minWidth: '300px',
              }}
            ></img>
            <div className="large-banner-title">
              {adData?.largeBannerAd?.title}
            </div>
            <div className="large-banner-desc">
              {adData?.largeBannerAd?.description}
            </div>
            <div
              className="large-banner-button"
              onClick={() => {
                tagEvent('large_ad_click', {
                  clicked: adData?.largeBannerAd,
                });
              }}
            >
              <a
                href={adData?.largeBannerAd?.buttonUrl}
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                {adData?.largeBannerAd?.buttonLabel}
              </a>
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
}
