import React from 'react';
import { useTranslation } from 'react-i18next';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import NoPendingInvitations from '../../../../../assets/images/ill_empty_access_sharing_invite_received.svg';
import PendingReceivedInvitationRow from './PendingReceivedInvitationRow';

interface IPendingReceivedInvitations {
  isMobile: boolean;
}

function PendingReceivedInvitations(props: IPendingReceivedInvitations) {
  const { t } = useTranslation();
  const { equipmentListData: data } = React.useContext(EquipmentListContext);

  return (
    <div className="access-sharing-subcontainer">
      <div className="access-sharing-subcontainer-header">
        <h3>{t('pending_received_invitations_title')}</h3>
      </div>
      <div className="access-sharing-subcontainer-body">
        {data.equipmentList?.length == 0 ? (
          <div className="access-sharing-subcontainer-body-empty">
            <div className="access-sharing-body-picture">
              <img src={NoPendingInvitations}></img>
            </div>
            <div className="access-sharing-body-text">
              <h4>{t('pending_no_received_invitations_title')}</h4>
              <p>{t('pending_no_received_invitations_text')}</p>
            </div>
          </div>
        ) : (
          <div className="access-sharing-subcontainer-body-table">
            <div className="access-sharing-subcontainer-body-table-header pending-received-invitation">
              <div>{t('equipment_or_fleet')}</div>
              <div>{t('role')}</div>
              <div>{t('invited_by')}</div>
              <div>{t('start_time')}</div>
              <div>{t('end_time')}</div>
              <div></div>
            </div>
            <div className="access-sharing-subcontainer-body-table-body pending-received-invitation">
              {data.equipmentList?.map(equipment => {
                return (
                  <PendingReceivedInvitationRow isMobile={props.isMobile} />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PendingReceivedInvitations;
