import { CacheContext } from 'app/components/ReactContexts/cacheContext';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { IEquipmentEntry } from 'types/IEquipmentEntry';

interface IProps {
  currentEquipment: IEquipmentEntry;
}

export function CompatibleMachines(props: IProps) {
  const [models, setModels] = React.useState<any[]>([]);
  const [categories, setCategories] = React.useState<any[]>([]);
  const [currentModel, setCurrentModel] = React.useState<any>();
  const [compatibleMachines, setCompatibleMachines] = React.useState<any[]>([]);
  const [rootParents, setRootParents] = React.useState<number[]>([]);
  const { getApiModels } = React.useContext(CacheContext);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const scrolableRef = React.useRef(null as null | HTMLDivElement);
  const posRef = React.useRef({ top: 0, left: 0, x: 0, y: 0 });

  const posMovementRef = React.useRef<{
    maxDistanceFromPos: number;
  }>({ maxDistanceFromPos: 0 });

  const mouseMoveHandler = function (e) {
    // How far the mouse has been moved
    const dx = e.clientX - posRef.current.x;
    const dy = e.clientY - posRef.current.y;

    if (
      Math.abs(dx) + Math.abs(dy) >
      posMovementRef.current.maxDistanceFromPos
    ) {
      posMovementRef.current.maxDistanceFromPos = Math.abs(dx) + Math.abs(dy);
    }

    // Scroll the element
    scrolableRef.current!.scrollTop = posRef.current.top - dy;
    scrolableRef.current!.scrollLeft = posRef.current.left - dx;
  };

  const mouseUpHandler = function () {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    scrolableRef.current!.style.cursor = 'grab';
    scrolableRef.current!.style.removeProperty('user-select');
  };

  const mouseDownHandler = function (e) {
    posRef.current = {
      // The current scroll
      left: scrolableRef.current!.scrollLeft,
      top: scrolableRef.current!.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    posMovementRef.current = { maxDistanceFromPos: 0 };

    scrolableRef.current!.style.cursor = 'grabbing';
    scrolableRef.current!.style.userSelect = 'none';

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const selectedEquipment = props.currentEquipment;

  const getApiModelsRef = React.useRef(0);

  React.useEffect(() => {
    (async () => {
      setCategories([]);
      setModels([]);
      setCurrentModel(undefined);
      const newNumber = getApiModelsRef.current + 1;
      getApiModelsRef.current = newNumber;
      const apiModel = await getApiModels();
      if (getApiModelsRef.current !== newNumber) {
        return;
      }
      setCategories(apiModel.categories);
      setModels(apiModel.models);
      setCurrentModel(
        apiModel.models.filter(
          model => model.model == selectedEquipment.model,
        )[0],
      );
    })();
  }, [selectedEquipment.model, i18n.language]);

  React.useEffect(() => {
    if (models && categories && currentModel) {
      var compatibleModels = models?.filter(
        m =>
          m.compatibleAttachments &&
          m.compatibleAttachments.includes(selectedEquipment.model),
      );
      var compatibleMachinesParents: any[] = [];

      if (compatibleModels) {
        for (let i = 0; i < compatibleModels.length; i++) {
          const parents: any[] = [];
          var currentParentId = compatibleModels[i].categoryId;
          while (currentParentId !== undefined && currentParentId != null) {
            const currentParent = categories?.filter(
              // eslint-disable-next-line no-loop-func
              c => c.id === currentParentId,
            )[0];
            currentParentId = currentParent?.parentId;
            parents.unshift(currentParent.id);
          }

          compatibleMachinesParents.push({
            model: compatibleModels[i],
            parents: parents,
          });
        }

        setCompatibleMachines(
          compatibleMachinesParents.map(item => item.model),
        );
        setRootParents(() => getRootParents(compatibleMachinesParents));
      }
    }
  }, [currentModel]);

  function getChildrenCount(categoryId) {
    let count = 0;
    if (
      compatibleMachines &&
      compatibleMachines?.some(m => m.categoryId == categoryId)
    ) {
      return compatibleMachines.filter(m => m.categoryId == categoryId).length;
    } else {
      let childrenCategories = categories?.filter(
        c => c.parentId == categoryId,
      );
      if (childrenCategories) {
        for (let i = 0; i < childrenCategories?.length; i++) {
          count += getChildrenCount(childrenCategories[i].id);
        }
      }
    }
    return count;
  }

  function getRootParents(compatibleAttachmentsParents) {
    if (
      !compatibleAttachmentsParents ||
      compatibleAttachmentsParents.length === 0
    ) {
      return [];
    }
    const commonSet: number[] = Array.from(
      new Set(compatibleAttachmentsParents.map(item => item.parents[1])),
    );

    return commonSet;
  }

  const getCompatibleCategories = () => {
    const compatibleCategories = categories.filter(category =>
      rootParents.includes(category.id),
    );
    return compatibleCategories;
  };

  return (
    <div className="m-b-40">
      {compatibleMachines && compatibleMachines.length > 0 && (
        <div className="equipment-content-actions">
          <div className="row">
            <div className="col-xxs-8">
              <h4>{t('compatible_machines')}</h4>
            </div>
            <div className="col-xxs-4">
              <Link
                to={{ pathname: '/resources/39' }}
                className="equipment-content-actions-viewAll"
                onClick={e => {
                  e.preventDefault();
                  navigate(`/resources/`, {
                    state: {
                      modelFilter: currentModel.model,
                      categories: categories,
                      compatibleModels: compatibleMachines,
                      models: models,
                      commonParentCategories: rootParents,
                    },
                  });
                }}
              >
                {t('attachment_slider_item_view_all')}
              </Link>
            </div>
          </div>
          <div className="attachments-slider-wrapper">
            <div className="box-for-border"></div>
            <div
              className="attachments-slider"
              ref={scrolableRef}
              onMouseDown={mouseDownHandler}
            >
              {getCompatibleCategories().map(category => (
                <div className="attachments-image" key={category.name}>
                  {/* <img src={category.iconUrl} /> */}
                  <div
                    className="image"
                    style={{ backgroundImage: `url(${category.iconUrl})` }}
                  ></div>
                  <div
                    className="attachments-hover-box"
                    onClick={() => {
                      if (posMovementRef.current.maxDistanceFromPos < 10) {
                        navigate('/resources/' + category.id, {
                          state: { modelFilter: currentModel.model },
                        });
                      }
                      posMovementRef.current = { maxDistanceFromPos: 0 };
                    }}
                  >
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${category.iconUrl})` }}
                    >
                      {/* <img src={category.iconUrl} /> */}
                    </div>
                    <div className="text">{category.name}</div>
                    <div className="number">
                      {getChildrenCount(category.id)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="separator-l"></div>
        </div>
      )}
    </div>
  );
}
