import { createContext } from 'react';
import { IMaintenanceHistoryEntry } from 'types/IMaintenanceHistoryEntry';
import { IMaintenanceSchedule } from 'types/IMaintenanceSchedule';

export interface IMaintenanceHistoryData {
  selectedEquipmentMaintenanceHistory?: IMaintenanceHistoryEntry[];
  maintenanceIntervals?: IMaintenanceSchedule[];
  selectedInterval?: IMaintenanceSchedule;
  selectedModel?: string;
  hasNewHistoryItem: boolean;
}

export interface IMaintenanceHistoryContext {
  setMaintenanceHistoryData: (newValue: IMaintenanceHistoryData) => void;
  maintenanceHistoryData: IMaintenanceHistoryData;
}

export const MaintenanceHistoryContext = createContext(
  {} as IMaintenanceHistoryContext,
);
