import { AccessSharingRole } from 'types/entities/AccessSharingRole';
import { RolePermissions } from './types';

export const accessSharingPermissions: RolePermissions = {
  [AccessSharingRole.Viewer]: {
    addNewUser: false,
    revokeOwnAccess: true,
    revokeAllUsersAccess: false,
    manageUserAccess: false,
    manageFleet: false,
    viewLogs: false,
  },
  [AccessSharingRole.Editor]: {
    addNewUser: false,
    revokeOwnAccess: true,
    revokeAllUsersAccess: false,
    manageUserAccess: false,
    manageFleet: false,
    viewLogs: false,
  },
  [AccessSharingRole.Admin]: {
    addNewUser: true,
    revokeOwnAccess: true,
    revokeAllUsersAccess: false,
    manageUserAccess: true,
    manageFleet: false,
    viewLogs: true,
  },
  [AccessSharingRole.Owner]: {
    addNewUser: true,
    revokeOwnAccess: false,
    revokeAllUsersAccess: true,
    manageUserAccess: true,
    manageFleet: true,
    viewLogs: true,
  },
};
