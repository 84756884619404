import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getMaintenanceIntervalTitle } from '../../maintenanceUtils';
import { CompletedMaintenanceDialog } from '../CompleteMaintenanceTrack';

export function MaintenanceIntervalCard(props) {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = React.useState(null);

  const title = getMaintenanceIntervalTitle(
    props.maintenanceInterval.intervalType,
    props.maintenanceInterval.intervalValue,
    t,
  );

  const handleClick = item => setSelectedItem(item);
  const handleClose = () => setSelectedItem(null);

  return (
    <>
      <div className="clickable-row" onClick={handleClick}>
        <div>{title}</div>
        <div>
          <div className="arrow-right">
            <svg
              id="chevron_right"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <rect id="Box" width="20" height="20" fill="none" />
              <path
                id="Vector"
                d="M0,9.467,4.72,4.733,0,0"
                transform="translate(7.641 5.266)"
                fill="none"
                stroke="#dc4405"
                stroke-linecap="round"
                stroke-width="1.7"
              />
            </svg>
          </div>
        </div>
      </div>
      {selectedItem && (
        <CompletedMaintenanceDialog
          maintenanceInterval={props.maintenanceInterval}
          closeDialog={handleClose}
          closeTrackMaintenanceDialog={props.closeTrackMaintenanceDialog}
          action={'new'}
          openEngineHoursUpdateDialog={props.openEngineHoursUpdateDialog}
          setOpenEngineHoursUpdateDialog={props.setOpenEngineHoursUpdateDialog}
        />
      )}
    </>
  );
}
