import * as React from 'react';
import { useTranslation } from 'react-i18next';

export function NoMaintenanceHistory() {
  const { t } = useTranslation();

  return (
    <div>
      <svg
        className="m-b-30"
        id="ill_empty_history"
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        height="120"
        viewBox="0 0 120 120"
      >
        <rect id="BG" width="120" height="120" rx="60" fill="#f7f7f7" />
        <g id="task" transform="translate(-400 -286.002)">
          <path
            id="Vector"
            d="M0,0H25"
            transform="translate(435.5 364.75)"
            fill="none"
            stroke="#909090"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0H25"
            transform="translate(435.5 347.25)"
            fill="none"
            stroke="#909090"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H25"
            transform="translate(435.5 329.75)"
            fill="none"
            stroke="#909090"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M0,5,2.5,7.5,10,0"
            transform="translate(474.5 324.75)"
            fill="none"
            stroke="#909090"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M0,5,2.5,7.5,10,0"
            transform="translate(474.5 342.25)"
            fill="none"
            stroke="#909090"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <path
            id="Vector-6"
            data-name="Vector"
            d="M0,5,2.5,7.5,10,0"
            transform="translate(474.5 359.75)"
            fill="none"
            stroke="#909090"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <path
            id="Vector-7"
            data-name="Vector"
            d="M0,0H60V60H0Z"
            transform="translate(428 316)"
            fill="none"
            opacity="0"
          />
        </g>
      </svg>
      <div className="f-sz-18 m-b-8">
        <h3>{t('maintenance_history_empty')}</h3>
      </div>
      <div>
        <p>{t('maintenance_history_empty_message')}</p>
      </div>
    </div>
  );
}
