import { createContext } from 'react';

export interface IUserLocation {
  location?: GeolocationPosition;
}

export interface IUserLocationContext {
  setUserLocation: (newValue: IUserLocation) => void;
  userLocation?: IUserLocation;
}

export const UserLocationContext = createContext({} as IUserLocationContext);
