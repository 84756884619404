import * as React from 'react';

export function UndoButton(props: { onClick: any }) {
  return (
    <span onClick={props.onClick} className="back-button">
      <svg
        id="ic_undo"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 32 32"
        className="undo-button"
      >
        <rect id="Box" width="32" height="32" fill="none" />
        <g id="undo" transform="translate(-104.87 -312.675)">
          <path
            id="Vector"
            d="M3.321,11.07h8.856a5.535,5.535,0,0,0,0-11.07H0"
            transform="translate(112.158 324.59)"
            fill="none"
            stroke="#1a1a1a"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.7"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M2.834,5.668,0,2.834,2.834,0"
            transform="translate(111.87 321.69)"
            fill="none"
            stroke="#1a1a1a"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.7"
          />
        </g>
      </svg>
    </span>
  );
}
