import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { LoadingOverlay } from 'app/components/LoadingOverlay';
import { ill_delete as DeleteIcon } from 'app/widgets/SVGs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface IDeleteGeofenceDialog {
  isLoading: boolean;
  geofenceName?: string;
  close: () => void;
  deleteGeofence: () => void;
}

export function DeleteGeofenceDialog(props: IDeleteGeofenceDialog) {
  const { t } = useTranslation();

  return (
    <Dialog className="modal-center modal-remove-item modal-fulltext">
      <div className="remove-item-dialog-content">
        <div className="image-to-remove">
          <DeleteIcon />
        </div>
        <h3 className="text-center">
          {t('geofence_delete_dialog_message', {
            geofenceName: props?.geofenceName,
          })}
        </h3>
        <div className="row">
          <div className="col-xxs-6">
            <Button disabled={props.isLoading} onClick={props.close}>
              {t('cancel')}
            </Button>
          </div>
          <div className="col-xxs-6">
            <Button
              disabled={props.isLoading}
              onClick={props.deleteGeofence}
              themeColor="error"
            >
              {t('menu_delete')}
              <LoadingOverlay isOpen={props.isLoading} text="" />
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
