import React, { ButtonHTMLAttributes } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import './SubmitButton.less';
import clsx from 'clsx';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
}

export default function SubmitButton({ children, className, ...props }: Props) {
  return (
    <Button
      {...props}
      themeColor="primary"
      className={clsx('submit-button', className)}
    >
      {children}
    </Button>
  );
}
