import * as React from 'react';
import { ajax } from 'ajax/ajax';
import { Switch } from '@progress/kendo-react-inputs';
import { useTranslation } from 'react-i18next';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { chevron_right as ChevronRight } from 'app/widgets/SVGs';
import { UserSettingsContext } from 'app/components/ReactContexts/userSettingsContext';
import { NotificationContext } from 'app/components/ReactContexts/notificationContext';
import { Success } from 'app/components/Notifications';
import {
  EditCompanyNameDialog,
  DeleteAccountConfirmationDialog,
  EditFirstNameDialog,
  EditPhoneNumberDialog,
  EditLastNameDialog,
} from 'app/pages/SettingsPage/Dialogs';
import EditIcon from 'assets/images/ic_maintenance_edit.svg';
import usePhoneMask from 'app/hooks/form-fields/usePhoneMask';
import useFlag from 'app/hooks/useFlag';
import { FeatureName } from 'types/entities/MobileSettings';

export function SettingsPage() {
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [isEditFirstNameDialogOpen, setIsEditFirstNameDialogOpen] =
    React.useState(false);
  const [isEditLastNameDialogOpen, setIsEditLastNameDialogOpen] =
    React.useState(false);
  const [showEditCompanyName, setShowEditCompanyName] = React.useState(false);
  const [showEditPhoneNumber, setShowEditPhoneNumber] = React.useState(false);

  // TODO Should be moved to the appropriate place when API is ready
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('0757997506');

  const { maskedValue: maskedPhoneNumber } = usePhoneMask(phoneNumber);

  const { setClassNames, removeClassNames, removeSpecificClass } =
    React.useContext(LayoutContext);
  const { userSettings, setUserSettings } =
    React.useContext(UserSettingsContext);
  const { addNotification } = React.useContext(NotificationContext);
  const { t } = useTranslation();
  const isSingleSignOnFeatureEnabled = useFlag(FeatureName.SINGLE_SIGN_ON);

  // mount
  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  const postUpdates = (setting: any, value: any) => {
    (async () => {
      const settings = {
        settings: {
          [setting]: value,
        },
      };

      const result = await ajax.post(
        `${ajax.appBaseUrl}/api/user/settings`,
        settings,
      );
      return result;
    })().then(result => {
      if (result.status == 200) {
        addNotification(
          <Success
            text={t('profile_saved')}
            additionalClass="alert-profile-saved"
          />,
          6000,
        );
      }
    });
  };
  const handleMeasurementUnitChange = event => {
    setUserSettings({ ...userSettings, measurementUnit: event.target.value });
    postUpdates('measurementUnit', event.target.value);
  };

  const handleSubscribedToAlertsChange = event => {
    setUserSettings({
      ...userSettings,
      subscribedToAlerts: event.target.value,
    });
    postUpdates('subscribedToAlerts', event.target.value);
  };

  const handleSubscribedToMessagesChange = event => {
    setUserSettings({
      ...userSettings,
      subscribedToMessages: event.target.value,
    });
    postUpdates('subscribedToMessages', event.target.value);
  };

  const handleClick = () => {
    removeSpecificClass('mobile-nav-shown');
  };

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        setShowDeleteDialog(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    <div className="relative">
      <h1>
        {isSingleSignOnFeatureEnabled
          ? t('account_and_settings')
          : t('profile_settings')}
      </h1>

      <div className="form-caption">
        <span>
          {isSingleSignOnFeatureEnabled
            ? t('manage_preferences_and_account')
            : t('manage_preferences')}
        </span>
      </div>
      <div className="box-content">
        <h3>{t('general_settings')}</h3>

        <div className="settings-item">
          <div className="settings-item_label">
            <h4>{t('units')}</h4>
          </div>
          <div className="settings-item_content">
            <div className="select-wrapper">
              <select
                value={userSettings.measurementUnit}
                onChange={handleMeasurementUnitChange}
                onClick={handleClick}
              >
                <option value="METRIC">{t('metric_unit')}</option>
                <option value="US">{t('imperial_unit')}</option>
              </select>
            </div>
          </div>
        </div>
        <div className="separator-xl"></div>
        <h3>{t('notifications')}</h3>

        <div className="settings-item">
          <div className="settings-item_label">
            <h4>{t('alerts')}</h4>
            <span>{t('informationAboutEquipment')}</span>
          </div>
          <div className="settings-item_content">
            <Switch
              checked={userSettings.subscribedToAlerts}
              onChange={handleSubscribedToAlertsChange}
              onLabel=""
              offLabel=""
            />
          </div>
        </div>

        <div className="settings-item">
          <div className="settings-item_label">
            <h4>{t('messages')}</h4>
            <span>{t('promotionsAndAnnouncements')}</span>
          </div>
          <div className="settings-item_content">
            <Switch
              checked={userSettings.subscribedToMessages}
              onChange={handleSubscribedToMessagesChange}
              onLabel=""
              offLabel=""
            />
          </div>
        </div>

        <div className="separator-xl"></div>

        <h3>{t('account')}</h3>

        {isSingleSignOnFeatureEnabled && (
          <>
            <div className="settings-item">
              <div className="settings-item_label">
                {firstName ? (
                  <>
                    <span>{t('settings.fields.first_name')}</span>
                    <h4>{firstName}</h4>
                  </>
                ) : (
                  <h4>{t('settings.fields.first_name')}</h4>
                )}
              </div>
              <div
                className="settings-item_content"
                title={t('edit')}
                onClick={() => setIsEditFirstNameDialogOpen(true)}
              >
                <img src={EditIcon} alt="Edit" />
              </div>
            </div>

            <div className="settings-item">
              <div className="settings-item_label">
                {lastName ? (
                  <>
                    <span>{t('settings.fields.last_name')}</span>
                    <h4>{lastName}</h4>
                  </>
                ) : (
                  <h4>{t('settings.fields.last_name')}</h4>
                )}
              </div>
              <div
                className="settings-item_content"
                title={t('edit')}
                onClick={() => setIsEditLastNameDialogOpen(true)}
              >
                <img src={EditIcon} alt="Edit" />
              </div>
            </div>

            <div className="settings-item">
              <div className="settings-item_label">
                {company ? (
                  <>
                    <span>{t('company')}</span>
                    <h4>{company}</h4>
                  </>
                ) : (
                  <h4>{t('company')}</h4>
                )}
              </div>
              <div
                className="settings-item_content"
                title={t('edit')}
                onClick={() => setShowEditCompanyName(true)}
              >
                <img src={EditIcon} alt="Edit" />
              </div>
            </div>
            <div className="settings-item">
              <div className="settings-item_label">
                <span>{t('email')}</span>
                <h4>{localStorage.getItem('kubota_user_name')}</h4>
              </div>
            </div>

            <div className="settings-item">
              <div className="settings-item_label">
                <span>{t('phone_number')}</span>
                <h4>{maskedPhoneNumber}</h4>
              </div>
              <div
                className="settings-item_content"
                title={t('edit')}
                onClick={() => setShowEditPhoneNumber(true)}
              >
                <img src={EditIcon} alt="Edit" />
              </div>
            </div>
          </>
        )}

        <div
          className="settings-item clickable"
          onClick={() => setShowDeleteDialog(true)}
        >
          <div className="settings-item_label">
            <h4>{t('request_delete_account')}</h4>
          </div>
          <div className="settings-item_content">
            <ChevronRight />
          </div>
        </div>
      </div>

      {showDeleteDialog && (
        <DeleteAccountConfirmationDialog
          close={() => setShowDeleteDialog(false)}
        />
      )}

      {isEditFirstNameDialogOpen && (
        <EditFirstNameDialog
          initialValue={firstName}
          onChange={name => setFirstName(name)}
          close={() => setIsEditFirstNameDialogOpen(false)}
        />
      )}

      {isEditLastNameDialogOpen && (
        <EditLastNameDialog
          initialValue={lastName}
          onChange={name => setLastName(name)}
          close={() => setIsEditLastNameDialogOpen(false)}
        />
      )}

      {showEditCompanyName && (
        <EditCompanyNameDialog
          initialValue={company}
          onChange={company => setCompany(company)}
          close={() => setShowEditCompanyName(false)}
        />
      )}

      {showEditPhoneNumber && (
        <EditPhoneNumberDialog
          initialValue={phoneNumber}
          onChange={phoneNumber => setPhoneNumber(phoneNumber)}
          close={() => setShowEditPhoneNumber(false)}
        />
      )}
    </div>
  );
}
