import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router-dom';
export const GuidesDisclaimerDialog = props => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const acceptDisclaimer = () => {
    localStorage.setItem('guidesDisclaimerAccepted', JSON.stringify(true));
    props.showDialog(false);
  };

  return (
    <>
      <Dialog className="modal-center modal-disclaimer modal-disclaimer-with-buttons">
        <div className="modal-container relative">
          <div>
            <h1>{t('disclaimer')}</h1>
            <p style={{ marginBottom: '36px' }}>
              {t('disclaimer_wording_info')}
            </p>
          </div>
          <article>
            <p>{t('disclaimer_wording_1')}</p>
            <p>{t('disclaimer_wording_2')}</p>
            <p>{t('disclaimer_wording_3')}</p>
            <p>{t('disclaimer_wording_4')}</p>
          </article>
          <div className="inline-buttons">
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t('cancel')}
            </Button>
            <Button themeColor="primary" onClick={acceptDisclaimer}>
              {t('disclaimer_accept_button_text')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
