import { Card } from 'app/components/Card';
import { IconButton } from 'app/components/buttons';
import { DeleteIcon, EditIcon } from 'app/components/icons';
import { getUserRoleString } from 'app/pages/AccessSharingPage/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IAccessSharingUserData } from 'types/IAccessSharingUser';
import './UserAccessSharingOverviewCard.less';
import OverviewDateColumn from './components/OverviewDateColumn';

interface Props {
  user: IAccessSharingUserData;
  isLoading?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}

export default function UserAccessSharingOverviewCard({
  user,
  isLoading,
  onEdit,
  onDelete,
}: Props) {
  const { t } = useTranslation();

  return (
    <Card className="user-access-sharing-overview-card">
      <div className="user-access-sharing-overview-card__user-info-block">
        <span className="user-access-sharing-overview-card__email">
          {user.email}
        </span>
        <span>{t(getUserRoleString(user.permission).toLocaleLowerCase())}</span>
      </div>

      <OverviewDateColumn title={t('start_time')} date={user.startDate} />
      <OverviewDateColumn title={t('end_time')} date={user.endDate} />

      <div className="user-access-sharing-overview-card__actions">
        {onEdit && (
          <IconButton onClick={onEdit} disabled={isLoading} unstyled>
            <EditIcon width={24} height={24} />
          </IconButton>
        )}

        {onDelete && (
          <IconButton onClick={onDelete} disabled={isLoading} unstyled>
            <DeleteIcon width={24} height={24} />
          </IconButton>
        )}
      </div>
    </Card>
  );
}
