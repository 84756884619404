import { Button } from '@progress/kendo-react-buttons';
import FleetImage from 'assets/images/ill_fleet.png';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from 'app/components/icons';
import './FleetCreatedView.less';
import { BackActionContentLayout } from 'app/components/layouts';
import { Card } from 'app/components/Card';

interface Props {
  fleetName: string;
  selectedEquipmentIds: Set<string>;
  onNextStep: () => void;
  onBack: () => void;
}

export default function FleetCreatedView({
  fleetName,
  selectedEquipmentIds,
  onNextStep,
  onBack,
}: Props) {
  const { t } = useTranslation();

  return (
    <BackActionContentLayout backIcon={CloseIcon} onBack={onBack}>
      <Card className="fleet-created-view" withBorder={false}>
        <div className="fleet-created-view__image">
          <img src={FleetImage} alt="fleet" />
        </div>

        <h1 className="fleet-created-view__title">
          {t('fleet_created_successfully', { fleetName })}
        </h1>

        <p className="fleet-created-view__equipment-selected-text">
          {t('create_fleet_success_equipment_selected', {
            numberOfEquipments: selectedEquipmentIds.size,
          })}
        </p>

        <h2 className="fleet-created-view__share-access-subtitle">
          {t('create_fleet_share_question')}
        </h2>

        <div className="fleet-created-view__actions">
          <Button onClick={onBack}>{t('not_now')}</Button>
          <Button onClick={onNextStep} themeColor={'primary'}>
            {t('share_access')}
          </Button>
        </div>
      </Card>
    </BackActionContentLayout>
  );
}
