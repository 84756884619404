import { createContext } from 'react';
import { IAdBannerData } from 'types/IAdBannerData';

export interface IAdData {
  largeBannerAd?: IAdBannerData;
  smallBannerAd?: IAdBannerData;
}

export interface IAdContext {
  setAdData: (newValue: IAdData) => void;
  adData?: IAdData;
}

export const AdContext = createContext({} as IAdContext);
