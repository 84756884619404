import React from 'react';
import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import { DateFormatOptions } from '@progress/kendo-react-intl';
import './DateTimePicker.less';
import clsx from 'clsx';
import { Hint, Error, Label } from '@progress/kendo-react-labels';

interface Props {
  label?: string;
  error?: string;
  helperText?: string;
  className?: string;
  dateFormat?: string | DateFormatOptions;
  timeFormat?: string | DateFormatOptions;
  datePlaceholder?: string;
  timePlaceholder?: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
}

export default function DateTimePicker({
  label,
  error,
  helperText,
  className,
  dateFormat,
  timeFormat,
  datePlaceholder,
  timePlaceholder,
  value,
  onChange,
}: Props) {
  return (
    <div className={clsx('date-time-picker', className)}>
      {label && <Label className="date-time-picker__label">{label}</Label>}

      <div className="date-time-picker__controls">
        <DatePicker
          className={clsx('date-time-picker__date-control', {
            'date-time-picker__date-control_error': error,
          })}
          format={dateFormat}
          placeholder={datePlaceholder}
          onChange={e => onChange(e.value)}
          value={value}
          //   max={endDate ?? undefined}
        />

        <TimePicker
          className={clsx('date-time-picker__time-control', {
            'date-time-picker__time-control_error': error,
          })}
          format={timeFormat}
          placeholder={timePlaceholder}
          onChange={e => onChange(e.value)}
          value={value}
          //   TODO finish with controls validation (min, max, etc)
          // max={getMaxStartTime()}
          // disabled={getMaxStartTime() === undefined}
          // disabled={!startDate}
        />
      </div>

      {helperText && (
        <Hint className="date-time-picker__helper-text">{helperText}</Hint>
      )}

      {error && <Error className="date-time-picker__error">{error}</Error>}
    </div>
  );
}
