import React from 'react';

export default function MoreIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="ic_more"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 32"
      width={props.width ?? '24'}
      height={props.height ?? '32'}
      {...props}
    >
      <g id="ic_more-2" data-name="ic_more" transform="translate(4 4)">
        <rect id="Box" width="16" height="24" fill="none" />
        <g
          id="ic_more-3"
          data-name="ic_more"
          transform="translate(6 21) rotate(-90)"
        >
          <path
            id="Vector"
            d="M2,0A2,2,0,1,0,4,2,2.006,2.006,0,0,0,2,0Z"
            fill={props.fill ?? '#909090'}
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M2,0A2,2,0,1,0,4,2,2.006,2.006,0,0,0,2,0Z"
            transform="translate(14)"
            fill={props.fill ?? '#909090'}
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M2,0A2,2,0,1,0,4,2,2.006,2.006,0,0,0,2,0Z"
            transform="translate(7)"
            fill={props.fill ?? '#909090'}
          />
        </g>
      </g>
      <rect
        id="Box-2"
        data-name="Box"
        fill="none"
        width={props.width ?? '24'}
        height={props.height ?? '32'}
      />
    </svg>
  );
}
