import EquipmentMenuItem from './components/EquipmentMenuItem/EquipmentMenuItem';
import EquipmentSelectRejectedNotification from './components/EquipmentSelectRejectedNotification';
import { NotificationContext } from 'app/components/ReactContexts/notificationContext';
import { useShareAccessEquipmentGuideContext } from 'app/hooks/access-sharing';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import './EquipmentSelect.less';
import clsx from 'clsx';

interface Props {
  equipment: IEquipmentEntry[];
  selectedEquipmentIds: Set<string>;
  className?: string;
  setSelectedEquipmentIds: (ids: Set<string>) => void;
}

// TODO Where do they come from? Is there a way to include it into IEquipmentEntry?
const sharedIds = new Set(['0650d2d7-840c-4f78-b26a-69a16af4263c']);
const inFleetIds = new Set(['0bc5a412-1b21-41f8-a849-761f5633d302']);

export default function EquipmentSelect({
  equipment,
  selectedEquipmentIds,
  className,
  setSelectedEquipmentIds,
}: Props) {
  const { t } = useTranslation();

  const { removeSnackbarNotification, addSnackbarNotification } =
    React.useContext(NotificationContext);

  const { openGuide } = useShareAccessEquipmentGuideContext();

  const isAllDisabled = useMemo(
    () =>
      equipment.every(
        equipment =>
          sharedIds.has(equipment.id) || inFleetIds.has(equipment.id),
      ),
    [equipment, selectedEquipmentIds],
  );

  const isAllChecked = useMemo(
    () =>
      equipment.every(
        equipment =>
          sharedIds.has(equipment.id) ||
          inFleetIds.has(equipment.id) ||
          selectedEquipmentIds.has(equipment.id),
      ),
    [equipment, selectedEquipmentIds],
  );

  function handleCheckAll(e: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;
    const newIds = new Set(selectedEquipmentIds);

    equipment.forEach(equipment => {
      const { id } = equipment;

      const isShared = sharedIds.has(id);
      const isInFleet = inFleetIds.has(id);
      const isDisabled = isShared || isInFleet;

      if (checked && !isDisabled) {
        newIds.add(id);
      } else if (!checked && !isDisabled) {
        newIds.delete(id);
      }
    });

    setSelectedEquipmentIds(newIds);
  }

  function handleCheck(isChecked: boolean, equipmentId: string) {
    const isShared = sharedIds.has(equipmentId);
    const isInFleet = inFleetIds.has(equipmentId);
    const isDisabled = isShared || isInFleet;

    if (isDisabled) {
      addSnackbarNotification(
        <EquipmentSelectRejectedNotification
          title={
            isInFleet
              ? t('equipment_already_in_fleet', {
                  fleet: 'Fleet Name',
                })
              : t('equipment_already_shared')
          }
          onLearnMore={() => {
            removeSnackbarNotification();
            openGuide();
          }}
        />,
        6000,
      );

      return;
    }

    const newIds = new Set(selectedEquipmentIds);
    if (isChecked) {
      newIds.add(equipmentId);
    } else {
      newIds.delete(equipmentId);
    }

    setSelectedEquipmentIds(newIds);
  }

  if (!equipment.length) {
    return <div>No options</div>;
  }

  return (
    <div className={clsx('equipment-select', className)}>
      <div className="equipment-select__select-all-block">
        <label htmlFor="selectAll">{t('all')}</label>

        <input
          id="selectAll"
          type="checkbox"
          className="equipment-select__checkbox"
          checked={isAllChecked && !isAllDisabled}
          onChange={handleCheckAll}
        />
      </div>

      <ul className="equipment-select__list">
        {equipment.map(equipment => {
          const { id } = equipment;

          const isChecked = selectedEquipmentIds.has(id);
          const isShared = sharedIds.has(id);
          const isInFleet = inFleetIds.has(id);

          return (
            <EquipmentMenuItem
              key={id}
              equipment={equipment}
              isChecked={isChecked}
              isShared={isShared}
              isInFleet={isInFleet}
              handleCheck={() => handleCheck(!isChecked, id)}
            />
          );
        })}
      </ul>
    </div>
  );
}
