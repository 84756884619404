import * as React from 'react';

import { ajax } from 'ajax/ajax';
import { SingedOutComponent } from './SingedOutComponent';
import { SingedInComponent } from './SingedInComponent';

interface IProfileTopRight {}

export function ProfileTopRight(props: IProfileTopRight) {
  const isLoggedIn = ajax.isLoggedIn();

  if (isLoggedIn) {
    return <SingedInComponent />;
  } else {
    return <SingedOutComponent />;
  }
}
