import { emailRegex } from 'app/common/utils';
import { AccessConfiguration } from 'app/components/access-sharing';
import { SubmitButton, TextField } from 'app/components/form-fields';
import {
  BackActionContentLayout,
  CardContentLayout,
} from 'app/components/layouts';
import { ConfirmAccessPeriodSettingsDialog } from 'app/pages/AccessSharingPage/SelectUser/ConfirmAccessPeriodSettingsDialog';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IAccessSharingPeriodType,
  IAccessSharingPermissionType,
  IAccessSharingUserData,
} from 'types/IAccessSharingUser';
import './AccessSharingView.less';

interface Props {
  selectedUsers: Map<string, IAccessSharingUserData>;
  editableUserEmail?: string;
  setSelectedUsers: (users: Map<string, IAccessSharingUserData>) => void;
  onBack: () => void;
  onNextStep: () => void;
}

// TODO validation isn't finished and will be fixed in next PRs
export default function AccessSharingView({
  selectedUsers,
  editableUserEmail,
  setSelectedUsers,
  onNextStep,
  onBack,
}: Props) {
  const { t } = useTranslation();

  const editableUser = selectedUsers.get(editableUserEmail ?? '');

  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState(editableUser?.email ?? '');
  const [permission, setPermission] = useState(
    editableUser?.permission ?? IAccessSharingPermissionType.Viewer,
  );
  const [period, setPeriod] = useState(
    editableUser?.accessPeriod ?? IAccessSharingPeriodType.Continuous,
  );

  const [startDate, setStartDate] = useState(editableUser?.startDate ?? null);
  const [endDate, setEndDate] = useState(editableUser?.endDate ?? null);
  const [startTime] = useState(editableUser?.startDate ?? null);
  const [endTime] = useState(editableUser?.endDate ?? null);

  const [
    showConfirmAccessPeriodSettingsDialog,
    setShowConfirmAccessPeriodSettingsDialog,
  ] = useState(false);

  function handleConfirmAccessPeriodSettingsDialog() {
    setShowConfirmAccessPeriodSettingsDialog(false);
    handleSubmit({ currentPeriod: IAccessSharingPeriodType.Continuous });
  }

  const handleSubmit = (options: {
    currentPeriod: IAccessSharingPeriodType;
  }) => {
    const { currentPeriod } = options;
    const startYear = startDate?.getFullYear() as number;
    const startMonth = startDate?.getMonth() as number;
    const startDay = startDate?.getDate() || 1;
    const startHours = startTime?.getHours() || 0;
    const startMinutes = startTime?.getMinutes() || 0;
    const endYear = endDate?.getFullYear() as number;
    const endMonth = endDate?.getMonth() as number;
    const endDay = endDate?.getDate() || 1;
    const endHours = endTime?.getHours() || 0;
    const endMinutes = endTime?.getMinutes() || 0;
    const isStartDateSet = startYear && startMonth;
    const isEndDateSet = endYear && endMonth;

    if (
      (!isStartDateSet || !isEndDateSet) &&
      currentPeriod === IAccessSharingPeriodType.Temporary
    ) {
      setShowConfirmAccessPeriodSettingsDialog(true);
      return;
    }

    const user = {
      email,
      permission: permission,
      accessPeriod: currentPeriod,
      startDate:
        currentPeriod === IAccessSharingPeriodType.Temporary && isStartDateSet
          ? new Date(startYear, startMonth, startDay, startHours, startMinutes)
          : undefined,
      endDate:
        currentPeriod === IAccessSharingPeriodType.Temporary && isEndDateSet
          ? new Date(endYear, endMonth, endDay, endHours, endMinutes)
          : undefined,
    };

    const updatedUsers = new Map(selectedUsers);
    updatedUsers.set(email, user);
    setSelectedUsers(updatedUsers);

    onNextStep();
  };

  const validateEmail = () => {
    if (editableUserEmail !== email && selectedUsers.has(email)) {
      setEmailError(t('email_already_selected'));
      return false;
    }

    if (!email) {
      setEmailError(t('field_is_required'));
      return false;
    }

    if (!email.toLowerCase().match(emailRegex)) {
      setEmailError(t('incorrect_email_format'));
      return false;
    }

    return true;
  };

  return (
    <>
      <BackActionContentLayout onBack={onBack}>
        <CardContentLayout
          title={t('select_user_to_share_title')}
          subtitle={t('select_user_to_share_subtitle')}
        >
          <div className="access-sharing-view">
            <TextField
              label={t('email')}
              value={email}
              onChange={event => {
                setEmail(event.target.value as string);
                setEmailError('');
              }}
              required
              className="access-sharing-view__text-field"
              placeholder={t('what_is_their_email')}
              error={emailError}
            />

            <AccessConfiguration
              initialPermission={permission}
              initialPeriod={period}
              initialStartDate={startDate}
              initialEndDate={endDate}
              onChangePermission={setPermission}
              onChangePeriod={setPeriod}
              onChangeStartDate={setStartDate}
              onChangeEndDate={setEndDate}
            />
          </div>

          <SubmitButton
            onClick={() => {
              if (validateEmail()) {
                handleSubmit({ currentPeriod: period });
              }
            }}
          >
            {t('continue_text')}
          </SubmitButton>
        </CardContentLayout>
      </BackActionContentLayout>

      {/* TODO refactor this dialog */}
      {showConfirmAccessPeriodSettingsDialog && (
        <ConfirmAccessPeriodSettingsDialog
          onCancel={() => setShowConfirmAccessPeriodSettingsDialog(false)}
          onContinue={handleConfirmAccessPeriodSettingsDialog}
        />
      )}
    </>
  );
}
