import * as React from 'react';
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from '@progress/kendo-react-layout';
import { ProfileTopRight } from '../ProfileTopRight';
import { NotificationsTopRight } from '../NotificationsTopRight';
import { LayoutContext } from '../ReactContexts/layoutContext';
import _ from 'lodash';
import { isCanada } from 'app/common/websiteCountry';
import { useNavigate } from 'react-router-dom';
// import { EquipmentListContext } from '../ReactContexts/equipmentListContext';

interface IAppBar {}

export function KubotaAppBar(props: IAppBar) {
  const { classNames, setClassNames, removeSpecificClass } =
    React.useContext(LayoutContext);
  // const { setEquipmentListData } = React.useContext(EquipmentListContext);
  const handleClick = () => {
    if (!_.some(classNames, cls => cls.class === 'mobile-nav-shown')) {
      setClassNames('mobile-nav-shown');
    } else {
      removeSpecificClass('mobile-nav-shown');
    }
  };
  const navigate = useNavigate();
  return (
    <AppBar className={`${isCanada ? 'website-CA' : ''} appbar-nav`}>
      <AppBarSection className="appbar-nav-toggle-section">
        <span className="mobile-menu-toggle" onClick={handleClick}>
          <svg
            id="ic_menu_hamburger"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <rect id="Box" width="32" height="32" fill="none" />
            <line
              id="Line"
              x2="20"
              transform="translate(6 8.5)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <line
              id="Line-2"
              data-name="Line"
              x2="20"
              transform="translate(6 23.5)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <line
              id="Line-3"
              data-name="Line"
              x2="20"
              transform="translate(6 16)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </svg>
        </span>
      </AppBarSection>
      <AppBarSection className="appbar-logo-section">
        <span
          className="site-logo"
          onClick={() => {
            //setEquipmentListData({ searchValue: '' });
            navigate('/equipment');
          }}
        >
          <img src="/logo.png" alt="logo" />
        </span>
      </AppBarSection>

      <AppBarSpacer className="appbar-spacer" />

      <AppBarSection>
        <NotificationsTopRight />
      </AppBarSection>

      <AppBarSection>
        <ProfileTopRight />
      </AppBarSection>
    </AppBar>
  );
}
