import { Button } from '@progress/kendo-react-buttons';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import {
  ic_banner_info_secondary as InfoIcon,
  ill_inhibit_disabled as StarterDisabledIcon,
  ill_inhibit_enabled as StarterEnabledIcon,
} from 'app/widgets/SVGs';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface InhibitRestartProcessingCommandProps {
  equipmentNickName?: string;
  isEnableing: boolean;
  isLoading: boolean;
  refreshEquipmentData: () => void;
  onSendCommand: (isCancel: boolean) => void;
  equipmentModel?: string;
  equipmentIdentifierType?: string;
  equipmentPinOrSerial?: string;
}
export function InhibitRestartProcessingCommand(
  props: InhibitRestartProcessingCommandProps,
) {
  const {
    isEnableing,
    isLoading,
    onSendCommand,
    refreshEquipmentData,
    equipmentModel,
    equipmentIdentifierType,
    equipmentPinOrSerial,
  } = props;
  const refreshTime = 15000;
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  // mount
  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(refreshEquipmentData, refreshTime);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <h1>{t('inhibit_restart_title')}</h1>
      <div className="inline-label-text">
        <span className="label">{t('equipment_model')}</span>
        <span className="text">{equipmentModel}</span>
      </div>
      <div className="inline-label-text">
        <span className="label">
          {equipmentIdentifierType === 'Pin'
            ? t('equipment_pin')
            : t('equipment_serial')}{' '}
        </span>
        <span className="text">{equipmentPinOrSerial}</span>
      </div>

      <div className="separator-xl"></div>

      <div className="inhibit-restart-processing-box">
        <div className="loading-keys">
          <div className="row">
            <div className="col-xxs-4">
              {isEnableing ? <StarterDisabledIcon /> : <StarterEnabledIcon />}
            </div>

            <div className="col-xxs-4">
              <div className="four-dots-loader">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>

            <div className="col-xxs-4">
              {isEnableing ? <StarterEnabledIcon /> : <StarterDisabledIcon />}
            </div>
          </div>
        </div>
        <div>
          <h3>
            {t(
              `${
                isEnableing
                  ? 'inhibit_restart_enableing_header_message'
                  : 'inhibit_restart_disabling_header_message'
              }`,
            )}
          </h3>
          <div className="separator-m"></div>
          <div>
            <Button
              disabled={isLoading}
              onClick={() => {
                onSendCommand(true);
              }}
              className="cancel-inhibit-restart-button"
            >
              {isLoading ? (
                <div className="loading_icon loading_icon-btn">
                  <div className="css-icon css-icon-dark"></div>
                </div>
              ) : (
                t('cancel')
              )}
            </Button>
          </div>
          <div className="separator-m"></div>
          <p
            dangerouslySetInnerHTML={{
              __html: t('inhibit_restart_command_status_message'),
            }}
          />
          <div className="separator-xl"></div>
          <div>
            <div className="errors-box">
              <span className="error-icon">
                <InfoIcon />
              </span>
              <div className="error-list">
                <span>{t('inhibit_restart_command_info_message')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
