import { NavigationIconButton } from 'app/components/buttons';
import { ArrowBackIcon } from 'app/components/icons';
import React from 'react';
import './BackActionDialogContentLayout.less';
import clsx from 'clsx';

export interface Props {
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  backIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  backIconProps?: { className: string };
  onBack: () => void;
}

export default function BackActionDialogContentLayout({
  children,
  className,
  isLoading,
  backIcon: BackIcon = ArrowBackIcon,
  backIconProps,
  onBack,
}: Props) {
  return (
    <div className={clsx('back-action-dialog-content-layout', className)}>
      <NavigationIconButton
        className={clsx(
          'back-action-dialog-content-layout__navigation-icon-button',
          backIconProps?.className,
        )}
        disabled={isLoading}
        onClick={onBack}
      >
        <BackIcon width={32} height={32} />
      </NavigationIconButton>

      <div>{children}</div>
    </div>
  );
}
