import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'app/components/dialogs';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = Pick<ConfirmationDialogProps, 'open' | 'onConfirm' | 'onClose'>;

export default function ConfirmNavigateAwayDialog({
  open,
  onConfirm,
  onClose,
}: Props) {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      open={open}
      title={t('confirm-navigate-away-dialog.title')}
      closeLabel={t('confirm-navigate-away-dialog.actions.cancel')}
      confirmationLabel={t('confirm-navigate-away-dialog.actions.confirm')}
      onConfirm={onConfirm}
      onClose={onClose}
    >
      {t('confirm-navigate-away-dialog.content')}
    </ConfirmationDialog>
  );
}
