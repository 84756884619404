import React from 'react';
import { useCallback } from 'react';
import type { MutateReturnValue, TryMutateOptions } from './types';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';
import { NotificationContext } from 'app/components/ReactContexts/notificationContext';
import { Success } from 'app/components/Notifications';

/**
 * Utility hook to create mutation callbacks.
 *
 * @example Mutation without args
 *
 * const tryMyMutation = useTryMutate(
 *   () => myMutation({ foo: 'bar' }),
 * );
 *
 *
 * @example Mutation with args
 *
 * const tryMyMutation = useTryMutate<{ foo: string }>(
 *   (arg) => myMutation(arg),
 * );
 *
 * @example Mutation that displays success/failure toasts
 *
 * const tryMyMutation = useTryMutate(
 *   () => myMutation({ foo: 'bar' }),
 *   {
 *     onComplete: (result, error, arg, api) => {
 *       if (!error) api.successToast('My mutation succeeded!')
 *       else api.errorToast('My mutation failed!')
 *     }
 *   }
 * );
 *
 * @param mutate Callback to perform the mutation logic.
 * @param options
 * @param options.onComplete Lifecycle function called after the mutation is complete – whether it was successful or not.
 */
export default function useTryMutate<
  Arg extends Object | void = void,
  Result extends Object | void = void,
>(
  mutate: (arg: Arg) => MutateReturnValue,
  { onComplete }: Partial<TryMutateOptions<Arg, Result>> = {},
) {
  const { addErrorModal } = React.useContext(GlobalErrorContext);
  const { addNotification, addSnackbarNotification } =
    React.useContext(NotificationContext);

  return useCallback(
    async (arg: Arg) => {
      let error: unknown;
      let result: Result | undefined;

      try {
        const maybePromise = mutate(arg);
        result = (await maybePromise) as Result;
        return true;
      } catch (thrown: unknown) {
        error = thrown;
        // TODO send log to any services: Sentry, etc
        return false;
      } finally {
        onComplete?.(
          result,
          error,
          // @ts-expect-error
          arg ?? undefined,
          {
            // TODO should be refactored and replaced with something like bellow
            showErrorModal: addErrorModal,
            successNotification: text =>
              addNotification(<Success text={text} />, 6000),
            successSnackbarNotification: text =>
              addSnackbarNotification(
                <div className="snackbar-container">
                  <span>{text}</span>
                </div>,
                6000,
              ),
            // errorToast: toast => {},
            // setToast({
            //   type: ToastTypes.ERROR,
            //   ...(typeof toast === 'string' ? { message: toast } : toast),
            // }),
            // successToast: toast => {},
            // setToast({
            //   type: ToastTypes.SUCCESS,
            //   ...(typeof toast === 'string' ? { message: toast } : toast),
            // }),
          },
        );
      }
    },
    [mutate, onComplete],
  );
}
