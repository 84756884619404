import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { Card, CardBody } from '@progress/kendo-react-layout';
import { ajax } from 'ajax/ajax';
import { isCanada } from 'app/common/websiteCountry';
import SuccessIcon from 'assets/images/ic_success.svg';
import { getLocale } from 'locales/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { formatNumberWithDecimals } from 'utils/general';
import { getDefaultThumbnail } from '../Equipment/utils';
import {
  getCanadaFormateDate,
  getUSFormateDateOnly,
} from '../TrackMaintenencePage/maintenanceUtils';

interface IUpdateHoursCardProps {
  equipment: IEquipmentEntry;
  updateHoursFc: Function;
}

export function UpdateHoursCard(props: IUpdateHoursCardProps) {
  const { t, i18n } = useTranslation();
  const [validInput, setValidInput] = React.useState(true);
  const [engineHours, setEngineHours] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [engineHoursError, setEngineHoursError] = React.useState('');
  const [isUpdated, setIsUpdated] = React.useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = React.useState<string>('');

  // todo: code refactorization after IID-1636 is merged(duplicated code)
  const locale = getLocale();
  const getDate = () => {
    return isCanada || locale === 'fr-CA' || locale === 'en-CA'
      ? getCanadaFormateDate(props.equipment.dateModified, 'date', locale)
      : getUSFormateDateOnly(props.equipment.dateModified, locale);
  };

  React.useEffect(() => {
    setLastUpdate(getDate());
  }, [props.equipment.dateModified]);

  const validateEngineHours = () => {
    if (!engineHours) {
      setEngineHoursError(t('field_is_required'));
      return false;
    }
    let convertedEngineHours = engineHours;
    if (i18n.language === 'fr') {
      if (engineHours.includes('.')) {
        setValidation(false, t('engine_hours_incorrect'));
        return false;
      }
      convertedEngineHours = engineHours.replace(t('hours_separator'), '.');
    }

    const hasDecimal = convertedEngineHours.includes(t('hours_separator'));
    const maxLength = hasDecimal ? 10 : 7;
    const numericValue = Number(convertedEngineHours);
    if (
      engineHours.length > maxLength ||
      isNaN(numericValue) ||
      numericValue > 9999999
    ) {
      setValidation(false, t('engine_hours_incorrect'));
      return false;
    }
    setEngineHoursError('');
    return true;
  };

  const handleInputChange = inputValue => {
    var regex = /^[0-9.]*$/;
    var convertedEngineHours = inputValue;
    const locale = getLocale();
    if (locale === 'fr-CA' || locale === 'en-CA' || i18n.language === 'fr') {
      if (inputValue.includes('.')) return;
      convertedEngineHours = inputValue.replace(t('hours_separator'), '.');
    }

    if (!regex.test(convertedEngineHours)) {
      setValidation(false, t('engine_hours_incorrect'));
      return;
    }

    const hasDecimal = convertedEngineHours.includes('.');
    const maxLength = hasDecimal ? 10 : 7;
    if ((convertedEngineHours.length || 0) > maxLength) {
      return;
    }

    const numericValue = Number(convertedEngineHours);
    if (isNaN(numericValue) || (!hasDecimal && numericValue > 9999999)) {
      setValidation(false, t('engine_hours_incorrect'));
    } else {
      if (hasDecimal) {
        const [, decimalPart] = convertedEngineHours.split('.');
        if (
          decimalPart &&
          ((decimalPart.length > 1 && props.equipment.hasTelematics) ||
            (decimalPart.length > 2 && !props.equipment.hasTelematics)) &&
          convertedEngineHours.length > (engineHours?.toString() || '').length
        ) {
          return;
        }
      }
      setEngineHours(inputValue);
      setEngineHoursError('');
    }
  };

  const setValidation = (isValid, errorMessage) => {
    setValidInput(isValid);
    setEngineHoursError(errorMessage);
  };

  const formatEngineHours = () => {
    let value = engineHours!.replace(t('hours_separator'), '.');
    const [integerPart, decimalPart] = value.split('.');
    const truncatedDecimalPart = decimalPart?.substring(0, 2);
    if (truncatedDecimalPart) {
      value = integerPart + '.' + truncatedDecimalPart;
    }
    return value;
  };

  const saveChangesButtonClicked = async () => {
    if (!validateEngineHours()) {
      return;
    }
    setIsLoading(true);
    const numericEngineHours = formatEngineHours();
    try {
      await ajax.put(
        `${ajax.appBaseUrl}/api/user/equipment/engineHours`,
        JSON.stringify({
          id: props.equipment.id,
          engineHours: numericEngineHours,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    } catch (error) {
      setEngineHoursError(error as string);
    }

    setIsLoading(false);
    setIsUpdated(true);

    setTimeout(() => {
      props.updateHoursFc(props.equipment.id, numericEngineHours);
    }, 1000);
  };
  return (
    <>
      <Card className="update-hours-card">
        <CardBody>
          <div className="row">
            <div className="col-xxs-9">
              <h4>{props.equipment.nickName || props.equipment.model}</h4>
              <div className="info-card row">
                <div className="col-md-12">
                  <span className="info-col-label">{t('equipment_model')}</span>
                  <span className="info-col-value">
                    {props.equipment.model}
                  </span>
                </div>
                <div className="col-md-12">
                  <span className="info-col-label">
                    {props.equipment.serial
                      ? t('equipment_pin')
                      : t('equipment_serial')}
                  </span>
                  <span className="info-col-value">
                    {props.equipment.pinOrSerial || '\u00A0'}{' '}
                  </span>
                </div>
                <div className="col-md-12">
                  <span className="info-col-label">{t('equipment_hours')}</span>
                  <span className="info-col-value">
                    {props.equipment.hasTelematics
                      ? formatNumberWithDecimals(
                          props.equipment.userEnteredEngineHours,
                          1,
                        ).replace('.', t('hours_separator'))
                      : formatNumberWithDecimals(
                          props.equipment.userEnteredEngineHours,
                          2,
                        ).replace('.', t('hours_separator'))}
                    <span className="update-note">
                      {' '}
                      ({t('entered_date')} {lastUpdate})
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-xxs-3">
              <img
                src={
                  props.equipment.modelIconUrl ||
                  getDefaultThumbnail(
                    props.equipment.type,
                    props.equipment.subcategory,
                  )
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = getDefaultThumbnail(
                    props.equipment.type,
                    props.equipment.subcategory,
                  );
                }}
                alt=""
                className="equipment-list-image"
              />
            </div>
          </div>
          <div className="row current-hours-form">
            <div className="col-sm-12">
              {isUpdated == false ? (
                <>
                  {' '}
                  <div className={`form-group`}>
                    <div className="form-content">
                      <div className="form-label">
                        <span>{t('current_hours')}</span>
                      </div>
                      <div className="inline-input-button">
                        <div className="form-control">
                          <Input
                            id="engineHours"
                            name="engineHours"
                            valid={validInput}
                            placeholder={t('enter_the_new_equipment_hours')}
                            value={engineHours}
                            onChange={event => {
                              const inputValue = event.target.value?.toString();
                              handleInputChange(inputValue);
                            }}
                            disabled={isLoading}
                          />
                        </div>
                        <Button
                          themeColor="primary"
                          onClick={saveChangesButtonClicked}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <div className="loading_icon loading_icon-btn">
                              <div className="css-icon"></div>
                            </div>
                          ) : (
                            t('save')
                          )}
                        </Button>
                      </div>
                    </div>
                    <div className="form-error">
                      {engineHoursError && (
                        <Error id={'engineHoursError'}>
                          {engineHoursError}
                        </Error>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="current-hours-updated-success">
                    <span className="current-hours-updated-image">
                      <img src={SuccessIcon} alt="" />
                    </span>
                    <span className="current-hours-updated-text">
                      {t('current_hours_updated')}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
