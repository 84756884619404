import { Button } from '@progress/kendo-react-buttons';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  onLearnMore: () => void;
}

export default function EquipmentSelectRejectedNotification({
  title,
  onLearnMore,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="snackbar-container">
      <span style={{ fontSize: '12px', maxWidth: '60%' }}>{title}</span>

      <Button
        themeColor="primary"
        className="learn-more-button"
        onClick={onLearnMore}
      >
        {t('learn_more')}
      </Button>
    </div>
  );
}
