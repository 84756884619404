import clsx from 'clsx';
import React from 'react';
import './Radio.less';
import { RadioValueType } from 'app/components/form-fields';

interface Props<T extends RadioValueType> {
  id?: string;
  name?: string;
  value: T;
  checked: boolean;
  className?: string;
  onChange: (value: T) => void;
}

export default function Radio<T extends RadioValueType>({
  id,
  name,
  value,
  checked,
  className,
  onChange,
}: Props<T>) {
  return (
    <input
      id={id}
      type="radio"
      name={name}
      className={clsx('radio', className)}
      value={value}
      checked={checked}
      onChange={() => onChange(value)}
    />
  );
}
