import { Dialog } from '@progress/kendo-react-dialogs';
import useClickOutside from 'app/hooks/useClickOutside';
import ShareFleet from 'assets/images/access_sharing_guide_fleet.png';
import ShareIndividual from 'assets/images/access_sharing_guide_individual.png';
import EquipmentSharedInFleet from 'assets/images/img_item_unavailable_infleet.png';
import EquipmentSharedIndividually from 'assets/images/img_item_unavailable_shared.png';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  close: () => void;
}

// TODO This components needs refactoring
export default function AccessSharingGuideDialog({ close }: Props) {
  const { t } = useTranslation();

  // Note: there's a point in creating a wrapper around the Dialog and adding an onClickOutside prop
  useClickOutside(close);

  return (
    <Dialog
      className="modal-container no-max-width"
      style={{ overflow: 'auto' }}
      contentStyle={{ overflow: 'visible', background: '#fff' }}
    >
      <div className="access-sharing-guide-model">
        <span className="back-button fixed-on-desktop" onClick={close}>
          <svg
            id="ic_nav_back"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <rect id="Box" width="48" height="48" fill="none" />
            <path
              id="ic_nav_up"
              d="M16.465,8.482H1.483"
              transform="translate(15.518 15.518)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <path
              id="Path_470"
              data-name="Path 470"
              d="M23.676,16.018,16.2,23.748l7.477,7.73"
              transform="translate(0.324 0.252)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </svg>
        </span>
        <div className="title">
          <h2>{t('access_sharing_guide_title')}</h2>
        </div>
        <div className="subtitle-caption">
          <span>{t('access_sharing_guide_subtitle')}</span>
        </div>
        <div className="content">
          <h4>{t('access_sharing_guide_equipment_shared_title')}</h4>
          <img src={EquipmentSharedIndividually} alt="" />
          <span
            dangerouslySetInnerHTML={{
              __html: t('access_sharing_guide_equipment_shared_description'),
            }}
          />
          <img className="no-border" src={ShareIndividual} alt="" />

          <h4>{t('access_sharing_guide_equipment_in_fleet_title')}</h4>
          <img src={EquipmentSharedInFleet} alt="" />
          <span
            dangerouslySetInnerHTML={{
              __html: t('access_sharing_guide_equipment_in_fleet_description'),
            }}
          />
          <img className="no-border" src={ShareFleet} alt="" />
        </div>
      </div>
    </Dialog>
  );
}
