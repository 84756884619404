import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { Button } from '@progress/kendo-react-buttons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NewMaintenanceDialog } from './Dialogs/TrackNewMaintenance';
import { EquipmentDetails } from './EquipmentDetailsBox';
import { MaintenanceHistoryList } from './MaintenanceHistoryList';
import { getSelectedEquipment } from './maintenanceUtils';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { AddMaintenanceSuccessPopUp } from './AddMaintenanceSuccessPopUp';
import { MaintenanceHistoryContext } from 'app/components/ReactContexts/maintenanceHistoryContext';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import SecondaryBtnIcon from 'assets/images/ic_btn_add.svg';

export function TrackMaintanenceContent() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [openNewMaintenance, setOpenNewMaintenance] =
    React.useState<boolean>(false);
  const [openEngineHoursUpdateDialog, setOpenEngineHoursUpdateDialog] =
    React.useState(false);
  const { maintenanceHistoryData, setMaintenanceHistoryData } =
    React.useContext(MaintenanceHistoryContext);
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  const { equipmentListData } = React.useContext(EquipmentListContext);
  const { t } = useTranslation();

  const selectedEquipment = getSelectedEquipment(equipmentListData, params.id);

  // mount
  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  React.useEffect(() => {
    if (maintenanceHistoryData.hasNewHistoryItem) {
      setMaintenanceHistoryData({
        ...maintenanceHistoryData,
        hasNewHistoryItem: false,
      });
    }
  }, [maintenanceHistoryData.hasNewHistoryItem]);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        setOpenNewMaintenance(false);
        setOpenEngineHoursUpdateDialog(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  const toggleDialog = () => {
    !selectedEquipment.hasTelematics &&
    selectedEquipment?.userEnteredEngineHours == 0
      ? setOpenEngineHoursUpdateDialog(true)
      : setOpenNewMaintenance(!openNewMaintenance);
  };

  if (!equipmentListData.selectedEquipment) {
    return <div>{t('loading_equipment')}</div>;
  }

  if (equipmentListData.selectedEquipment.type !== 'machine') {
    const index = location.pathname.indexOf('/trackMaintenance');

    if (index >= 0) {
      navigate(location.pathname.substring(0, index), { replace: true });
    }
  }

  return (
    <div className="layout-info-panel track-maintantance">
      <>
        {maintenanceHistoryData.hasNewHistoryItem && (
          <div className="popup-container">
            <AddMaintenanceSuccessPopUp />
          </div>
        )}
      </>
      <div className="layout-info-panel_container">
        <span
          className="back-button back-button-on-layout fixed-on-desktop"
          onClick={() => navigate(`/equipment/${selectedEquipment?.id}`)}
        >
          <BackIcon />
        </span>
        <div className="box-content">
          <div className="content-container new-mantainance">
            <div className="layout-info-panel-header">
              <div className="ellipsis">
                {equipmentListData.selectedEquipment.nickName ||
                  equipmentListData.selectedEquipment.model}
              </div>
              <h1>{t('track_maintenance')}</h1>
            </div>
            <EquipmentDetails
              eqipmentDetails={selectedEquipment}
              openEngineHoursUpdateDialog={openEngineHoursUpdateDialog}
              setOpenEngineHoursUpdateDialog={setOpenEngineHoursUpdateDialog}
              openNewMaintenanceDialog={setOpenNewMaintenance}
            />
            <MaintenanceHistoryList
              openEngineHoursUpdateDialog={openEngineHoursUpdateDialog}
              setOpenEngineHoursUpdateDialog={setOpenEngineHoursUpdateDialog}
            />
            <div className="sticky-mobile-button">
              <Button
                className="content-button"
                themeColor="secondary"
                onClick={toggleDialog}
              >
                <span className="btn_icon">
                  <img src={SecondaryBtnIcon} alt="Secodary btn icon" />
                </span>
                <span>
                  {t('maintenance_history_track').toLocaleUpperCase()}
                </span>
              </Button>
            </div>
          </div>
        </div>
        {openNewMaintenance && (
          <NewMaintenanceDialog
            isVisible={openNewMaintenance}
            setVisible={setOpenNewMaintenance}
            equipmentDetails={selectedEquipment}
            openEngineHoursUpdateDialog={openEngineHoursUpdateDialog}
            setOpenEngineHoursUpdateDialog={setOpenEngineHoursUpdateDialog}
          />
        )}
      </div>
    </div>
  );
}
