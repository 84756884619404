import * as React from 'react';
import { ajax } from 'ajax/ajax';
import { NotificationsComponent } from './NotificationsComponent';

interface INotificationsTopRight {}

export function NotificationsTopRight(props: INotificationsTopRight) {
  const isLoggedIn = ajax.isLoggedIn();

  if (isLoggedIn) {
    return <NotificationsComponent />;
  } else {
    return <></>;
  }
}
