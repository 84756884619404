import { MaintenanceHistoryContext } from 'app/components/ReactContexts/maintenanceHistoryContext';
import {
  getCheckListMeasures,
  getMaintenanceScheduleIntervalTitle,
  getProcessedMaintenanceSchedule,
} from 'app/pages/TrackMaintenencePage/maintenanceUtils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IMaintenanceCheck } from 'types/IMaintenanceCheckList';
import { Button } from '@progress/kendo-react-buttons';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import { ajax } from 'ajax/ajax';
import { IMaintenanceSchedule } from 'types/IMaintenanceSchedule';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';

export function MaintenanceDetails() {
  const { t } = useTranslation();
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);

  const { equipmentListData } = React.useContext(EquipmentListContext);
  const { maintenanceHistoryData, setMaintenanceHistoryData } =
    React.useContext(MaintenanceHistoryContext);
  const { addErrorModal } = React.useContext(GlobalErrorContext);

  const [checkList, setCheckList] = React.useState<IMaintenanceCheck[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const model = equipmentListData.selectedEquipment
    ? equipmentListData.selectedEquipment.model
    : params.model;

  const intervals = params.interval?.split('-') || [];

  const getMaintenanceCheckListData = () => {
    setIsLoading(true);
    (async () => {
      await ajax
        .get(`${ajax.appBaseUrl}/api/maintenanceSchedule/${model}`, {
          headers: {
            version: '2022_R03',
          },
        })
        .then(response => {
          const maintenanceIntervals = getProcessedMaintenanceSchedule(
            response.data,
            false,
          ) as IMaintenanceSchedule[];

          const filteredInterval = maintenanceIntervals.filter(
            interval =>
              interval.intervalType.replace(/\s/g, '') === intervals[1] &&
              interval.intervalValue === Number(intervals[0]),
          )[0];

          setCheckList(() => filteredInterval.maintenanceCheckList);

          setMaintenanceHistoryData({
            ...maintenanceHistoryData,
            selectedInterval: filteredInterval,
          });
        })
        .catch(err => addErrorModal())
        .finally(() => setIsLoading(false));
    })();
  };

  const maintenanceCheckList = maintenanceHistoryData.selectedInterval
    ? (maintenanceHistoryData.selectedInterval
        ?.maintenanceCheckList as IMaintenanceCheck[])
    : checkList;

  const measures = getCheckListMeasures(maintenanceCheckList);
  const title = getMaintenanceScheduleIntervalTitle(
    maintenanceHistoryData.selectedInterval?.intervalType,
    maintenanceHistoryData.selectedInterval?.intervalValue,
    t,
  );

  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  React.useEffect(() => {
    if (!maintenanceHistoryData.selectedInterval) {
      getMaintenanceCheckListData();
    }
  }, []);

  return isLoading ? (
    <div className="equipment-content-empty-loading">
      <div className="loading">
        <div className="loading_text">{t('loading...')}</div>
        <div className="loading_icon">
          <div className="css-icon"></div>
        </div>
      </div>
    </div>
  ) : (
    <div className="layout-info-panel maintanance-schedule-panel">
      <div className="layout-info-panel_container">
        <span
          className="back-button back-button-on-layout fixed-on-desktop"
          onClick={() => navigate(-1)}
        >
          <BackIcon />
        </span>
        <div className="box-content">
          <div className="content-container">
            <div className="info-model ellipsis">
              {equipmentListData.selectedEquipment?.nickName || model}
            </div>
            <div className="layout-info-panel-header">
              <h1>{title}</h1>
            </div>
            <div className="info-categories-list">
              {measures?.map(measure => {
                return (
                  <div key={measure}>
                    <h4>{measure}</h4>
                    {maintenanceCheckList?.map(item => {
                      return (
                        item.measures === measure && (
                          <div
                            className="info-categories-list-item"
                            key={item.id}
                          >
                            <span>{item.checkPoint}</span>
                          </div>
                        )
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <div>
              <Button
                className="btn-accent full-width"
                onClick={() => navigate('/dealers')}
              >
                {t('contact_dealer')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
