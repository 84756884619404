import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { CacheContext } from 'app/components/ReactContexts/cacheContext';
import { tagEvent } from 'utils/ga';
import { useTranslation } from 'react-i18next';
import ManualsIcon from 'assets/images/ic_res_manuals.svg';
import ChevronRightIcon from 'assets/images/chevron_right.svg';

export function ManualViewer() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [manuals, setManual] = React.useState<any>();
  const [selectedManual, setSelectedManual] = React.useState<any | null>(null);
  const { getApiModels } = React.useContext(CacheContext);
  const { equipmentListData } = React.useContext(EquipmentListContext);
  const model =
    equipmentListData.selectedEquipment && params.id != undefined
      ? equipmentListData.selectedEquipment.model
      : params.model;
  const equipmentFilter = equipmentListData.selectedEquipment
    ? equipmentListData.selectedEquipment.id
    : params.id;
  const [paramsExist, setParamsExist] = React.useState(true);
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      const apiModel = await getApiModels();
      setParamsExist(true);
      if (apiModel.models && !apiModel.models.some(c => c.model == model)) {
        setParamsExist(false);
      }

      if (equipmentListData) {
        equipmentFilter
          ? setManual(equipmentListData.selectedEquipment?.manualEntries)
          : setManual(
              apiModel.models.filter(c => c.model == model)[0].manualEntries,
            );
      }

      if (
        (params.id != undefined && params.id != equipmentFilter) ||
        (params.model && params.model != model)
      ) {
        setParamsExist(false);
      }

      setIsLoading(false);
      if (manuals && manuals.length > 0) {
        setSelectedManual(manuals.length == 1 ? manuals[0]?.url : null);
      }
    })();
  }, [i18n.language, equipmentListData, manuals]);

  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  if (paramsExist && manuals) {
    tagEvent('load_manual', { model });
  }

  const handleManualSelect = selectedManual => {
    setSelectedManual(selectedManual.url);
  };

  const backButton = () => {
    if (selectedManual == null || manuals.length <= 1) {
      navigate(-1);
    } else if (manuals.length > 1) {
      setSelectedManual(null);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="loading-full-page">
          <div className="loading">
            <div className="loading_text">{t('loading')}...</div>
            <div className="loading_icon">
              <div className="css-icon"></div>
            </div>
          </div>
        </div>
      ) : paramsExist ? (
        <div className={equipmentFilter ? 'container' : ''}>
          <div className="relative">
            <span
              className="back-button back-button-on-layout fixed-on-desktop"
              onClick={() => backButton()}
            >
              <BackIcon />
            </span>
            {selectedManual == null && manuals && manuals.length > 1 && (
              <div className="box-content box-content-pdf">
                <div className="row equipment-content-links">
                  <div className="col-sm-12">
                    <div className="equipment-content-actions-title">
                      <h4>{t('manuals')}</h4>
                    </div>
                    <div className="arrow-links arrow-links-no-icon-border">
                      {manuals.map((manualItem, index) => (
                        <div
                          key={manualItem.index}
                          onClick={() => handleManualSelect(manualItem)}
                          className={`arrow-links_item ${
                            manualItem?.url === selectedManual ? 'selected' : ''
                          }`}
                        >
                          <img
                            className="arrow-links_icon"
                            src={ManualsIcon}
                          ></img>
                          <span>{manualItem.name}</span>
                          <img
                            className="arrow-links_icon arrow-links_icon-right"
                            src={ChevronRightIcon}
                          ></img>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {selectedManual && selectedManual != null && (
              <div className="box-content box-content-full-height box-content-pdf">
                <iframe
                  src={selectedManual}
                  title="pdf manual"
                  className="box-content-pdf-renderer"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <NotFoundPage />
      )}
    </>
  );
}
