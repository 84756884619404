import clsx from 'clsx';
import React, { ButtonHTMLAttributes } from 'react';
import './IconButton.less';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  withShadow?: boolean;
  unstyled?: boolean;
}

export default function IconButton({
  children,
  withShadow,
  unstyled,
  ...props
}: Props) {
  if (unstyled) {
    return (
      <button
        {...props}
        className={clsx('icon-button_unstyled', props.className)}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      {...props}
      className={clsx(
        'icon-button',
        {
          'icon-button_with-shadow': withShadow,
        },
        props.className,
      )}
    >
      {children}
    </button>
  );
}
