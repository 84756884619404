import { isCanada } from 'app/common/websiteCountry';
import { MaintenanceHistoryContext } from 'app/components/ReactContexts/maintenanceHistoryContext';
import { getLocale } from 'locales/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IMaintenanceHistoryEntry } from 'types/IMaintenanceHistoryEntry';
import { formatNumberWithDecimals } from 'utils/general';
import { CompletedMaintenanceDialog } from '../Dialogs/CompleteMaintenanceTrack';
import {
  formatDate,
  getCanadaFormateDate,
  getMaintenanceIntervalTitle,
} from '../maintenanceUtils';

interface IMaintenanceListCardProps {
  maintenanceItem: IMaintenanceHistoryEntry;
  index: number;
  key: any;
  openEngineHoursUpdateDialog: boolean;
  setOpenEngineHoursUpdateDialog: any;
}

export function MaintenanceHistoryListCard(props: IMaintenanceListCardProps) {
  const { t, i18n } = useTranslation();
  const { maintenanceHistoryData } = React.useContext(
    MaintenanceHistoryContext,
  );

  const [maintenanceInterval, setMaintenanceInterval] = React.useState<any>();
  const [selectedItem, setSelectedItem] = React.useState(null);

  const locale = getLocale();

  const getHours = () => {
    const completedEngineHours = props.maintenanceItem.completedEngineHours;

    return isCanada ||
      locale === 'fr-CA' ||
      locale === 'en-CA' ||
      i18n.language === 'fr'
      ? formatNumberWithDecimals(completedEngineHours, 2).replace(
          '.',
          t('hours_separator'),
        )
      : formatNumberWithDecimals(completedEngineHours, 2);
  };

  const getDate = () => {
    const updatedDate = props.maintenanceItem?.updatedDate || '';

    return isCanada || locale === 'fr-CA' || locale === 'en-CA'
      ? getCanadaFormateDate(updatedDate, 'date', locale)
      : formatDate(updatedDate, 'mmm dd yyyy');
  };

  const title = getMaintenanceIntervalTitle(
    props.maintenanceItem.intervalType,
    props.maintenanceItem.intervalValue,
    t,
  );

  React.useEffect(() => {
    const filteredIntervals =
      maintenanceHistoryData.maintenanceIntervals?.filter(
        item =>
          item.intervalType === props.maintenanceItem.intervalType &&
          item.intervalValue <= props.maintenanceItem.intervalValue,
      );

    const checkList: any = filteredIntervals
      ?.map(item => item.maintenanceCheckList)
      .flat();

    const checks = checkList?.filter(obj =>
      props.maintenanceItem.maintenanceCheckList.hasOwnProperty(obj.id),
    );

    setMaintenanceInterval({
      id: props.maintenanceItem.id,
      intervalType: props.maintenanceItem.intervalType,
      intervalValue: props.maintenanceItem.intervalValue,
      maintenanceCheckList: checks,
    });
  }, [maintenanceHistoryData]);

  const handleClick = item => maintenanceInterval && setSelectedItem(item);
  const handleClose = () => setSelectedItem(null);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        handleClose();
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    <>
      <div className="clickable-row" onClick={handleClick}>
        <div>
          <h4>{title}</h4>
          <div>
            {t('maintenance_history_status_hours_completion', {
              engineHours: getHours(),
            })}
          </div>
          <div>
            {t('maintenance_history_status_update', { date: getDate() })}
          </div>
        </div>
        <div>
          <div className="arrow-right">
            <svg
              id="chevron_right"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <rect id="Box" width="20" height="20" fill="none" />
              <path
                id="Vector"
                d="M0,9.467,4.72,4.733,0,0"
                transform="translate(7.641 5.266)"
                fill="none"
                stroke="#dc4405"
                stroke-linecap="round"
                stroke-width="1.7"
              />
            </svg>
          </div>
        </div>
      </div>
      {selectedItem && (
        <CompletedMaintenanceDialog
          maintenanceInterval={maintenanceInterval}
          closeDialog={handleClose}
          action={'edit'}
          openEngineHoursUpdateDialog={props.openEngineHoursUpdateDialog}
          setOpenEngineHoursUpdateDialog={props.setOpenEngineHoursUpdateDialog}
        />
      )}
    </>
  );
}
