import { FleetSelect } from 'app/components/access-sharing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './FleetSelectionView.less';
import { SubmitButton } from 'app/components/form-fields';
import {
  BackActionContentLayout,
  CardContentLayout,
} from 'app/components/layouts';

interface Props {
  fleets: any[];
  selectedFleetId?: string;
  setSelectedFleetId: (id: string) => void;
  onBack: () => void;
  onCreateFleet?: () => void;
  onNextStep: () => void;
}

export default function FleetSelectionView({
  fleets,
  selectedFleetId,
  setSelectedFleetId,
  onBack,
  onCreateFleet,
  onNextStep,
}: Props) {
  const { t } = useTranslation();

  return (
    <BackActionContentLayout onBack={onBack}>
      <CardContentLayout
        title={t('select_fleet_to_share_access')}
        subtitle={t('choose_fleet')}
      >
        <FleetSelect
          className="fleet-selection-view__fleet-select"
          fleets={fleets}
          selectedFleetId={selectedFleetId}
          setSelectedFleetId={setSelectedFleetId}
          onCreateFleet={onCreateFleet}
        />

        <SubmitButton
          className="fleet-selection-view__submit-btn"
          onClick={onNextStep}
        >
          {t('continue_text')}
        </SubmitButton>
      </CardContentLayout>
    </BackActionContentLayout>
  );
}
