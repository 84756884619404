import { Button } from '@progress/kendo-react-buttons';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EquipmentAndFleets from './EquipmentAndFleets';
import PendingInvitations from './PendingInvitations';
import SharedWith from './SharedWith';

enum AccessSharingTabs {
  EquipmentAndFleets,
  SharedWith,
  PendingInvitations,
}

export enum AcessSharingFilters {
  All,
  OwnedByMe,
  SharedWithMe,
}

function AccessSharingPage() {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState<AccessSharingTabs>(
    AccessSharingTabs.EquipmentAndFleets,
  );
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);

  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024);

  React.useEffect(() => {
    window.addEventListener('resize', e => {
      window.innerWidth < 1024 ? setIsMobile(true) : setIsMobile(false);
    });
  }, [window.innerWidth]);

  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => {
      removeClassNames(index);
    };
  }, []);

  return (
    <div className="relative">
      <h2 className="title-access-sharing">{t('title_access_sharing')}</h2>
      <div className="access-sharing-tab-container">
        {/* TODO replace it with a new Tabs component */}
        <div className="access-sharing-tab-buttons">
          <Button
            className="button-fault-codes-tab"
            themeColor={
              selectedTab == AccessSharingTabs.EquipmentAndFleets
                ? 'primary'
                : null
            }
            onClick={() => setSelectedTab(AccessSharingTabs.EquipmentAndFleets)}
          >
            {t('equipment_and_fleets')}
          </Button>
          <Button
            className="button-fault-codes-tab"
            themeColor={
              selectedTab == AccessSharingTabs.SharedWith ? 'primary' : null
            }
            onClick={() => setSelectedTab(AccessSharingTabs.SharedWith)}
          >
            {t('shared_with')}
          </Button>
          <Button
            className="button-fault-codes-tab"
            themeColor={
              selectedTab == AccessSharingTabs.PendingInvitations
                ? 'primary'
                : null
            }
            onClick={() => setSelectedTab(AccessSharingTabs.PendingInvitations)}
          >
            {t('pending_invitations')}
          </Button>
        </div>
      </div>
      <div>
        {selectedTab == AccessSharingTabs.EquipmentAndFleets ? (
          <EquipmentAndFleets isMobile={isMobile} />
        ) : selectedTab == AccessSharingTabs.SharedWith ? (
          <SharedWith isMobile={isMobile} />
        ) : (
          <PendingInvitations isMobile={isMobile} />
        )}
      </div>
    </div>
  );
}

export default AccessSharingPage;
