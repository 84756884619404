import {
  getUSFormateDateOnly,
  getUSFormatHours,
} from 'app/pages/TrackMaintenencePage/maintenanceUtils';
import { getLocale } from 'locales/utils';
import React from 'react';

interface Props {
  title: string;
  date?: Date;
}

export default function OverviewDateColumn({ title, date }: Props) {
  // TODO date time formatting isn't finished.
  const locale = getLocale();

  if (!date) {
    return null;
  }

  return (
    <div>
      <span className="user-access-sharing-overview-card__date-title">
        {title}
      </span>

      <div className="user-access-sharing-overview-card__date">
        <span>{getUSFormateDateOnly(date, locale)}</span>
        <span>{getUSFormatHours(date)}</span>
      </div>
    </div>
  );
}
