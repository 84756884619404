import useStepper from 'app/hooks/useStepper';
import React, { createContext, useContext, useState } from 'react';
import { IAccessSharingUserData } from 'types/IAccessSharingUser';
import { Context, AccessSharingFlowContextProps } from './types';

const AccessSharingFlowContext = createContext<Context>({} as Context);

export function AccessSharingFlowProvider({
  children,
  startStep,
  stepCount,
}: AccessSharingFlowContextProps) {
  const stepper = useStepper({
    stepCount,
    startStep,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFleetCreated, setIsFleetCreated] = useState(false);
  const [selectedFleetId, setSelectedFleetId] = useState<string>();
  const [selectedFleetName, setSelectedFleetName] = useState<string>();
  const [selectedEquipmentIds, setSelectedEquipmentIds] = useState(
    new Set<string>(),
    // new Set([
    //   '02262458-e154-45e7-9cee-9d576ed8a0c2',
    //   '1490197a-cde7-40ab-ac4b-6419eff9c358',
    // ]),
  );
  const [selectedUsers, setSelectedUsers] = useState(
    // new Map<string, IAccessSharingUserData>(),
    new Map<string, IAccessSharingUserData>([
      [
        'user@inmotionsoftware.com',
        {
          email: 'user@inmotionsoftware.com',
          permission: 1,
          accessPeriod: 1,
          startDate: new Date(),
          endDate: new Date(),
        },
      ],
    ]),
  );
  const [editableUserEmail, setEditableUserEmail] = useState<
    string | undefined
  >('user@inmotionsoftware.com');

  // TODO all these fields can be replaced with RHF
  const value = {
    stepper,
    isEditing,
    isSubmitted,
    isFleetCreated,
    selectedFleetId,
    selectedFleetName,
    selectedEquipmentIds,
    selectedUsers,
    editableUserEmail,
    setIsEditing,
    setIsSubmitted,
    setIsFleetCreated,
    setSelectedFleetId,
    setSelectedFleetName,
    setSelectedEquipmentIds,
    setSelectedUsers,
    setEditableUserEmail,
  };

  return (
    <AccessSharingFlowContext.Provider value={value}>
      {children}
    </AccessSharingFlowContext.Provider>
  );
}

const useAccessSharingFlowContext = () => useContext(AccessSharingFlowContext);

export default useAccessSharingFlowContext;
