import * as React from 'react';
import { MaintenanceHistoryListCard } from './MaintenanceHistoryListCard';
import { NoMaintenanceHistory } from './NoMaintenanceHistory';
import { MaintenanceHistoryContext } from 'app/components/ReactContexts/maintenanceHistoryContext';
import { useTranslation } from 'react-i18next';
import * as utils from '../maintenanceUtils';
import { useParams } from 'react-router-dom';
import { ajax } from 'ajax/ajax';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';

interface IProps {
  openEngineHoursUpdateDialog: boolean;
  setOpenEngineHoursUpdateDialog: any;
}

export function MaintenanceHistoryList(props: IProps) {
  const { t } = useTranslation();
  const { equipmentListData } = React.useContext(EquipmentListContext);
  const { maintenanceHistoryData, setMaintenanceHistoryData } =
    React.useContext(MaintenanceHistoryContext);
  const { addErrorModal } = React.useContext(GlobalErrorContext);

  const [modelIsLoading, setModelIsLoading] = React.useState<boolean>(false);

  const params = useParams();

  React.useEffect(() => {
    !maintenanceHistoryData.selectedEquipmentMaintenanceHistory &&
      (async () => {
        const equipment = utils.getSelectedEquipment(
          equipmentListData,
          params.id,
        );

        try {
          setModelIsLoading(true);
          const maintenanceHistoryResponse = await ajax.get(
            `${ajax.appBaseUrl}/api/user/equipment/${params.id}/maintenanceHistory`,
          );

          const maintenanceScheduleResponse = await ajax.get(
            `${ajax.appBaseUrl}/api/maintenanceSchedule/${equipment?.model}`,
            {
              headers: {
                version: '2022_R03',
              },
            },
          );

          setMaintenanceHistoryData({
            ...maintenanceHistoryData,
            selectedEquipmentMaintenanceHistory:
              utils.getSortedMaintenanceHistory(
                maintenanceHistoryResponse.data,
              ),
            maintenanceIntervals: utils.getProcessedMaintenanceSchedule(
              maintenanceScheduleResponse.data,
              true,
            ),
          });
          setModelIsLoading(false);
        } catch (err: any) {
          addErrorModal();
        }
      })();
  }, [equipmentListData.selectedEquipment]);

  return (
    <>
      {maintenanceHistoryData.selectedEquipmentMaintenanceHistory &&
        maintenanceHistoryData.selectedEquipmentMaintenanceHistory?.length >
          0 && (
          <div className="info-panel-list-header">
            <h3>{t('maintenance_history_title')}</h3>
          </div>
        )}
      {modelIsLoading ? (
        <div className="loading loading-attachments">
          <div className="loading_text">{t('loading_maintenance_history')}</div>
          <div className="loading_icon">
            <div className="css-icon"></div>
          </div>
        </div>
      ) : (
        <div className="info-panel-list info-panel-list-history">
          <div className="info-panel-list-items scroll-area">
            {maintenanceHistoryData?.selectedEquipmentMaintenanceHistory
              ?.length ? (
              maintenanceHistoryData.selectedEquipmentMaintenanceHistory.map(
                (maintenanceItem, index) => (
                  <MaintenanceHistoryListCard
                    maintenanceItem={maintenanceItem}
                    index={index}
                    key={maintenanceItem.id}
                    openEngineHoursUpdateDialog={
                      props.openEngineHoursUpdateDialog
                    }
                    setOpenEngineHoursUpdateDialog={
                      props.setOpenEngineHoursUpdateDialog
                    }
                  />
                ),
              )
            ) : (
              <NoMaintenanceHistory />
            )}
          </div>
        </div>
      )}
    </>
  );
}
