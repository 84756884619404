import { AccessSharingRole } from 'types/entities/AccessSharingRole';

export const dummyEquipmentData: any[] = [
  {
    id: '02262458-e154-45e7-9cee-9d576ed8a0c2',
    role: AccessSharingRole.Viewer,
    dateModified: '2024-05-09T16:44:39Z',
    model: 'KX057-5',
    categoryId: 8,
    category: 'Compact Excavators',
    subcategory: 'Construction',
    identifierType: 'Pin',
    type: 'machine',
    pinOrSerial: 'KBCDZ37CCN3K15386',
    pin: 'KBCDZ37CCN3K15386',
    serial: '15386',
    nickName: 'A-Do-Not-Edit-1',
    primaryManufacturer: 'Kubota',
    secondaryManufacturer: null,
    userEnteredEngineHours: 477.36583333333334,
    hasTelematics: true,
    telematics: {
      locationTime: '2023-09-06T19:33:47Z',
      cumulativeOperatingHours: 477.3,
      location: {
        latitude: 36.105457,
        longitude: -94.1333,
        altitudeMeters: 367.53,
        positionHeadingAngle: 45,
      },
      engineRunning: false,
      fuelTempCelsius: null,
      fuelRemainingPercent: 82,
      defTempCelsius: -1,
      defQualityPercent: null,
      defRemainingPercent: null,
      defPressureKPascal: null,
      engineRPM: 0,
      coolantTempCelsius: 77,
      hydraulicTempCelsius: 62,
      extPowerVolts: 13.011,
      airInletTempCelsius: null,
      ambientAirTempCelsius: null,
      runNumber: 4521,
      motionState: 'moving',
      faultCodes: [],
      restartInhibitStatus: {
        canModify: true,
        commandStatus: 'RestartDisabled',
        equipmentStatus: 'RestartDisabled',
        equipmentFeatureStatus: 'disabled',
      },
      smartAttachment: null,
      electric: null,
      insideGeofences: [],
    },
    modelHeroUrl:
      'https://ktcictstorage.z13.web.core.windows.net/images/kx057-5_hero_small.jpg',
    modelFullUrl: null,
    modelIconUrl:
      'https://ktcictstorage.z13.web.core.windows.net/images/KX057-5small.jpg',
    manualEntries: [
      {
        name: 'Manual for KX057-5, U55-5',
        url: 'https://ktcictstorage.z13.web.core.windows.net/manuals/KX057-5andU55-5.pdf',
      },
    ],
    videoEntries: [
      {
        name: 'Diesel Particulate Filter (DPF) Regeneration - KX and U Series',
        url: 'https://ktcictstorage.z13.web.core.windows.net/videos/ST70_Kx&U_Re_Gen_Explained_Final_V2.mov.mp4',
      },
    ],
    guideUrl: 'https://kubotaguides.blob.core.windows.net/KX057-5/',
    warrantyUrl:
      'https://www.kubotausa.com/service-support/warranty/limited-warranty',
    hasFaultCodes: true,
    hasMaintenanceSchedules: true,
    hasCalibrationGuides: false,
    smartAttachment: null,
  },
  {
    id: '1490197a-cde7-40ab-ac4b-6419eff9c358',
    role: AccessSharingRole.Editor,
    dateModified: '2024-02-14T16:11:38Z',
    model: 'SVL97-2',
    categoryId: 10,
    category: 'Track Loaders',
    subcategory: 'Construction',
    identifierType: 'Pin',
    type: 'machine',
    pinOrSerial: 'KBCZ064CCM1H65044',
    pin: 'KBCZ064CCM1H65044',
    serial: '65044',
    nickName: 'A-Do-Not-Edit-2',
    primaryManufacturer: 'Kubota',
    secondaryManufacturer: null,
    userEnteredEngineHours: 0,
    hasTelematics: true,
    telematics: {
      locationTime: '2023-09-06T19:33:13Z',
      cumulativeOperatingHours: 1554.4,
      location: {
        latitude: 30.594545,
        longitude: -84.08533,
        altitudeMeters: 14.75,
        positionHeadingAngle: 354,
      },
      engineRunning: true,
      fuelTempCelsius: null,
      fuelRemainingPercent: 25,
      defTempCelsius: 47,
      defQualityPercent: 32,
      defRemainingPercent: 30,
      defPressureKPascal: null,
      engineRPM: 1150,
      coolantTempCelsius: 85,
      hydraulicTempCelsius: 65,
      extPowerVolts: 12.4,
      airInletTempCelsius: null,
      ambientAirTempCelsius: 41.625,
      runNumber: 2676,
      motionState: 'moving',
      faultCodes: [],
      restartInhibitStatus: {
        canModify: true,
        commandStatus: 'RestartEnabled',
        equipmentStatus: 'RestartDisabled',
        equipmentFeatureStatus: 'enabled',
      },
      smartAttachment: null,
      electric: {
        battery: {
          temperature: 24.4,
          voltage: 13.1,
          current: 10.1,
          remainingCharge: 100,
          health: 85,
          remainingCapacity: 10,
          fullCharge: true,
          charging: true,
          discharging: true,
          chargerPresent: true,
          timeUntilFullCharge: null,
        },
        charger: {
          present: false,
          voltage: 13.2,
          current: 20.1,
          temperature: 32.01,
        },
        remainingRange: {
          time: 9600,
          distance: 309,
        },
        ambientTemperature: 23.4,
      },
      insideGeofences: [],
    },
    modelHeroUrl:
      'https://ktcictstorage.z13.web.core.windows.net/images/svl97_hero_sized_down.jpg',
    modelFullUrl: null,
    modelIconUrl:
      'https://ktcictstorage.z13.web.core.windows.net/images/SVL97-2small.png',
    manualEntries: [
      {
        name: 'Manual for SVL97-2',
        url: 'https://ktcictstorage.z13.web.core.windows.net/manuals/V0641-58111.pdf',
      },
    ],
    videoEntries: [
      {
        name: 'Air Filter Cleaning & Replacing - BX2380',
        url: 'https://ktcictstorage.z13.web.core.windows.net/videos/ST23_V4_BX2380__Air%20Filter%20Clean&Rep.mp4',
      },
      {
        name: 'Air Filter Cleaning & Replacing - L3301, L3901',
        url: 'https://ktcictstorage.z13.web.core.windows.net/videos/ST33_V3_LSeries_Air%20Filter%20Clean&Rep.mp4',
      },
      {
        name: 'Test YouTube Video',
        url: 'https://www.youtube.com/watch?v=LXb3EKWsInQ&ab_channel=Jacob%2BKatieSchwarz',
      },
      {
        name: 'YouTube Test #1',
        url: 'http://www.youtube.com/watch?v=1aa_oP-6AEs',
      },
      {
        name: 'YouTube Test #2',
        url: 'http://youtube.com/watch?v=1aa_oP-6AEs',
      },
      {
        name: 'YouTube Test #3',
        url: 'http://m.youtube.com/watch?v=1aa_oP-6AEs',
      },
      {
        name: 'YouTube Test #4',
        url: 'http://youtu.be/watch?v=1aa_oP-6AEs',
      },
      {
        name: 'YouTube Test #1 - https',
        url: 'https://www.youtube.com/watch?v=1aa_oP-6AEs',
      },
      {
        name: 'YouTube Test #2 - https',
        url: 'https://youtube.com/watch?v=1aa_oP-6AEs',
      },
      {
        name: 'YouTube Test #3 - https',
        url: 'https://m.youtube.com/watch?v=1aa_oP-6AEs',
      },
      {
        name: 'YouTube Test #4 - https',
        url: 'https://youtu.be/watch?v=1aa_oP-6AEs',
      },
    ],
    guideUrl: 'https://kubotaguides.blob.core.windows.net/SVL97-2/',
    warrantyUrl:
      'https://www.kubotausa.com/service-support/warranty/limited-warranty',
    hasFaultCodes: false,
    hasMaintenanceSchedules: true,
    hasCalibrationGuides: false,
    smartAttachment: null,
  },
  {
    id: '0bc5a412-1b21-41f8-a849-761f5633d302',
    role: AccessSharingRole.Admin,
    dateModified: '2024-08-05T12:28:53Z',
    model: 'SVL97-2',
    categoryId: 10,
    category: 'Track Loaders',
    subcategory: 'Construction',
    identifierType: 'Pin',
    type: 'machine',
    pinOrSerial: 'KBCZ064CVM1C61952',
    pin: 'KBCZ064CVM1C61952',
    serial: '61952',
    nickName: 'A-Do-Not-Edit-3',
    primaryManufacturer: 'Kubota',
    secondaryManufacturer: null,
    userEnteredEngineHours: 1874.7,
    hasTelematics: true,
    telematics: {
      locationTime: '2023-09-06T19:32:27Z',
      cumulativeOperatingHours: 1874.7,
      location: {
        latitude: 33.33631,
        longitude: -86.88702,
        altitudeMeters: 187.88,
        positionHeadingAngle: 331,
      },
      engineRunning: true,
      fuelTempCelsius: null,
      fuelRemainingPercent: 9,
      defTempCelsius: 49,
      defQualityPercent: 34.5,
      defRemainingPercent: 9,
      defPressureKPascal: null,
      engineRPM: 1150,
      coolantTempCelsius: 83,
      hydraulicTempCelsius: 65,
      extPowerVolts: 12,
      airInletTempCelsius: null,
      ambientAirTempCelsius: 42.1875,
      runNumber: 1130,
      motionState: 'stationary',
      faultCodes: [],
      restartInhibitStatus: {
        canModify: true,
        commandStatus: 'RestartDisabled',
        equipmentStatus: 'RestartDisabled',
        equipmentFeatureStatus: 'enabled',
      },
      smartAttachment: {
        smartTagSerial: '123456789001',
        model: 'SC7072',
        serial: '12345SC7072TEST',
        modelHeroUrl:
          'https://www.landpride.com/sites/default/files/styles/800x289/public/product-landing-banners/lp18_sc70_svl95-2s_023_web_0.jpg?itok=Siosf9k8',
        modelFullUrl: null,
        modelIconUrl:
          'https://cdn-assets.landpride.com/lp_files/styles/scale_1300_wide/public/lp17_sc70_8_0.png',
      },
      electric: null,
      insideGeofences: [],
    },
    modelHeroUrl:
      'https://ktcictstorage.z13.web.core.windows.net/images/svl97_hero_sized_down.jpg',
    modelFullUrl: null,
    modelIconUrl:
      'https://ktcictstorage.z13.web.core.windows.net/images/SVL97-2small.png',
    manualEntries: [
      {
        name: 'Manual for SVL97-2',
        url: 'https://ktcictstorage.z13.web.core.windows.net/manuals/V0641-58111.pdf',
      },
    ],
    videoEntries: [
      {
        name: 'Air Filter Cleaning & Replacing - BX2380',
        url: 'https://ktcictstorage.z13.web.core.windows.net/videos/ST23_V4_BX2380__Air%20Filter%20Clean&Rep.mp4',
      },
      {
        name: 'Air Filter Cleaning & Replacing - L3301, L3901',
        url: 'https://ktcictstorage.z13.web.core.windows.net/videos/ST33_V3_LSeries_Air%20Filter%20Clean&Rep.mp4',
      },
      {
        name: 'Test YouTube Video',
        url: 'https://www.youtube.com/watch?v=LXb3EKWsInQ&ab_channel=Jacob%2BKatieSchwarz',
      },
      {
        name: 'YouTube Test #1',
        url: 'http://www.youtube.com/watch?v=1aa_oP-6AEs',
      },
      {
        name: 'YouTube Test #2',
        url: 'http://youtube.com/watch?v=1aa_oP-6AEs',
      },
      {
        name: 'YouTube Test #3',
        url: 'http://m.youtube.com/watch?v=1aa_oP-6AEs',
      },
      {
        name: 'YouTube Test #4',
        url: 'http://youtu.be/watch?v=1aa_oP-6AEs',
      },
      {
        name: 'YouTube Test #1 - https',
        url: 'https://www.youtube.com/watch?v=1aa_oP-6AEs',
      },
      {
        name: 'YouTube Test #2 - https',
        url: 'https://youtube.com/watch?v=1aa_oP-6AEs',
      },
      {
        name: 'YouTube Test #3 - https',
        url: 'https://m.youtube.com/watch?v=1aa_oP-6AEs',
      },
      {
        name: 'YouTube Test #4 - https',
        url: 'https://youtu.be/watch?v=1aa_oP-6AEs',
      },
    ],
    guideUrl: 'https://kubotaguides.blob.core.windows.net/SVL97-2/',
    warrantyUrl:
      'https://www.kubotausa.com/service-support/warranty/limited-warranty',
    hasFaultCodes: false,
    hasMaintenanceSchedules: true,
    hasCalibrationGuides: false,
    smartAttachment: null,
  },
  {
    id: '0650d2d7-840c-4f78-b26a-69a16af4263c',
    role: AccessSharingRole.Owner,
    dateModified: '2024-02-14T16:11:38Z',
    model: 'SSV75',
    categoryId: 9,
    category: 'Skid Steer Loaders',
    subcategory: 'Construction',
    identifierType: 'Pin',
    type: 'machine',
    pinOrSerial: 'KBCZ141CHPJD32095',
    pin: 'KBCZ141CHPJD32095',
    serial: '32095',
    nickName: 'A-Do-Not-Edit-5',
    primaryManufacturer: 'Kubota',
    secondaryManufacturer: null,
    userEnteredEngineHours: 0,
    hasTelematics: true,
    telematics: {
      locationTime: '2023-09-06T19:31:04Z',
      cumulativeOperatingHours: 62.3,
      location: {
        latitude: 25.80344,
        longitude: -80.25265,
        altitudeMeters: -34.09,
        positionHeadingAngle: 53,
      },
      engineRunning: false,
      fuelTempCelsius: null,
      fuelRemainingPercent: 54,
      defTempCelsius: -1,
      defQualityPercent: null,
      defRemainingPercent: null,
      defPressureKPascal: null,
      engineRPM: 0,
      coolantTempCelsius: 74,
      hydraulicTempCelsius: 51,
      extPowerVolts: 13.102,
      airInletTempCelsius: null,
      ambientAirTempCelsius: null,
      runNumber: 335,
      motionState: 'stationary',
      faultCodes: [],
      restartInhibitStatus: {
        canModify: true,
        commandStatus: 'RestartEnabled',
        equipmentStatus: 'RestartEnabled',
        equipmentFeatureStatus: 'enabled',
      },
      smartAttachment: null,
      electric: {
        battery: {
          temperature: 24.4,
          voltage: 13.1,
          current: 10.1,
          remainingCharge: 40,
          health: 85,
          remainingCapacity: 10,
          fullCharge: false,
          charging: false,
          discharging: true,
          chargerPresent: true,
          timeUntilFullCharge: null,
        },
        charger: {
          present: false,
          voltage: 13.2,
          current: 20.1,
          temperature: 32.01,
        },
        remainingRange: {
          time: 3600,
          distance: 40,
        },
        ambientTemperature: 23.4,
      },
      insideGeofences: [636],
    },
    modelHeroUrl:
      'https://ktcictstorage.z13.web.core.windows.net/images/SSV75_Hero.jpg',
    modelFullUrl:
      'https://ktcictstorage.z13.web.core.windows.net/images/SSV75_Full.png',
    modelIconUrl:
      'https://ktcictstorage.z13.web.core.windows.net/images/SSV75_Icon.png',
    manualEntries: [
      {
        name: 'Manual for SSV65,SSV65P, SSV75,SSV75P',
        url: 'https://ktcictstorage.z13.web.core.windows.net/manuals/V1311-58135.pdf',
      },
    ],
    videoEntries: [],
    guideUrl: null,
    warrantyUrl:
      'https://www.kubotausa.com/service-support/warranty/limited-warranty',
    hasFaultCodes: false,
    hasMaintenanceSchedules: true,
    hasCalibrationGuides: false,
    smartAttachment: null,
  },
  {
    id: '02223c3b-1bcd-4b0d-8aa1-7a1e6334fad8',
    role: AccessSharingRole.Viewer,
    dateModified: '2024-02-14T16:11:38Z',
    model: 'KX057-5',
    categoryId: 8,
    category: 'Compact Excavators',
    subcategory: 'Construction',
    identifierType: 'Pin',
    type: 'machine',
    pinOrSerial: 'KBCDZ37CAN3J15274',
    pin: 'KBCDZ37CAN3J15274',
    serial: '15274',
    nickName: 'A-Do-Not-Edit-6',
    primaryManufacturer: 'Kubota',
    secondaryManufacturer: null,
    userEnteredEngineHours: 0,
    hasTelematics: true,
    telematics: {
      locationTime: '2023-09-06T19:31:02Z',
      cumulativeOperatingHours: 170.9,
      location: {
        latitude: 32.648117,
        longitude: -95.48923,
        altitudeMeters: 95.19,
        positionHeadingAngle: 353,
      },
      engineRunning: true,
      fuelTempCelsius: null,
      fuelRemainingPercent: 51,
      defTempCelsius: -1,
      defQualityPercent: null,
      defRemainingPercent: null,
      defPressureKPascal: null,
      engineRPM: 0,
      coolantTempCelsius: 78,
      hydraulicTempCelsius: 63,
      extPowerVolts: 11.886,
      airInletTempCelsius: null,
      ambientAirTempCelsius: null,
      runNumber: 270,
      motionState: 'stationary',
      faultCodes: [],
      restartInhibitStatus: {
        canModify: true,
        commandStatus: 'RestartDisabled',
        equipmentStatus: 'RestartEnabled',
        equipmentFeatureStatus: 'enabled',
      },
      smartAttachment: null,
      electric: {
        battery: {
          temperature: 24.4,
          voltage: 13.1,
          current: 10.1,
          remainingCharge: 5,
          health: 85,
          remainingCapacity: 10,
          fullCharge: false,
          charging: false,
          discharging: true,
          chargerPresent: false,
          timeUntilFullCharge: null,
        },
        charger: {
          present: false,
          voltage: 13.2,
          current: 20.1,
          temperature: 32.01,
        },
        remainingRange: {
          time: 1300,
          distance: 22,
        },
        ambientTemperature: 23.4,
      },
      insideGeofences: [605],
    },
    modelHeroUrl:
      'https://ktcictstorage.z13.web.core.windows.net/images/kx057-5_hero_small.jpg',
    modelFullUrl: null,
    modelIconUrl:
      'https://ktcictstorage.z13.web.core.windows.net/images/KX057-5small.jpg',
    manualEntries: [
      {
        name: 'Manual for KX057-5, U55-5',
        url: 'https://ktcictstorage.z13.web.core.windows.net/manuals/KX057-5andU55-5.pdf',
      },
    ],
    videoEntries: [
      {
        name: 'Diesel Particulate Filter (DPF) Regeneration - KX and U Series',
        url: 'https://ktcictstorage.z13.web.core.windows.net/videos/ST70_Kx&U_Re_Gen_Explained_Final_V2.mov.mp4',
      },
    ],
    guideUrl: 'https://kubotaguides.blob.core.windows.net/KX057-5/',
    warrantyUrl:
      'https://www.kubotausa.com/service-support/warranty/limited-warranty',
    hasFaultCodes: true,
    hasMaintenanceSchedules: true,
    hasCalibrationGuides: false,
    smartAttachment: null,
  },
];

export const dummyFleetsData: any[] = [
  {
    id: '1',
    name: 'Arizona Tractors',
    pieces: 24,
    role: AccessSharingRole.Viewer,
    owner: 'Bronn',
    email: 'bronn@gmail.com',
    sharedWithUsers: 3,
  },
  {
    id: '2',
    name: 'Texas Group',
    pieces: 12,
    role: AccessSharingRole.Editor,
    owner: 'Shae',
    email: 'shae@gmail.com',
    sharedWithUsers: 4,
  },
  {
    id: '3',
    name: 'Huston Farm',
    pieces: 3,
    role: AccessSharingRole.Admin,
    owner: 'Loras',
    email: 'loras@gmail.com',
    sharedWithUsers: 2,
  },
  {
    id: '4',
    name: 'Corn Fields',
    pieces: 10,
    role: AccessSharingRole.Owner,
    owner: 'Gendry',
    email: 'gendry@gmail.com',
    sharedWithUsers: 3,
  },
];

export const dummyUserAccess: any[] = [
  {
    id: 1,
    name: 'Nicolas Mooney',
    email: 'nicolas.mooney@mail.com',
    role: AccessSharingRole.Admin,
    startTime: 'March 25, 2024 09:30 AM',
    endTime: 'March 25, 2024 09:30 AM',
  },
  {
    id: 2,
    name: 'Aine Knapp',
    email: 'aine.knapp@mail.com',
    role: AccessSharingRole.Editor,
  },
  {
    id: 3,
    name: 'Jemima Clark',
    email: 'jemima.clark@mail.com',
    role: AccessSharingRole.Viewer,
    endTime: 'March 25, 2024 09:30 AM',
  },
  {
    id: 4,
    name: 'Walter Lee',
    email: 'walter.lee@mail.com',
    role: AccessSharingRole.Admin,
    startTime: 'March 25, 2024 09:30 AM',
    endTime: 'March 25, 2024 09:30 AM',
  },
  {
    id: 5,
    name: 'Hilton Schramm',
    email: 'hilton.schramm@mail.com',
    role: AccessSharingRole.Editor,
    endTime: 'March 25, 2024 09:30 AM',
  },
];
