import * as React from 'react';
import BannerEmailImg from '../../../assets/images/ill_banner_email.svg';

export function EmailSentNotification(props: { children: JSX.Element }) {
  return (
    <div className="alert-container">
      <span className="alert-icon">
        <img alt="Notification Success" src={BannerEmailImg}></img>
      </span>
      <span className="alert-text">{props.children}</span>
    </div>
  );
}
