import * as React from 'react';
import * as _ from 'lodash';
import { useNavigate, useParams } from 'react-router';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from 'assets/images/chevron_right.svg';
import { ic_guides_back as GuideBackIcon } from 'app/widgets/SVGs';
import { ic_guides_forward as GuideForwardIcon } from 'app/widgets/SVGs';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { ajax } from 'ajax/ajax';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { GuidesDisclaimerDialog } from './GuidesDisclaimer';
import { CacheContext } from 'app/components/ReactContexts/cacheContext';
import { tagEvent } from 'utils/ga';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';

export function GuidesPage() {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { getApiModels } = React.useContext(CacheContext);
  const { equipmentListData } = React.useContext(EquipmentListContext);
  const model =
    equipmentListData.selectedEquipment && params.id != undefined
      ? equipmentListData.selectedEquipment.model
      : params.model;
  const equipmentFilter = equipmentListData.selectedEquipment
    ? equipmentListData.selectedEquipment.id
    : params.id;
  const [selectedGuide, setSelectedGuide] = React.useState<any>();
  const [currentStep, setCurrentStep] = React.useState(0);
  const [fileText, setFileText] = React.useState('');
  const [guidesList, setGuidesList] = React.useState<Array<any>>();
  const [paramsExist, setParamsExist] = React.useState(true);
  const [showDialog, setShowDialog] = React.useState<any>(false);
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  const { addErrorModal } = React.useContext(GlobalErrorContext);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      setParamsExist(true);
      const apiModel = await getApiModels();
      if (apiModel.models && !apiModel.models.some(c => c.model == model)) {
        setParamsExist(false);
      }
    })();
    if (
      (params.id != undefined && params.id != equipmentFilter) ||
      (params.model && params.model != model)
    ) {
      setParamsExist(false);
    } else {
      const storedValue = localStorage.getItem('guidesDisclaimerAccepted');
      const guidesDisclaimerAccepted = storedValue
        ? JSON.parse(storedValue)
        : false;

      if (!guidesDisclaimerAccepted) {
        setShowDialog(true);
      }
      (async () => {
        try {
          const response = await ajax.getAsAnonymous(
            `${ajax.appBaseUrl}/apiv2/model/${model}/guides`,
          );
          if (response.status === 200) {
            setGuidesList(response.data);
          }
        } catch (ex) {
          addErrorModal();
        }
      })();
    }
    setIsLoading(false);
  }, [i18n.language]);

  React.useEffect(() => {
    if (selectedGuide) {
      const fetchFileText = async () => {
        try {
          const response = await fetch(
            `${selectedGuide.steps[currentStep].text}`,
          );
          const text = await response.text();
          setFileText(text);
        } catch (error) {
          console.error('Error fetching file:', error);
        }
      };

      fetchFileText();
    }
  }, [currentStep, selectedGuide]);

  if (paramsExist && selectedGuide && currentStep === 0) {
    tagEvent('load_guide', {
      model,
      name: selectedGuide.name,
    });
  }

  return (
    <>
      {isLoading ? (
        <div className="loading-full-page">
          <div className="loading">
            <div className="loading_text">{t('loading')}...</div>
            <div className="loading_icon">
              <div className="css-icon"></div>
            </div>
          </div>
        </div>
      ) : paramsExist ? (
        <div className={equipmentFilter ? 'container' : ''}>
          <div className="relative">
            <span
              className="back-button back-button-on-layout fixed-on-desktop"
              onClick={() => {
                if (selectedGuide) {
                  setSelectedGuide(null);
                  setCurrentStep(0);
                } else {
                  navigate(-1);
                }
              }}
            >
              <BackIcon />
            </span>
            {selectedGuide ? (
              <div className="box-content guides-box-content">
                <div className="guides-container">
                  <h3 className="fault-code-name">{model}</h3>
                </div>
                <h2 className="guides-title-box">
                  {currentStep > 0 && (
                    <span
                      className="guides-btn guides-btn-back"
                      onMouseDown={event => {
                        event.preventDefault();
                        setCurrentStep(currentStep - 1);
                      }}
                    >
                      <GuideBackIcon />
                    </span>
                  )}
                  <span className="guides-title">{selectedGuide.name}</span>
                  {currentStep < selectedGuide.steps.length - 1 && (
                    <span
                      className="guides-btn guides-btn-forward"
                      onMouseDown={event => {
                        event.preventDefault();
                        setCurrentStep(currentStep + 1);
                      }}
                    >
                      <GuideForwardIcon />
                    </span>
                  )}
                </h2>
                <div className="guides-container guides-container-content">
                  <div className="guides-step-text">
                    {t('guides_step_fmt', {
                      currentStep: currentStep + 1,
                      numberOfSteps: selectedGuide.steps.length,
                    })}
                  </div>
                  <div className="separator-l"></div>
                  <p>{fileText}</p>
                  <div className="separator-l"></div>
                  <div className="image">
                    <img src={selectedGuide.steps[currentStep].image} />
                  </div>
                  <div className="separator-l"></div>
                </div>
                <div className="guides-audio-container">
                  <audio key={selectedGuide.steps[currentStep].audio} controls>
                    <source
                      src={selectedGuide.steps[currentStep].audio}
                      type="audio/mp3"
                    />
                  </audio>
                </div>
              </div>
            ) : (
              <div className="box-content">
                <h3 className="fault-code-name">{model}</h3>
                <h2>{t('guides_list_title')}</h2>

                <div className="row section-margin-top">
                  <div className="col-sm-12">
                    <div className="arrow-links arrow-links-no-border">
                      {guidesList && guidesList.length > 0 ? (
                        <>
                          {_.map(guidesList, guide => (
                            <div
                              key={guide.name}
                              className="arrow-links_item"
                              onClick={() => {
                                setSelectedGuide(guide);
                              }}
                            >
                              <span>{guide.name}</span>
                              <img
                                className="arrow-links_icon arrow-links_icon-right"
                                src={ChevronRightIcon}
                              />
                            </div>
                          ))}
                        </>
                      ) : (
                        <div>{t('no_results_found')}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <NotFoundPage />
      )}
      {showDialog && <GuidesDisclaimerDialog showDialog={setShowDialog} />}
    </>
  );
}
