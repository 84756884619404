import { Button } from '@progress/kendo-react-buttons';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from '@progress/kendo-react-layout';
import { ajax } from 'ajax/ajax';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';
import { NotificationContext } from 'app/components/ReactContexts/notificationContext';
import { PushNotificationContext } from 'app/components/ReactContexts/pushNotificationContext';
import ClickOutside from 'app/widgets/ClickOutside';
import {
  ic_nav_back as BackIcon,
  ic_nav_close as CloseIcon,
  ic_notif_delete as DeleteNotifIcon,
  ill_empty_notif as EmptyNotificationIcon,
  ic_notif_mark_read as MarkAsReadIcon,
  ic_more as MoreIcon,
} from 'app/widgets/SVGs';
import * as _ from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Notification } from 'types/Notification';
import { DeleteNotificationsDialog } from './DeleteNotificationsDialog';

interface INotifications {
  isExpanded: boolean;
  notificationsList: Notification[];
  onUpdateNotificationsList: (updatedList: any[]) => void;
  updateIsExpanded: (updatedIsExpanded: boolean) => void;
  updateIsUpdateHoursExpanded: (updatedIsUpdateHoursExpanded: boolean) => void;
}

export function Notifications(props: INotifications) {
  const [currentNotificationsList, setCurrentNotificationsList] =
    React.useState<Notification[]>([]);
  const scrollPositionRef = React.useRef(0);
  const cardRef = React.useRef<HTMLDivElement | null>(null);
  const [positionHistory, setPositionHistory] = React.useState(0);

  const { t } = useTranslation();

  const [showNotificationMore, setShowNotificationMore] = React.useState(false);
  const [showGeneralMore, setShowGeneralMore] = React.useState(false);
  const [currentNotification, setCurrentNotification] = React.useState<any>();
  const { equipmentListData, setEquipmentListData } =
    React.useContext(EquipmentListContext);
  const [showNotificationDetails, setShowNotificationDetails] =
    React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [isAlertsSelected, setIsAlertsSelected] = React.useState(true);
  const [unreadAlertsCount, setUnreadAlertsCount] = React.useState(0);
  const [unreadMessagesCount, setUnreadMessagesCount] = React.useState(0);
  const [notificationEquipment, setNotificationEquipment] =
    React.useState<any>();
  const { pushNotifications, removePushNotification } = React.useContext(
    PushNotificationContext,
  );
  const [isCheckAll, setIsCheckAll] = React.useState(false);
  const [checkedList, setCheckedList] = React.useState<Array<string>>([]);
  const [deleteNotificationsExpanded, setDeleteNotificationsExpanded] =
    React.useState(false);
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [isGeneralSelection, setIsGeneralSelection] = React.useState(true);
  const { addErrorModal } = React.useContext(GlobalErrorContext);

  const { removeSnackbarNotification, addSnackbarNotification } =
    React.useContext(NotificationContext);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        setDeleteNotificationsExpanded(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  const isAlert = (notification: Notification) => {
    return notification.sourceFrom === 'telematics';
  };

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        setUnreadAlertsCount(
          props.notificationsList.filter(n => isAlert(n) && !n.isRead).length,
        );
        setUnreadMessagesCount(
          props.notificationsList.filter(n => !isAlert(n) && !n.isRead).length,
        );
        setCurrentNotificationsList(
          props.notificationsList.filter(notification =>
            isAlertsSelected ? isAlert(notification) : !isAlert(notification),
          ),
        );
      } catch (ex) {
        addErrorModal();
      } finally {
        setLoading(false);
      }
    })();
  }, [isAlertsSelected, props.notificationsList]);

  React.useEffect(() => {
    if (cardRef.current) {
      cardRef.current.scrollTo(0, positionHistory);
    }
  }, [showNotificationDetails == false]);

  React.useEffect(() => {
    if (pushNotifications.length === 0) {
      return;
    }

    const messageId = pushNotifications[pushNotifications.length - 1];
    const notification = props.notificationsList.filter(
      n => n.id == messageId,
    )?.[0];

    if (notification == undefined) {
      return;
    }

    if (notification) {
      setCurrentNotification(notification);
      setShowNotificationDetails(true);
      setShowNotificationMore(false);
      markAsRead([messageId]);
      const updatedList = props.notificationsList.map(n =>
        messageId === n.id ? { ...n, isRead: true } : n,
      );
      props.onUpdateNotificationsList(updatedList);
      setIsAlertsSelected(isAlert(notification));
    }
  }, [pushNotifications]);

  const deleteMessage = async ids => {
    try {
      await ajax.delete(`${ajax.appBaseUrl}/apiv2/user/inbox`, {
        data: { uuids: ids },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const markAsRead = async (ids: Array<string>) => {
    const chunkSize = 2000;

    const idChunks: Array<Array<string>> = [];
    for (let i = 0; i < ids.length; i += chunkSize) {
      idChunks.push(ids.slice(i, i + chunkSize));
    }

    for (const chunk of idChunks) {
      try {
        await ajax.post(
          `${ajax.appBaseUrl}/apiv2/user/inbox/markAsRead`,
          {
            uuids: chunk,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
      } catch (e) {
        addErrorModal();
      }
    }
  };

  const markAsUnread = async ids => {
    try {
      await ajax.post(
        `${ajax.appBaseUrl}/apiv2/user/inbox/markAsUnread`,
        {
          uuids: ids,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      var updatedList = props.notificationsList;
      for (const id of ids) {
        updatedList = updatedList.map(notification =>
          notification.id === id
            ? { ...notification, isRead: false }
            : notification,
        );
      }
      props.onUpdateNotificationsList(updatedList);
    } catch (e) {
      addErrorModal();
    }
  };

  const allMarkAsRead = async () => {
    setShowGeneralMore(false);
    var updatedList = props.notificationsList;
    for (const item of currentNotificationsList) {
      updatedList = updatedList.map(notification =>
        notification.id === item.id
          ? { ...notification, isRead: true }
          : notification,
      );
    }
    props.onUpdateNotificationsList(updatedList);

    setCheckedList([]);
    setIsCheckAll(false);

    var notificationsIds = currentNotificationsList
      .filter(item => item.isRead === false)
      .map(n => n.id);

    if (notificationsIds.length > 0) {
      await markAsRead(notificationsIds);
    }
  };

  const bulkMarkAsRead = async () => {
    var updatedList = props.notificationsList;
    for (const item of checkedList) {
      updatedList = updatedList.map(notification =>
        notification.id === item
          ? { ...notification, isRead: true }
          : notification,
      );
    }
    props.onUpdateNotificationsList(updatedList);
    setCheckedList([]);
    setIsCheckAll(false);

    const unreadCheckedList = props.notificationsList
      .filter(item => checkedList.includes(item.id) && item.isRead === false)
      .map(item => item.id);

    if (unreadCheckedList.length > 0) {
      await markAsRead(unreadCheckedList);
    }
  };

  const handleCardScroll = () => {
    if (cardRef.current) {
      scrollPositionRef.current = cardRef.current.scrollTop;
      setPositionHistory(cardRef.current.scrollTop);
    }
  };

  const handleCheck = e => {
    const { id, checked } = e.target;

    setCheckedList([...checkedList, id]);
    if (!checked) {
      setCheckedList(checkedList.filter(item => item !== id));
    }
    if (checked && currentNotificationsList.length == checkedList.length + 1) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  };

  const handleCheckAll = e => {
    setIsCheckAll(!isCheckAll);
    setCheckedList(currentNotificationsList.map(li => li.id));
    if (isCheckAll) {
      setCheckedList([]);
    }
  };

  const undoTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const deleteNotifications = async () => {
    setShowSnackbar(true);
    addSnackbarNotification(
      <div className="snackbar-container">
        {isAlertsSelected ? (
          <span>
            {t('deleted_alerts', {
              d: !isGeneralSelection
                ? checkedList.length
                : props.notificationsList.filter(notification =>
                    isAlert(notification),
                  ).length,
            })}
          </span>
        ) : (
          <span>
            {t('deleted_messages', {
              d: !isGeneralSelection
                ? checkedList.length
                : props.notificationsList.filter(
                    notification => !isAlert(notification),
                  ).length,
            })}
          </span>
        )}
        <span
          className="undo-button"
          onClick={() => {
            handleUndo();
          }}
        >
          {t('undo_action')}
        </span>
      </div>,
      6000,
    );

    setDeleteNotificationsExpanded(false);
    if (!isGeneralSelection && checkedList && checkedList.length > 0) {
      var updatedList = currentNotificationsList;
      checkedList.forEach(item => {
        updatedList = updatedList.filter(n => n.id != item);
      });
      setCurrentNotificationsList(updatedList);
      if (isAlertsSelected) {
        setUnreadAlertsCount(
          updatedList.filter(n => isAlert(n) && !n.isRead).length,
        );
      } else {
        setUnreadMessagesCount(
          updatedList.filter(n => !isAlert(n) && !n.isRead).length,
        );
      }
    } else if (isGeneralSelection) {
      setCurrentNotificationsList([]);
      if (!isAlertsSelected) {
        setUnreadMessagesCount(0);
      } else {
        setUnreadAlertsCount(0);
      }
    }
    setCheckedList([]);
    setIsCheckAll(false);

    undoTimeoutRef.current = setTimeout(async () => {
      setShowSnackbar(false);

      var notificationsToDelete = isGeneralSelection
        ? props.notificationsList
            .filter(n => (isAlertsSelected ? isAlert(n) : !isAlert(n)))
            .map(n => n.id)
        : checkedList;

      var newList = props.notificationsList;

      for (const item of notificationsToDelete) {
        newList = newList.filter(n => n.id !== item);
      }
      props.onUpdateNotificationsList(newList);
      await deleteMessage(notificationsToDelete);
    }, 6000);
  };

  const handleUndo = () => {
    if (undoTimeoutRef.current) {
      clearTimeout(undoTimeoutRef.current);
    }
    setShowSnackbar(false);
    removeSnackbarNotification();

    setCurrentNotificationsList(
      props.notificationsList.filter(notification =>
        isAlertsSelected ? isAlert(notification) : !isAlert(notification),
      ),
    );

    if (isAlertsSelected) {
      setUnreadAlertsCount(
        props.notificationsList.filter(n => isAlert(n) && !n.isRead).length,
      );
    } else {
      setUnreadMessagesCount(
        props.notificationsList.filter(n => !isAlert(n) && !n.isRead).length,
      );
    }
  };

  const formatDate = date => {
    const inputDate = new Date(date);
    const currentDate = new Date();

    const isToday =
      inputDate.getDate() === currentDate.getDate() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear();

    if (isToday) {
      return t('today');
    } else {
      const formattedDate = inputDate.toLocaleDateString('en-us', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      });
      return formattedDate;
    }
  };

  const formatBody = body => {
    const websiteRegex = /\[(.*?)\]\((https?:\/\/\S+?)\)/g;
    return body.replace(websiteRegex, (match, label, url) => {
      return `<a href="${url}" target="_blank">${label}</a>`;
    });
  };

  const createMarkup = text => ({ __html: text });

  const getDeepLinkUrl = () => {
    switch (currentNotification.deepLink.alertId) {
      case 'BAT-L-LOW':
      case 'COOL-T-HIGH':
      case 'DEF-L-LOW':
      case 'HYDR-T-HIGH':
      case 'FUEL-L-LOW':
      case 'WARRANTY':
        return `/equipment/${currentNotification.deepLink.exposedEquipmentId}`;
      case 'FAULT-CODE':
        return `/equipment/${currentNotification.deepLink.exposedEquipmentId}/faultCodes`;
      case 'MAINTENANCE':
        return `/equipment/${currentNotification.deepLink.exposedEquipmentId}/maintenanceSchedule`;
      case 'TRANSPORT':
      case 'GEOFENCE-OUT':
        return `/equipment/geofences`;
      default:
        break;
    }
  };

  const getDeepLinkLabel = () => {
    switch (currentNotification.deepLink.alertId) {
      case 'BAT-L-LOW':
        return t('view_battery_level');
      case 'COOL-T-HIGH':
        return t('view_coolant_temp');
      case 'DEF-L-LOW':
        return t('view_def_level');
      case 'HYDR-T-HIGH':
        return t('view_hydraulic_temp');
      case 'FUEL-L-LOW':
        return t('view_fuel_level');
      case 'WARRANTY':
        return t('view_warranty_info');
      case 'FAULT-CODE':
        return t('view_fault_code');
      case 'MAINTENANCE':
        return t('view_maintenance_schedule');
      case 'TRANSPORT':
      case 'GEOFENCE-OUT':
        return t('view_location');
      default:
        break;
    }
  };

  const checkInFilteredList = () => {
    let filteredList = equipmentListData.equipmentList;
    const filtersList = equipmentListData.filtersList;
    if (filtersList && filtersList.includes('active_fault_codes')) {
      filteredList = filteredList?.filter(
        f =>
          f.telematics &&
          f.telematics.faultCodes &&
          f.telematics?.faultCodes?.length > 0,
      );
    }

    if (filtersList && filtersList.includes('machine_running')) {
      filteredList = filteredList?.filter(
        f => f.telematics && f.telematics.engineRunning,
      );
    }

    if (filtersList && filtersList.includes('machine_in_transport')) {
      filteredList = filteredList?.filter(
        f => f.telematics && f.telematics.motionState == 'in-transport',
      );
    }

    if (filtersList && filtersList.includes('restart_inhibited')) {
      filteredList = filteredList?.filter(
        f =>
          f.telematics &&
          f.telematics.restartInhibitStatus != null &&
          f.telematics.restartInhibitStatus?.equipmentStatus ==
            'RestartDisabled',
      );
    }

    if (filteredList?.find(item => item.id == notificationEquipment.id))
      return true;
    return false;
  };

  return (
    <ClickOutside
      onClickOutside={e => {
        const target = e.target as HTMLButtonElement;
        if (
          !deleteNotificationsExpanded &&
          !showSnackbar &&
          ![
            'btn-notif',
            'ic_menu_notif',
            'tab-number-notif',
            'bell-bottom',
            'bell-bottom1',
          ].find(el => el === target.id)
        )
          props.updateIsExpanded(false);
      }}
    >
      <>
        <Card className="top-card card-notifications">
          {!showNotificationDetails && (
            <>
              <CardHeader>
                <CardTitle>
                  {checkedList && checkedList.length > 0 ? (
                    <>
                      <span
                        className="close-icon"
                        onClick={() => {
                          setCheckedList([]);
                          setIsCheckAll(false);
                        }}
                      >
                        <CloseIcon />
                      </span>
                      <span>
                        {t('selected_notifications', {
                          d: checkedList.length,
                        })}
                      </span>
                    </>
                  ) : (
                    <>{t('notifications')}</>
                  )}
                </CardTitle>
                {checkedList &&
                  checkedList.length == 0 &&
                  currentNotificationsList.length > 0 && (
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setShowGeneralMore(!showGeneralMore);
                      }}
                    >
                      <MoreIcon />
                    </span>
                  )}
              </CardHeader>
              {showGeneralMore && (
                <span className="notification-more dot-menu-dropdown">
                  <ClickOutside
                    onClickOutside={() => {
                      setShowGeneralMore(false);
                    }}
                  >
                    <div className="dot-menu-dropdown-options">
                      <div>
                        <>
                          {isAlertsSelected ? (
                            <>
                              <span
                                onClick={async () => {
                                  setIsGeneralSelection(true);
                                  await allMarkAsRead();
                                }}
                              >
                                {t('mark_alerts_as_read')}
                              </span>
                              <span
                                onClick={() => {
                                  setIsGeneralSelection(true);
                                  setDeleteNotificationsExpanded(true);
                                  setShowGeneralMore(false);
                                }}
                              >
                                {t('delete_alerts')}
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                onClick={async () => {
                                  setIsGeneralSelection(true);
                                  await allMarkAsRead();
                                }}
                              >
                                {t('mark_messages_as_read')}
                              </span>
                              <span
                                onClick={() => {
                                  setIsGeneralSelection(true);
                                  setDeleteNotificationsExpanded(true);
                                  setShowGeneralMore(false);
                                }}
                              >
                                {t('delete_messages')}
                              </span>
                            </>
                          )}
                        </>
                      </div>
                    </div>
                  </ClickOutside>
                </span>
              )}
            </>
          )}
          <CardBody>
            {!showNotificationDetails && (
              <div className="notifications-tab-buttons">
                <Button
                  disabled={
                    !isAlertsSelected && checkedList && checkedList.length > 0
                  }
                  className="button-fault-codes-tab"
                  themeColor={isAlertsSelected ? 'primary' : null}
                  onClick={() => {
                    setIsAlertsSelected(true);
                    setCurrentNotification(null);
                    setShowNotificationMore(false);
                    setShowNotificationDetails(false);
                    setCheckedList([]);
                    setIsCheckAll(false);
                  }}
                >
                  {t('alerts')}
                  <span className="tab-number">
                    {!loading && currentNotificationsList
                      ? unreadAlertsCount > 9
                        ? '9+'
                        : unreadAlertsCount == 0
                        ? ''
                        : unreadAlertsCount
                      : ''}
                  </span>
                </Button>
                <Button
                  disabled={
                    isAlertsSelected && checkedList && checkedList.length > 0
                  }
                  className="button-fault-codes-tab"
                  themeColor={!isAlertsSelected ? 'primary' : null}
                  onClick={() => {
                    setIsAlertsSelected(false);
                    setCurrentNotification(null);
                    setShowNotificationMore(false);
                    setShowNotificationDetails(false);
                    setCheckedList([]);
                    setIsCheckAll(false);
                  }}
                >
                  {t('messages')}
                  <span className="tab-number">
                    {!loading && currentNotificationsList
                      ? unreadMessagesCount > 9
                        ? '9+'
                        : unreadMessagesCount == 0
                        ? ''
                        : unreadMessagesCount
                      : ''}
                  </span>
                </Button>
              </div>
            )}
            <div
              className="card-notifications-container"
              ref={cardRef}
              onScroll={() => {
                if (!showNotificationDetails) handleCardScroll();
              }}
            >
              {!showNotificationDetails ? (
                <>
                  {loading ? (
                    <div className="loading-full-page">
                      <div className="loading">
                        <div className="loading_text">{t('loading')}...</div>
                        <div className="loading_icon">
                          <div className="css-icon"></div>
                        </div>
                      </div>
                    </div>
                  ) : !currentNotificationsList ||
                    currentNotificationsList.length === 0 ? (
                    isAlertsSelected ? (
                      <div className="notifications-no-item-found">
                        <EmptyNotificationIcon />
                        <span>{t('no_alerts')}</span>
                      </div>
                    ) : (
                      <div className="notifications-no-item-found">
                        <EmptyNotificationIcon />
                        <span>{t('no_messages')}</span>
                      </div>
                    )
                  ) : (
                    _.map(currentNotificationsList, (notification, index) => {
                      return (
                        <div key={notification.id}>
                          <input
                            id={notification.id}
                            type="checkbox"
                            onChange={handleCheck}
                            checked={checkedList.includes(notification.id)}
                          />
                          {notification.isRead ? (
                            <span
                              className="notification-box read-notification"
                              onClick={() => {
                                setCurrentNotification(notification);
                                setShowNotificationDetails(true);
                                setShowNotificationMore(false);
                                if (cardRef.current) {
                                  setPositionHistory(cardRef.current.scrollTop);
                                }
                                if (
                                  notification.deepLink &&
                                  notification.deepLink.exposedEquipmentId
                                ) {
                                  const notificationEquipment =
                                    equipmentListData.equipmentList?.find(
                                      equipment =>
                                        equipment.id ===
                                        notification.deepLink
                                          .exposedEquipmentId,
                                    );
                                  setNotificationEquipment(
                                    notificationEquipment,
                                  );
                                } else {
                                  setNotificationEquipment(null);
                                }
                              }}
                            >
                              <div>
                                <div className="notification-title">
                                  {notification.title}
                                </div>
                                <div
                                  className="notification-text"
                                  dangerouslySetInnerHTML={createMarkup(
                                    formatBody(notification.body),
                                  )}
                                ></div>
                              </div>
                              <div className="notification-date">
                                {formatDate(notification.createdTime)}
                              </div>
                            </span>
                          ) : (
                            <span
                              className="notification-box"
                              onClick={async () => {
                                setCurrentNotification(notification);
                                setShowNotificationDetails(true);
                                await markAsRead([notification.id]);
                                var updatedList = props.notificationsList.map(
                                  n =>
                                    notification.id === n.id
                                      ? { ...n, isRead: true }
                                      : n,
                                );
                                props.onUpdateNotificationsList(updatedList);
                                setShowNotificationMore(false);
                                if (cardRef.current) {
                                  setPositionHistory(cardRef.current.scrollTop);
                                }
                                if (
                                  notification.deepLink &&
                                  notification.deepLink.exposedEquipmentId
                                ) {
                                  const notificationEquipment =
                                    equipmentListData.equipmentList?.find(
                                      equipment =>
                                        equipment.id ===
                                        notification.deepLink
                                          .exposedEquipmentId,
                                    );
                                  setNotificationEquipment(
                                    notificationEquipment,
                                  );
                                } else {
                                  setNotificationEquipment(null);
                                }
                              }}
                            >
                              <div>
                                <div className="notification-title">
                                  {notification.title}
                                </div>
                                <div
                                  className="notification-text"
                                  dangerouslySetInnerHTML={createMarkup(
                                    formatBody(notification.body),
                                  )}
                                ></div>
                              </div>
                              <div className="notification-date">
                                {formatDate(notification.createdTime)}
                              </div>
                            </span>
                          )}
                        </div>
                      );
                    })
                  )}
                </>
              ) : (
                currentNotification && (
                  <>
                    <div className="relative notification-open-box">
                      <span
                        className="back-button back-button-on-layout"
                        onClick={() => {
                          setCurrentNotification(null);
                          setShowNotificationMore(false);
                          setShowNotificationDetails(false);
                          if (cardRef.current) {
                            cardRef.current.scrollTo(0, positionHistory);
                          }
                          if (
                            pushNotifications.includes(currentNotification.id)
                          ) {
                            removePushNotification(currentNotification.id);
                          }
                        }}
                      >
                        <BackIcon />
                      </span>

                      <span className="notification-more dot-menu-dropdown">
                        <ClickOutside
                          onClickOutside={() => {
                            setShowNotificationMore(false);
                          }}
                        >
                          <>
                            <span
                              onClick={() => {
                                setShowNotificationMore(!showNotificationMore);
                              }}
                            >
                              <MoreIcon />
                            </span>
                            {showNotificationMore && (
                              <div className="dot-menu-dropdown-options">
                                <div>
                                  <>
                                    <span
                                      id="markAsUnread"
                                      onClick={() => {
                                        markAsUnread([currentNotification.id]);
                                        setShowNotificationDetails(false);
                                        setCurrentNotification(null);
                                      }}
                                    >
                                      {t('mark_as_unread')}
                                    </span>
                                    <span
                                      id="delete"
                                      onClick={async () => {
                                        await deleteMessage([
                                          currentNotification.id,
                                        ]);
                                        setCheckedList(
                                          checkedList.filter(
                                            n => n !== currentNotification.id,
                                          ),
                                        );
                                        setCurrentNotification(null);
                                        const updatedList =
                                          props.notificationsList.filter(
                                            n =>
                                              n.id !== currentNotification.id,
                                          );
                                        props.onUpdateNotificationsList(
                                          updatedList,
                                        );
                                        setShowNotificationDetails(false);
                                      }}
                                    >
                                      {t('menu_delete')}
                                    </span>
                                  </>
                                </div>
                              </div>
                            )}
                          </>
                        </ClickOutside>
                      </span>

                      <span className="notification-box">
                        <div>
                          <div className="notification-title">
                            {currentNotification.title}
                          </div>
                          <div
                            className="notification-text"
                            dangerouslySetInnerHTML={createMarkup(
                              formatBody(currentNotification.body),
                            )}
                          ></div>
                        </div>
                        <div className="notification-date">
                          {formatDate(currentNotification.createdTime)}
                        </div>
                      </span>
                      {notificationEquipment && (
                        <>
                          <div>
                            {notificationEquipment.identifierType == 'Pin'
                              ? t('equipment_pin')
                              : t('equipment_serial')}
                            : {notificationEquipment.pinOrSerial}
                          </div>
                          <div>
                            {t('equipment_model')}:{' '}
                            {notificationEquipment.model}
                          </div>
                          <div className="separator-m"></div>
                        </>
                      )}

                      {currentNotification.deepLink &&
                        currentNotification.deepLink.alertId != undefined &&
                        currentNotification.deepLink.alertId != '' && (
                          <div>
                            <Link
                              to={{ pathname: getDeepLinkUrl() }}
                              onClick={() => {
                                if (checkInFilteredList()) {
                                  setEquipmentListData({
                                    ...equipmentListData,
                                    selectedEquipment: notificationEquipment,
                                    panTo: {
                                      position: {
                                        lat: notificationEquipment.telematics
                                          ?.location?.latitude,
                                        lng: notificationEquipment.telematics
                                          ?.location?.longitude,
                                      },
                                    },
                                  });
                                } else {
                                  setEquipmentListData({
                                    ...equipmentListData,
                                    filtersList: [],
                                    selectedEquipment: notificationEquipment,
                                    panTo: {
                                      position: {
                                        lat: notificationEquipment.telematics
                                          ?.location?.latitude,
                                        lng: notificationEquipment.telematics
                                          ?.location?.longitude,
                                      },
                                    },
                                  });
                                }

                                props.updateIsExpanded(false);
                              }}
                            >
                              {getDeepLinkLabel()}
                            </Link>
                          </div>
                        )}

                      {currentNotification.deepLink &&
                        currentNotification.deepLink.workflow != undefined &&
                        currentNotification.deepLink.workflow != '' && (
                          <div>
                            <Link
                              to={{ pathname: '' }}
                              onClick={() => {
                                props.updateIsExpanded(false);
                                props.updateIsUpdateHoursExpanded(true);
                              }}
                            >
                              {t('view_update_hours')}
                            </Link>
                          </div>
                        )}
                    </div>
                  </>
                )
              )}
            </div>
            {!showNotificationDetails && checkedList && checkedList.length > 0 && (
              <div className="notifications-bar">
                <div className="notifications-bar-select-all">
                  <input
                    className="m-l-12"
                    type="checkbox"
                    name="selectAll"
                    id="selectAll"
                    onChange={handleCheckAll}
                    checked={isCheckAll}
                  />
                  <label>{t('fault_mode_all')}</label>
                </div>
                <div className="notifications-bar-buttons">
                  <span
                    onClick={async () => {
                      setIsGeneralSelection(false);
                      await bulkMarkAsRead();
                    }}
                  >
                    <MarkAsReadIcon />
                    <span>{t('mark_as_read')}</span>
                  </span>
                  <span
                    onClick={() => {
                      setIsGeneralSelection(false);
                      setDeleteNotificationsExpanded(true);
                    }}
                  >
                    <DeleteNotifIcon />
                    <span>{t('menu_delete')}</span>
                  </span>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
        {deleteNotificationsExpanded && (
          <DeleteNotificationsDialog
            isLoading={false}
            isGeneralSelection={isGeneralSelection}
            close={() => {
              setDeleteNotificationsExpanded(false);
            }}
            isAlertsSelected={isAlertsSelected}
            deleteNotifications={async () => {
              await deleteNotifications();
            }}
          />
        )}
      </>
    </ClickOutside>
  );
}
