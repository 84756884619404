import { SharedUserAccessCard } from 'app/components/access-sharing/cards';
import { SharedUserAccess } from 'app/components/access-sharing/cards/SharedUserAccessCard/types';
import { ActionsListItem } from 'app/components/menu';
import useHasAccessSharingPermissions from 'app/hooks/useHasAccessSharingPermissions';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessSharingRole } from 'types/entities/AccessSharingRole';

interface Props {
  equipmentRole: AccessSharingRole;
  userAccess: SharedUserAccess[];
  expiredUserAccess: SharedUserAccess[];
  onChangeAccess: (userAccess: SharedUserAccess) => void;
  onRevokeAccess: (userAccess: SharedUserAccess) => void;
}

export default function MachineDetailsUserAccessCards({
  equipmentRole,
  userAccess,
  expiredUserAccess,
  onChangeAccess,
  onRevokeAccess,
}: Props) {
  const { t } = useTranslation();
  const hasAccess = useHasAccessSharingPermissions(equipmentRole);

  const generateCardMenuActions = useCallback(
    (data: SharedUserAccess): ActionsListItem[] => [
      {
        label: t('access_sharing.role_based_actions.change_permissions'),
        exclude: !hasAccess('manageUserAccess'),
        action: () => {
          onChangeAccess(data);
        },
      },
      {
        label: t('access_sharing.role_based_actions.revoke_access'),
        action: () => {
          onRevokeAccess(data);
        },
      },
    ],
    [hasAccess],
  );

  return (
    <>
      {userAccess.map(data => (
        <SharedUserAccessCard
          key={data.id}
          userAccess={data}
          actions={generateCardMenuActions(data)}
        />
      ))}

      {!!expiredUserAccess.length && (
        <span className="machine-details-page__expired-access-label">
          {t('access_sharing.machine_details.expired_access')}
        </span>
      )}

      {expiredUserAccess.map(data => (
        <SharedUserAccessCard
          key={data.id}
          userAccess={data}
          isExpired
          actions={generateCardMenuActions(data)}
        />
      ))}
    </>
  );
}
