import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { ill_delete as DeleteIcon } from 'app/widgets/SVGs';
import { useTranslation } from 'react-i18next';
import './DeleteDialog.less';
import useClickOutside from 'app/hooks/useClickOutside';

export interface Props {
  open: boolean;
  title: string;
  confirmationLabel?: string;
  closeLabel?: string;
  onConfirm: () => void;
  onClose: () => void;
}

export default function DeleteDialog({
  open,
  title,
  confirmationLabel,
  closeLabel,
  onConfirm,
  onClose,
}: Props) {
  const { t } = useTranslation();

  // Note: there's a point in creating a wrapper around the Dialog and adding an onClickOutside prop
  useClickOutside(onClose);

  if (!open) {
    return null;
  }

  return (
    <Dialog className="modal-center modal-remove-item modal-fulltext">
      <div className="delete-dialog">
        <DeleteIcon />

        <h1 className="delete-dialog__title">{title}</h1>

        <div className="delete-dialog__actions">
          <Button className="delete-dialog__button" onClick={onClose}>
            {closeLabel ?? t('cancel')}
          </Button>

          <Button
            className="delete-dialog__button"
            themeColor="error"
            onClick={onConfirm}
          >
            {confirmationLabel ?? t('remove')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
