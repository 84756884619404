import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { IInhibitRestartMetadata, InhibitRestartStatus } from './type';

export const getInhibitRestartMetadata = (
  selectedEquipment?: IEquipmentEntry,
): IInhibitRestartMetadata => {
  const currentCommandStatus =
    selectedEquipment?.telematics?.restartInhibitStatus?.commandStatus;
  const currentEquipmentStatus =
    selectedEquipment?.telematics?.restartInhibitStatus?.equipmentStatus;
  const currentEquimentFeatureStatus =
    selectedEquipment?.telematics?.restartInhibitStatus?.equipmentFeatureStatus;

  const hasPendingCommand = currentCommandStatus !== currentEquipmentStatus;

  const {
    isEnabled,
    commandStatusToSend,
  }: {
    isEnabled: boolean | undefined;
    commandStatusToSend: string | undefined;
  } = hasPendingCommand
    ? { isEnabled: false, commandStatusToSend: undefined }
    : currentEquipmentStatus === InhibitRestartStatus.RestartEnabled
    ? {
        isEnabled: true,
        commandStatusToSend: InhibitRestartStatus.RestartDisabled,
      }
    : {
        isEnabled: false,
        commandStatusToSend: InhibitRestartStatus.RestartEnabled,
      };
  const isEnableing = hasPendingCommand
    ? currentCommandStatus === InhibitRestartStatus.RestartEnabled
      ? true
      : false
    : false;

  const isLocallyDisabled =
    currentEquimentFeatureStatus === 'enabled' ? false : true;
  return {
    hasPendingCommand,
    isEnabled,
    isEnableing,
    commandStatusToSend,
    isLocallyDisabled,
  };
};
