import React from 'react';

export default function AddIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="ic_btn_add"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.width ?? '24'}
      height={props.height ?? '24'}
      {...props}
    >
      <rect id="Box" width="24" height="24" fill="none" />
      <line
        id="Line"
        x2="16"
        transform="translate(4 12)"
        fill="none"
        stroke={props.stroke ?? '#1a1a1a'}
        strokeLinecap="round"
        strokeWidth={props.strokeWidth ?? '1.5'}
      />
      <line
        id="Line-2"
        data-name="Line"
        x2="16"
        transform="translate(12 4) rotate(90)"
        fill="none"
        stroke={props.stroke ?? '#1a1a1a'}
        strokeLinecap="round"
        strokeWidth={props.strokeWidth ?? '1.5'}
      />
    </svg>
  );
}
