import * as React from 'react';
import { MarkerF, PolygonF } from '@react-google-maps/api';
import { IGeofenceEntry, IPointEntry } from 'types/IGeofenceEntry';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import _ from 'lodash';
import ClickOutside from 'app/widgets/ClickOutside';
import { useIsMobile } from 'app/hooks/useIsMobile';

interface IGeofencePolygon {
  geofence: IGeofenceEntry;
  currentZoom: number;
}

export function GeofencePolygon(props: IGeofencePolygon) {
  const { setEquipmentListData, equipmentListData } =
    React.useContext(EquipmentListContext);

  const sumOfPoints = _.reduce(
    _.take(props.geofence.points, props.geofence.points.length - 1),
    (sumOfPoints: IPointEntry, currentPoint) => {
      return {
        latitude: sumOfPoints.latitude + currentPoint.latitude,
        longitude: sumOfPoints.longitude + currentPoint.longitude,
      };
    },
  );
  const centroid = {
    latitude: sumOfPoints!.latitude / (props.geofence.points.length - 1),
    longitude: sumOfPoints!.longitude / (props.geofence.points.length - 1),
  } as IPointEntry;

  const isMobile = useIsMobile();

  return (
    <>
      {props.currentZoom >= 12 && (
        <MarkerF
          key={'text_marker_' + props.geofence.description}
          position={{ lat: centroid.latitude, lng: centroid.longitude }}
          label={{
            text: props.geofence.description,
            className: 'pseudo_label',
          }}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            fillOpacity: 0,
            strokeOpacity: 0,
          }}
        />
      )}
      {props.geofence.id === equipmentListData.selectedGeofence?.id &&
      !isMobile ? (
        <ClickOutside
          onClickOutside={(e: any) => {
            if (e.target.parentElement.parentElement.id !== 'listandmap') {
              setEquipmentListData(oldData => ({
                ...oldData,
                selectedGeofence: undefined,
              }));
            }
          }}
        >
          <PolygonF
            onClick={() => {
              setEquipmentListData(oldData => ({
                ...oldData,
                selectedGeofence: props.geofence,
                panTo: {
                  latLongBonds: props.geofence.points,
                },
              }));
            }}
            paths={props.geofence.points
              .filter((v, index) => index < props.geofence.points.length - 1)
              .map(g => ({
                lat: g.latitude,
                lng: g.longitude,
              }))}
            options={{
              strokeColor: '#DC4405',
              fillColor: '#DC44054D',
              strokeWeight:
                equipmentListData.selectedGeofence?.id === props.geofence.id
                  ? 3
                  : 1,
              fillOpacity: 1,
            }}
          />
        </ClickOutside>
      ) : (
        <PolygonF
          onClick={() => {
            setEquipmentListData(oldData => ({
              ...oldData,
              selectedGeofence: props.geofence,
              panTo: {
                latLongBonds: props.geofence.points,
              },
            }));
          }}
          paths={props.geofence.points
            .filter((v, index) => index < props.geofence.points.length - 1)
            .map(g => ({
              lat: g.latitude,
              lng: g.longitude,
            }))}
          options={{
            strokeColor: '#DC4405',
            fillColor: '#DC44054D',
            strokeWeight:
              equipmentListData.selectedGeofence?.id === props.geofence.id
                ? 3
                : 1,
            fillOpacity: 1,
          }}
        />
      )}
    </>
  );
}
