import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { ajax } from 'ajax/ajax';
import { PasswordDecorators } from 'app/widgets/PasswordDecorators';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { emailRegex } from 'app/common/utils';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { tagEvent, usePageTracking } from 'utils/ga';
import HidePassIcon from '../../../assets/images/ic_field_hidepass.svg';
import ShowPassIcon from '../../../assets/images/ic_field_showpass.svg';

export function CreateAccountPage() {
  usePageTracking();

  const [emailError, setEmailError] = React.useState('');
  const [email, setEmail] = React.useState('');

  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [phoneNumberError, setPhoneNumberError] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [repeatPassword, setRepeatPassword] = React.useState('');
  const [repeatPasswordError, setRepeatPasswordError] = React.useState('');
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] =
    React.useState(false);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024);
  const [language, setLanguage] = React.useState(i18next.resolvedLanguage);
  const [isCanada, setIsCanada] = React.useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  React.useEffect(() => {
    window.addEventListener('resize', e => {
      window.innerWidth < 1024 ? setIsMobile(true) : setIsMobile(false);
    });
  }, [window.innerWidth]);

  const validateEmail = () => {
    if (!email) {
      setEmailError(t('field_is_required'));
      return false;
    }

    if (!email.toLowerCase().match(emailRegex)) {
      setEmailError(t('incorrect_email_format'));
      return false;
    }
    return true;
  };

  const validatePhoneNumber = () => {
    if (!phoneNumber.replace(/\D/g, '')) {
      setPhoneNumberError(t('field_is_required'));
      return false;
    }
    if (!phoneNumber.replace(/\D/g, '').match(/^\+{0,1}\d{9,11}$/)) {
      setPhoneNumberError(t('phone_incorrect_error'));
      return false;
    }
    return true;
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError(t('field_is_required'));
      return false;
    }
    if (
      password.length < 8 ||
      !password.match(/^.*[a-zA-Z]+.*$/) ||
      !password.match(/^.*[0-9]+.*$/) ||
      !password.match(/[^\w\s]/g)
    ) {
      setPasswordError(t('password_insecure'));
      return false;
    }
    return true;
  };

  const validateConfirmPassword = () => {
    if (!repeatPassword) {
      setRepeatPasswordError(t('field_is_required'));
      return false;
    }
    if (password !== repeatPassword) {
      setRepeatPasswordError(t('passwords_do_not_match'));
      return false;
    }
    return true;
  };

  const validateForm = () => {
    let isValid = true;
    isValid = validateEmail() && isValid;
    isValid = validatePhoneNumber() && isValid;
    isValid = validatePassword() && isValid;
    isValid = validateConfirmPassword() && isValid;
    return isValid;
  };

  const [isSubmiting, setIsSubmiting] = React.useState(false);

  const loginButtonClicked = () => {
    if (!validateForm()) {
      return;
    }
    const params = new URLSearchParams();
    params.append('email', email);
    params.append('password', password);
    params.append('phone_number', phoneNumber.replace(/\D/g, ''));
    setIsSubmiting(true);
    ajax
      .postAsAnonymous(`${ajax.appBaseUrl}/oauth/user`, params)
      .then(() => {
        ajax.getLogin(email, password, '/');
        tagEvent('create_account');
      })
      .catch(e => {
        if (e.response.data.errorCode == 'error.email_exists') {
          setEmailError(t('email_exists'));
          return;
        }

        if (e.response.data.errorCode == 'NotAValidPhoneNumber') {
          setPhoneNumberError(t('phone_number_invalid'));
          return;
        }
        setEmailError(e.response.data.errorMessage);
        //throw e;
      })
      .finally(() => {
        setIsSubmiting(false);
        localStorage.setItem('justLoggedOn', 'true');
      });
  };

  const handleTermsClick = () => {
    isCanada
      ? language === 'en'
        ? window.open('https://kubota.ca/terms-conditions', '_blank')
        : window.open('https://kubota.ca/fr/terms-conditions', '_blank')
      : window.open(
          ' https://www.kubotausa.com/terms-of-use/kusa-terms-of-use',
          '_blank',
        );
  };

  React.useEffect(() => {
    window.navigator.language.split('-')[1]?.toLocaleUpperCase() === 'CA' &&
      setIsCanada(true);
  }, []);

  React.useEffect(
    () => setLanguage(i18next.resolvedLanguage),
    [i18next.resolvedLanguage],
  );

  return (
    <>
      <div className="relative">
        <span
          className="back-button back-button-on-layout fixed-on-desktop"
          onClick={() =>
            isMobile
              ? navigate('/login', {
                  state: { showLandingPage: true },
                })
              : navigate('/login')
          }
        >
          <svg
            id="ic_nav_back"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <rect id="Box" width="48" height="48" fill="none" />
            <path
              id="ic_nav_up"
              d="M16.465,8.482H1.483"
              transform="translate(15.518 15.518)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <path
              id="Path_470"
              data-name="Path 470"
              d="M23.676,16.018,16.2,23.748l7.477,7.73"
              transform="translate(0.324 0.252)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </svg>
        </span>
        <h1>{t('create_account')}</h1>

        <div className="form-caption">
          <div>
            <span
              dangerouslySetInnerHTML={{ __html: t('already_have_an_account') }}
            />{' '}
            <span
              className="terms-link signin-link"
              onClick={() =>
                isMobile
                  ? navigate('/login', {
                      state: { showLandingPage: false },
                    })
                  : navigate('/login')
              }
            >
              {t('sign_in')}
            </span>
          </div>
          <div>
            <b>{t('required_fields_warning')}</b>
          </div>
        </div>

        <div className={`form-group ${emailError && 'error'}`}>
          <div className="form-content">
            <div className="form-label">
              <span>{t('email')} *</span>
            </div>
            <div className="form-control">
              <Input
                tabIndex={1}
                id="email"
                name="email"
                placeholder={t('enter_email_address')}
                value={email}
                onChange={event => {
                  setEmail(event.target.value as string);
                  setEmailError('');
                }}
                disabled={isSubmiting}
              />
            </div>
          </div>
          <div className="form-error">
            {emailError && <Error id={'emailError'}>{emailError}</Error>}
          </div>
        </div>

        <div className={`form-group ${passwordError && 'error'}`}>
          <div className="form-content">
            <div className="form-label">
              <span>{t('password')} *</span>
            </div>
            <div className="form-control form-control-password">
              <Input
                id="password"
                name="password"
                tabIndex={2}
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder={t('enter_password_simple')}
                value={password}
                onChange={event => {
                  setPassword(event.target.value as string);
                  setPasswordError('');
                }}
                disabled={isSubmiting}
              />
              <span
                className={`show-password ${
                  isPasswordVisible ? 'pass-visible' : ''
                }`}
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                <img
                  alt="Hide Password"
                  src={HidePassIcon}
                  className="hide-pass-icon"
                ></img>
                <img
                  alt="Show Password"
                  src={ShowPassIcon}
                  className="show-pass-icon"
                ></img>
              </span>
            </div>
          </div>
          <div className="form-error">
            {passwordError && (
              <Error id={'passwordError'}>{passwordError}</Error>
            )}
          </div>
          <div className="password-decorators">
            <PasswordDecorators password={password} />
          </div>
        </div>

        <div className={`form-group ${repeatPasswordError && 'error'}`}>
          <div className="form-content">
            <div className="form-label">
              <span>{t('repeat_password')} *</span>
            </div>
            <div className="form-control form-control-password">
              <Input
                id="repeatPassword"
                tabIndex={3}
                name="repeatPassword"
                type={isRepeatPasswordVisible ? 'text' : 'password'}
                placeholder={t('repeat_password_hint')}
                value={repeatPassword}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    loginButtonClicked();
                  }
                }}
                onChange={event => {
                  setRepeatPassword(event.target.value as string);
                  setRepeatPasswordError('');
                }}
                disabled={isSubmiting}
              />
              <span
                className={`show-password ${
                  isRepeatPasswordVisible ? 'pass-visible' : ''
                }`}
                onClick={() =>
                  setIsRepeatPasswordVisible(!isRepeatPasswordVisible)
                }
              >
                <img
                  alt="Hide Password"
                  src={HidePassIcon}
                  className="hide-pass-icon"
                ></img>
                <img
                  alt="Show Password"
                  src={ShowPassIcon}
                  className="show-pass-icon"
                ></img>
              </span>
            </div>
          </div>
          <div className="form-error">
            {repeatPasswordError && (
              <Error id={'repeatPasswordError'}>{repeatPasswordError}</Error>
            )}
          </div>
        </div>

        <div className={`form-group ${phoneNumberError && 'error'}`}>
          <div className="form-content">
            <div className="form-label">
              <span>{t('phone_number')} *</span>
            </div>
            <div className="form-control">
              <Input
                id="phoneNumber"
                tabIndex={4}
                name="phoneNumber"
                autoComplete="off"
                placeholder={t('enter_your_phone_number')}
                value={phoneNumber}
                onChange={event => {
                  var input = event.target.value as string;
                  input = input.replace(/\D/g, '');
                  let formattedInput = '';

                  if (input.length > 0) {
                    formattedInput += '(' + input.substring(0, 3);
                  }
                  if (input.length >= 4) {
                    formattedInput += ') ' + input.substring(3, 6);
                  }
                  if (input.length >= 7) {
                    formattedInput += '-' + input.substring(6, 10);
                  }
                  setPhoneNumber(formattedInput);
                  setPhoneNumberError('');
                }}
                disabled={isSubmiting}
              />
              <Input
                type="email"
                id="username"
                label="username"
                name="username"
                autoComplete="username"
                value={email}
                hidden
              />
            </div>
          </div>
          <div className="form-error">
            {phoneNumberError && (
              <Error id={'phoneNumberError'}>{phoneNumberError}</Error>
            )}
          </div>
        </div>

        <Button
          tabIndex={5}
          className="form-button"
          themeColor="primary"
          onClick={loginButtonClicked}
          disabled={isSubmiting}
        >
          {isSubmiting ? (
            <div className="loading_icon loading_icon-btn">
              <div className="css-icon"></div>
            </div>
          ) : (
            t('create_account')
          )}
        </Button>

        <div className="separator-l" />
        <div className="text-center">
          <p>
            <span>{`${t('create_account_terms1')} `}</span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handleTermsClick()}
            >
              <span className="terms-link primary">
                {t('create_account_terms2')}
              </span>
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
