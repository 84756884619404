import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { ic_close_small_black as BlackCloseIcon } from 'app/widgets/SVGs';
import SearchIcon from 'assets/images/ic_search.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AcessSharingFilters } from '../..';
import NoSharedEquipments from '../../../../../assets/images/ill_empty_access_sharing_equipment.png';
import SharedEquipmentRow from './SharedEquipmentRow';
import { useNavigate } from 'react-router-dom';
import { dummyEquipmentData } from 'app/constants/access-sharing';

interface ISharedEquipment {
  isMobile: boolean;
}

function SharedEquipment(props: ISharedEquipment) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { equipmentListData: data } = React.useContext(EquipmentListContext);

  const [searchValue, setSearchValue] = React.useState<string>('');

  const [isExpanded, setIsExpanded] = React.useState(false);

  const [filter, setFilter] = React.useState(AcessSharingFilters.All);

  return (
    <div className="access-sharing-subcontainer">
      <div className="access-sharing-subcontainer-header">
        {data.equipmentList?.length == 0 ? (
          <h3>{t('shared_equipment_title')}</h3>
        ) : (
          <div className="access-sharing-subcontainer-header-content">
            <h3>{t('shared_equipment_title')}</h3>
            <div className="site-search open">
              <span className="search-icon">
                <img alt="Search" src={SearchIcon}></img>
              </span>
              <div className="search-input">
                <Input
                  name="equipment_fleets_search"
                  value={searchValue}
                  placeholder={t('shared_equipment_search_placeholder')}
                  onChange={newValue => {
                    setSearchValue(newValue.value);
                  }}
                  onClick={() => setIsExpanded(!isExpanded)}
                />
                {searchValue && (
                  <span
                    className="search-icon-close"
                    onClick={() => {
                      setSearchValue('');
                    }}
                  >
                    <BlackCloseIcon />
                  </span>
                )}
              </div>
            </div>
            <div className="access-sharing-filters">
              <div
                className={`access-sharing-filter-item ${
                  filter == AcessSharingFilters.All ? 'active' : ''
                }`}
                onClick={() => setFilter(AcessSharingFilters.All)}
              >
                <div>{t('all')}</div>
              </div>
              <div
                className={`access-sharing-filter-item ${
                  filter == AcessSharingFilters.OwnedByMe ? 'active' : ''
                }`}
                onClick={() => setFilter(AcessSharingFilters.OwnedByMe)}
              >
                <div>{t('owned_by_me')}</div>
              </div>
              <div
                className={`access-sharing-filter-item ${
                  filter == AcessSharingFilters.SharedWithMe ? 'active' : ''
                }`}
                onClick={() => setFilter(AcessSharingFilters.SharedWithMe)}
              >
                <div>{t('shared_with_me')}</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="access-sharing-subcontainer-body">
        {data.equipmentList?.length == 0 ? (
          <div className="access-sharing-subcontainer-body-empty">
            <div className="access-sharing-body-picture">
              <img src={NoSharedEquipments}></img>
            </div>
            <div className="access-sharing-body-text">
              <h4>{t('shared_equipment_no_equipment_title')}</h4>
              <p>{t('shared_equipment_no_equipment_text')}</p>
            </div>
          </div>
        ) : (
          <div className="access-sharing-subcontainer-body-table">
            <div className="access-sharing-subcontainer-body-table-header">
              <div>{t('equipment')}</div>
              <div>{t('my_access')}</div>
              <div>{t('owner')}</div>
              <div></div>
              <div>{t('shared_with')}</div>
              <div></div>
            </div>
            <div className="access-sharing-subcontainer-body-table-body">
              {dummyEquipmentData.map(equipment => {
                return (
                  <SharedEquipmentRow
                    equipment={equipment}
                    isMobile={props.isMobile}
                    key={equipment.id}
                  />
                );
              })}
            </div>
          </div>
        )}
        <div className="access-sharing-subcontainer-body-buttons-container">
          <Button
            themeColor="secondary"
            onClick={() => navigate('share-equipment')}
          >
            {t('shared_equipment_button').toUpperCase()}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SharedEquipment;
