import * as React from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    // Note: Up to @screen-sm (1024px) .layout handles content scrolling, below that .layout_container takes over this responsibility.
    const layoutElement = document.querySelector('.layout');
    const layoutContainerElement = document.querySelector('.layout_container');

    layoutElement?.scroll(0, 0);
    layoutContainerElement?.scroll(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
