import { Input } from '@progress/kendo-react-inputs';
import { ajax } from 'ajax/ajax';
import _ from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import AddEquipMachine from '../../../../assets/images/add_equip_machine.png';
import AddEquipAttachment from '../../../../assets/images/add_equip_attachment.png';
import AddEquipDialogImg from '../../../../assets/images/ill_howto_add_equip.png';
import AddEquipAttachmentImg from '../../../../assets/images/ill_howto_add_attachment.png';
import WarrningIcon from '../../../../assets/images/ic_more_info.svg';
import ErrorIcon from '../../../../assets/images/add-eq-error.svg';
import AddEquipAuth from '../../../../assets/images/modal_add-equi-auth.png';
import { useNavigate } from 'react-router';
import { Button } from '@progress/kendo-react-buttons';
import { Success } from 'app/components/Notifications';
import { Error } from '@progress/kendo-react-labels';
import { NotificationContext } from 'app/components/ReactContexts/notificationContext';
import { LoadingOverlay } from 'app/components/LoadingOverlay';
import { Dialog } from '@progress/kendo-react-dialogs';
import { ErrorModal } from 'app/components/ErrorModal';
import { getDefaultThumbnail } from '../utils';
import { CacheContext } from 'app/components/ReactContexts/cacheContext';
import { tagEvent } from 'utils/ga';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';

interface IAddEquipmentProps {
  close: () => void;
  updateEquipmentList?: (
    equipmentList: any,
    selectedIndex?: any,
    selectedEquipment?: any,
  ) => void;
}

enum ComponentState {
  Default,
  Machine,
  Attachment,
  NotLoggedIn,
}

enum Errors {
  MachinePin,
  MachineModel,
  AttachmentPin,
  AttachmentModel,
}

export function AddEquipment(props: IAddEquipmentProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotification } = React.useContext(NotificationContext);
  const { getApiModels } = React.useContext(CacheContext);
  const { addErrorModal } = React.useContext(GlobalErrorContext);

  const [componentState, setComponentState] = React.useState<ComponentState>(
    ComponentState.Default,
  );

  const [prevComponentState, setprevComponentState] =
    React.useState<ComponentState>(ComponentState.Default);

  const [errors, setErrors] = React.useState<Array<Errors>>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showLoadingOverlay, setshowLoadingOverlay] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState<boolean>(false);

  const [errorMessage, setErrorMessage] = React.useState<string>();

  const [machinePin, setMachinePin] = React.useState('');
  const [machineModel, setMachineModel] = React.useState('');

  const [attachmentPin, setAttachmentPin] = React.useState('');
  const [attachmentModel, setAttachmentModel] = React.useState('');

  const [machineList, setMachineList] = React.useState<Array<any> | undefined>(
    undefined,
  );
  const [attachmentList, setAttachmentList] = React.useState<
    Array<any> | undefined
  >(undefined);

  const handleMachineSearch = async (pin: string, model: string) => {
    const errors: Errors[] = [];
    if (model.length < 3) {
      errors.push(Errors.MachineModel);
    }
    if (pin.length < 1) {
      errors.push(Errors.MachinePin);
    }

    setErrors(errors);
    if (errors.length > 0) {
      return;
    }

    try {
      setIsLoading(true);
      const response = ajax.isLoggedIn()
        ? await ajax.get(
            `${ajax.appBaseUrl}/api/models?serial=${pin}&partialModel=${model}`,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            },
          )
        : await ajax.getAsAnonymous(
            `${ajax.appBaseUrl}/api/models?serial=${pin}&partialModel=${model}`,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            },
          );

      if (response.status === 200) {
        setMachineList(response.data?.models);
      }
    } catch (ex) {
      addErrorModal();
    } finally {
      setIsLoading(false);
    }
  };

  const handleAttachmentSearch = async (pin: string, model: string) => {
    const errors: Errors[] = [];
    if (model.length < 1) {
      errors.push(Errors.AttachmentModel);
    }
    if (pin.length < 1) {
      errors.push(Errors.AttachmentPin);
    }

    setErrors(errors);
    if (errors.length > 0) {
      return;
    }

    setIsLoading(true);
    const apiModels = await getApiModels();
    const attachments = _.filter(
      apiModels.models,
      attachment =>
        attachment.type === 'attachment' &&
        attachment.model
          .toLowerCase()
          .startsWith(attachmentModel.toLowerCase()),
    );
    setAttachmentList(attachments);
    setIsLoading(false);
  };

  const defaultComponent = (
    <div className="row height-100">
      <div className="col-sm-8">
        <div className="modal-container relative">
          <span className="back-button fixed-on-desktop" onClick={props.close}>
            <svg
              id="ic_nav_back"
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
            >
              <rect id="Box" width="48" height="48" fill="none" />
              <path
                id="ic_nav_up"
                d="M16.465,8.482H1.483"
                transform="translate(15.518 15.518)"
                fill="none"
                stroke="#1a1a1a"
                strokeLinecap="round"
                strokeWidth="2"
              />
              <path
                id="Path_470"
                data-name="Path 470"
                d="M23.676,16.018,16.2,23.748l7.477,7.73"
                transform="translate(0.324 0.252)"
                fill="none"
                stroke="#1a1a1a"
                strokeLinecap="round"
                strokeWidth="2"
              />
            </svg>
          </span>
          <h2>{t('AddEquipment.Title')}</h2>
          <div className="subtitle-caption">
            <span>{t('AddEquipment.SelectType')}</span>
          </div>
          <div className="add-equipment-boxes">
            <div
              className="add-equipment-item"
              onClick={() => setComponentState(ComponentState.Machine)}
              title={t('AddEquipment.Machine')}
            >
              <div className="add-equipment-box-img">
                <img src={AddEquipMachine} />
              </div>
              <h3>{t('AddEquipment.Machine')}</h3>
              <span className="chevron-right">
                <svg
                  id="chevron_right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <rect width="20" height="20" fill="none" />
                  <path
                    d="M0,9.467,4.72,4.733,0,0"
                    transform="translate(7.641 5.266)"
                    fill="none"
                    stroke="#dc4405"
                    strokeLinecap="round"
                    strokeWidth="1.7"
                  />
                </svg>
              </span>
            </div>
            <div
              className="add-equipment-item"
              onClick={() => setComponentState(ComponentState.Attachment)}
              title={t('AddEquipment.Attachment')}
            >
              <div className="add-equipment-box-img">
                <img src={AddEquipAttachment} />
              </div>
              <h3>{t('AddEquipment.Attachment')}</h3>
              <span className="chevron-right">
                <svg
                  id="chevron_right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <rect width="20" height="20" fill="none" />
                  <path
                    d="M0,9.467,4.72,4.733,0,0"
                    transform="translate(7.641 5.266)"
                    fill="none"
                    stroke="#dc4405"
                    strokeLinecap="round"
                    strokeWidth="1.7"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="modal-image-container">
          <div
            className="top-icon"
            style={{ backgroundImage: `url(${AddEquipDialogImg})` }}
          ></div>
          <div className="image-caption">
            <div className="icon">
              <img src={WarrningIcon} alt="More Info"></img>
            </div>
            <div className="text">
              <p>
                {t('AddEquipment.AddEquipment1')}
                {` ${t('AddEquipment.AddEquipment2')}`}
              </p>
              <p>{t('AddEquipment.SerialHint')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  React.useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'Enter') {
        if (componentState === ComponentState.Machine) {
          handleMachineSearch(machinePin, machineModel);
        }
        if (componentState === ComponentState.Attachment) {
          handleAttachmentSearch(attachmentPin, attachmentModel);
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    componentState,
    machinePin,
    machineModel,
    attachmentPin,
    attachmentModel,
  ]);

  const machineComponent = (
    <div className="row height-100">
      <div className="col-sm-8">
        <div className="modal-container relative">
          <span
            className="back-button fixed-on-desktop"
            onClick={() => {
              setIsLoading(false);
              setErrors([]);
              setMachineList(undefined);
              setMachineModel('');
              setMachinePin('');
              setshowLoadingOverlay(false);
              setComponentState(ComponentState.Default);
            }}
          >
            <svg
              id="ic_nav_back"
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
            >
              <rect id="Box" width="48" height="48" fill="none" />
              <path
                id="ic_nav_up"
                d="M16.465,8.482H1.483"
                transform="translate(15.518 15.518)"
                fill="none"
                stroke="#1a1a1a"
                strokeLinecap="round"
                strokeWidth="2"
              />
              <path
                id="Path_470"
                data-name="Path 470"
                d="M23.676,16.018,16.2,23.748l7.477,7.73"
                transform="translate(0.324 0.252)"
                fill="none"
                stroke="#1a1a1a"
                strokeLinecap="round"
                strokeWidth="2"
              />
            </svg>
          </span>
          <h2>{t('AddEquipment.AddMachineTitle')}</h2>
          <div className="subtitle-caption">
            <span>{t('AddEquipment.MachineSubtitle')}</span>
          </div>
          <div className="add-equipment-form">
            <div
              className={`form-group ${
                errors.includes(Errors.MachinePin) && 'error'
              }`}
            >
              <div className="form-content">
                <div className="form-label">
                  <span>{t('AddEquipment.MachinePinLabel')}</span>
                </div>
                <div className="form-control">
                  <Input
                    id="machinePin"
                    name="machinePin"
                    maxLength={40}
                    placeholder={t('AddEquipment.MachinePinHint')}
                    value={machinePin}
                    onChange={event => {
                      setMachinePin(event.target.value as string);
                    }}
                  />
                </div>
              </div>
              <div className="form-error">
                {errors.includes(Errors.MachinePin) && (
                  <Error>{t('AddEquipment.MachinePinError')}</Error>
                )}
              </div>
            </div>
            <div
              className={`form-group ${
                errors.includes(Errors.MachineModel) && 'error'
              }`}
            >
              <div className="form-content">
                <div className="form-label">
                  <span>{t('AddEquipment.MachineModelLabel')}</span>
                </div>
                <div className="inline-input-button">
                  <div className="form-control">
                    <Input
                      id="machineModel"
                      maxLength={40}
                      name="machineModel"
                      placeholder={t('AddEquipment.MachineModelHint')}
                      value={machineModel}
                      onChange={event => {
                        setMachineModel(event.target.value as string);
                      }}
                    />
                  </div>
                  <Button
                    themeColor="primary"
                    onClick={() => {
                      handleMachineSearch(machinePin, machineModel);
                    }}
                  >
                    {t('AddEquipment.Find')}
                  </Button>
                </div>
              </div>
              <div className="form-error">
                {errors.includes(Errors.MachineModel) && (
                  <Error>{t('AddEquipment.MachineModelError')}</Error>
                )}
              </div>
            </div>

            {isLoading && (
              <div className="loading loading-full-width">
                <div className="loading_text">{t('AddEquipment.Search')}</div>
                <div className="loading_icon">
                  <div className="css-icon"></div>
                </div>
              </div>
            )}

            {!isLoading && _.isArray(machineList) && machineList.length === 0 && (
              <div className="errors-box">
                <span className="error-icon">
                  <img src={ErrorIcon} alt="Error"></img>
                </span>
                <div className="error-list">
                  <span>
                    <b>
                      {t('AddEquipment.NotFoundTitle') +
                        ` '${machinePin} / ${machineModel}'`}
                    </b>
                  </span>
                  <span>{t('AddEquipment.NotFoundMessage')}</span>
                </div>
              </div>
            )}

            {!isLoading && _.isArray(machineList) && machineList.length > 0 && (
              <div>
                <h4>{t('AddEquipment.Select')}</h4>
                <div className="add-equipment-boxes smaller">
                  {_.map(machineList, (machine, ind) => (
                    <div
                      key={`${machine.model}-${ind}`}
                      className="add-equipment-item"
                      onClick={async () => {
                        if (!ajax.isLoggedIn()) {
                          setprevComponentState(ComponentState.Machine);
                          setComponentState(ComponentState.NotLoggedIn);
                          return;
                        }

                        setshowLoadingOverlay(true);
                        try {
                          const response = await ajax.post(
                            `${ajax.appBaseUrl}/api/user/equipment/addFromScan`,
                            {
                              identifierType: 'Serial',
                              pinOrSerial: machinePin,
                              model: machine.searchModel || machine.model,
                              engineHours: 0.0,
                            },
                            {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            },
                          );

                          if (response.status === 200) {
                            const equipmentMachines = _.filter(
                              response.data || [],
                              e => e.type === 'machine',
                            );

                            const equipmentAttatchments = _.filter(
                              response.data || [],
                              e => e.type !== 'machine',
                            );

                            const sortedEquipmentMachines = _.orderBy(
                              equipmentMachines || [],
                              e =>
                                (e.nickName || e.model).toLowerCase() ||
                                !e.hasTelematics,
                              ['asc'],
                            );

                            tagEvent('add_equipment');
                            const sortedEquipmentAttatchments = _.orderBy(
                              equipmentAttatchments || [],
                              e => (e.nickName || e.model).toLowerCase(),
                              ['asc'],
                            );

                            const orderedData = [
                              ...sortedEquipmentMachines,
                              ...sortedEquipmentAttatchments,
                            ];

                            const index = _.findIndex(
                              (orderedData as any[]) || [],
                              e =>
                                e.pinOrSerial === machinePin &&
                                e.model === machine.model,
                            );

                            if (index > -1) {
                              props.updateEquipmentList?.(
                                orderedData,
                                index,
                                orderedData[index],
                              );
                              navigate(`/equipment/${orderedData[index].id}`);
                            } else {
                              props.updateEquipmentList?.(orderedData);
                            }

                            addNotification(
                              <Success
                                text={t('AddEquipment.NotificationSuccess')}
                              />,
                              6000,
                            );
                            props.close();
                          }
                        } catch (ex: any) {
                          setErrorMessage(ex.response.data);
                          setShowErrorModal(true);
                          setshowLoadingOverlay(false);
                          console.log(ex);
                        }
                      }}
                    >
                      <div className="add-equipment-box-img">
                        <img
                          src={
                            machine.modelIconUrl ||
                            getDefaultThumbnail('machine', machine.category)
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = getDefaultThumbnail(
                              'machine',
                              machine.category,
                            );
                          }}
                          alt={machine.model}
                        />
                      </div>
                      <h3>
                        <span>{machine.searchModel}</span>
                      </h3>
                      <span className="chevron-right">
                        <svg
                          id="chevron_right"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <rect width="20" height="20" fill="none" />
                          <path
                            d="M0,9.467,4.72,4.733,0,0"
                            transform="translate(7.641 5.266)"
                            fill="none"
                            stroke="#dc4405"
                            strokeLinecap="round"
                            strokeWidth="1.7"
                          />
                        </svg>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="modal-image-container">
          <div
            className="top-icon"
            style={{ backgroundImage: `url(${AddEquipDialogImg})` }}
          ></div>
          <div className="image-caption">
            <div className="icon">
              <img src={WarrningIcon} alt="More Info"></img>
            </div>
            <div className="text">
              <p>
                {t('AddEquipment.AddEquipment1')}
                {` ${t('AddEquipment.AddEquipment2')}`}
              </p>
              <p>{t('AddEquipment.SerialHint')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const attachmentComponent = (
    <div className="row height-100">
      <div className="col-sm-8">
        <div className="modal-container relative">
          <span
            className="back-button fixed-on-desktop"
            onClick={() => {
              setIsLoading(false);
              setshowLoadingOverlay(false);
              setErrors([]);
              setAttachmentList(undefined);
              setAttachmentModel('');
              setAttachmentPin('');
              setComponentState(ComponentState.Default);
            }}
          >
            <svg
              id="ic_nav_back"
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
            >
              <rect id="Box" width="48" height="48" fill="none" />
              <path
                id="ic_nav_up"
                d="M16.465,8.482H1.483"
                transform="translate(15.518 15.518)"
                fill="none"
                stroke="#1a1a1a"
                strokeLinecap="round"
                strokeWidth="2"
              />
              <path
                id="Path_470"
                data-name="Path 470"
                d="M23.676,16.018,16.2,23.748l7.477,7.73"
                transform="translate(0.324 0.252)"
                fill="none"
                stroke="#1a1a1a"
                strokeLinecap="round"
                strokeWidth="2"
              />
            </svg>
          </span>
          <h2>{t('AddEquipment.AddAttachmentTitle')}</h2>
          <div className="subtitle-caption">
            <span>{t('AddEquipment.AttachmentSubtitle')}</span>
          </div>
          <div className="add-equipment-form">
            <div
              className={`form-group ${
                errors.includes(Errors.AttachmentPin) && 'error'
              }`}
            >
              <div className="form-content">
                <div className="form-label">
                  <span>{t('AddEquipment.MachinePinLabel')}</span>
                </div>
                <div className="form-control">
                  <Input
                    id="attachmentin"
                    name="attachmentPin"
                    maxLength={40}
                    placeholder={t('AddEquipment.AttachmentPinHint')}
                    value={attachmentPin}
                    onChange={event => {
                      setAttachmentPin(event.target.value as string);
                    }}
                  />
                </div>
              </div>
              <div className="form-error">
                {errors.includes(Errors.AttachmentPin) && (
                  <Error>{t('AddEquipment.AttachmentPinError')}</Error>
                )}
              </div>
            </div>
            <div
              className={`form-group ${
                errors.includes(Errors.AttachmentModel) && 'error'
              }`}
            >
              <div className="form-content">
                <div className="form-label">
                  <span>{t('AddEquipment.AttachmentModelLabel')}</span>
                </div>
                <div className="inline-input-button">
                  <div className="form-control">
                    <Input
                      id="attachmentModel"
                      maxLength={40}
                      name="attachmentModel"
                      placeholder={t('AddEquipment.AttachmentModelHint')}
                      value={attachmentModel}
                      onChange={event => {
                        setAttachmentModel(event.target.value as string);
                      }}
                    />
                  </div>
                  <Button
                    themeColor="primary"
                    onClick={() => {
                      handleAttachmentSearch(attachmentPin, attachmentModel);
                    }}
                  >
                    {t('AddEquipment.Find')}
                  </Button>
                </div>
              </div>
              <div className="form-error">
                {errors.includes(Errors.AttachmentModel) && (
                  <Error>{t('AddEquipment.AttachmentModelError')}</Error>
                )}
              </div>
            </div>
            {isLoading && (
              <div className="loading loading-full-width">
                <div className="loading_text">{t('AddEquipment.Search')}</div>
                <div className="loading_icon">
                  <div className="css-icon"></div>
                </div>
              </div>
            )}

            {!isLoading &&
              _.isArray(attachmentList) &&
              attachmentList.length === 0 && (
                <div className="errors-box">
                  <span className="error-icon">
                    <img src={ErrorIcon} alt="Error"></img>
                  </span>
                  <div className="error-list">
                    <span>
                      <b>
                        {t('AddEquipment.NotFoundTitle') +
                          ` '${attachmentPin} / ${attachmentModel}'`}
                      </b>
                    </span>
                    <span>{t('AddEquipment.NotFoundMessage')}</span>
                  </div>
                </div>
              )}

            {!isLoading &&
              _.isArray(attachmentList) &&
              attachmentList.length > 0 && (
                <div>
                  <h4>{t('AddEquipment.Select')}</h4>
                  <div className="add-equipment-boxes smaller">
                    {_.map(attachmentList, (attachment, ind) => (
                      <div
                        className="add-equipment-item"
                        key={`${attachment.model}-${ind}`}
                        onClick={async () => {
                          try {
                            if (!ajax.isLoggedIn()) {
                              setprevComponentState(ComponentState.Attachment);
                              setComponentState(ComponentState.NotLoggedIn);
                              return;
                            }
                            setshowLoadingOverlay(true);
                            const response = await ajax.post(
                              `${ajax.appBaseUrl}/api/user/equipment/addFromScan`,
                              {
                                identifierType: 'Serial',
                                pinOrSerial: attachmentPin,
                                model:
                                  attachment.searchModel || attachment.model,
                                engineHours: 0.0,
                                type: 'attachment',
                              },
                              {
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                              },
                            );

                            if (response.status === 200) {
                              const equipmentMachines = _.filter(
                                response.data || [],
                                e => e.type === 'machine',
                              );

                              const equipmentAttatchments = _.filter(
                                response.data || [],
                                e => e.type !== 'machine',
                              );

                              const sortedEquipmentMachines = _.orderBy(
                                equipmentMachines || [],
                                e =>
                                  (e.nickName || e.model).toLowerCase() ||
                                  !e.hasTelematics,
                                ['asc'],
                              );

                              tagEvent('add_attachment');
                              const sortedEquipmentAttatchments = _.orderBy(
                                equipmentAttatchments || [],
                                e => (e.nickName || e.model).toLowerCase(),
                                ['asc'],
                              );

                              const orderedData = [
                                ...sortedEquipmentMachines,
                                ...sortedEquipmentAttatchments,
                              ];

                              const index = _.findIndex(
                                (orderedData as any[]) || [],
                                e =>
                                  e.pinOrSerial === attachmentPin &&
                                  e.model === attachment.model,
                              );

                              if (index > -1) {
                                props.updateEquipmentList?.(
                                  orderedData,
                                  index,
                                  orderedData[index],
                                );
                                navigate(`/equipment/${orderedData[index].id}`);
                              } else {
                                props.updateEquipmentList?.(orderedData);
                              }

                              addNotification(
                                <Success
                                  text={t('AddEquipment.NotificationSuccess')}
                                />,
                                6000,
                              );
                              props.close();
                            }
                          } catch (ex: any) {
                            setErrorMessage(ex.response.data);
                            setShowErrorModal(true);
                            setshowLoadingOverlay(false);
                            console.log(ex);
                          }
                        }}
                      >
                        <div className="add-equipment-box-img">
                          <img
                            src={
                              attachment.modelIconUrl ||
                              getDefaultThumbnail(
                                'attachment',
                                attachment.category,
                              )
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = getDefaultThumbnail(
                                'attachment',
                                attachment.category,
                              );
                            }}
                            alt={attachment.model}
                          />
                        </div>
                        <h3>
                          <span>{attachment.model}</span>
                        </h3>
                        <span className="chevron-right">
                          <svg
                            id="chevron_right"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            <rect width="20" height="20" fill="none" />
                            <path
                              d="M0,9.467,4.72,4.733,0,0"
                              transform="translate(7.641 5.266)"
                              fill="none"
                              stroke="#dc4405"
                              strokeLinecap="round"
                              strokeWidth="1.7"
                            />
                          </svg>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="modal-image-container">
          <div
            className="top-icon"
            style={{ backgroundImage: `url(${AddEquipAttachmentImg})` }}
          ></div>
          <div className="image-caption">
            <div className="icon">
              <img src={WarrningIcon} alt="More Info"></img>
            </div>
            <div className="text">
              <p>
                {t('AddEquipment.AddEquipment4')}
                {` ${t('AddEquipment.AddEquipment2')}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const notLoggedInComponent = (
    <div className="row height-100 equip-auth-wrapper">
      <div className="col-sm-12">
        <div className="modal-container relative">
          <span
            className="back-button fixed-on-desktop"
            onClick={() => {
              setIsLoading(false);
              setComponentState(prevComponentState);
            }}
          >
            <svg
              id="ic_nav_back"
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
            >
              <rect id="Box" width="48" height="48" fill="none" />
              <path
                id="ic_nav_up"
                d="M16.465,8.482H1.483"
                transform="translate(15.518 15.518)"
                fill="none"
                stroke="#1a1a1a"
                strokeLinecap="round"
                strokeWidth="2"
              />
              <path
                id="Path_470"
                data-name="Path 470"
                d="M23.676,16.018,16.2,23.748l7.477,7.73"
                transform="translate(0.324 0.252)"
                fill="none"
                stroke="#1a1a1a"
                strokeLinecap="round"
                strokeWidth="2"
              />
            </svg>
          </span>
          <h2>{t('AddEquipment.AuthToSave')}</h2>
        </div>
        <div className="add-equipment-full-img">
          <img src={AddEquipAuth} alt="Add Equipment Authentification" />
        </div>
        <div className="modal-container relative">
          <div
            className="form-caption"
            dangerouslySetInnerHTML={{ __html: t('AddEquipment.SignInToAcc') }}
          ></div>
          <div className="row">
            <div className="col-xxs-6">
              <Button
                onClick={() => {
                  navigate('/login');
                }}
              >
                {t('sign_in')}
              </Button>
            </div>
            <div className="col-xxs-6">
              <Button
                themeColor="primary"
                onClick={() => {
                  navigate('/createAccount');
                }}
              >
                {t('create_account')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {showErrorModal && (
        <Dialog className="modal-center modal-error modal-remove-item modal-fulltext">
          <ErrorModal
            title={t('unexpected_error_title')}
            text={errorMessage || t('unexpected_error_message')}
            onClose={() => {
              setShowErrorModal(false);
              setErrorMessage('');
            }}
          />
        </Dialog>
      )}
      {componentState === ComponentState.Default && defaultComponent}
      {componentState === ComponentState.Machine && (
        <div
          className={`height-100 ${showLoadingOverlay ? 'dialog-loading' : ''}`}
        >
          <LoadingOverlay
            isOpen={showLoadingOverlay}
            text={`${t('loading')}...`}
          />
          {machineComponent}
        </div>
      )}
      {componentState === ComponentState.Attachment && (
        <div
          className={`height-100 ${showLoadingOverlay ? 'dialog-loading' : ''}`}
        >
          <LoadingOverlay
            isOpen={showLoadingOverlay}
            text={`${t('loading')}...`}
          />
          {attachmentComponent}
        </div>
      )}
      {componentState === ComponentState.NotLoggedIn && notLoggedInComponent}
    </>
  );
}
