import * as _ from 'lodash';
import moment, { MomentInput } from 'moment';
import 'moment/locale/fr';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { IMaintenanceCheck } from 'types/IMaintenanceCheckList';
import { IMaintenanceHistoryEntry } from 'types/IMaintenanceHistoryEntry';
import { IMaintenanceSchedule } from 'types/IMaintenanceSchedule';

export function getCurrentEngineHours(equipment: IEquipmentEntry) {
  return (
    equipment.telematics?.cumulativeOperatingHours ||
    equipment.userEnteredEngineHours
  );
}

export function getMaxInterval(maintenanceHistory, maintenanceType) {
  const filteredItems = maintenanceHistory.filter(
    it => it.intervalType === maintenanceType,
  );

  return filteredItems.length > 0
    ? Math.max(...filteredItems.map(it => it.intervalValue))
    : 0;
}

export function getMaintenanceIntervalTitle(intervalType, intervalValue, t) {
  let result;
  switch (intervalType) {
    case 'Every X Hours':
      result = t('maintenance_item_hours', {
        interval: intervalValue,
      });
      break;
    case 'X Hours':
      result = t('initial_maintenance_item_hours', {
        interval: intervalValue,
      });
      break;
    case 'Every X Months':
      result = t('maintenance_item_months', {
        interval: intervalValue,
      });
      break;
    case 'X Months':
      result = t('initial_maintenance_item_months', {
        interval: intervalValue,
      });
      break;
    case 'Every X Years':
      result = t('maintenance_item_years', {
        interval: intervalValue,
      });
      break;
    case 'X Years':
      result = t('initial_maintenance_item_years', {
        interval: intervalValue,
      });
      break;
    case 'Annually':
      result = t('maintenance_item_anually');
      break;
    case 'Daily Check':
      result = t('maintenance_item_daily');
      break;
    case 'Seasonally':
      result = t('maintenance_item_seasonally');
      break;
    case 'As Needed':
      result = t('maintenance_item_as_needed');
      break;
    default:
      result = t('unknown');
      break;
  }
  return result;
}

const IntervalTypes = {
  XHours: 'X Hours',
  EveryXHours: 'Every X Hours',
  XMonths: 'X Months',
  EveryXMonths: 'Every X Months',
  XYears: 'X Years',
  EveryXYears: 'Every X Years',
  Annually: 'Annually',
  DailyCheck: 'Daily Check',
  Seasonally: 'Seasonally',
  AsNeeded: 'As Needed',
};

export function getMaintenanceScheduleIntervalTitle(
  intervalType,
  intervalValue,
  t,
) {
  const intervalTypeMap = {
    [IntervalTypes.XHours]: t('initial_maintenance_item_hours', {
      interval: intervalValue,
    }),
    [IntervalTypes.EveryXHours]: t('maintenance_schedule_item_hours', {
      interval: intervalValue,
    }),
    [IntervalTypes.XMonths]: t('initial_maintenance_item_months', {
      interval: intervalValue,
    }),
    [IntervalTypes.EveryXMonths]: t('maintenance_item_months', {
      interval: intervalValue,
    }),
    [IntervalTypes.XYears]: t('initial_maintenance_item_years', {
      interval: intervalValue,
    }),
    [IntervalTypes.EveryXYears]: t('maintenance_item_years', {
      interval: intervalValue,
    }),
    [IntervalTypes.Annually]: t('maintenance_item_anually'),
    [IntervalTypes.DailyCheck]: t('maintenance_item_daily'),
    [IntervalTypes.Seasonally]: t('maintenance_item_seasonally'),
    [IntervalTypes.AsNeeded]: t('maintenance_item_as_needed'),
  };

  return intervalTypeMap[intervalType] || t('unknown');
}

export const getCompleteMaintenanceTitle = (intervalType, intervalValue, t) => {
  let title;

  switch (intervalType) {
    case 'Every X Hours':
      title = t('maintenance_track_new_service_hours', {
        interval: intervalValue,
      });
      break;
    case 'Every X Months':
      title = t('maintenance_track_new_service_months', {
        interval: intervalValue,
      });
      break;
    case 'Every X Years':
      title = t('maintenance_track_new_service_years', {
        interval: intervalValue,
      });
      break;
    case 'Annually':
      title = t('maintenance_track_new_service_anually');
      break;
    case 'Daily Check':
      title = t('maintenance_track_new_service_daily');
      break;
    case 'Seasonally':
      title = t('maintenance_track_new_service_seasonally');
      break;
    case 'As Needed':
      title = t('maintenance_track_new_service_as_needed');
      break;
    default:
      title = t('unknown');
  }

  return title;
};

export const getListHierarchy = intervalType => {
  let hierarchy;

  switch (intervalType) {
    case 'Every X Hours':
      hierarchy = 1;
      break;
    case 'Every X Months':
      hierarchy = 2;
      break;
    case 'Every X Years':
      hierarchy = 3;
      break;
    case 'Annually':
      hierarchy = 4;
      break;
    case 'Daily Check':
      hierarchy = 5;
      break;
    case 'As Needed':
      hierarchy = 6;
      break;
    default:
      hierarchy = 7;
  }

  return hierarchy;
};

export function newGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getSortedMaintenanceHistory(maintenanceHistory) {
  return maintenanceHistory
    .map(item => {
      return {
        id: item.id,
        intervalType: item.intervalType,
        intervalValue: item.intervalValue,
        completedEngineHours: item.completedEngineHours,
        notes: item.notes,
        updatedDate: item.updatedDate,
        maintenanceCheckList: item.maintenanceCheckList,
        sortOrder: getListHierarchy(item.intervalType),
      };
    })
    .sort(
      (a, b) => a.sortOrder - b.sortOrder || a.intervalValue - b.intervalValue,
    );
}

export function getProcessedMaintenanceSchedule(
  maintenanceScedule,
  onTrackMaintenance,
) {
  return (
    Object.values(
      maintenanceScedule
        .filter(
          it =>
            (!_.isNil(it.intervalType) || !_.isNil(it.firstCheckType)) &&
            !_.isNil(it.checkPoint) &&
            !_.isNil(it.measures),
        )
        .reduce((items, obj) => {
          var key = `${obj.intervalType}_${obj.intervalValue}`;

          if (onTrackMaintenance && !_.isNil(obj.firstCheckType)) {
            key = `Every ${obj.firstCheckType}_${obj.firstCheckValue}`;
          }

          if (items.hasOwnProperty(key)) {
            items[key].maintenanceCheckList.push({
              id: obj.id,
              checkPoint: obj.checkPoint,
              measures: obj.measures,
              isFirstTimeCheck: !_.isNil(obj.firstCheckType),
            });
          } else {
            items[key] = {
              intervalType: obj.intervalType || obj.firstCheckType,
              intervalValue: obj.intervalValue || obj.firstCheckValue,
              sortOrder: obj.sortOrder,
              hierarchyPosition:
                onTrackMaintenance &&
                getListHierarchy(obj.intervalType || obj.firstCheckType),
              maintenanceCheckList: [
                {
                  id: obj.id,
                  checkPoint: obj.checkPoint,
                  measures: obj.measures,
                  isFirstTimeCheck: !_.isNil(obj.firstCheckType),
                },
              ],
            };
          }

          return items;
        }, {}),
    ) as IMaintenanceSchedule[]
  ).sort((a, b) =>
    onTrackMaintenance
      ? a.hierarchyPosition - b.hierarchyPosition ||
        a.intervalValue - b.intervalValue
      : a.sortOrder - b.sortOrder || a.intervalValue - b.intervalValue,
  );
}

export const getSelectedEquipment = (equipmentList, id) => {
  return !equipmentList.selectedEquipment
    ? equipmentList.equipmentList?.find(item => item.id === id)
    : equipmentList.selectedEquipment;
};

export const getCheckListMeasures = (checkList: IMaintenanceCheck[]) =>
  checkList
    .map(item => item.measures)
    .filter((it, index, self) => self.indexOf(it) == index);

export const setCheckBoxOnEdit = (maintenanceCheckList, currentCheckList) => {
  const checkedMaintenance = Object.fromEntries(
    Object.entries(maintenanceCheckList).filter(
      ([key, value]) => value === true,
    ),
  );

  const checkList = currentCheckList.filter(item =>
    Object.keys(checkedMaintenance).includes(item.id),
  );

  return checkList.map(it => it.id);
};

export const formatDate = (date: Date, format: string) => {
  let formatedDate;
  format = format.toLocaleLowerCase();

  switch (format) {
    case 'mmm yyyy':
      formatedDate = new Date(date).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
      });
      break;
    case 'mmm dd yyyy':
      formatedDate = new Date(date).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      });
      break;
    default:
      formatedDate = date;
  }

  return formatedDate;
};

const dateFormatMap = {
  'en-CA': {
    date_time: 'DD MMMM, YYYY [at] hh:mm A',
    date: 'DD MMM, YYYY',
    local_env: 'en',
  },
  'fr-CA': {
    date_time: 'DD MMMM, YYYY à HH:mm',
    date: 'DD MMM YYYY',
    local_env: 'fr',
  },
};

export const getCanadaFormateDate = (
  date: any,
  formatType: string,
  locale: string,
): string => {
  if (!date) {
    return '';
  }

  moment.locale(dateFormatMap[locale]?.local_env);
  const format = dateFormatMap[locale]?.[formatType] || 'YYYY-MM-DD';
  return moment(date).format(format);
};

export const getUSFormateDate = (date: any, locale: string) => {
  if (!date) {
    return '';
  }
  moment.locale(locale === 'fr-US' ? 'fr' : 'en');
  const dateFormat =
    locale === 'fr-US' ? 'DD MMMM, YYYY à HH:mm' : 'MMMM DD, YYYY [at] hh:mm A';

  return moment(date).format(dateFormat);
};

export const getUSFormateDateOnly = (date: any, locale: string) => {
  if (!date) {
    return '';
  }
  moment.locale(locale === 'fr-US' ? 'fr' : 'en');
  const dateFormat = locale === 'fr-US' ? 'DD MMMM, YYYY' : 'MMMM DD, YYYY';

  return moment(date).format(dateFormat);
};

export const getUSFormatHours = (date: MomentInput) => {
  moment.locale('en');
  return moment(date).format('hh:mm A');
};

export const getCanadaFormateHours = (hours: number, locale: string) => {
  let numberOptions: Intl.NumberFormatOptions;
  if (locale === 'fr-CA') {
    numberOptions = {
      maximumFractionDigits: 1,
      useGrouping: false,
    };
  } else {
    numberOptions = {
      style: 'decimal',
      maximumFractionDigits: 1,
      useGrouping: false,
    };
  }
  return hours.toLocaleString(locale, numberOptions);
};

export function getLastHistoryInterval(maintenanceHistory) {
  if (!maintenanceHistory || maintenanceHistory.length === 0) {
    return undefined;
  }

  const initialValue = { completedEngineHours: 0 };
  const lastInterval = maintenanceHistory.reduce(
    (prev, crt) =>
      prev.completedEngineHours > crt.completedEngineHours ? prev : crt,
    initialValue,
  );

  return lastInterval.id ? lastInterval : undefined;
}

function getLastEveryHourInterval(
  maintenanceHistory: IMaintenanceHistoryEntry[] | undefined,
) {
  const everyHourHistory = maintenanceHistory?.filter(
    item => item.intervalType === 'Every X Hours',
  );

  const initialValue = { intervalValue: 0 };
  const lastInterval = everyHourHistory?.reduce(
    (crt, next) => (crt.intervalValue > next.intervalValue ? crt : next),
    initialValue,
  );

  return lastInterval?.intervalValue;
}

export function getNextScheduleInterval(
  maintenanceSchedules: IMaintenanceSchedule[] | undefined,
  maintenanceHistory: IMaintenanceHistoryEntry[] | undefined,
  lastMaintenanceHistory: IMaintenanceHistoryEntry,
  currentEngineHours: number,
) {
  if (!maintenanceSchedules || maintenanceSchedules.length === 0) {
    return 0;
  }

  let nextSchedules = maintenanceSchedules
    .filter(it => it.intervalType === 'Every X Hours')
    .reduce(
      (prev, crt) => (crt.intervalValue < prev.intervalValue ? crt : prev),
      { intervalValue: Infinity },
    );

  const minInterval =
    nextSchedules.intervalValue == Infinity ? 0 : nextSchedules.intervalValue;
  const currentMaintenanceHours =
    lastMaintenanceHistory.completedEngineHours > currentEngineHours
      ? lastMaintenanceHistory.completedEngineHours
      : currentEngineHours;
  const intervalDifference = currentMaintenanceHours % minInterval;
  let lastInterval = currentMaintenanceHours - intervalDifference;

  if (
    currentEngineHours > lastMaintenanceHistory.completedEngineHours &&
    intervalDifference == 0
  ) {
    lastInterval -= minInterval;
  }

  let nextInterval =
    lastMaintenanceHistory &&
    lastMaintenanceHistory.completedEngineHours >= lastInterval
      ? lastInterval + minInterval
      : lastInterval;

  const lastHistoryInterval = getLastEveryHourInterval(maintenanceHistory) ?? 0;

  while (lastHistoryInterval >= nextInterval) {
    nextInterval += minInterval;
  }

  return nextInterval;
}
