import * as React from 'react';

import { DealerListContext } from 'app/components/ReactContexts/dealerListContext';

interface ILocationSearch {
  prediction: google.maps.places.AutocompletePrediction;
  placeId: string;
  callback?: () => void;
}

export function LocationSearch(props: ILocationSearch) {
  const { setDealerListData } = React.useContext(DealerListContext);

  const locationSearchClicked = () => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId: props.placeId }).then(response => {
      //response.results[0].geometry.location
      setDealerListData(oldState => ({
        ...oldState,
        panTo: {
          position: {
            lat: response.results[0].geometry.location.lat(),
            lng: response.results[0].geometry.location.lng(),
          },
          zoom: 9,
        },
      }));

      props.callback?.();
    });
  };

  return (
    <div onClick={locationSearchClicked} className="location-option">
      <div>
        <b>{props.prediction.structured_formatting.main_text}</b>
      </div>
      <div>{props.prediction.structured_formatting.secondary_text}</div>
    </div>
  );
}
