import { AccessSharingGuideDialog } from 'app/components/access-sharing';
import React, { createContext, useContext, useState } from 'react';
import { Context } from './types';

const ShareAccessEquipmentGuideContext = createContext<Context>({} as Context);

export function ShareAccessEquipmentGuideProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  function openGuide() {
    setIsOpen(true);
  }

  function closeGuide() {
    setIsOpen(false);
  }

  return (
    <ShareAccessEquipmentGuideContext.Provider value={{ openGuide }}>
      {children}

      {isOpen && <AccessSharingGuideDialog close={closeGuide} />}
    </ShareAccessEquipmentGuideContext.Provider>
  );
}

const useShareAccessEquipmentGuideContext = () =>
  useContext(ShareAccessEquipmentGuideContext);

export default useShareAccessEquipmentGuideContext;
