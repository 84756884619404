import { LargeBanner } from 'app/components/LargeBanner';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface IEquipmentListCard {
  children: JSX.Element;
}

export function EquipmentContentIndex(props: IEquipmentListCard) {
  const { equipmentListData: data, setEquipmentListData: setData } =
    React.useContext(EquipmentListContext);

  const location = useLocation();

  const { t } = useTranslation();

  const equipments = data.equipmentList ?? [];

  React.useEffect(() => {
    if (
      data.selectedEquipment?.id &&
      (data.selectedEquipment.id !== location.pathname.substring(11, 47) ||
        data.selectedIndex !==
          data.equipmentList?.findIndex(
            eq => eq.id == data.selectedEquipment?.id,
          )) &&
      location.pathname.includes('equipment/') &&
      !location.pathname.endsWith('equipment/')
    ) {
      const equipmentIndex = data.equipmentList!.findIndex(
        e => e.id === location.pathname.substring(11, 47),
      );
      const equipmentValue = (data.equipmentList || {})[equipmentIndex];
      setData({
        ...data,
        selectedIndex: equipmentIndex,
        selectedEquipment: equipmentValue,
      });
      return;
    }
    if (
      (!data.selectedEquipment && data.selectedIndex) ||
      data.selectedIndex === 0
    ) {
      setData({ ...data, selectedEquipment: equipments[data.selectedIndex] });
      return;
    }
  }, [location.pathname]);

  if (!data.selectedEquipment) {
    return (
      <>
        {localStorage.getItem('justLoggedOn') === 'true' && <LargeBanner />}
        <div className="equipment-content-empty-loading">
          <div className="loading">
            <div className="loading_text">{t('loading_equipment...')}</div>
            <div className="loading_icon">
              <div className="css-icon"></div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {' '}
      {localStorage.getItem('justLoggedOn') === 'true' && <LargeBanner />}
      {props.children}
    </>
  );
}
