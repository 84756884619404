import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { ajax } from 'ajax/ajax';
import { ErrorModal } from 'app/components/ErrorModal';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { MaintenanceHistoryContext } from 'app/components/ReactContexts/maintenanceHistoryContext';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IMaintenanceHistoryEntry } from 'types/IMaintenanceHistoryEntry';
import { tagEvent } from 'utils/ga';
import { formatNumberWithDecimals } from 'utils/general';
import { EquipmentDetails } from '../../EquipmentDetailsBox';
import * as utils from '../../maintenanceUtils';

interface IProps {
  maintenanceInterval: any;
  closeDialog: any;
  closeTrackMaintenanceDialog?: any;
  action: string;
  openEngineHoursUpdateDialog: boolean;
  setOpenEngineHoursUpdateDialog: any;
}

export function CompletedMaintenanceDialog(props: IProps) {
  const { t, i18n } = useTranslation();
  const { equipmentListData } = React.useContext(EquipmentListContext);
  const { maintenanceHistoryData, setMaintenanceHistoryData } =
    React.useContext(MaintenanceHistoryContext);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [charactersCount, setCharactersCount] = React.useState<number>();
  const [showErrorModal, setShowErrorModal] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const currentEquipment = { ...equipmentListData.selectedEquipment };
  const currentMaintenanceHistory = [
    ...(maintenanceHistoryData?.selectedEquipmentMaintenanceHistory || []),
  ];
  const selectedMaintenanceHistory = currentMaintenanceHistory?.filter(
    item =>
      item.id == props.maintenanceInterval.id &&
      item.intervalType === props.maintenanceInterval.intervalType &&
      item.intervalValue === props.maintenanceInterval.intervalValue,
  )[0];

  const title = utils.getCompleteMaintenanceTitle(
    props.maintenanceInterval.intervalType,
    props.maintenanceInterval.intervalValue,
    t,
  );

  const currentCheckList = props.maintenanceInterval.maintenanceCheckList;
  const checkListMeasures = utils.getCheckListMeasures(currentCheckList);

  const [isCheckAll, setIsCheckAll] = React.useState(false);
  const [isCheck, setIsCheck] = React.useState<any>(
    props.action === 'edit'
      ? utils.setCheckBoxOnEdit(
          selectedMaintenanceHistory.maintenanceCheckList,
          currentCheckList,
        )
      : [],
  );

  const [engineHours, setEngineHours] = React.useState(
    props.action === 'edit'
      ? formatNumberWithDecimals(
          selectedMaintenanceHistory.completedEngineHours,
          2,
        )?.replace('.', t('hours_separator'))
      : formatNumberWithDecimals(
          currentEquipment.telematics?.cumulativeOperatingHours,
          2,
        )?.replace('.', t('hours_separator')) ||
          formatNumberWithDecimals(
            currentEquipment.userEnteredEngineHours,
            2,
          )?.replace('.', t('hours_separator')),
  );
  const [notes, setNotes] = React.useState(
    props.action === 'edit' ? selectedMaintenanceHistory.notes : undefined,
  );

  React.useEffect(() => {
    if (currentCheckList.length == isCheck.length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  }, []);

  React.useEffect(() => {
    notes ? setCharactersCount(notes?.length) : setCharactersCount(0);
  }, [notes]);

  const handleEngineHoursBox = e => {
    const stringValue = e.target.value.toString();
    let convertedEngineHours = stringValue;
    if (i18n.language === 'fr') {
      if (stringValue.includes('.')) {
        return false;
      }
      convertedEngineHours = stringValue.replace(t('hours_separator'), '.');
    }

    const hasDecimal = stringValue.includes(t('hours_separator'));
    if (hasDecimal) {
      const [, decimalPart] = convertedEngineHours.split('.');
      if (
        decimalPart &&
        decimalPart.length > 2 &&
        convertedEngineHours.length > (engineHours?.toString() || '').length
      )
        return false;
    }
    const maxLength = hasDecimal ? 10 : 7;
    const numericValue = Number(convertedEngineHours);
    if (
      stringValue.length > maxLength ||
      isNaN(numericValue) ||
      numericValue > 9999999
    ) {
      return false;
    }
    setEngineHours(stringValue);
    return true;
  };

  const handleNotesBox = e => setNotes(e.target.value);

  const handleCheck = e => {
    const { id, checked } = e.target;

    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
    if (checked && currentCheckList.length == isCheck.length + 1) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  };

  const handleCheckAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(currentCheckList.map(li => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleSave = () => {
    const maintenanceCheckList = {};
    currentCheckList.forEach(item => {
      maintenanceCheckList[item.id] = isCheck.includes(item.id);
    });

    const newMaintenanceData = {
      maintenanceCheckList: maintenanceCheckList,
      id:
        props.action == 'new' ? utils.newGuid() : selectedMaintenanceHistory.id,
      intervalType: props.maintenanceInterval.intervalType,
      intervalValue: props.maintenanceInterval.intervalValue,
      completedEngineHours: Number(
        engineHours?.replace(t('hours_separator'), '.'),
      ),
      notes: notes ? notes : '',
      updatedDate: new Date(),
    } as IMaintenanceHistoryEntry;

    (async () => {
      setIsLoading(true);
      await ajax
        .put(
          `${ajax.appBaseUrl}/api/user/equipment/${currentEquipment.id}/maintenanceHistory`,
          JSON.stringify(newMaintenanceData),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(() => {
          const selectedMaintenaceIndex = currentMaintenanceHistory.indexOf(
            selectedMaintenanceHistory,
          );
          props.action == 'new'
            ? currentMaintenanceHistory.push(newMaintenanceData)
            : (currentMaintenanceHistory[selectedMaintenaceIndex] =
                newMaintenanceData);

          setMaintenanceHistoryData({
            ...maintenanceHistoryData,
            selectedEquipmentMaintenanceHistory: currentMaintenanceHistory,
            hasNewHistoryItem: props.action === 'new' ? true : false,
          });

          props.action == 'new' && props.closeTrackMaintenanceDialog();
          tagEvent('add_maintenance_record');
          props.closeDialog();
        })
        .catch(err => {
          setErrorMessage(err.response.data.message as string);
          setShowErrorModal(true);
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();
  };

  return (
    <>
      {showErrorModal && (
        <Dialog className="modal-center modal-error modal-remove-item modal-fulltext">
          <ErrorModal
            title={t('unexpected_error_title')}
            text={errorMessage || t('unexpected_error_message')}
            onClose={() => {
              setShowErrorModal(false);
              setErrorMessage('');
            }}
          />
        </Dialog>
      )}
      <Dialog className="dialog-container-wide">
        <div className="modal-container relative">
          <span className="back-button absolute" onClick={props.closeDialog}>
            <BackIcon />
          </span>

          <div>
            <div className="ellipsis">
              {equipmentListData.selectedEquipment?.nickName ||
                equipmentListData.selectedEquipment?.model}
            </div>
            <h1 className="m-b-30">{title}</h1>
          </div>
          <div className="custom-modal-layout">
            <div className="custom-modal-row-50">
              <EquipmentDetails
                eqipmentDetails={equipmentListData.selectedEquipment}
                openEngineHoursUpdateDialog={props.openEngineHoursUpdateDialog}
                setOpenEngineHoursUpdateDialog={
                  props.setOpenEngineHoursUpdateDialog
                }
              />
              <div className="form-group">
                <div className="form-content">
                  <div className="form-label">
                    <label>
                      {t('maintenance_checklist_engine_hours_hint')}
                    </label>
                  </div>
                </div>
                <div className="form-control">
                  <Input
                    type="text"
                    onChange={handleEngineHoursBox}
                    value={engineHours}
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-content">
                  <div className="form-label custom-label">
                    <label>{t('maintenance_checklist_notes_hint')}</label>
                    <span className="custom-label-right">
                      {t('maintenance_checklist_notes_counter2', {
                        characters: charactersCount,
                      })}
                    </span>
                  </div>
                </div>
                <div className="form-control">
                  <TextArea
                    maxLength={2500}
                    rows={5}
                    onChange={handleNotesBox}
                    value={notes}
                    placeholder={t('maintenance_checklist_notes_holder')}
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
            <div className="custom-modal-row-50 checkbox-list">
              <div className="checkbox-header">
                <h4>{t('select_maintenance_items_completed')}</h4>
                <div className="list-select-all">
                  <label>{t('check_all')}</label>
                  <input
                    className="m-l-12"
                    type="checkbox"
                    name="selectAll"
                    id="selectAll"
                    onChange={handleCheckAll}
                    checked={isCheckAll}
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className="maintenance-box">
                <div className="scroll-area" style={{ cursor: 'default' }}>
                  {checkListMeasures?.map(measure => {
                    return (
                      <div key={measure}>
                        <div className="maintenance-box-header">
                          <label className="weight-bold">{measure}</label>
                          <hr></hr>
                        </div>
                        {currentCheckList?.map(item => {
                          return (
                            item.measures === measure && (
                              <div
                                className="maintenance-box-element"
                                key={item.id}
                              >
                                <label className="input-check">
                                  {item.checkPoint}
                                </label>
                                <input
                                  id={item.id}
                                  name={item.checkPoint}
                                  type="checkbox"
                                  onChange={handleCheck}
                                  checked={isCheck.includes(item.id)}
                                  disabled={isLoading}
                                />
                              </div>
                            )
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <Button
                  className="btn-accent"
                  onClick={handleSave}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="loading_icon loading_icon-btn">
                      <div className="css-icon"></div>
                    </div>
                  ) : (
                    t('save')
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
