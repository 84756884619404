import { Card, CardBody } from '@progress/kendo-react-layout';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import _ from 'lodash';
import * as React from 'react';

import MenuIcon from 'assets/images/ic_more_secondary.svg';
//import { UserLocationContext } from 'app/components/ReactContexts/userLocationContext';
import { useLoadScript } from '@react-google-maps/api';
import { ajax } from 'ajax/ajax';
import { librariesList } from 'app/common/librariesList';
import { getHaversineDistance } from 'app/components/HerversineDistance/HaversineDistance';
import { LoadingOverlay } from 'app/components/LoadingOverlay';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { UserLocationContext } from 'app/components/ReactContexts/userLocationContext';
import ClickOutside from 'app/widgets/ClickOutside';
import { useTranslation } from 'react-i18next';
import { IGeofenceEntry, IPointEntry } from 'types/IGeofenceEntry';
import { tagEvent } from 'utils/ga';
import { DeleteGeofenceDialog } from './DeleteGeofenceDialog';
import { EditGeofenceName } from './EditGeofenceName';

interface IGeofenceGeofenceCard {
  index: number;
  geofence: IGeofenceEntry;
  measurementUnit: 'US' | 'METRIC';
}

export function GeofenceGeofenceCard(props: IGeofenceGeofenceCard) {
  const { setEquipmentListData: setData, equipmentListData } =
    React.useContext(EquipmentListContext);
  const { addErrorModal } = React.useContext(GlobalErrorContext);

  const { userLocation } = React.useContext(UserLocationContext);

  const [verticalEllipsisMenuIsExpanded, setVerticalEllipsisMenuIsExpanded] =
    React.useState(false);

  const [editGeofenceNameExpanded, setEditGeofenceNameExpanded] =
    React.useState(false);
  const [deleteGeofenceExpanded, setDeleteGeofenceExpanded] =
    React.useState(false);

  const spanRef = React.useRef<HTMLSpanElement | null>();

  React.useEffect(() => {
    if (spanRef.current) {
      if (equipmentListData.selectedGeofence?.id === props.geofence.id) {
        if (
          spanRef.current.getBoundingClientRect().bottom > window.innerHeight
        ) {
          spanRef.current.scrollIntoView(false);
        }

        if (spanRef.current.getBoundingClientRect().top < 0) {
          spanRef.current.scrollIntoView();
        }
      }
    }
  }, [equipmentListData.selectedGeofence?.id]);

  const cardCoordinatesRef = React.useRef<{
    screenX: number;
    screenY: number;
    hasMoved: boolean;
  }>();

  const { t } = useTranslation();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_API_KEY as string,
    libraries: librariesList,
  });

  const onDeleteEquipment = () => {
    ajax
      .delete(`${ajax.appBaseUrl}/api/user/geofence/${props.geofence.id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        setData(oldData => ({
          ...oldData,
          geofenceList: oldData.geofenceList?.filter(
            (value, index) => index !== props.index,
          ),
        }));
        tagEvent('delete_geofence');
      })
      .catch(e => {
        addErrorModal();
      })
      .finally(() => {
        setDeleteGeofenceExpanded(false);
      });
  };

  const sumOfPoints = _.reduce(
    _.take(props.geofence.points, props.geofence.points.length - 1),
    (sumOfPoints: IPointEntry, currentPoint) => {
      return {
        latitude: sumOfPoints.latitude + currentPoint.latitude,
        longitude: sumOfPoints.longitude + currentPoint.longitude,
      };
    },
  );

  const centroid = {
    latitude: sumOfPoints!.latitude / (props.geofence.points.length - 1),
    longitude: sumOfPoints!.longitude / (props.geofence.points.length - 1),
  } as IPointEntry;

  const [adress, setAdress] = React.useState('');

  React.useEffect(() => {
    if (isLoaded && centroid) {
      new google.maps.Geocoder()
        .geocode({
          location: {
            lat: centroid.latitude,
            lng: centroid.longitude,
          },
        })
        .then(result => {
          const cityName = result.results[0].address_components.find(ac =>
            ac.types.includes('locality'),
          )?.long_name;

          const neighberhoodName = result.results[0].address_components.find(
            ac => ac.types.includes('neighborhood'),
          )?.long_name;

          const routeName = result.results[0].address_components.find(ac =>
            ac.types.includes('route'),
          )?.long_name;

          const stateName =
            result.results[0].address_components.find(ac =>
              ac.types.includes('administrative_area_level_1'),
            )?.long_name ||
            result.results[1].address_components.find(ac =>
              ac.types.includes('administrative_area_level_1'),
            )?.long_name;

          const administrative_area_level_3 =
            result.results[0].address_components.find(ac =>
              ac.types.includes('administrative_area_level_1'),
            )?.long_name;

          const administrative_area_level_2 =
            result.results[0].address_components.find(ac =>
              ac.types.includes('administrative_area_level_1'),
            )?.long_name;

          const cityName1 = result.results[1].address_components.find(ac =>
            ac.types.includes('locality'),
          )?.long_name;
          setAdress(
            `${
              cityName ||
              cityName1 ||
              neighberhoodName ||
              routeName ||
              administrative_area_level_3 ||
              administrative_area_level_2 ||
              cityName1
            } ${stateName}`,
          );
        })
        .catch(() => {
          setAdress(t('location_unavailable1'));
        });
    }
  }, [isLoaded]);

  const { removeSpecificClass } = React.useContext(LayoutContext);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        setEditGeofenceNameExpanded(false);
        setDeleteGeofenceExpanded(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    <span
      ref={ref => {
        spanRef.current = ref;
      }}
    >
      {editGeofenceNameExpanded && (
        <EditGeofenceName
          closeDialog={() => {
            setEditGeofenceNameExpanded(false);
          }}
          geofence={props.geofence}
          index={props.index}
        />
      )}

      {deleteGeofenceExpanded && (
        <DeleteGeofenceDialog
          isLoading={false}
          close={() => {
            setDeleteGeofenceExpanded(false);
          }}
          geofenceName={props.geofence.description}
          deleteGeofence={() => {
            onDeleteEquipment();
          }}
        />
      )}
      <Card
        className={`equipment-list-card ${
          props.geofence.id === equipmentListData.selectedGeofence?.id &&
          'equipment-list-card-selected'
        }`}
        onMouseDown={e => {
          cardCoordinatesRef.current = {
            screenX: e.screenX,
            screenY: e.screenY,
            hasMoved: false,
          };
        }}
        onMouseMove={e => {
          if (!cardCoordinatesRef.current) {
            return;
          }
          if (
            Math.abs(e.screenX - cardCoordinatesRef.current.screenX) +
              Math.abs(e.screenY - cardCoordinatesRef.current.screenY) >
            10
          ) {
            cardCoordinatesRef.current = {
              hasMoved: true,
              screenX: -100,
              screenY: -100,
            };
          }
        }}
        onMouseUp={() => {
          if (!cardCoordinatesRef.current?.hasMoved) {
            setData(oldData => ({
              ...oldData,
              selectedGeofence: props.geofence,
              panTo: {
                latLongBonds: props.geofence.points,
              },
            }));
          }
        }}
      >
        <CardBody className="geofences">
          <div
            className="only-mobile-click-box"
            onClick={() => {
              removeSpecificClass('geofences-menu-extended');
            }}
          ></div>
          <div
            className="row-3"
            onMouseLeave={() => {
              setVerticalEllipsisMenuIsExpanded(false);
            }}
          >
            <div className="col-xxs-3 geofence-item-number">
              <svg
                id="ic_geofence_item"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <rect id="Box" width="24" height="24" fill="none" />
                <g id="icon" transform="translate(-5171 -1647.5)">
                  <path
                    id="Map"
                    d="M4404,688.5c-4.135,0-7.5-1.57-7.5-3.5,0-1.4,1.773-2.657,4.518-3.212A39.575,39.575,0,0,0,4404,685.25a39.234,39.234,0,0,0,2.981-3.462c2.746.556,4.519,1.817,4.519,3.212C4411.5,686.93,4408.136,688.5,4404,688.5Z"
                    transform="translate(779 982.5)"
                    fill="none"
                    stroke="#909090"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                  <g id="Pin" transform="translate(0 1)">
                    <path
                      id="Path"
                      d="M5188,1656c0,3.7-6,9.75-6,9.75s-6-6.047-6-9.75a6,6,0,0,1,12,0Z"
                      transform="translate(1 1)"
                      fill="none"
                      stroke="#909090"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.2"
                    />
                    <circle
                      id="Path-2"
                      data-name="Path"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(5180.5 1654.5)"
                      fill="none"
                      stroke="#909090"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className="col-xxs-6 geofence-item-text">
              <div className="equipment-list-nickname geofences-list-title">
                {props.geofence.description}
              </div>
              {adress ? (
                <div className="geo-time">{adress}</div>
              ) : (
                <LoadingOverlay isOpen text={t('loading_adress')} />
              )}
            </div>
            <div className="col-xxs-3 geofence-item-address">
              <span className="geofences-menu-container">
                {userLocation?.location?.coords && (
                  <span>
                    {props.measurementUnit === 'US' ? (
                      <>
                        {Math.floor(
                          0.000621371 *
                            getHaversineDistance(
                              {
                                latitude:
                                  userLocation?.location?.coords.latitude,
                                longitude:
                                  userLocation?.location?.coords.longitude,
                              },
                              centroid,
                            ) *
                            100,
                        ) / 100}{' '}
                        mi
                      </>
                    ) : (
                      <>
                        {Math.floor(
                          (getHaversineDistance(
                            {
                              latitude: userLocation?.location?.coords.latitude,
                              longitude:
                                userLocation?.location?.coords.longitude,
                            },
                            centroid,
                          ) /
                            1000) *
                            100,
                        ) / 100}{' '}
                        km
                      </>
                    )}
                  </span>
                )}
                <div className="geofences-menu-icon">
                  <span className="dot-menu-dropdown">
                    <ClickOutside
                      onClickOutside={() => {
                        setVerticalEllipsisMenuIsExpanded(false);
                      }}
                    >
                      <img
                        src={MenuIcon}
                        className="geofences-menu-icon"
                        onClick={ev => {
                          setVerticalEllipsisMenuIsExpanded(
                            !verticalEllipsisMenuIsExpanded,
                          );
                        }}
                      />
                      <div className="dot-menu-dropdown-options">
                        <div>
                          {verticalEllipsisMenuIsExpanded && (
                            <>
                              <span
                                onClick={() => {
                                  setEditGeofenceNameExpanded(true);
                                }}
                              >
                                {' '}
                                {t('edit_name')}
                              </span>
                              <span
                                onClick={() => {
                                  setDeleteGeofenceExpanded(true);
                                }}
                              >
                                {' '}
                                {t('menu_delete')}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </ClickOutside>
                  </span>
                </div>
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </span>
  );
}
