import * as React from 'react';
import AlertSuccessIcon from '../../../assets/images/notification-success.svg';
import AlertErrorIcon from '../../../assets/images/ill_banner_error.svg';

export function Success(props: { text: string; additionalClass?: string }) {
  return (
    <div className={`alert-container alert-success ${props.additionalClass}`}>
      <span className="alert-icon">
        <img alt="Notification Success" src={AlertSuccessIcon}></img>
      </span>
      <span className="alert-text">{props.text}</span>
    </div>
  );
}

export function ErrorNotification(props: { text: string; title?: string }) {
  return (
    <div className="errors-box form-global-errors alert-container">
      <span className="error-icon alert-icon">
        <img src={AlertErrorIcon} alt="Error"></img>
      </span>
      <div className="error-list">
        {props.title && (
          <span>
            <b>{props.title}</b>
          </span>
        )}
        <span>{props.text}</span>
      </div>
    </div>
  );
}
