import { FleetSelectionView } from 'app/components/access-sharing/flows';
import { useAccessSharingFlowContext } from 'app/hooks/access-sharing';
import { ACCESS_SHARING_FLEET_STEPS } from 'app/constants/access-sharing';
import React from 'react';

interface Props {
  fleets: any[];
  onBack: () => void;
}

export default function FleetSelectionStepController({
  fleets,
  onBack,
}: Props) {
  const {
    stepper,
    isEditing,
    setIsEditing,
    selectedFleetId,
    setSelectedFleetId,
  } = useAccessSharingFlowContext();

  const { setStep } = stepper;

  function handlePrevStep() {
    if (isEditing) {
      setStep(ACCESS_SHARING_FLEET_STEPS.OVERVIEW);
      setIsEditing(false);
    } else {
      onBack();
    }
  }

  function handleNextStep() {
    if (isEditing) {
      setStep(ACCESS_SHARING_FLEET_STEPS.OVERVIEW);
      setIsEditing(false);
    } else {
      setStep(ACCESS_SHARING_FLEET_STEPS.ACCESS_SHARING);
    }
  }

  return (
    <FleetSelectionView
      fleets={fleets}
      selectedFleetId={selectedFleetId}
      setSelectedFleetId={setSelectedFleetId}
      onBack={handlePrevStep}
      onNextStep={handleNextStep}
    />
  );
}
