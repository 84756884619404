// import { ajax } from '../../ajax';
import { ShareFleetRequest } from './types';

// const url = ajax.appBaseUrl;

export default class FleetService {
  static async share(data: ShareFleetRequest) {
    // return ajax.post<ShareFleetResponse>(`${url}/fleet/share`, data);
    return new Promise(res => {
      setTimeout(() => {
        res(1);
      }, 2000);
    });
  }
}
