import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { FilterCard } from 'app/components/FilterCard';
import { ListAndMap } from 'app/components/ListAndMap';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import ClickOutside from 'app/widgets/ClickOutside';
import SecondaryBtnIcon from 'assets/images/ic_btn_add.svg';
import FilterIcon from 'assets/images/ic_btn_filter.svg';
import SearchIcon from 'assets/images/ic_search.svg';
import SearchIconBack from 'assets/images/ic_search_back.svg';
import _ from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { AddEquipment } from '../AddEquipment';
import { EquipmentListCard } from './EquipmentListCard';

interface IEquipmentList {}

export function EquipmentList(props: IEquipmentList) {
  const { equipmentListData: data, setEquipmentListData: setData } =
    React.useContext(EquipmentListContext);
  const [showAddDialog, setShowAddDialog] = React.useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = React.useState('');
  const [searchBarExtended, setSearchBarExtended] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const { t } = useTranslation();

  const filterButtonRef = React.useRef<Button>(null);

  const scrolableRef = React.useRef(null as null | HTMLDivElement);
  const posRef = React.useRef({ top: 0, left: 0, x: 0, y: 0 });
  const [filteredEquipmentList, setFilteredEquipmentList] = React.useState<any>(
    data.equipmentList,
  );
  const handleUpdateFilteredList = filteredList => {
    setFilteredEquipmentList(filteredList);
  };

  const mouseMoveHandler = function (e) {
    // How far the mouse has been moved
    const dx = e.clientX - posRef.current.x;
    const dy = e.clientY - posRef.current.y;

    // Scroll the element
    scrolableRef.current!.scrollTop = posRef.current.top - dy;
    scrolableRef.current!.scrollLeft = posRef.current.left - dx;
  };

  const mouseUpHandler = function () {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    scrolableRef.current!.style.cursor = 'grab';
    scrolableRef.current!.style.removeProperty('user-select');
  };

  const mouseDownHandler = function (e) {
    posRef.current = {
      // The current scroll
      left: scrolableRef.current!.scrollLeft,
      top: scrolableRef.current!.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    scrolableRef.current!.style.cursor = 'grabbing';
    scrolableRef.current!.style.userSelect = 'none';

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const updateEquipmentList = (
    equipmentList,
    selectedIndex,
    selectedEquipment,
  ) => {
    if (selectedIndex >= 0) {
      setData({
        ...data,
        equipmentList,
        selectedIndex,
        selectedEquipment,
      });
    } else {
      setData({
        ...data,
        equipmentList,
      });
    }
  };

  React.useEffect(() => {
    if (
      (location.pathname === '/equipment' ||
        location.pathname === '/equipment/') &&
      data.selectedEquipment?.id
    ) {
      navigate('/equipment/' + data.selectedEquipment.id, { replace: true });
    }
  }, [data.selectedEquipment?.id, location.pathname]);

  const { setClassNames, removeSpecificClass, removeClassNames } =
    React.useContext(LayoutContext);
  React.useEffect(() => {
    let index: number = setClassNames('layout-equipment');

    return () => {
      removeClassNames(index);
      removeSpecificClass('equipment-details-visible');
    };
  }, []);

  React.useEffect(() => {
    var attachments = data.equipmentList?.filter(e => !!e.smartAttachment);
    attachments?.forEach(item => {
      var index = data.equipmentList?.findIndex(
        e => e.telematics?.smartAttachment?.serial === item.serial,
      );
      var deleteIndex = data.equipmentList?.findIndex(
        e => e.serial === item.serial,
      );
      if (!!index && index >= 0) {
        if (!!deleteIndex && deleteIndex >= 0)
          data.equipmentList?.splice(deleteIndex, 1);
        data.equipmentList?.splice(index - 1, 0, item);
      }
    });
  }, []);

  React.useEffect(() => {
    var filteredList = data.equipmentList;
    if (data.filtersList && data.filtersList.includes('active_fault_codes')) {
      filteredList = filteredList?.filter(
        f =>
          f.telematics &&
          f.telematics.faultCodes &&
          f.telematics?.faultCodes?.length > 0,
      );
    }

    if (data.filtersList && data.filtersList.includes('machine_running')) {
      filteredList = filteredList?.filter(
        f => f.telematics && f.telematics.engineRunning,
      );
    }

    if (data.filtersList && data.filtersList.includes('machine_in_transport')) {
      filteredList = filteredList?.filter(
        f => f.telematics && f.telematics.motionState == 'in-transport',
      );
    }

    if (data.filtersList && data.filtersList.includes('restart_inhibited')) {
      filteredList = filteredList?.filter(
        f =>
          f.telematics &&
          f.telematics.restartInhibitStatus != null &&
          f.telematics.restartInhibitStatus?.equipmentStatus ==
            'RestartDisabled',
      );
    }

    setFilteredEquipmentList(filteredList);
  }, [data.equipmentList, data.filtersList]);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        setShowAddDialog(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  const hasAnyTelematics = data.equipmentList?.find(e => e.hasTelematics);

  return (
    <div className="equipment-list">
      {showAddDialog && (
        <Dialog className="modal-equipment modal-fixed-button">
          <AddEquipment
            updateEquipmentList={updateEquipmentList}
            close={() => setShowAddDialog(false)}
          />
        </Dialog>
      )}
      {showFilter && (
        <ClickOutside
          onClickOutside={event => {
            const targetNode = event.target as Node;
            if (
              filterButtonRef.current &&
              !filterButtonRef.current.element?.contains(targetNode)
            ) {
              setShowFilter(false);
            }
          }}
        >
          <FilterCard updateFilteredList={handleUpdateFilteredList} />
        </ClickOutside>
      )}
      <div className="equipment-list-top-bar">
        {hasAnyTelematics && <ListAndMap isMapMode={false} />}
        <div
          className={`site-search right-to-left ${
            !hasAnyTelematics
              ? 'open_non_telematics'
              : searchBarExtended
              ? 'open'
              : ''
          }`}
        >
          <span
            className="search-icon"
            onClick={() => {
              if (searchBarExtended) {
                setSearchValue('');
              }
              setSearchBarExtended(!searchBarExtended);
            }}
          >
            {!hasAnyTelematics ? (
              <img alt="Search" src={SearchIcon}></img>
            ) : (
              <>
                {searchBarExtended && (
                  <img alt="Search" src={SearchIconBack}></img>
                )}
                {!searchBarExtended && (
                  <img alt="Search" src={SearchIcon}></img>
                )}
              </>
            )}
          </span>
          <div className="search-input">
            {(searchBarExtended || !hasAnyTelematics) && (
              <ClickOutside
                onClickOutside={() => {
                  if (!(searchValue?.length > 0) && hasAnyTelematics) {
                    setSearchBarExtended(false);
                  }
                }}
              >
                <div>
                  <Input
                    autoFocus
                    id="equipmentSearch"
                    name="equipmentSearch"
                    placeholder={t('search_equipment')}
                    value={searchValue}
                    onChange={newValue => {
                      setSearchValue(newValue.value);
                    }}
                  />
                  {searchValue && (
                    <span
                      className="search-icon-close"
                      onClick={() => {
                        setSearchValue('');
                      }}
                    >
                      <svg
                        id="ic_close_small"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <rect id="Box" width="24" height="24" fill="none" />
                        <g id="Icon" transform="translate(-2078.936 -713.936)">
                          <line
                            id="Line_1"
                            data-name="Line 1"
                            x2="8"
                            y2="8"
                            transform="translate(2086.935 721.935)"
                            fill="none"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeWidth="1"
                          />
                          <line
                            id="Line_2"
                            data-name="Line 2"
                            x1="8"
                            y2="8"
                            transform="translate(2086.935 721.935)"
                            fill="none"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeWidth="1"
                          />
                        </g>
                      </svg>
                    </span>
                  )}
                </div>
              </ClickOutside>
            )}
          </div>
        </div>
        <Button
          className={`
            button-plus 
            k-item 
            k-menu-item 
            add-equipment-button
            ${!hasAnyTelematics && 'non-telematics-add-button'} 
            ${!hasAnyTelematics ? '' : searchBarExtended ? 'search-opened' : ''}
          `}
          onClick={() => setShowAddDialog(true)}
          themeColor="secondary"
        >
          <span className="btn_icon">
            <img src={SecondaryBtnIcon} alt="Secodary btn icon" />
          </span>
        </Button>
        <Button
          className={`
            button-plus 
            k-item 
            k-menu-item 
            filter-equipment-button
            ${!hasAnyTelematics && 'non-telematics-filter-button'} 
            ${!hasAnyTelematics ? '' : searchBarExtended ? 'search-opened' : ''}
          `}
          ref={filterButtonRef}
          onClick={() => setShowFilter(!showFilter)}
          themeColor="secondary"
        >
          <span className="btn_icon image-with-dot">
            <img src={FilterIcon} alt="Secodary btn icon" />
            {data.filtersList && data.filtersList.length > 0 && (
              <span className="red-dot"></span>
            )}
          </span>
        </Button>
      </div>

      {!_.some(
        filteredEquipmentList || [],
        eq =>
          (eq?.nickName || '')
            .toLowerCase()
            .includes(searchValue.toLocaleLowerCase()) ||
          (eq?.pinOrSerial || '')
            .toLowerCase()
            .includes(searchValue.toLocaleLowerCase()) ||
          (eq?.model || '')
            .toLowerCase()
            .includes(searchValue.toLocaleLowerCase()),
      ) && (
        <>
          <div className="no-found-message">
            <h4>{t('no_results_found')}</h4>
            {data.filtersList && data.filtersList.length > 0 && (
              <div>{t('no_equipment_found_message')}</div>
            )}
          </div>
        </>
      )}

      {filteredEquipmentList && (
        <div
          className="equipment-list-scroll-items"
          ref={scrolableRef}
          onMouseDown={filteredEquipmentList && mouseDownHandler}
        >
          {data.equipmentList &&
            data.equipmentList.map((equipment: IEquipmentEntry, index) => {
              if (
                filteredEquipmentList.some(f => f.id == equipment.id) &&
                ((equipment?.nickName || '')
                  .toLowerCase()
                  .includes(searchValue.toLocaleLowerCase()) ||
                  (equipment?.pinOrSerial || '')
                    .toLowerCase()
                    .includes(searchValue.toLocaleLowerCase()) ||
                  (equipment?.model || '')
                    .toLowerCase()
                    .includes(searchValue.toLocaleLowerCase()))
              ) {
                return (
                  <EquipmentListCard
                    key={equipment.id}
                    equipment={equipment}
                    index={index}
                  />
                );
              }
              return null;
            })}
        </div>
      )}
    </div>
  );
}
