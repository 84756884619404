import ChevronRight from 'assets/images/chevron_right.svg';
import GeofencesRedBorder from 'assets/images/geofences_red_border.svg';
import GeofencesWhiteBackground from 'assets/images/geofences_white_background.svg';
import EvEquipmentIcon from 'assets/images/ic_equipment_ev.svg';
import PinError from 'assets/images/ic_pin_error.svg';
import EquipmentLinkIcon from 'assets/images/ic_pin_link.svg';
import _ from 'lodash';
import * as React from 'react';

import { Card } from '@progress/kendo-react-layout';
import { InfoWindowF, MarkerF } from '@react-google-maps/api';
import { EquipmentTelematicDescription } from 'app/components/EquipmentTelematicDescription';
import { getHaversineDistance } from 'app/components/HerversineDistance/HaversineDistance';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { UserLocationContext } from 'app/components/ReactContexts/userLocationContext';
import { SmartAttachmentModelDescription } from 'app/components/SmartAttachmentModelDescription';
import ClickOutside from 'app/widgets/ClickOutside';
import { useNavigate } from 'react-router-dom';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { getDefaultThumbnail } from '../utils';
import { CustomPoint, checkInside } from './utils';

interface IEquipmentMarker {
  equipment: IEquipmentEntry;
  index: number;
  measurementUnit: string;
}

export function EquipmentMarker(props: IEquipmentMarker) {
  const navigate = useNavigate();

  const markerPosition = {
    lat: props.equipment.telematics?.location?.latitude || -1,
    lng: props.equipment.telematics?.location?.longitude || -1,
  };

  const { userLocation } = React.useContext(UserLocationContext);

  const [modelIconAspectRatio, setModelIconAspectRatio] = React.useState(1);
  const [isModelIconAspectRatioSet, setIsModelIconAspectRatioSet] =
    React.useState(false);

  React.useEffect(() => {
    var img = new Image();
    img.src =
      props.equipment.modelIconUrl ||
      getDefaultThumbnail(props.equipment.type, props.equipment.subcategory);
    img.onload = function () {
      var width = img.naturalWidth;
      var height = img.naturalHeight;
      setModelIconAspectRatio(width / height > 1 ? width / height : 1);
      setIsModelIconAspectRatioSet(true);
    };
  }, []);

  const { setEquipmentListData, equipmentListData } =
    React.useContext(EquipmentListContext);

  if (
    !props.equipment.telematics?.location?.latitude ||
    !props.equipment.telematics?.location?.longitude
  ) {
    return <></>;
  }

  let insideGeofence = false;
  equipmentListData.geofenceList?.map(geofence => {
    let polygon = geofence.points.map(
      gfp => new CustomPoint(gfp.latitude, gfp.longitude),
    );
    let p = new CustomPoint(markerPosition.lat, markerPosition.lng);
    let n = polygon.length;

    if (checkInside(polygon, n, p)) {
      insideGeofence = true;
    }
    return geofence;
  });

  if (equipmentListData.geofenceList?.length === 0) {
    insideGeofence = true;
  }

  const smartAttachmentInfo = props.equipment.telematics?.smartAttachment
    ?.serial
    ? equipmentListData.equipmentList?.find(
        s => s.serial === props.equipment.telematics?.smartAttachment?.serial,
      )
      ? equipmentListData.equipmentList?.find(
          s => s.serial === props.equipment.telematics?.smartAttachment?.serial,
        )
      : props.equipment.telematics?.smartAttachment
    : null;

  const smartAttachmentProps = {
    name: smartAttachmentInfo
      ? smartAttachmentInfo.nickName || smartAttachmentInfo.model
      : props.equipment.telematics?.smartAttachment?.model,
    model: props.equipment.telematics?.smartAttachment?.model,
    pin: props.equipment.telematics?.smartAttachment?.serial,
    imageUrl:
      smartAttachmentInfo?.modelIconUrl ||
      getDefaultThumbnail(
        smartAttachmentInfo?.type,
        smartAttachmentInfo?.subcategory,
      ),
  };

  return (
    <div
      onClick={() => {
        setEquipmentListData(oldData => ({
          ...oldData,
          selectedEquipment: props.equipment,
          panTo: {
            position: {
              lat: props.equipment.telematics?.location?.latitude,
              lng: props.equipment.telematics?.location?.longitude,
            },
            zoom: 14,
          },
        }));
      }}
    >
      {props.equipment.id === equipmentListData.selectedEquipment?.id && (
        <>
          <InfoWindowF
            onCloseClick={() => {
              setEquipmentListData(oldData => ({
                ...oldData,
                selectedEquipment: undefined,
              }));
            }}
            position={markerPosition}
            options={{
              pixelOffset: new window.google.maps.Size(0, -31, 'px', 'px'),
            }}
          >
            <ClickOutside
              onClickOutside={(e: any) => {
                if (e.target.parentElement.parentElement.id !== 'listandmap') {
                  setEquipmentListData(oldData => ({
                    ...oldData,
                    selectedEquipment: undefined,
                  }));
                }
              }}
            >
              <div
                style={{ width: '350px' }}
                className="geofences-card-wrapper"
              >
                <span
                  className="cursor-pointer geofences-card-footer-equipment"
                  onClick={() => {
                    setEquipmentListData(oldData => ({
                      ...oldData,
                      openContent: true,
                    }));
                    navigate(`/equipment/${props.equipment.id}`);
                  }}
                >
                  <img src={ChevronRight} style={{ float: 'right' }} />
                </span>

                <EquipmentTelematicDescription equipment={props.equipment} />
              </div>
              <div className="geofence-bottom-wrapper">
                {userLocation?.location?.coords &&
                  props.equipment.telematics?.location?.latitude &&
                  props.equipment.telematics?.location?.longitude && (
                    <span className="geofences-card-footer-distance">
                      {props.measurementUnit === 'US' ? (
                        <>
                          {Math.floor(
                            0.000621371 *
                              getHaversineDistance(
                                {
                                  latitude:
                                    userLocation?.location?.coords.latitude,
                                  longitude:
                                    userLocation?.location?.coords.longitude,
                                },
                                props.equipment.telematics?.location,
                              ) *
                              100,
                          ) / 100}{' '}
                          mi
                        </>
                      ) : (
                        <>
                          {Math.floor(
                            (getHaversineDistance(
                              {
                                latitude:
                                  userLocation?.location?.coords.latitude,
                                longitude:
                                  userLocation?.location?.coords.longitude,
                              },
                              props.equipment.telematics?.location,
                            ) /
                              1000) *
                              100,
                          ) / 100}{' '}
                          km
                        </>
                      )}
                    </span>
                  )}
              </div>
              <div>
                {props.equipment.telematics?.smartAttachment && (
                  <>
                    {smartAttachmentInfo && (
                      <Card
                        className={'equipment-list-card'}
                        style={{ width: '350px' }}
                      >
                        <div className="intel-info-link-icon">
                          <img
                            src={EquipmentLinkIcon}
                            alt="Equipment Link Icon"
                          ></img>
                          {smartAttachmentInfo?.id && (
                            <span
                              className="cursor-pointer geofences-card-footer-equipment"
                              onClick={() => {
                                setEquipmentListData(oldData => ({
                                  ...oldData,
                                  openContent: true,
                                }));
                                navigate(
                                  `/equipment/${smartAttachmentInfo?.id}`,
                                );
                              }}
                            >
                              <img
                                src={ChevronRight}
                                style={{
                                  float: 'right',
                                  position: 'absolute',
                                  left: '315px',
                                  top: '20px',
                                }}
                              />
                            </span>
                          )}
                        </div>
                        <SmartAttachmentModelDescription
                          equipment={smartAttachmentProps}
                        />
                      </Card>
                    )}
                  </>
                )}
              </div>
            </ClickOutside>
          </InfoWindowF>
        </>
      )}

      {isModelIconAspectRatioSet && (
        <>
          <MarkerF
            opacity={equipmentListData.isDrawing ? 0.2 : 1}
            onClick={() => {
              setEquipmentListData(oldData => ({
                ...oldData,
                selectedEquipment: props.equipment,
                panTo: {
                  position: {
                    lat: props.equipment.telematics?.location?.latitude,
                    lng: props.equipment.telematics?.location?.longitude,
                  },
                  zoom: 14,
                },
              }));
            }}
            key={`equipmentMarker${props.equipment.id}_${equipmentListData.isDrawing}`}
            icon={{
              url:
                props.equipment.modelIconUrl ||
                getDefaultThumbnail(
                  props.equipment.type,
                  props.equipment.subcategory,
                ),
              scale: 1,
              scaledSize: new window.google.maps.Size(
                28,
                28 / modelIconAspectRatio,
                'px',
                'px',
              ), // If this size changes make sure the css fix styles are changed as well (object-fit: contain in geofences.less).
              anchor: new window.google.maps.Point(
                14,
                14 / modelIconAspectRatio,
              ), // Center of the icon
            }}
            zIndex={2 + props.index * 4}
            position={markerPosition}
          />
          <MarkerF
            opacity={equipmentListData.isDrawing ? 0.2 : 1}
            onClick={() => {
              setEquipmentListData(oldData => ({
                ...oldData,
                selectedEquipment: props.equipment,
                panTo: {
                  position: {
                    lat: props.equipment.telematics?.location?.latitude,
                    lng: props.equipment.telematics?.location?.longitude,
                  },
                  zoom: 14,
                },
              }));
            }}
            key={`whiteBackgroundMarker${props.equipment.id}_${equipmentListData.isDrawing}`}
            icon={{
              url: GeofencesWhiteBackground,
              scaledSize: new window.google.maps.Size(50, 50),
              anchor: new window.google.maps.Point(25, 25), // Center of the icon
            }}
            zIndex={props.index * 4}
            position={markerPosition}
          />
        </>
      )}

      {isModelIconAspectRatioSet &&
        !insideGeofence &&
        !equipmentListData.isDrawing && (
          <MarkerF
            onClick={() => {
              setEquipmentListData(oldData => ({
                ...oldData,
                selectedEquipment: props.equipment,
                panTo: {
                  position: {
                    lat: props.equipment.telematics?.location?.latitude,
                    lng: props.equipment.telematics?.location?.longitude,
                  },
                  zoom: 14,
                },
              }));
            }}
            key={`redBorderMarker${props.equipment.id}`}
            icon={{
              url: GeofencesRedBorder,
              scaledSize: new window.google.maps.Size(60, 60),
              anchor: new window.google.maps.Point(30, 30), // Center of the icon
            }}
            zIndex={1 + props.index * 4}
            position={markerPosition}
          />
        )}

      {isModelIconAspectRatioSet &&
        !_.isEmpty(props.equipment.telematics?.faultCodes) &&
        !equipmentListData.isDrawing && (
          <MarkerF
            onClick={() => {
              setEquipmentListData(oldData => ({
                ...oldData,
                selectedEquipment: props.equipment,
                panTo: {
                  position: {
                    lat: props.equipment.telematics?.location?.latitude,
                    lng: props.equipment.telematics?.location?.longitude,
                  },
                  zoom: 14,
                },
              }));
            }}
            key={`redPinMarker${props.equipment.id}`}
            icon={{
              url: PinError,
              scaledSize: new window.google.maps.Size(20, 20),
              anchor: new window.google.maps.Point(-8, 32), // Center of the icon
            }}
            zIndex={3 + props.index * 4}
            position={markerPosition}
          />
        )}
      {!!props.equipment.telematics?.smartAttachment ? (
        <MarkerF
          onClick={() => {
            setEquipmentListData(oldData => ({
              ...oldData,
              selectedEquipment: props.equipment,
              panTo: {
                position: {
                  lat: props.equipment.telematics?.location?.latitude,
                  lng: props.equipment.telematics?.location?.longitude,
                },
                zoom: 14,
              },
            }));
          }}
          key={`saPinMarker${props.equipment.id}`}
          icon={{
            url: EquipmentLinkIcon,
            scaledSize: new window.google.maps.Size(20, 20),
            anchor: new window.google.maps.Point(-8, -8), // Center of the icon
          }}
          zIndex={3 + props.index * 4}
          position={markerPosition}
        />
      ) : null}
      {!!props.equipment.telematics?.electric &&
      !!!props.equipment.telematics?.smartAttachment ? (
        <MarkerF
          onClick={() => {
            setEquipmentListData(oldData => ({
              ...oldData,
              selectedEquipment: props.equipment,
              panTo: {
                position: {
                  lat: props.equipment.telematics?.location?.latitude,
                  lng: props.equipment.telematics?.location?.longitude,
                },
                zoom: 14,
              },
            }));
          }}
          key={`evPinMarker${props.equipment.id}`}
          icon={{
            url: EvEquipmentIcon,
            scaledSize: new window.google.maps.Size(20, 20),
            anchor: new window.google.maps.Point(-8, -8), // Center of the icon
          }}
          zIndex={3 + props.index * 4}
          position={markerPosition}
        />
      ) : null}
    </div>
  );
}
