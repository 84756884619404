import React from 'react';
import PendingReceivedInvitations from './PendingReceivedInvitations';
import PendingSentInvitations from './PendingSentInvitations';

interface IPendingInvitations {
  isMobile: boolean;
}

function PendingInvitations(props: IPendingInvitations) {
  return (
    <div>
      <PendingReceivedInvitations isMobile={props.isMobile} />
      <PendingSentInvitations isMobile={props.isMobile} />
    </div>
  );
}

export default PendingInvitations;
