import { Button } from '@progress/kendo-react-buttons';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { ic_restart_inhibit_locally_disabled as LocallyDisabledIcon } from 'app/widgets/SVGs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

interface InhibitRestartLocallyDisabledProps {
  equipmentModel?: string;
  equipmentIdentifierType?: string;
  equipmentPinOrSerial?: string;
}
export function InhibitRestartLocallyDisabled(
  props: InhibitRestartLocallyDisabledProps,
) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  // mount
  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  const { equipmentModel, equipmentIdentifierType, equipmentPinOrSerial } =
    props;

  return (
    <>
      <h1>{t('inhibit_restart_title')}</h1>
      <div className="inline-label-text">
        <span className="label">{t('equipment_model')}</span>
        <span className="text">{equipmentModel}</span>
      </div>
      <div className="inline-label-text">
        <span className="label">
          {equipmentIdentifierType === 'Pin'
            ? t('equipment_pin')
            : t('equipment_serial')}{' '}
        </span>
        <span className="text">{equipmentPinOrSerial}</span>
      </div>
      <div className="separator-xl"></div>
      <div>
        <div className="inhebit-svg">
          <LocallyDisabledIcon />
        </div>
      </div>
      <div>
        <div>
          <div className="separator-l"></div>
          <h3>{t('locally_disabled_inhibit_restart_title')}</h3>
        </div>
        <div className="separator-l"></div>
        <div>
          <Button
            onClick={() => {
              navigate('/dealers');
            }}
            className="button-small"
          >
            {t('contact_dealer')}
          </Button>
        </div>
        <div className="separator-s"></div>
        <div>
          <p>{t('locally_disabled_inhibit_restart_description')}</p>
        </div>
        <div>
          <p>{t('locally_disabled_inhibit_restart_message')}</p>
        </div>
      </div>
    </>
  );
}
