import React from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { ic_more_secondary as MoreIcon } from 'app/widgets/SVGs';
import ClickOutside from 'app/widgets/ClickOutside';
import { Card } from '@progress/kendo-react-layout';
import { Avatar } from 'app/components/Avatar';

interface ISharedWithRow {
  user: IEquipmentEntry;
  isMobile: boolean;
}

function SharedWithRow(props: ISharedWithRow) {
  const [showGeneralMore, setShowGeneralMore] = React.useState(false);
  const { t } = useTranslation();
  return (
    <>
      {!props.isMobile ? (
        <div>
          <div className="access-sharing-table-row shared-with">
            <div>
              <div className="picture-and-description shared-with">
                <Avatar name="Andrew" />
                <div>
                  <div className="equipment-list-nickname">Andrew</div>
                  <div>john.mayer@email.com</div>
                </div>
              </div>
            </div>
            <div className="shared-with-access-to">
              2 pieces of equipment, 1 fleet
            </div>
            <div className="shared-with-access-to">
              THW-D37DJK, STV-45GM6, Texas Group
            </div>
            <div>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowGeneralMore(!showGeneralMore);
                }}
              >
                <MoreIcon />
              </span>
              {showGeneralMore && (
                <span className="notification-more dot-menu-dropdown">
                  <ClickOutside
                    onClickOutside={() => {
                      setShowGeneralMore(false);
                    }}
                  >
                    <div className="dot-menu-dropdown-options">
                      <div>
                        <span onClick={async () => {}}>
                          {t('access_sharing_add_new_user')}
                        </span>
                        <span onClick={() => {}}>{t('view_logs')}</span>
                      </div>
                    </div>
                  </ClickOutside>
                </span>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="access-sharing-table-card shared-with">
          <div className="row picture-and-description shared-with">
            <Avatar name="Andrew" />
            <div className="col">
              <div className="equipment-list-nickname">Andrew</div>
              <div>john.mayer@email.com</div>
            </div>
          </div>
          <div className="equipment-list-details">
            <div className="row-4">
              <div className="col-xxs-12">
                <Card className="access-sharing-equipment-details">
                  <div className="row">
                    <div className="colored-info">{t('access_to')}</div>
                  </div>

                  <div className="row">
                    <div className="col-xxs-4">
                      <span className="bolded-info">3 equipment</span>
                    </div>
                    <div className="col-xxs-8">
                      <span>THW-D37DJK, STV-45GM6, RTV-X1120DW</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxs-4">
                      <span className="bolded-info">1 fleet</span>
                    </div>
                    <div className="col-xxs-8">
                      <span>Texas Group</span>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="more-details-button">
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowGeneralMore(!showGeneralMore);
                }}
              >
                <MoreIcon />
              </span>
              {showGeneralMore && (
                <span className="notification-more dot-menu-dropdown">
                  <ClickOutside
                    onClickOutside={() => {
                      setShowGeneralMore(false);
                    }}
                  >
                    <div className="dot-menu-dropdown-options">
                      <div>
                        <span onClick={async () => {}}>
                          {t('access_sharing_add_new_user')}
                        </span>
                        <span onClick={() => {}}>{t('view_logs')}</span>
                      </div>
                    </div>
                  </ClickOutside>
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SharedWithRow;
