import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@progress/kendo-react-buttons';
import { ill_banner_email as BannerEmailIcon } from 'app/widgets/SVGs';
import { ajax } from 'ajax/ajax';
import { NotificationContext } from '../ReactContexts/notificationContext';
import { EmailSentNotification } from '../Notifications/emailSentNotification';
import { ErrorNotification } from '../Notifications';

interface IKubotaMenu {}

export function VerifyEmailMenu(props: IKubotaMenu) {
  const { t } = useTranslation();

  const { addNotification } = React.useContext(NotificationContext);

  const [hasSentNotification, setHasSentNotification] = React.useState(false);

  const resentVerificationName = async () => {
    setHasSentNotification(true);
    await ajax
      .post(`${ajax.appBaseUrl}/oauth/verify`)
      .then(() => {
        (window as any).kubota_notif_sent = true;
        addNotification(
          <EmailSentNotification>
            {t('verification_email_sent')}
          </EmailSentNotification>,
          6000,
        );
      })
      .catch(e => {
        console.log(e);
        setHasSentNotification(false);
        addNotification(
          <ErrorNotification text={t('verification_email_not_sent')} />,
          6000,
        );
      });
  };

  return (
    <div className="verify-email-wrapper">
      <BannerEmailIcon />
      <h5 className="verify-email-title">{t('verify_your_email_adress')}</h5>
      <p>{t('please_check_your_inbox')}</p>
      {!hasSentNotification && !(window as any).kubota_notif_sent && (
        <Button
          onClick={resentVerificationName}
          className="verify-email-button"
        >
          {t('resend')}
        </Button>
      )}
    </div>
  );
}
