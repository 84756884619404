import clsx from 'clsx';
import React, { ButtonHTMLAttributes } from 'react';
import './BaseButton.less';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export default function BaseButton({ children, ...props }: Props) {
  return (
    <button {...props} className={clsx('base-button', props.className)}>
      {children}
    </button>
  );
}
