import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@progress/kendo-react-buttons';

interface IPendingReceivedInvitationRow {
  isMobile: boolean;
}

function PendingReceivedInvitationRow(props: IPendingReceivedInvitationRow) {
  // TODO replace with a new hook, like usePrefixedTranslation(namespace: string, prefix: string)
  const { t } = useTranslation();
  const translationKey =
    'access_sharing.pending_received_invitations.table.actions';

  return (
    <>
      {!props.isMobile ? (
        <div>
          <div className="access-sharing-table-row pending-received-invitation">
            <div className="equipment-or-fleet-name">
              SNW-D37DJK / 12345123 SDSSANW-D37DJK / 12345123
            </div>
            <div className="pending-details">OWNER</div>
            <div className="pending-details">
              <span>Alexander</span>
              <span className="grayed-text">Jan 24, 2024</span>
            </div>
            <div className="pending-details">
              <span>January 22, 2024</span>
              <span>04:32 PM</span>
            </div>
            <div className="pending-details">
              <span>December 24, 2024</span>
              <span>11:30 PM</span>
            </div>
            <div className="pending-invitation-buttons-container">
              <Button className="pending-invitation-button">
                {t(`${translationKey}.decline`)}
              </Button>
              <Button
                className="pending-invitation-button"
                themeColor="primary"
              >
                {t(`${translationKey}.accept`)}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="pending-invitation-card">
          <div className="title-and-date">
            <div className="pending-invitation-title">VIEWER ROLE</div>
            <div className="pending-invitation-date">Jan 24, 2024</div>
          </div>
          <div className="access-to-container">
            <div className="bolded-info">{t('access_to')}</div>
            <div>TNW-D37DJK / 12345, TNW-D37DK /12345, TNW-D37DJK / 12345</div>
          </div>
          <div className="start-time-container">
            <span className="bolded-info">{t('start_time')}: </span>
            <span>March 25, 2024 09:30 PM</span>
          </div>
          <div className="invited-by-container">
            <span className="bolded-info">{t('invited_by')}: </span>
            <span>Alexander</span>
          </div>
          <div className="pending-invitation-buttons-container">
            <Button className="pending-invitation-button">
              {t(`${translationKey}.decline`)}
            </Button>
            <Button className="pending-invitation-button" themeColor="primary">
              {t(`${translationKey}.accept`)}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default PendingReceivedInvitationRow;
