import { ajax } from 'ajax/ajax';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslation } from 'react-i18next';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { PasswordDecorators } from 'app/widgets/PasswordDecorators';
import HidePassIcon from '../../../assets/images/ic_field_hidepass.svg';
import ShowPassIcon from '../../../assets/images/ic_field_showpass.svg';
import { ill_banner_email as BannerEmailIcon } from 'app/widgets/SVGs';
import { emailRegex } from 'app/common/utils';
import { usePageTracking } from 'utils/ga';

export function ForgotPasswordPage() {
  usePageTracking();

  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');

  const [hasSentVerificationCode, setHasSentVerificationCode] =
    React.useState(false);

  const [token, setToken] = React.useState('');

  const [verificationCode, setVerificationCode] = React.useState('');
  const [verificationCodeError, setVerificationCodeError] = React.useState('');

  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [repeatPassword, setRepeatPassword] = React.useState('');
  const [repeatPasswordError, setRepeatPasswordError] = React.useState('');
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] =
    React.useState(false);

  const [showResendButton, setShowResentButton] = React.useState(true);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024);

  let [error, setError] = React.useState('');

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isSubmiting, setIsSubmiting] = React.useState(false);

  const validateEmail = () => {
    if (!email) {
      setEmailError(t('field_is_required'));
      return false;
    }

    if (!email.toLowerCase().match(emailRegex)) {
      setEmailError(t('incorrect_email_format'));
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    window.addEventListener('resize', e => {
      window.innerWidth < 1024 ? setIsMobile(true) : setIsMobile(false);
    });
  }, [window.innerWidth]);

  const sendVerificationCodeClicked = () => {
    if (!validateEmail()) {
      return;
    }
    const params = new URLSearchParams();
    params.append('email', email);

    setIsSubmiting(true);

    ajax
      .postAsAnonymous(`${ajax.appBaseUrl}/oauth/forgot_password`, params)
      .then((res: any) => {
        setToken(res.data?.token);
        setHasSentVerificationCode(true);
      })
      .catch(e => {
        if (e?.response?.data?.errorCode === 'error.email_send_error') {
          setEmailError(t('could_not_send_message'));
          return;
        }
        setError(e?.response?.data?.errorMessage);
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError(t('field_is_required'));
      return false;
    }
    if (
      password.length < 8 ||
      !password.match(/^.*[a-zA-Z]+.*$/) ||
      !password.match(/^.*[0-9]+.*$/) ||
      !password.match(/[^\w\s]/g)
    ) {
      setPasswordError(t('password_insecure'));
      return false;
    }
    return true;
  };

  const validateVerificationCode = () => {
    if (!verificationCode) {
      setVerificationCodeError(t('field_is_required'));
      return false;
    }
    return true;
  };

  const validateConfirmPassword = () => {
    if (!repeatPassword) {
      setRepeatPasswordError(t('field_is_required'));
      return false;
    }
    if (password !== repeatPassword) {
      setRepeatPasswordError(t('passwords_do_not_match'));
      return false;
    }
    return true;
  };

  const validateForm = () => {
    let isValid = true;
    isValid = validatePassword() && isValid;
    isValid = validateConfirmPassword() && isValid;
    isValid = validateVerificationCode() && isValid;
    return isValid;
  };

  const setPasswordButtonClicked = () => {
    if (!validateForm()) {
      return;
    }
    const params = new URLSearchParams();
    params.append('token', token);
    params.append('code', verificationCode);
    params.append('new_password', password);
    setIsSubmiting(true);
    ajax
      .putAsAnonymous(`${ajax.appBaseUrl}/oauth/reset_password`, params)
      .then((res: any) => {
        ajax.getLogin(email, password, '/');
      })
      .catch(e => {
        console.log(e);

        e.response.data.errorCode === 'InvalidCode' &&
          setVerificationCodeError(t('verification_code_error'));
        e.response.data.errorCode === 'error.invalid_token' &&
          setVerificationCodeError(t('verification_code_expired'));

        setError(e?.response?.data?.errorMessage);
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  };

  if (hasSentVerificationCode) {
    return (
      <>
        <div className="relative">
          <span
            className="back-button back-button-on-layout fixed-on-desktop"
            onClick={() => setHasSentVerificationCode(false)}
          >
            <svg
              id="ic_nav_back"
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
            >
              <rect id="Box" width="48" height="48" fill="none" />
              <path
                id="ic_nav_up"
                d="M16.465,8.482H1.483"
                transform="translate(15.518 15.518)"
                fill="none"
                stroke="#1a1a1a"
                strokeLinecap="round"
                strokeWidth="2"
              />
              <path
                id="Path_470"
                data-name="Path 470"
                d="M23.676,16.018,16.2,23.748l7.477,7.73"
                transform="translate(0.324 0.252)"
                fill="none"
                stroke="#1a1a1a"
                strokeLinecap="round"
                strokeWidth="2"
              />
            </svg>
          </span>

          <h1>{t('reset_password')}</h1>
          <div className="verify-alert">
            <div>
              <BannerEmailIcon />
            </div>

            <p>
              <span>{t('forgot_password_subheader1')}</span>
              <span className="email-link">{` ${email}`}</span>
              <span>{`. ${t('forgot_password_subheader2')}`}</span>
            </p>
            <Button
              onClick={() => {
                setShowResentButton(false);
                sendVerificationCodeClicked();
              }}
              hidden={!showResendButton}
              className="verify-email-button"
              disabled={isSubmiting}
            >
              {isSubmiting ? (
                <div className="loading_icon loading_icon-btn">
                  <div className="css-icon"></div>
                </div>
              ) : (
                t('resend')
              )}
            </Button>
          </div>

          <div className="form-caption">
            <div>{t('create_account_header')}</div>
            <div>
              <b>{t('required_fields_warning')}</b>
            </div>
          </div>

          <div className={`form-group ${verificationCodeError && 'error'}`}>
            <div className="form-content">
              <div className="form-label">
                <span>{t('verification_code')} *</span>
              </div>
              <div className="form-control">
                <Input
                  id="verificationCode"
                  name="verificationCode"
                  tabIndex={1}
                  placeholder={t('enter_verification_code')}
                  value={verificationCode}
                  onChange={event => {
                    setVerificationCode(event.target.value as string);
                    setVerificationCodeError('');
                  }}
                  disabled={isSubmiting}
                />
                <Input id="username" name="username" value={email} hidden />
              </div>
            </div>
            <div className="form-error">
              {verificationCodeError && (
                <Error id={'verificationCodeError'}>
                  {verificationCodeError}
                </Error>
              )}
            </div>
          </div>

          <div className={`form-group ${passwordError && 'error'}`}>
            <div className="form-content">
              <div className="form-label">
                <span>{t('new_password')} *</span>
              </div>
              <div className="form-control form-control-password">
                <Input
                  id="password"
                  name="password"
                  tabIndex={2}
                  type={isPasswordVisible ? 'text' : 'password'}
                  placeholder={t('enter_password')}
                  value={password}
                  onChange={event => {
                    setPassword(event.target.value as string);
                    setPasswordError('');
                  }}
                  disabled={isSubmiting}
                />
                <span
                  className={`show-password ${
                    isPasswordVisible ? 'pass-visible' : ''
                  }`}
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                >
                  <img
                    alt="Hide Password"
                    src={HidePassIcon}
                    className="hide-pass-icon"
                  ></img>
                  <img
                    alt="Show Password"
                    src={ShowPassIcon}
                    className="show-pass-icon"
                  ></img>
                </span>
              </div>
            </div>
            <div className="form-error">
              {passwordError && (
                <Error id={'passwordError'}>{passwordError}</Error>
              )}
            </div>
          </div>

          <div className="password-decorators">
            <PasswordDecorators password={password} />
          </div>

          <div className={`form-group ${repeatPasswordError && 'error'}`}>
            <div className="form-content">
              <div className="form-label">
                <span>{t('repeat_password')} *</span>
              </div>
              <div className="form-control form-control-password">
                <Input
                  id="repeatPassword"
                  name="repeatPassword"
                  tabIndex={3}
                  type={isRepeatPasswordVisible ? 'text' : 'password'}
                  placeholder={t('repeat_password_hint')}
                  value={repeatPassword}
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      setPasswordButtonClicked();
                    }
                  }}
                  onChange={event => {
                    setRepeatPassword(event.target.value as string);
                    setRepeatPasswordError('');
                  }}
                  disabled={isSubmiting}
                />
                <span
                  className={`show-password ${
                    isRepeatPasswordVisible ? 'pass-visible' : ''
                  }`}
                  onClick={() =>
                    setIsRepeatPasswordVisible(!isRepeatPasswordVisible)
                  }
                >
                  <img
                    alt="Hide Password"
                    src={HidePassIcon}
                    className="hide-pass-icon"
                  ></img>
                  <img
                    alt="Show Password"
                    src={ShowPassIcon}
                    className="show-pass-icon"
                  ></img>
                </span>
              </div>
            </div>
            <div className="form-error">
              {repeatPasswordError && (
                <Error id={'repeatPasswordError'}>{repeatPasswordError}</Error>
              )}
            </div>
          </div>

          <Button
            tabIndex={4}
            themeColor="primary"
            onClick={setPasswordButtonClicked}
            disabled={isSubmiting}
          >
            {isSubmiting ? (
              <div className="loading_icon loading_icon-btn">
                <div className="css-icon"></div>
              </div>
            ) : (
              t('reset_password')
            )}
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="relative">
        <span
          className="back-button back-button-on-layout fixed-on-desktop"
          onClick={() =>
            isMobile
              ? navigate('/login', {
                  state: { showLandingPage: false },
                })
              : navigate('/login')
          }
        >
          <svg
            id="ic_nav_back"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <rect id="Box" width="48" height="48" fill="none" />
            <path
              id="ic_nav_up"
              d="M16.465,8.482H1.483"
              transform="translate(15.518 15.518)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <path
              id="Path_470"
              data-name="Path 470"
              d="M23.676,16.018,16.2,23.748l7.477,7.73"
              transform="translate(0.324 0.252)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </svg>
        </span>

        <h1>{t('forgot_password')}</h1>
        <div className="form-caption">
          <div>{t('create_account_header')}</div>
          <div>
            <b>{t('required_fields_warning')}</b>
          </div>
        </div>

        <div className={`form-group ${emailError && 'error'}`}>
          <div className="form-content">
            <div className="form-label">
              <span>{t('email')} *</span>
            </div>
            <div className="form-control">
              <Input
                id="email"
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    sendVerificationCodeClicked();
                  }
                }}
                name="email"
                placeholder={t('enter_email_address')}
                value={email}
                onChange={event => {
                  setEmail(event.target.value as string);
                  setEmailError('');
                }}
                disabled={isSubmiting}
              />
            </div>
          </div>
          <div className="form-error">
            {emailError && <Error id={'emailError'}>{emailError}</Error>}
          </div>
        </div>

        <Button
          themeColor="primary"
          onClick={sendVerificationCodeClicked}
          disabled={isSubmiting}
        >
          {isSubmiting ? (
            <div className="loading_icon loading_icon-btn">
              <div className="css-icon"></div>
            </div>
          ) : (
            t('send_verification_code')
          )}
        </Button>
        {error && <Error id={'error'}>{error}</Error>}
      </div>
    </>
  );
}
