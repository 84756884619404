import {
  CardBody,
  GridLayout,
  GridLayoutItem,
} from '@progress/kendo-react-layout';
import { getDefaultThumbnail } from 'app/pages/Equipment/utils';
import IntelIcon from 'assets/images/ic_equipment_intellattach.svg';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface ISmartAttachmentModelDescription {
  equipment: {
    name?: string;
    model?: string;
    pin?: string;
    imageUrl: string;
  };
}

export function SmartAttachmentModelDescription(
  props: ISmartAttachmentModelDescription,
) {
  const { t } = useTranslation();
  return (
    <>
      <CardBody>
        <div className="row">
          <div className="col-xxs-3">
            <img
              src={props.equipment.imageUrl}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = getDefaultThumbnail(
                  'attachment',
                  undefined,
                );
              }}
              alt=""
              className="equipment-list-image"
            />
          </div>
          <div className="col-xxs-9">
            <span
              className="equipment-list-nickname"
              title={props.equipment.name}
            >
              {props.equipment.name}
            </span>
            <GridLayout
              rows={[{ height: '5' }, { height: '5' }]}
              cols={[{ width: '25%' }, { width: '75%' }]}
              className="inline-label-text"
            >
              <GridLayoutItem className="label">
                {t('equipment_model')}
              </GridLayoutItem>
              <GridLayoutItem className="text">
                <img style={{ width: '20px' }} src={IntelIcon} alt="Intel" />
                {props.equipment.model}
              </GridLayoutItem>
              <GridLayoutItem className="label">
                {t('equipment_serial')}
              </GridLayoutItem>
              <GridLayoutItem className="text">
                {props.equipment.pin}
              </GridLayoutItem>
            </GridLayout>
          </div>
        </div>
      </CardBody>
    </>
  );
}
