import * as React from 'react';

export function InfoButton(props: { onClick: any }) {
  return (
    <span onClick={props.onClick} className="back-button">
      <svg
        id="ic_nav_back"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <rect id="Box" width="48" height="48" fill="none" />
        <g id="icon" transform="translate(11 12)">
          <path
            id="Vector"
            d="M11,22A11,11,0,1,0,0,11,11.032,11.032,0,0,0,11,22Z"
            transform="translate(24 24) rotate(180)"
            fill="none"
            stroke="#1a1a1a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0V5"
            transform="translate(13 17) rotate(180)"
            fill="none"
            stroke="#1a1a1a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H.009"
            transform="translate(13.007 9) rotate(180)"
            fill="none"
            stroke="#1a1a1a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </svg>
    </span>
  );
}
