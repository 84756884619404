import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EquipmentListContext } from '../ReactContexts/equipmentListContext';

interface IListAndMap {
  isMapMode: boolean;
}

export function ListAndMap(props: IListAndMap) {
  const navigate = useNavigate();

  const location = useLocation();
  const { setEquipmentListData: setData } =
    React.useContext(EquipmentListContext);

  return (
    <div
      id="listandmap"
      className={`list-and-map ${
        props.isMapMode ? 'map-mode-active' : 'list-mode-active'
      }`}
      onClick={() => {
        if (location.pathname.indexOf('geofences') >= 0) {
          navigate('/equipment');
        } else {
          setData(oldData => ({
            ...oldData,
            panTo: {
              latLongBonds: (oldData.equipmentList || [])
                .filter(
                  el =>
                    el.hasTelematics &&
                    el.telematics?.location?.latitude &&
                    el.telematics?.location?.longitude,
                )
                .map(el => ({
                  latitude: el.telematics?.location?.latitude,
                  longitude: el.telematics?.location?.longitude,
                })),
            },
          }));
          navigate('/equipment/geofences');
        }
      }}
    >
      <span className={`list-switch ${!props.isMapMode && 'active'}`}>
        <svg
          id="ic_switch_list"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <rect id="Box" width="24" height="24" fill="none" />
          <g id="Icon" transform="translate(408 -620) rotate(90)">
            <path
              id="Vector"
              d="M6,13.425V1.575C6,.45,5.553,0,4.424,0H1.576C.447,0,0,.45,0,1.575v11.85C0,14.55.447,15,1.576,15H4.424C5.553,15,6,14.55,6,13.425Z"
              transform="translate(624.325 388.5)"
              fill="none"
              stroke="#909090"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M6,13.425V1.575C6,.45,5.553,0,4.424,0H1.576C.455,0,0,.45,0,1.575v11.85C0,14.55.447,15,1.576,15H4.424C5.553,15,6,14.55,6,13.425Z"
              transform="translate(633.675 388.5)"
              fill="none"
              stroke="#909090"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M0,0H24V24H0Z"
              transform="translate(620 384)"
              fill="none"
              opacity="0"
            />
          </g>
        </svg>
      </span>
      <span className={`map-switch ${props.isMapMode && 'active'}`}>
        <svg
          id="ic_switch_map"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <rect id="Box" width="24" height="24" fill="none" />
          <g id="map" transform="translate(-684 -252)">
            <path
              id="Vector"
              d="M0,4.442v9.73c0,1.9,1.35,2.68,2.99,1.74l2.35-1.34a2.232,2.232,0,0,1,1.89-.05l5.25,2.63a2.268,2.268,0,0,0,1.89-.05l4.33-2.48a2.24,2.24,0,0,0,1.01-1.74V3.152c0-1.9-1.35-2.68-2.99-1.74l-2.35,1.34a2.232,2.232,0,0,1-1.89.05L7.23.182a2.268,2.268,0,0,0-1.89.05L1.01,2.712A2.21,2.21,0,0,0,0,4.442Z"
              transform="translate(686.29 255.338)"
              fill="none"
              stroke="#909090"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M0,0V13"
              transform="translate(692.56 256)"
              fill="none"
              stroke="#909090"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M0,0V13.38"
              transform="translate(699.73 258.62)"
              fill="none"
              stroke="#909090"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M0,0H24V24H0Z"
              transform="translate(708 276) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </svg>
      </span>
    </div>
  );
}
