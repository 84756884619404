import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import * as React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useTranslation } from 'react-i18next';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { Button } from '@progress/kendo-react-buttons';
import { ajax } from 'ajax/ajax';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { tagEvent } from 'utils/ga';

interface IEditNickname {
  closeDialog: () => void;
}

export function EditNickname(props: IEditNickname) {
  const { equipmentListData: data, setEquipmentListData: setData } =
    React.useContext(EquipmentListContext);
  const { t } = useTranslation();

  const [nickname, setNickname] = React.useState('');
  const [nicknameError, setNicknameError] = React.useState('');

  const validateNickname = () => {
    if (!nickname) {
      setNicknameError(t('field_is_required'));
      return false;
    }

    if (nickname.length > 200) {
      setNicknameError(t('nickname_length_200_exceeded'));
      return false;
    }
    return true;
  };

  const equipmentData = data.selectedEquipment as IEquipmentEntry;

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setNickname(equipmentData.nickName || '');
  }, [equipmentData.nickName]);

  React.useEffect(() => {
    const handlePopState = () => props.closeDialog();

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [equipmentData]);

  const saveChangesButtonClicked = () => {
    if (!validateNickname()) {
      return;
    }
    setIsLoading(true);
    ajax
      .put(
        `${ajax.appBaseUrl}/api/user/equipment/nickName`,
        JSON.stringify({
          id: equipmentData.id,
          nickName: nickname,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        setData(oldData => ({
          ...oldData,
          selectedEquipment: {
            ...(oldData.selectedEquipment as IEquipmentEntry),
            nickName: nickname,
          },
          equipmentList: oldData.equipmentList!.map((equip, index) => {
            if (index === oldData.selectedIndex) {
              return {
                ...equip,
                nickName: nickname,
              };
            }
            return equip;
          }),
        }));
        tagEvent('edit_nickname');
        props.closeDialog();
      })
      .catch(e => {
        setNicknameError(e?.response?.data?.errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog className="modal-form">
      <div className="modal-container relative">
        <span
          className="back-button absolute"
          onClick={() => {
            props.closeDialog();
          }}
        >
          <svg
            id="ic_nav_back"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <rect id="Box" width="48" height="48" fill="none" />
            <path
              id="ic_nav_up"
              d="M16.465,8.482H1.483"
              transform="translate(15.518 15.518)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <path
              id="Path_470"
              data-name="Path 470"
              d="M23.676,16.018,16.2,23.748l7.477,7.73"
              transform="translate(0.324 0.252)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </svg>
        </span>
        <>
          {equipmentData.nickName ? (
            <h2>{t('edit_nickname')}</h2>
          ) : (
            <h2>{t('add_nickname')}</h2>
          )}
          <div className="form-caption">
            <div className="inline-label-text">
              <span className="label">{t('equipment_model')}</span>
              <span className="text">{equipmentData.model}</span>
            </div>
            <div className="inline-label-text">
              <span className="label">
                {equipmentData.identifierType === 'Pin'
                  ? t('equipment_pin')
                  : t('equipment_serial')}
              </span>
              <span className="text">{equipmentData.pinOrSerial}</span>
            </div>
          </div>

          <div className={`form-group ${nicknameError && 'error'}`}>
            <div className="form-content">
              <div className="form-label">
                <span>{t('nickname')}</span>
              </div>
              <div className="form-control">
                <Input
                  id="nickname"
                  name="nickname"
                  maxLength={45}
                  placeholder={t('enter_the_new_nickname')}
                  value={nickname}
                  onChange={event => {
                    setNickname(event.target.value as string);
                    setNicknameError('');
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className="form-error">
              {nicknameError && (
                <Error id={'nicknameError'}>{nicknameError}</Error>
              )}
            </div>
          </div>
          <Button
            themeColor="primary"
            onClick={saveChangesButtonClicked}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="loading_icon loading_icon-btn">
                <div className="css-icon"></div>
              </div>
            ) : (
              t('save_changes')
            )}
          </Button>
        </>
      </div>
    </Dialog>
  );
}
