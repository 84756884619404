import * as React from 'react';
import { ajax } from 'ajax/ajax';
import { Card, CardBody } from '@progress/kendo-react-layout';
import { DealerListContext } from 'app/components/ReactContexts/dealerListContext';
import { IDealerEntry } from 'types/IDealerEntry';

import CallIcon from 'assets/images/ic_location_call.svg';
import WebsiteIcon from 'assets/images/ic_location_web.svg';
import LocationDirections from 'assets/images/ic_location_directions.svg';

import FavoriteOff from 'assets/images/ic_favourite_off.svg';
import FavoriteOn from 'assets/images/ic_favourite_on.svg';
import { DealerSignInDialog } from './DealerSignInDialog';
import { getHaversineDistance } from '../../../components/HerversineDistance/HaversineDistance';
import { UserLocationContext } from 'app/components/ReactContexts/userLocationContext';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';

interface IDealerCard {
  dealer: IDealerEntry;
  measurementUnit: 'US' | 'METRIC';
  setIsDialogOpen: any;
}

export function DealerCard(props: IDealerCard) {
  const { dealerListData, setDealerListData } =
    React.useContext(DealerListContext);
  const { addErrorModal } = React.useContext(GlobalErrorContext);

  const [showSignInDialog, setShowSignInDialog] = React.useState(false);

  const cardRef = React.useRef({} as HTMLSpanElement);

  const { userLocation } = React.useContext(UserLocationContext);

  React.useEffect(() => {
    if (
      props.dealer.dealerNumber === dealerListData.selectedDealer?.dealerNumber
    ) {
      cardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [dealerListData.selectedDealer?.dealerNumber, props.dealer.dealerNumber]);

  let redirectUrl = props.dealer.website;
  // thanks chatgpt
  if (!/^https?:\/\//i.test(redirectUrl)) {
    redirectUrl = `https://${redirectUrl}`;
  }

  const isFavorited = !!dealerListData.favoriteDealers?.find(
    favoriteDealer => favoriteDealer.dealerNumber === props.dealer.dealerNumber,
  );

  const [favoriteRequesting, setFavoriteRequesting] = React.useState(false);

  const addToFavorites = () => {
    setFavoriteRequesting(true);
    ajax
      .post(`${ajax.appBaseUrl}/api/user/dealer/${props.dealer.dealerNumber}`)
      .then(() => {
        setDealerListData(oldState => {
          return {
            ...oldState,
            favoriteDealers: (oldState.favoriteDealers || []).concat([
              props.dealer,
            ]),
          };
        });
        setFavoriteRequesting(false);
      })
      .catch(err => {
        addErrorModal();
        setFavoriteRequesting(false);
      });
  };

  const removeFromFavorites = () => {
    setFavoriteRequesting(true);
    ajax
      .delete(`${ajax.appBaseUrl}/api/user/dealer/${props.dealer.dealerNumber}`)
      .then(() => {
        setDealerListData(oldState => {
          return {
            ...oldState,
            favoriteDealers: (oldState.favoriteDealers || []).filter(
              dealer => dealer.dealerNumber !== props.dealer.dealerNumber,
            ),
          };
        });
        setFavoriteRequesting(false);
      })
      .catch(err => {
        addErrorModal();
        setFavoriteRequesting(false);
      });
  };

  const toggleSingInDialog = (value: boolean) => {
    setShowSignInDialog(value);
    props.setIsDialogOpen(value);
  };

  const cardCoordinatesRef = React.useRef<{
    screenX: number;
    screenY: number;
    hasMoved: boolean;
  }>();

  return (
    <span ref={cardRef} className="scroll-ref">
      {showSignInDialog && (
        <DealerSignInDialog close={() => toggleSingInDialog(false)} />
      )}
      <Card
        className={
          props.dealer.dealerNumber ===
          dealerListData.selectedDealer?.dealerNumber
            ? 'equipment-list-card equipment-list-card-selected'
            : 'equipment-list-card'
        }
        onMouseDown={e => {
          cardCoordinatesRef.current = {
            screenX: e.screenX,
            screenY: e.screenY,
            hasMoved: false,
          };
        }}
        onMouseMove={e => {
          if (!cardCoordinatesRef.current) {
            return;
          }
          if (
            Math.abs(e.screenX - cardCoordinatesRef.current.screenX) +
              Math.abs(e.screenY - cardCoordinatesRef.current.screenY) >
            10
          ) {
            cardCoordinatesRef.current = {
              hasMoved: true,
              screenX: -100,
              screenY: -100,
            };
          }
        }}
        onMouseUp={() => {
          if (!cardCoordinatesRef.current?.hasMoved) {
            setDealerListData(oldState => ({
              ...oldState,
              selectedDealer: props.dealer,
              panTo: {
                position: {
                  lat: props.dealer.address.latitude,
                  lng: props.dealer.address.longitude,
                },
                zoom: 11,
              },
              surpressZoomDataLoad: true,
            }));
          }
        }}
      >
        <CardBody>
          <div className="row-2">
            <div className="col-xxs-10">
              <h5 className="dealers-list-dealer_name">{props.dealer.name}</h5>
            </div>
            <div className="col-xxs-2 dealers-list-star_container">
              {isFavorited ? (
                <img
                  src={FavoriteOn}
                  onClick={() => {
                    if (!favoriteRequesting) {
                      removeFromFavorites();
                    }
                  }}
                />
              ) : (
                <img
                  src={FavoriteOff}
                  onClick={() => {
                    if (!ajax.isLoggedIn()) {
                      toggleSingInDialog(true);
                    } else {
                      if (!favoriteRequesting) {
                        addToFavorites();
                      }
                    }
                  }}
                />
              )}
            </div>
          </div>
          <div>{props.dealer.address.street}</div>
          <div>
            {props.dealer.address.city}, {props.dealer.address.stateCode},{' '}
            {props.dealer.address.zip}, {props.dealer.address.countryCode}
          </div>
          <div className="separator-s"></div>
          <div className={`row-3`}>
            <div>
              <div
                className="dealers-list-value_card dealers-list-distance"
                style={{
                  margin: userLocation?.location?.coords ? undefined : '0px',
                }}
                onClick={() => {
                  if (userLocation?.location?.coords) {
                    window.open(
                      `https://www.google.com/maps/dir/${userLocation?.location?.coords.latitude},${userLocation?.location?.coords.longitude}/${props.dealer.name}+${props.dealer.address.street}+${props.dealer.address.city}+${props.dealer.address.stateCode}+${props.dealer.address.countryCode}`,
                      '_blank',
                    );
                  } else {
                    window.open(
                      `https://www.google.com/maps/search/${props.dealer.name}+${props.dealer.address.street}+${props.dealer.address.city}+${props.dealer.address.stateCode}+${props.dealer.address.countryCode}`,
                      '_blank',
                    );
                  }
                }}
              >
                <img src={LocationDirections} />
                {userLocation?.location?.coords && (
                  <span>
                    {props.measurementUnit === 'US' ? (
                      <>
                        {Math.floor(
                          0.000621371 *
                            getHaversineDistance(
                              {
                                latitude:
                                  userLocation?.location?.coords.latitude,
                                longitude:
                                  userLocation?.location?.coords.longitude,
                              },
                              props.dealer.address,
                            ) *
                            100,
                        ) / 100}{' '}
                        mi
                      </>
                    ) : (
                      <>
                        {Math.floor(
                          (getHaversineDistance(
                            {
                              latitude: userLocation?.location?.coords.latitude,
                              longitude:
                                userLocation?.location?.coords.longitude,
                            },
                            props.dealer.address,
                          ) /
                            1000) *
                            100,
                        ) / 100}{' '}
                        km
                      </>
                    )}
                  </span>
                )}
              </div>
            </div>
            <div>
              <div className="dealers-list-value_card">
                <a className="dealers-tel" href={'tel:' + props.dealer.phone}>
                  <img src={CallIcon} />
                  {props.dealer.phone}
                </a>
              </div>
            </div>
            <div>
              <div className="dealers-list-value_card url-icon-box">
                <a
                  target="_blank"
                  href={redirectUrl}
                  title={redirectUrl}
                  rel="noreferrer"
                >
                  <img src={WebsiteIcon} />
                </a>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </span>
  );
}
