export const formatNumberWithDecimals = (num, decimals) => {
  let parsedNum = parseFloat(num);
  if (isNaN(parsedNum)) {
    return num;
  }
  if (Number.isInteger(parsedNum)) {
    return parsedNum.toString();
  }
  let formattedNum = parsedNum.toFixed(decimals);
  return formattedNum.replace(/\.?0+$/, '');
};

export const convertNumberToDistance = (meters, measurementUnit) => {
  const yardsToMilesCoef = 1760;
  const metersToKmCoef = 1000;
  const metersToYardsCoef = 1.0936;
  if (measurementUnit === 'US') {
    var yd = meters * metersToYardsCoef;
    if (yd >= yardsToMilesCoef) {
      return `${parseFloat((yd / yardsToMilesCoef).toFixed(2))} miles`;
    } else {
      return `${Math.round(parseFloat(yd.toFixed(2)))} yd`;
    }
  } else {
    if (meters >= metersToKmCoef) {
      return `${parseFloat((meters / metersToKmCoef).toFixed(2))} km`;
    } else {
      return `${meters} m`;
    }
  }
};

export const formatSeconds = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  let formattedString = '';
  if (hours > 0) {
    formattedString += hours + 'h ';
  }
  if (minutes > 0 || hours === 0) {
    formattedString += minutes + 'm';
  }

  return formattedString;
};
