import { createContext } from 'react';

export interface IGlobalVideoState {
  video: {
    url: string;
    name: string;
  };
  isModalOpen: boolean;
  isPiPEnabled: boolean;
}

export interface IGlobalVideoContext {
  globalVideoStateData: IGlobalVideoState;
  setGlobalVideoStateData: (
    newValue:
      | IGlobalVideoState
      | ((oldValue: IGlobalVideoState) => IGlobalVideoState),
  ) => void;
}

export const GlobalVideoContext = createContext({} as IGlobalVideoContext);
