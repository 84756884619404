import React from 'react';
import SharedEquipment from './SharedEquipment';
import SharedFleets from './SharedFleets';

interface IEquipmentAndFleets {
  isMobile: boolean;
}

function EquipmentAndFleets(props: IEquipmentAndFleets) {
  return (
    <div>
      <SharedEquipment isMobile={props.isMobile} />
      <SharedFleets isMobile={props.isMobile} />
    </div>
  );
}

export default EquipmentAndFleets;
