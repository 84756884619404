import {
  ic_banner_close as CloseIcon,
  ic_nav_close as CloseIcon2,
} from 'app/widgets/SVGs';
import QrCode from 'assets/images/qr_code.svg';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AndroidBadge from '../../../../assets/images/badge_android.svg';
import IOSBadge from '../../../../assets/images/badge_ios.svg';
import AppIcon from '../../../../assets/images/ic_app@2x.png';
import MobileAppsImgSm from '../../../../assets/images/ill_mobile_apps.png';
import MobileAppsImg from '../../../../assets/images/ill_mobile_apps@2x.png';

import i18next from 'i18next';
import { getLocale } from 'locales/utils';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { usePageTracking } from 'utils/ga';
import { getPrivacyPolicyUrl, gettermsAndConditionsUrl } from 'utils/link-urls';
import ill_feature_addequipment_fr from './ill_feature_addequipment/data-fr.json';
import ill_feature_addequipment from './ill_feature_addequipment/data.json';
import ill_feature_compatible from './ill_feature_compatible/data.json';
import ill_feature_geofence_fr from './ill_feature_geofence/data-fr.json';
import ill_feature_geofence from './ill_feature_geofence/data.json';
import ill_feature_maintenance_fr from './ill_feature_maintenance/data-fr.json';
import ill_feature_maintenance from './ill_feature_maintenance/data.json';
import ill_feature_resources from './ill_feature_resources/data.json';
import ill_feature_telematics_fr from './ill_feature_telematics/data-fr.json';
import ill_feature_telematics from './ill_feature_telematics/data.json';

interface ICardAndImage {
  children: JSX.Element;
  header: JSX.Element;
  webpImage?: string;
  jpgImage?: string;
}

export function ContentAndImage(props: ICardAndImage) {
  usePageTracking();

  const [isTablet, setIsTablet] = React.useState(window.innerWidth <= 1280);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024);
  const [showBanner, setShowBanner] = React.useState(false);
  const [showPresentation, setShowPresentation] = React.useState(false);
  const [operatingSystem, setOperatingSystem] = React.useState('Other');
  const [language, setLanguage] = React.useState(i18next.resolvedLanguage);
  const [isFooterSticky, setIsFooterSticky] = React.useState(true);
  const elementRef = React.useRef<Element | null>(null);
  const playingAnimations = React.useRef<string[]>([]);
  const footerRef = React.useRef<HTMLDivElement>(null);
  const locale = getLocale();

  const ill_feature_addequipment_ref =
    React.useRef<LottieRefCurrentProps>(null);
  const ill_feature_compatible_ref = React.useRef<LottieRefCurrentProps>(null);
  const ill_feature_geofence_ref = React.useRef<LottieRefCurrentProps>(null);
  const ill_feature_maintenance_ref = React.useRef<LottieRefCurrentProps>(null);
  const ill_feature_resources_ref = React.useRef<LottieRefCurrentProps>(null);
  const ill_feature_telematics_ref = React.useRef<LottieRefCurrentProps>(null);
  const ill_feature_addequipment_ref_fr =
    React.useRef<LottieRefCurrentProps>(null);
  const ill_feature_geofence_ref_fr = React.useRef<LottieRefCurrentProps>(null);
  const ill_feature_maintenance_ref_fr =
    React.useRef<LottieRefCurrentProps>(null);
  const ill_feature_telematics_ref_fr =
    React.useRef<LottieRefCurrentProps>(null);
  const refs = [
    ill_feature_addequipment_ref,
    ill_feature_compatible_ref,
    ill_feature_geofence_ref,
    ill_feature_maintenance_ref,
    ill_feature_resources_ref,
    ill_feature_telematics_ref,
    ill_feature_addequipment_ref_fr,
    ill_feature_geofence_ref_fr,
    ill_feature_maintenance_ref_fr,
    ill_feature_telematics_ref_fr,
  ];

  const { t } = useTranslation();

  const scrollHandler = () => {
    if (footerRef.current) {
      let bounding = footerRef.current?.getBoundingClientRect();
      let footerBottomPos = isTablet ? 12 : 32;
      if (window.innerHeight - bounding.bottom > footerBottomPos) {
        setIsFooterSticky(false);
      }
    }

    refs.forEach(ref => {
      if (ref?.current == null) {
        return;
      }

      // element position in current viewport (0, window.height)
      if (ref.current.animationContainerRef.current == undefined) {
        return;
      }
      const { top, bottom } =
        ref.current.animationContainerRef.current?.getBoundingClientRect() as any;

      if (
        top >= 0 &&
        bottom < window.innerHeight &&
        ref.current.animationItem?.animationID &&
        !playingAnimations.current.includes(
          ref.current.animationItem.animationID,
        )
      ) {
        ref.current.setDirection(1);
        ref.current.play();
        playingAnimations.current = playingAnimations.current.concat([
          ref.current?.animationItem?.animationID as string,
        ]);
        setTimeout(() => {
          ref.current?.setDirection(-1);
          ref.current?.play();
          playingAnimations.current = playingAnimations.current.filter(
            id => id !== (ref.current?.animationItem?.animationID as string),
          );
        }, 3000);
      }
    });
  };

  const handleTouchStart = ref => {
    if (
      !playingAnimations.current.includes(
        ref?.current?.animationItem?.animationID as string,
      )
    ) {
      ref?.current?.setDirection(1);
      ref?.current?.play();
      playingAnimations.current = playingAnimations.current.concat([
        ref?.current?.animationItem?.animationID as string,
      ]);
      setTimeout(() => {
        ref?.current?.setDirection(-1);
        ref?.current?.play();
        playingAnimations.current = playingAnimations.current.filter(
          id => id !== (ref?.current?.animationItem?.animationID as string),
        );
      }, 3000);
    }
  };

  const handleMouseEnter = ref => {
    ref?.current?.setDirection(1);
    ref?.current?.play();
  };

  const handleMouseLeave = ref => {
    ref?.current?.setDirection(-1);
    ref?.current?.play();
  };

  // prettier-ignore
  const langFeatureAddEquipmentRef = {
    'en': ill_feature_addequipment_ref,
    'fr': ill_feature_addequipment_ref_fr,
  };

  // prettier-ignore
  const langFeatureGeofenceRef = {
    'en': ill_feature_geofence_ref,
    'fr': ill_feature_geofence_ref_fr,
  };

  // prettier-ignore
  const langFeatureMaintenanceRef = {
    'en': ill_feature_maintenance_ref,
    'fr': ill_feature_maintenance_ref_fr,
  };

  // prettier-ignore
  const langFeatureTelematicsRef = {
    'en': ill_feature_telematics_ref,
    'fr': ill_feature_telematics_ref_fr,
  };

  // prettier-ignore
  const langAnimationDataAddEquipment = {
      'en': ill_feature_addequipment,
      'fr': ill_feature_addequipment_fr,
    };

  // prettier-ignore
  const langAnimationDataGeofence = {
      'en': ill_feature_geofence,
      'fr': ill_feature_geofence_fr,
    };

  // prettier-ignore
  const langAnimationDataMaintenance = {
      'en': ill_feature_maintenance,
      'fr': ill_feature_maintenance_fr,
    };

  // prettier-ignore
  const langAnimationDataTelematics = {
      'en': ill_feature_telematics,
      'fr': ill_feature_telematics_fr,
    };

  const detectMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      setOperatingSystem('iOS');
    } else if (/Android/.test(userAgent)) {
      setOperatingSystem('Android');
    } else {
      setOperatingSystem('Other');
    }
  };

  const commonProps = {
    autoplay: false,
    loop: false,
    onLoadedImages: () => scrollHandler(),
  };

  const handleTermsClick = () => {
    var href = gettermsAndConditionsUrl(locale);
    window.open(href, '_blank');
  };

  const handlePolicyClick = () => {
    var href = getPrivacyPolicyUrl(locale);
    window.open(href, '_blank');
  };

  React.useEffect(
    () => setLanguage(i18next.resolvedLanguage),
    [i18next.resolvedLanguage],
  );

  React.useEffect(() => {
    window.addEventListener('resize', e => {
      window.innerWidth <= 1280 ? setIsTablet(true) : setIsTablet(false);
      window.innerWidth < 1024 ? setIsMobile(true) : setIsMobile(false);
    });
  }, [window.innerWidth]);

  React.useEffect(() => {
    detectMobileOperatingSystem();

    const updateStateAndCheckElement = () => {
      const element = document.getElementById('mobileLandingPage');
      if (element !== elementRef.current) {
        elementRef.current = element;
        if ((isMobile && elementRef.current !== null) || !isMobile) {
          if (isMobile) {
            setShowBanner(true);
          }
          setShowPresentation(true);
        } else {
          setShowBanner(false);
          setShowPresentation(false);
        }
      }
    };

    updateStateAndCheckElement();

    const interval = setInterval(updateStateAndCheckElement, 0);

    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    if ((isMobile && elementRef.current !== null) || !isMobile) {
      setShowPresentation(true);
    } else {
      setShowPresentation(false);
    }
  }, [isMobile, isTablet]);

  React.useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    document.addEventListener('resize', scrollHandler);

    return () => {
      document.removeEventListener('scroll', scrollHandler);
      document.removeEventListener('resize', scrollHandler);
    };
  }, []);

  return (
    <>
      <div
        onScroll={scrollHandler}
        onResize={scrollHandler}
        className={`layout layout-content-and-image ${
          isMobile && showBanner && operatingSystem != 'Other'
            ? 'download-link-visible'
            : ''
        }`}
      >
        <div className="header-container">{props.header}</div>
        <div className="row row-0 ">
          {isMobile && showBanner && operatingSystem != 'Other' && (
            <div className="header-container banner">
              <div className="banner_item">
                <span
                  className="back-button back-button-on-layout fixed-on-desktop"
                  onClick={() => setShowBanner(false)}
                >
                  <CloseIcon />
                </span>
              </div>
              <img
                className="app-icon banner_item"
                src={AppIcon}
                alt="app icon"
              />
              <div className="banner-info banner_item">
                <span className="banner-header">{t('app_name')}</span>
                <span className="banner-text">{t('download_app')}</span>
              </div>
              <div className="btn-primary">
                <span
                  onClick={() => {
                    if (operatingSystem == 'Android') {
                      window.open(
                        'https://play.google.com/store/apps/details?id=com.kubotausa.mykubota',
                        '_blank',
                        'noopener,noreferrer',
                      );
                    } else if (operatingSystem == 'iOS') {
                      window.open(
                        'https://apps.apple.com/us/app/mykubota/id1434354490',
                        '_blank',
                        'noopener,noreferrer',
                      );
                    }
                  }}
                >
                  {t('download')}
                </span>
              </div>
            </div>
          )}
          <div className="layout_container col-md-5 col-sm-6">
            <div>
              <div className="container">{props.children}</div>
            </div>
          </div>

          <div className="layout_main-image col-md-7 col-sm-6">
            {showPresentation && (
              <div className="presentation-wrapper">
                <section
                  onMouseEnter={() => {
                    if (!isMobile && !isTablet) {
                      handleMouseEnter(
                        langFeatureTelematicsRef[language] ||
                          langFeatureTelematicsRef['en'],
                      );
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isMobile && !isTablet) {
                      handleMouseLeave(
                        langFeatureTelematicsRef[language] ||
                          langFeatureTelematicsRef['en'],
                      );
                    }
                  }}
                  onTouchStart={() => {
                    handleTouchStart(
                      langFeatureTelematicsRef[language] ||
                        langFeatureTelematicsRef['en'],
                    );
                  }}
                  className="row"
                  id="resources"
                >
                  <div className="presentation-container">
                    <div className="container-img">
                      <Lottie
                        {...commonProps}
                        lottieRef={
                          langFeatureTelematicsRef[language] ||
                          langFeatureTelematicsRef['en']
                        }
                        animationData={
                          langAnimationDataTelematics[language] ||
                          langAnimationDataTelematics['en']
                        }
                      />
                    </div>
                    <div className="container-text">
                      <h2 className="text-header">
                        {t('login_presentation_telematics')}
                      </h2>
                      <div className="info-text">
                        <Trans i18nKey="login_presentation_telematics_info" />
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  onMouseEnter={() => {
                    if (!isMobile && !isTablet) {
                      handleMouseEnter(
                        langFeatureGeofenceRef[language] ||
                          langFeatureGeofenceRef['en'],
                      );
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isMobile && !isTablet) {
                      handleMouseLeave(
                        langFeatureGeofenceRef[language] ||
                          langFeatureGeofenceRef['en'],
                      );
                    }
                  }}
                  onTouchStart={() => {
                    handleTouchStart(
                      langFeatureGeofenceRef[language] ||
                        langFeatureGeofenceRef['en'],
                    );
                  }}
                  className="row"
                  id="addequipment"
                >
                  <div className="presentation-container reverse">
                    <div className="container-img">
                      <Lottie
                        {...commonProps}
                        lottieRef={
                          langFeatureGeofenceRef[language] ||
                          langFeatureGeofenceRef['en']
                        }
                        animationData={
                          langAnimationDataGeofence[language] ||
                          langAnimationDataGeofence['en']
                        }
                      />
                    </div>
                    <div className="container-text">
                      <h2 className="text-header">
                        {t('login_presentation_geofences')}
                      </h2>
                      <div className="info-text">
                        <Trans i18nKey="login_presentation_geofences_info" />
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  onMouseEnter={() => {
                    if (!isMobile && !isTablet) {
                      handleMouseEnter(
                        langFeatureAddEquipmentRef[language] ||
                          langFeatureAddEquipmentRef['en'],
                      );
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isMobile && !isTablet) {
                      handleMouseLeave(
                        langFeatureAddEquipmentRef[language] ||
                          langFeatureAddEquipmentRef['en'],
                      );
                    }
                  }}
                  onTouchStart={() => {
                    handleTouchStart(
                      langFeatureAddEquipmentRef[language] ||
                        langFeatureAddEquipmentRef['en'],
                    );
                  }}
                  className="row"
                  id="telematics"
                >
                  <div className="presentation-container">
                    <div className="container-img">
                      <Lottie
                        {...commonProps}
                        lottieRef={
                          langFeatureAddEquipmentRef[language] ||
                          langFeatureAddEquipmentRef['en']
                        }
                        animationData={
                          langAnimationDataAddEquipment[language] ||
                          langAnimationDataAddEquipment['en']
                        }
                      />
                    </div>
                    <div className="container-text">
                      <h2 className="text-header">
                        {t('login_presentation_add_equipment')}
                      </h2>
                      <div className="info-text">
                        <Trans i18nKey="login_presentation_add_equipment_info" />
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  onMouseEnter={() => {
                    if (!isMobile && !isTablet) {
                      handleMouseEnter(
                        langFeatureMaintenanceRef[language] ||
                          langFeatureMaintenanceRef['en'],
                      );
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isMobile && !isTablet) {
                      handleMouseLeave(
                        langFeatureMaintenanceRef[language] ||
                          langFeatureMaintenanceRef['en'],
                      );
                    }
                  }}
                  onTouchStart={() => {
                    handleTouchStart(
                      langFeatureMaintenanceRef[language] ||
                        langFeatureMaintenanceRef['en'],
                    );
                  }}
                  className="row"
                  id="maintenance"
                >
                  <div className="presentation-container reverse">
                    <div className="container-img">
                      <Lottie
                        {...commonProps}
                        lottieRef={
                          langFeatureMaintenanceRef[language] ||
                          langFeatureMaintenanceRef['en']
                        }
                        animationData={
                          langAnimationDataMaintenance[language] ||
                          langAnimationDataMaintenance['en']
                        }
                      />
                    </div>
                    <div className="container-text">
                      <h2 className="text-header">
                        {t('login_presentation_maintenance')}
                      </h2>
                      <div className="info-text">
                        <Trans i18nKey="login_presentation_maintenance_info" />
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  onMouseEnter={() => {
                    if (!isMobile && !isTablet) {
                      handleMouseEnter(ill_feature_compatible_ref);
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isMobile && !isTablet) {
                      handleMouseLeave(ill_feature_compatible_ref);
                    }
                  }}
                  onTouchStart={() => {
                    handleTouchStart(ill_feature_compatible_ref);
                  }}
                  className="row"
                  id="geofence"
                >
                  <div className="presentation-container">
                    <div className="container-img">
                      <Lottie
                        name="geofence"
                        {...commonProps}
                        lottieRef={ill_feature_compatible_ref}
                        animationData={ill_feature_compatible}
                      />
                    </div>
                    <div className="container-text">
                      <h2 className="text-header">
                        {t('login_presentation_compatible')}
                      </h2>
                      <div className="info-text">
                        <Trans i18nKey="login_presentation_compatible_info" />
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  onMouseEnter={() => {
                    if (!isMobile && !isTablet) {
                      handleMouseEnter(ill_feature_resources_ref);
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isMobile && !isTablet) {
                      handleMouseLeave(ill_feature_resources_ref);
                    }
                  }}
                  onTouchStart={() => {
                    handleTouchStart(ill_feature_resources_ref);
                  }}
                  className="row"
                  id="compatible"
                >
                  <div className="presentation-container reverse">
                    <div className="container-img">
                      <Lottie
                        {...commonProps}
                        lottieRef={ill_feature_resources_ref}
                        animationData={ill_feature_resources}
                      />
                    </div>
                    <div className="container-text">
                      <h2 className="text-header">
                        {t('login_presentation_resources')}
                      </h2>
                      <div className="info-text">
                        <Trans i18nKey="login_presentation_resources_info" />
                      </div>
                    </div>
                  </div>
                </section>
                <div
                  ref={footerRef}
                  className={`container-footer ${
                    isFooterSticky ? '' : 'sticky-to-relative'
                  }`}
                >
                  {isMobile ? (
                    <div className="mobile-footer">
                      <div className="mobile-footer-headers">
                        <h3 className="mobile-footer-header">
                          {t('mobile_apps_presentation')}
                        </h3>
                        <img
                          className="mobile-footer-image"
                          src={MobileAppsImgSm}
                          alt="mobile apps"
                        />
                      </div>
                      <div className="mobile-footer-info">
                        <p>{t('mobile_apps_presentation_info')}</p>
                        <span className="footer-store-icons">
                          <img
                            className="store-badge"
                            src={IOSBadge}
                            alt="app store badge"
                            onClick={() => {
                              window.open(
                                'https://apps.apple.com/us/app/mykubota/id1434354490',
                                '_blank',
                                'noopener,noreferrer',
                              );
                            }}
                          />
                          <img
                            className="store-badge"
                            src={AndroidBadge}
                            alt="google play badge"
                            onClick={() => {
                              window.open(
                                'https://play.google.com/store/apps/details?id=com.kubotausa.mykubota',
                                '_blank',
                                'noopener,noreferrer',
                              );
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {!isTablet && (
                        <>
                          <div className="footer-qr-container">
                            <img src={QrCode}></img>
                          </div>
                          <div className="footer-vertical-br"></div>
                        </>
                      )}

                      <div className="footer-info">
                        <h3 className="footer-header">
                          {t('mobile_apps_presentation')}
                        </h3>
                        <p>{t('mobile_apps_presentation_info')}</p>
                        <span className="footer-store-icons">
                          <img
                            className="store-badge"
                            src={IOSBadge}
                            alt="app store badge"
                            onClick={() => {
                              window.open(
                                'https://apps.apple.com/us/app/mykubota/id1434354490',
                                '_blank',
                                'noopener,noreferrer',
                              );
                            }}
                          />
                          <img
                            className="store-badge"
                            src={AndroidBadge}
                            alt="google play badge"
                            onClick={() => {
                              window.open(
                                'https://play.google.com/store/apps/details?id=com.kubotausa.mykubota',
                                '_blank',
                                'noopener,noreferrer',
                              );
                            }}
                          />
                        </span>
                      </div>
                      <img
                        className="footer-image"
                        src={MobileAppsImg}
                        alt="mobile apps"
                      />
                      {isFooterSticky && (
                        <span
                          className="footer-back-button"
                          onClick={() => setIsFooterSticky(false)}
                        >
                          <CloseIcon2 />
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          {showPresentation &&
            (isMobile ? (
              <div className="footer-container_mobile">
                <span className="trademark-mobile">
                  {t('kubota_trade_mark', { year: new Date().getFullYear() })}
                </span>
                <span className="legal-mobile">
                  <span
                    className="legal-link"
                    onClick={() => handleTermsClick()}
                  >
                    {t('terms_and_conditions')}
                  </span>
                  <span
                    className="legal-link"
                    onClick={() => handlePolicyClick()}
                  >
                    {t('privacy_policy')}
                  </span>
                </span>
              </div>
            ) : (
              <div className="footer-container">
                <span className="trademark">
                  {t('kubota_trade_mark', { year: new Date().getFullYear() })}
                </span>
                <span className="legal">
                  <span
                    className="legal-link"
                    onClick={() => handleTermsClick()}
                  >
                    {t('terms_and_conditions')}
                  </span>
                  <span
                    className="legal-link"
                    onClick={() => handlePolicyClick()}
                  >
                    {t('privacy_policy')}
                  </span>
                </span>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
