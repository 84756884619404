import { Card } from 'app/components/Card';
import React from 'react';
import './MachineOwnerCard.less';
import { Avatar } from 'app/components/Avatar';
import { Divider } from 'app/components/Divider';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface Props {
  name: string;
  email: string;
  className?: string;
}

export default function MachineOwnerCard({ name, email, className }: Props) {
  const { t } = useTranslation();

  return (
    <Card className={clsx('machine-owner-card__card', className)}>
      <div className="machine-owner-card__content">
        <Avatar name={name} />

        <div className="machine-owner-card__info">
          <span className="machine-owner-card__role-and-name">
            {t('owner').toUpperCase()}

            <Divider orientation="vertical" />

            {/* A postfix (Me) is added when API is ready */}
            <b className="machine-owner-card__name">{name}</b>
          </span>

          <span className="machine-owner-card__email">{email}</span>
        </div>
      </div>
    </Card>
  );
}
