import React from 'react';
import { ajax } from 'ajax/ajax';
import { Navigate } from 'react-router-dom';
import { usePageTracking } from 'utils/ga';

export const PrivateRoute = props => {
  usePageTracking();

  const isLoggedIn = ajax.isLoggedIn();

  return isLoggedIn ? props.children : <Navigate to="/login" />;
};
