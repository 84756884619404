import React from 'react';
import './CardContentLayout.less';
import clsx from 'clsx';
import { Card } from 'app/components/Card';

interface Props {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  className?: string;
}

export default function CardContentLayout({
  children,
  title,
  subtitle,
  className,
}: Props) {
  return (
    <Card className={clsx('card-content-layout', className)} withBorder={false}>
      {title && <h1 className="card-content-layout__title">{title}</h1>}

      {subtitle && <p className="card-content-layout__subtitle">{subtitle}</p>}

      {children}
    </Card>
  );
}
