import { NotificationGroup } from '@progress/kendo-react-notification';
import { NotificationContext } from 'app/components/ReactContexts/notificationContext';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SuccessIcon from '../../../assets/images/ic_banner_add.svg';

export const AddMaintenanceSuccessPopUp = () => {
  const anchor = React.useRef(null);

  const { t } = useTranslation();
  const { notifications, addNotification } =
    React.useContext(NotificationContext);

  React.useEffect(() => {
    addNotification(
      <div className="alert-container">
        <span className="alert-icon">
          <img alt="Success" src={SuccessIcon}></img>
        </span>
        <span className="alert-texts">
          <span>{t('maintenance_add_success_message')}</span>
        </span>
      </div>,
      6000,
    );
  }, []);

  return (
    <>
      <NotificationGroup className="alert position" ref={anchor}>
        {notifications?.map(notification => notification.element)}
      </NotificationGroup>
    </>
  );
};
