import * as React from 'react';
import { DrawingManagerF, PolygonF } from '@react-google-maps/api';
import { IPointEntry } from 'types/IGeofenceEntry';
import _ from 'lodash';
import { Card, CardBody } from '@progress/kendo-react-layout';
import { Input } from '@progress/kendo-react-inputs';
import { useTranslation } from 'react-i18next';
import { Button } from '@progress/kendo-react-buttons';
import { ajax } from 'ajax/ajax';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { NotificationContext } from 'app/components/ReactContexts/notificationContext';
import GuidePicture from 'assets/images/ill_guide_addgeofence.webp';
import { ic_close_small_black as BlackCloseIcon } from 'app/widgets/SVGs';
import { Error } from '@progress/kendo-react-labels';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';

import { Success } from 'app/components/Notifications';
import { tagEvent } from 'utils/ga';

interface IDrawGeofence {
  infoBoxShown: boolean;
  setInfoBoxShown: React.Dispatch<React.SetStateAction<boolean>>;
  drawnPolygon: IPointEntry[];
  setDrawnPolygon: React.Dispatch<React.SetStateAction<IPointEntry[]>>;
}

export function DrawGeofence(props: IDrawGeofence) {
  const { addNotification } = React.useContext(NotificationContext);
  const { addErrorModal } = React.useContext(GlobalErrorContext);
  const [description, setDescription] = React.useState('');
  const [descriptionError, setDescriptionError] = React.useState('');
  const [isLoading, setisLoading] = React.useState(false);

  const validateDescription = () => {
    if (!description) {
      setDescriptionError(t('field_is_required'));
      return false;
    }

    if (description?.length > 45) {
      setDescriptionError(t('maximum_length_45_exceeded'));
      return false;
    }
    return true;
  };

  const { equipmentListData, setEquipmentListData } =
    React.useContext(EquipmentListContext);

  const { t } = useTranslation();

  const saveNewGeofence = () => {
    if (!validateDescription()) {
      return;
    }

    setisLoading(true);
    ajax
      .post(
        `${ajax.appBaseUrl}/api/user/geofence`,
        {
          description: description,
          points: props.drawnPolygon.concat([props.drawnPolygon[0]]),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            version: '2022_R03',
          },
        },
      )
      .then((response: any) => {
        setEquipmentListData(oldValue => ({
          ...oldValue,
          geofenceList: _.orderBy(
            response.data,
            e => e.description.toLowerCase(),
            ['asc'],
          ),
          selectedGeofence:
            response.data.length > 0
              ? response.data[response.data.length - 1]
              : undefined,
        }));
        addNotification(
          <>
            <Success text={t('geofence_successfully_added')} />
          </>,
          6000,
        );
        setEquipmentListData(oldvalue => ({
          ...oldvalue,
          isDrawing: false,
        }));
        props.setDrawnPolygon([]);
        tagEvent('create_geofence');
      })
      .catch(err => addErrorModal())
      .finally(() => setisLoading(false));
  };

  return (
    <>
      <DrawingManagerF
        key="drawingManager"
        onPolygonComplete={poly => {
          const polygon = poly
            .getPath()
            .getArray()
            .map(point => ({
              latitude: point.lat(),
              longitude: point.lng(),
            }));

          if (polygon.length >= 3) {
            props.setDrawnPolygon(polygon);
          } else {
            props.setInfoBoxShown(true);
          }
          poly.setMap(null);
          setDescription('');
          setDescriptionError('');
        }}
        options={{
          drawingControl: false,
          drawingMode:
            equipmentListData.isDrawing && _.isEmpty(props.drawnPolygon)
              ? ('polygon' as any)
              : null,
          polygonOptions: {
            strokeColor: '#DC4405',
            fillColor: '#DC44054D',
            fillOpacity: 1,
          },
        }}
      />
      {equipmentListData.isDrawing && !_.isEmpty(props.drawnPolygon) && (
        <>
          <PolygonF
            paths={props.drawnPolygon.map(g => ({
              lat: g.latitude,
              lng: g.longitude,
            }))}
            options={{
              strokeColor: '#DC4405',
              fillColor: '#DC44054D',
              fillOpacity: 1,
            }}
          />
          <Card className="geofences-drawing-description-root">
            <CardBody className="row">
              <div className="col-sm-7 col-xs-7 col-xxs-6">
                <Input
                  id="description"
                  name="description"
                  maxLength={45}
                  placeholder={t('enter_geofence_name')}
                  value={description}
                  disabled={isLoading}
                  className={descriptionError && 'error-input'}
                  onChange={event => {
                    setDescription(event.target.value as string);
                    descriptionError && setDescriptionError('');
                  }}
                />
                <div className="form-error">
                  {descriptionError && (
                    <Error id={'passwordError'}>{descriptionError}</Error>
                  )}
                </div>
              </div>

              <div className="col-sm-5 col-xs-5 col-xxs-6">
                <Button
                  themeColor={'primary'}
                  disabled={isLoading}
                  onClick={saveNewGeofence}
                >
                  {t('save_geofence')}
                </Button>
              </div>
            </CardBody>
          </Card>
        </>
      )}
      {props.infoBoxShown && equipmentListData.isDrawing && (
        <>
          <Card className="geofences-drawing-info-box-root">
            <CardBody>
              <div className="geofence-info-titlebox">
                <h4>{t('how_to_create')}</h4>
                <div className="geofence-info-button-wrapper">
                  <Button
                    className="geofences-drawing-info-box-button"
                    onClick={() => {
                      props.setInfoBoxShown(false);
                    }}
                  >
                    <BlackCloseIcon />
                  </Button>
                </div>
              </div>
              <img src={GuidePicture} />
              <p className="geofences-drawing-info-box-text">
                {t('geofence_infobox_information')}
              </p>
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
}
