import { Error, Hint, Label } from '@progress/kendo-react-labels';
import clsx from 'clsx';
import React from 'react';
import './RadioGroup.less';
import { RadioOption } from './types';
import { Radio, RadioValueType } from 'app/components/form-fields';

interface Props<T extends RadioValueType> {
  name: string;
  label?: string;
  error?: string;
  helperText?: string;
  className?: string;
  options: RadioOption<T>[];
  value: T;
  onChange: (value: T) => void;
}

export default function RadioGroup<T extends RadioValueType>({
  name,
  label,
  error,
  helperText,
  className,
  options,
  value,
  onChange,
}: Props<T>) {
  return (
    <div className={clsx('radio-group', className)}>
      {label && <Label className="radio-group__label">{label}</Label>}

      <div className="radio-group__options">
        {options.map(option => {
          const isChecked = value === option.value;

          return (
            <label key={option.label} className="radio-group__option">
              <Radio
                name={name}
                value={option.value}
                checked={isChecked}
                onChange={() => onChange(option.value)}
              />

              <div>
                <span
                  className={clsx('radio-group__text', {
                    'radio-group__text_checked': isChecked,
                  })}
                >
                  {option.label}
                </span>
              </div>
            </label>
          );
        })}
      </div>

      {helperText && (
        <Hint className="radio-group__helper-text">{helperText}</Hint>
      )}

      {error && <Error className="radio-group__error">{error}</Error>}
    </div>
  );
}
