import { Button } from '@progress/kendo-react-buttons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@progress/kendo-react-dialogs';

interface IGlobalErrorModalProps {
  onClose: () => void;
}

export function GlobalErrorModal(props: IGlobalErrorModalProps) {
  const { t } = useTranslation();

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        props.onClose();
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    <Dialog className="modal-center modal-error modal-remove-item modal-fulltext">
      <div className="remove-item-dialog-content">
        <h3>{t('unexpected_error_title')}</h3>
        <p>{t('unexpected_error_message')}</p>
        <div className="row buttons-wrapper">
          <div className="col-xxs-12">
            <Button onClick={props.onClose} themeColor="primary">
              {t('ok')}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
