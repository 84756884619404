import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { ajax } from 'ajax/ajax';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import _ from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IGeofenceEntry } from 'types/IGeofenceEntry';
import { tagEvent } from 'utils/ga';

interface IEditGeofenceName {
  closeDialog: () => void;
  index: number;
  geofence: IGeofenceEntry;
}

export function EditGeofenceName(props: IEditGeofenceName) {
  const { setEquipmentListData: setData } =
    React.useContext(EquipmentListContext);
  const { t } = useTranslation();

  const [geofenceName, setGeofenceName] = React.useState(
    props.geofence.description,
  );
  const [geofenceNameError, setGeofenceNameError] = React.useState('');

  const validateGeofenceName = () => {
    if (!geofenceName) {
      setGeofenceNameError(t('field_is_required'));
      return false;
    }

    if (geofenceName?.length > 45) {
      setGeofenceNameError(t('maximum_length_45_exceeded'));
      return false;
    }
    return true;
  };

  const [isLoading, setIsLoading] = React.useState(false);

  const saveChangesButtonClicked = () => {
    if (!validateGeofenceName()) {
      return;
    }
    setIsLoading(true);
    ajax
      .put(
        `${ajax.appBaseUrl}/api/user/geofence`,
        JSON.stringify({
          id: props.geofence.id,
          description: geofenceName,
          points: props.geofence.points,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        setData(oldData => ({
          ...oldData,
          geofenceList: _.orderBy(
            oldData.geofenceList?.map((geof, ind) => {
              if (ind !== props.index) {
                return geof;
              }
              return {
                id: props.geofence.id,
                description: geofenceName,
                points: props.geofence.points,
              };
            }),
            e => e.description.toLowerCase(),
            ['asc'],
          ),
        }));
        tagEvent('edit_geofence_name');
        props.closeDialog();
      })
      .catch(e => {
        setGeofenceNameError(e?.response?.data?.errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog className="modal-form">
      <div className="modal-container relative">
        <span
          className="back-button absolute"
          onClick={() => {
            props.closeDialog();
          }}
        >
          <svg
            id="ic_nav_back"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <rect id="Box" width="48" height="48" fill="none" />
            <path
              id="ic_nav_up"
              d="M16.465,8.482H1.483"
              transform="translate(15.518 15.518)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <path
              id="Path_470"
              data-name="Path 470"
              d="M23.676,16.018,16.2,23.748l7.477,7.73"
              transform="translate(0.324 0.252)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </svg>
        </span>
        <>
          <h2>{t('edit_geofence_name')}</h2>
          <div className="form-caption">{t('you_can_rename')}</div>

          <div className={`form-group ${geofenceNameError && 'error'}`}>
            <div className="form-content">
              <div className="form-label">
                <span>{t('geofence_name')}</span>
              </div>
              <div className="form-control">
                <Input
                  id="geofenceName"
                  name="geofenceName"
                  maxLength={45}
                  placeholder={t('enter_geofence_name')}
                  value={geofenceName}
                  onChange={event => {
                    setGeofenceName(event.target.value as string);
                    setGeofenceNameError('');
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className="form-error">
              {geofenceNameError && (
                <Error id={'nicknameError'}>{geofenceNameError}</Error>
              )}
            </div>
          </div>
          <Button
            themeColor="primary"
            onClick={saveChangesButtonClicked}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="loading_icon loading_icon-btn">
                <div className="css-icon"></div>
              </div>
            ) : (
              t('save_changes')
            )}
          </Button>
        </>
      </div>
    </Dialog>
  );
}
