import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import PreviewPicture from 'assets/images/ill_modal_guest_dealers.png';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

interface IDealerSignInDialog {
  close: () => void;
}

export function DealerSignInDialog(props: IDealerSignInDialog) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Dialog className="modal-form">
      <div className="row">
        <div className="col-sm-12">
          <div className="modal-container relative">
            <span
              className="back-button absolute"
              onClick={() => {
                props.close();
              }}
            >
              <svg
                id="ic_nav_back"
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <rect id="Box" width="48" height="48" fill="none" />
                <path
                  id="ic_nav_up"
                  d="M16.465,8.482H1.483"
                  transform="translate(15.518 15.518)"
                  fill="none"
                  stroke="#1a1a1a"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_470"
                  data-name="Path 470"
                  d="M23.676,16.018,16.2,23.748l7.477,7.73"
                  transform="translate(0.324 0.252)"
                  fill="none"
                  stroke="#1a1a1a"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </svg>
            </span>
            <div className="col-sm-11">
              <h2>{t('sign_in_to_add_dealer')}</h2>
              <img src={PreviewPicture} />
              <div
                className="form-caption"
                dangerouslySetInnerHTML={{
                  __html: t('AddEquipment.SignInToAcc'),
                }}
              ></div>
              <div className="row-2">
                <div className="col-xxs-6">
                  <Button
                    themeColor="primary"
                    onClick={() => {
                      navigate('/login');
                    }}
                  >
                    {t('sign_in')}
                  </Button>
                </div>
                <div className="col-xxs-6">
                  <Button
                    onClick={() => {
                      navigate('/createAccount');
                    }}
                  >
                    {t('create_account')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
