import React from 'react';

export default function DeleteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="ic_delete"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.width ?? '24'}
      height={props.height ?? '24'}
      {...props}
    >
      <rect id="Box" width="24" height="24" fill="none" />
      <g id="Icon" transform="translate(-2078.936 -713.936)">
        <line
          id="Line_1"
          data-name="Line 1"
          x2="8"
          y2="8"
          transform="translate(2086.935 721.935)"
          fill="none"
          stroke={props.stroke ?? '#1a1a1a'}
          strokeLinecap="round"
          strokeWidth={props.strokeWidth ?? '1.2'}
        />
        <line
          id="Line_2"
          data-name="Line 2"
          x1="8"
          y2="8"
          transform="translate(2086.935 721.935)"
          fill="none"
          stroke={props.stroke ?? '#1a1a1a'}
          strokeLinecap="round"
          strokeWidth={props.strokeWidth ?? '1.2'}
        />
      </g>
    </svg>
  );
}
