import React from 'react';

export default function TrashIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="ic_btn_delete"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.width ?? '24'}
      height={props.height ?? '24'}
      {...props}
    >
      <rect id="BG" width="24" height="24" fill="none" />
      <g id="icon" transform="translate(6.233 5.58)">
        <path
          id="Vector"
          d="M11.5,0H.029"
          transform="translate(-0.029 2.234)"
          fill="none"
          stroke={props.stroke ?? '#1a1a1a'}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={props.strokeWidth ?? '1'}
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,1.906l.141-.841C.244.456.321,0,1.406,0H3.087C4.172,0,4.256.481,4.352,1.072l.141.834"
          transform="translate(3.512)"
          fill="none"
          stroke={props.stroke ?? '#1a1a1a'}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={props.strokeWidth ?? '1'}
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M8.794,0,8.376,6.464c-.071,1.008-.128,1.791-1.919,1.791H2.336C.546,8.254.488,7.471.417,6.464L0,0"
          transform="translate(1.362 4.583)"
          fill="none"
          stroke={props.stroke ?? '#1a1a1a'}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={props.strokeWidth ?? '1'}
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,0H2.137"
          transform="translate(4.687 9.307)"
          fill="none"
          stroke={props.stroke ?? '#1a1a1a'}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={props.strokeWidth ?? '1'}
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M0,0H3.209"
          transform="translate(4.154 6.74)"
          fill="none"
          stroke={props.stroke ?? '#1a1a1a'}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={props.strokeWidth ?? '1'}
        />
      </g>
    </svg>
  );
}
