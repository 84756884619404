import React, { useState } from 'react';
import { FleetService } from 'ajax/services';
import useTryMutate from 'app/hooks/useTryMutate';
import { useAccessSharingFlowContext } from 'app/hooks/access-sharing';
import { ACCESS_SHARING_FLEET_STEPS } from 'app/constants/access-sharing';
import { FleetSharingOverviewView } from 'app/components/access-sharing/flows';
import { useTranslation } from 'react-i18next';

type Props = {
  fleets: any[];
  onBack: () => void;
};

export default function FleetSharingOverviewStepController({
  fleets,
  onBack,
}: Props) {
  const { t } = useTranslation();

  const {
    stepper,
    selectedFleetId,
    selectedUsers,
    setIsEditing,
    setIsSubmitted,
    setSelectedUsers,
    setEditableUserEmail,
  } = useAccessSharingFlowContext();

  const { setStep } = stepper;
  const [isLoading, setIsLoading] = useState(false);

  function handleEditEquipment() {
    setStep(ACCESS_SHARING_FLEET_STEPS.FLEET_SELECTION);
    setIsEditing(true);
  }

  function handleEditUser(email: string) {
    setStep(ACCESS_SHARING_FLEET_STEPS.ACCESS_SHARING);
    setEditableUserEmail(email);
    setIsEditing(true);
  }

  function handleAddUser() {
    setStep(ACCESS_SHARING_FLEET_STEPS.ACCESS_SHARING);
    setEditableUserEmail(undefined);
    setIsEditing(true);
  }

  const handleSubmit = useTryMutate(
    () => {
      // TODO For testing purposes. It takes the first fleet in case of absence of a selectedFleetId
      const id = selectedFleetId ?? fleets[0];
      // assert(selectedFleetId, 'Fleet ID not selected');

      setIsLoading(true);

      const users = Array.from(selectedUsers.values());
      // return FleetService.share({ fleetId: selectedFleetId, users });
      return FleetService.share({ fleetId: id, users });
    },
    {
      onComplete: (res, err, arg, api) => {
        setIsLoading(false);

        if (err) {
          api.showErrorModal();
        } else {
          api.successNotification(
            t('access_sharing.notifications.invitation-successfully-sent'),
          );
          setIsSubmitted(true);
          // NOTE: We add a brief delay before calling onBack to ensure that
          // useNavigationPrompt has enough time to update its state and avoid showing the
          // ConfirmNavigateAwayDialog.
          setTimeout(onBack, 100);
        }
      },
    },
  );

  return (
    <FleetSharingOverviewView
      fleets={fleets}
      selectedFleetId={selectedFleetId}
      selectedUsers={selectedUsers}
      isLoading={isLoading}
      setSelectedUsers={setSelectedUsers}
      onEditFleet={handleEditEquipment}
      onEditUser={handleEditUser}
      onAddUser={handleAddUser}
      onBack={onBack}
      onSubmit={handleSubmit}
    />
  );
}
