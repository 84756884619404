import { Button } from '@progress/kendo-react-buttons';
import { TextField } from 'app/components/form-fields';
import { useShareAccessEquipmentGuideContext } from 'app/hooks/access-sharing';
import ill_warning_small from 'assets/images/ill_warning_small.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './FleetNameTextField.less';
import clsx from 'clsx';
import { MAX_FLEET_NAME_LENGTH } from './constants';

interface Props {
  isAllEquipmentDisabled: boolean;
  selectedFleetName?: string;
  fleetNameError: string;
  className?: string;
  setSelectedFleetName: (name: string) => void;
  clearFieldError: () => void;
}

export default function FleetNameTextField({
  isAllEquipmentDisabled,
  selectedFleetName,
  fleetNameError,
  className,
  setSelectedFleetName,
  clearFieldError,
}: Props) {
  const { t } = useTranslation();

  const { openGuide } = useShareAccessEquipmentGuideContext();

  if (isAllEquipmentDisabled) {
    return (
      <div
        className={clsx('fleet-name-text-field__no-equipment-field', className)}
      >
        <img src={ill_warning_small} alt="" />
        <span className="fleet-name-text-field__text">
          {t('all_equipment_shared')}
        </span>

        <Button
          themeColor="primary"
          className="fleet-name-text-field__learn-more-btn"
          onClick={() => openGuide()}
        >
          {t('learn_more')}
        </Button>
      </div>
    );
  }

  return (
    <TextField
      label={t('fleet_name_label')}
      required
      value={selectedFleetName}
      placeholder={t('fleet_name_placeholder')}
      onChange={event => {
        setSelectedFleetName(event.value);
        clearFieldError();
      }}
      maxLength={MAX_FLEET_NAME_LENGTH}
      error={fleetNameError}
      className={className}
    />
  );
}
