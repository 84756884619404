import React from 'react';

export default function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="ic_nav_close"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={props.width ?? '32'}
      height={props.height ?? '32'}
      {...props}
    >
      <rect id="Box" width="32" height="32" fill="none" />
      <g id="Icon" transform="translate(-2076.936 -711.936)">
        <line
          id="Line_1"
          data-name="Line 1"
          x2="12"
          y2="12"
          transform="translate(2086.935 721.935)"
          fill="none"
          stroke={props.stroke ?? '#1a1a1a'}
          strokeLinecap="round"
          strokeWidth={props.strokeWidth ?? '2'}
        />
        <line
          id="Line_2"
          data-name="Line 2"
          x1="12"
          y2="12"
          transform="translate(2086.935 721.935)"
          fill="none"
          stroke={props.stroke ?? '#1a1a1a'}
          strokeLinecap="round"
          strokeWidth={props.strokeWidth ?? '2'}
        />
      </g>
    </svg>
  );
}
