import React from 'react';
import { useAccessSharingFlowContext } from 'app/hooks/access-sharing';
import { ACCESS_SHARING_FLEET_STEPS } from 'app/constants/access-sharing';
import { FleetCreatedView } from 'app/components/access-sharing/flows';

interface Props {
  onBack: () => void;
}

export default function FleetCreatedStepController({ onBack }: Props) {
  const { stepper, selectedFleetName, selectedEquipmentIds } =
    useAccessSharingFlowContext();
  const { setStep } = stepper;

  function handleNextStep() {
    setStep(ACCESS_SHARING_FLEET_STEPS.ACCESS_SHARING);
  }

  return (
    <FleetCreatedView
      fleetName={selectedFleetName!}
      selectedEquipmentIds={selectedEquipmentIds}
      onBack={onBack}
      onNextStep={handleNextStep}
    />
  );
}
