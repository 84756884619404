import React from 'react';
import { ShareAccessEquipmentGuideProvider } from 'app/hooks/access-sharing';
import { useAccessSharingFlowContext } from 'app/hooks/access-sharing';
import { ACCESS_SHARING_FLEET_STEPS } from 'app/constants/access-sharing';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { CreateFleetView } from 'app/components/access-sharing/flows';

interface Props {
  equipment: IEquipmentEntry[];
  onBack: () => void;
}

export default function CreateFleetStepController({
  equipment,
  onBack,
}: Props) {
  const {
    stepper,
    selectedFleetName,
    selectedEquipmentIds,
    setIsFleetCreated,
    setSelectedFleetName,
    setSelectedEquipmentIds,
  } = useAccessSharingFlowContext();

  const { setStep } = stepper;

  function handleNextStep() {
    setIsFleetCreated(true);
    setStep(ACCESS_SHARING_FLEET_STEPS.FLEET_CREATED);
  }

  return (
    <ShareAccessEquipmentGuideProvider>
      <CreateFleetView
        equipment={equipment}
        selectedFleetName={selectedFleetName}
        selectedEquipmentIds={selectedEquipmentIds}
        setSelectedFleetName={setSelectedFleetName}
        setSelectedEquipmentIds={setSelectedEquipmentIds}
        onBack={onBack}
        onNextStep={handleNextStep}
      />
    </ShareAccessEquipmentGuideProvider>
  );
}
