import { useTranslation } from 'react-i18next';
import React from 'react';
import NoSharedFleets from '../../../../../assets/images/ill_empty_access_sharing_fleets.png';
import { Button } from '@progress/kendo-react-buttons';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { AcessSharingFilters } from '../..';
import SharedFleetsRow from './SharedFleetsRow';
import { Input } from '@progress/kendo-react-inputs';
import SearchIcon from 'assets/images/ic_search.svg';
import { ic_close_small_black as BlackCloseIcon } from 'app/widgets/SVGs';
import { useNavigate } from 'react-router-dom';
import { dummyFleetsData } from 'app/constants/access-sharing';

interface ISharedFleets {
  isMobile: boolean;
}

function SharedFleets(props: ISharedFleets) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { equipmentListData: data } = React.useContext(EquipmentListContext);
  const [searchValue, setSearchValue] = React.useState<string>('');

  const [isExpanded, setIsExpanded] = React.useState(false);

  const [filter, setFilter] = React.useState(AcessSharingFilters.All);

  return (
    <div className="access-sharing-subcontainer">
      <div className="access-sharing-subcontainer-header">
        {data.equipmentList?.length == 0 ? (
          <h3>{t('shared_fleets_title')}</h3>
        ) : (
          <div className="access-sharing-subcontainer-header-content">
            <h3>{t('shared_fleets_title')}</h3>
            <div className="site-search open">
              <span className="search-icon">
                <img alt="Search" src={SearchIcon}></img>
              </span>
              <div className="search-input">
                <Input
                  name="equipment_fleets_search"
                  value={searchValue}
                  placeholder={t('shared_equipment_search_placeholder')}
                  onChange={newValue => {
                    setSearchValue(newValue.value);
                  }}
                  onClick={() => setIsExpanded(!isExpanded)}
                />
                {searchValue && (
                  <span
                    className="search-icon-close"
                    onClick={() => {
                      setSearchValue('');
                    }}
                  >
                    <BlackCloseIcon />
                  </span>
                )}
              </div>
            </div>
            <div className="access-sharing-filters">
              <div
                className={`access-sharing-filter-item ${
                  filter == AcessSharingFilters.All ? 'active' : ''
                }`}
                onClick={() => setFilter(AcessSharingFilters.All)}
              >
                <div>{t('all')}</div>
              </div>
              <div
                className={`access-sharing-filter-item ${
                  filter == AcessSharingFilters.OwnedByMe ? 'active' : ''
                }`}
                onClick={() => setFilter(AcessSharingFilters.OwnedByMe)}
              >
                <div>{t('owned_by_me')}</div>
              </div>
              <div
                className={`access-sharing-filter-item ${
                  filter == AcessSharingFilters.SharedWithMe ? 'active' : ''
                }`}
                onClick={() => setFilter(AcessSharingFilters.SharedWithMe)}
              >
                <div>{t('shared_with_me')}</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="access-sharing-subcontainer-body">
        {data.equipmentList?.length == 0 ? (
          <div className="access-sharing-subcontainer-body-empty">
            <div className="access-sharing-body-picture">
              <img src={NoSharedFleets}></img>
            </div>
            <div className="access-sharing-body-text">
              <h4>{t('shared_fleets_no_fleets_title')}</h4>
              <p>{t('shared_fleets_no_fleets_text')}</p>
            </div>
          </div>
        ) : (
          <div className="access-sharing-subcontainer-body-table">
            <div className="access-sharing-subcontainer-body-table-header">
              <div>{t('fleet')}</div>
              <div>{t('my_access')}</div>
              <div>{t('owner')}</div>
              <div></div>
              <div>{t('shared_with')}</div>
              <div></div>
            </div>
            <div className="access-sharing-subcontainer-body-table-body">
              {/* {data.equipmentList?.map(equipment => { */}
              {dummyFleetsData.map(fleet => {
                return (
                  <SharedFleetsRow
                    key={fleet.id}
                    fleet={fleet}
                    isMobile={props.isMobile}
                  />
                );
              })}
            </div>
          </div>
        )}
        <div className="access-sharing-subcontainer-body-buttons-container">
          <Button
            className="content-button round-button"
            themeColor="tertiary"
            onClick={() => navigate('create-fleet')}
          >
            {t('shared_fleets_create_button')}
          </Button>
          <Button
            className="content-button round-button"
            themeColor="secondary"
            onClick={() => navigate('share-fleet')}
          >
            {t('shared_fleets_share_button')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SharedFleets;
