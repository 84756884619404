import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { ajax } from 'ajax/ajax';
import { ErrorModal } from 'app/components/ErrorModal';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import { getLocale } from 'locales/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';

interface IProps {
  isVisible: boolean;
  setVisible: any;
  openNewMaintenanceDialog: any;
  shouldRedirect: boolean;
  redirect: () => void;
}

export function AddEngineHoursDialog(props: IProps) {
  const { t, i18n } = useTranslation();
  const { equipmentListData, setEquipmentListData } =
    React.useContext(EquipmentListContext);
  const [enteredEngineHours, setEnteredEngineHours] = React.useState(
    equipmentListData.selectedEquipment?.userEnteredEngineHours
      ?.toString()
      .replace('.', t('hours_separator')),
  );
  const [isSubmiting, setIsSubmiting] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [validInput, setValidInput] = React.useState(true);
  const [engineHoursError, setEngineHoursError] = React.useState('');

  const validateEngineHours = () => {
    if (!enteredEngineHours) {
      setEngineHoursError(t('field_is_required'));
      return false;
    }
    return true;
  };

  const currentEquipment = { ...equipmentListData.selectedEquipment };
  const hadNoHoursBefore = currentEquipment?.userEnteredEngineHours == 0;

  const toggleDialog = () => {
    props.setVisible(!props.isVisible);
    hadNoHoursBefore &&
      enteredEngineHours &&
      props.shouldRedirect &&
      props.openNewMaintenanceDialog(true);
    props.redirect();
  };

  const handleClose = () => {
    props.redirect();
    props.setVisible(false);
  };

  const handleChange = inputValue => {
    var regex = /^[0-9.]*$/;
    var convertedEngineHours = inputValue;
    const locale = getLocale();
    if (locale === 'fr-CA' || locale === 'en-CA' || i18n.language === 'fr') {
      if (inputValue.includes('.')) return;
      convertedEngineHours = inputValue.replace(t('hours_separator'), '.');
    }

    if (!regex.test(convertedEngineHours)) {
      setValidation(false, t('engine_hours_incorrect'));
      return;
    }

    const hasDecimal = convertedEngineHours.includes('.');
    const maxLength = hasDecimal ? 10 : 7;
    if ((convertedEngineHours.length || 0) > maxLength) {
      return;
    }

    const numericValue = Number(convertedEngineHours);
    if (isNaN(numericValue) || (!hasDecimal && numericValue > 9999999)) {
      setValidation(false, t('engine_hours_incorrect'));
    } else {
      if (hasDecimal) {
        const [, decimalPart] = convertedEngineHours.split('.');
        if (
          decimalPart &&
          ((decimalPart.length > 1 && currentEquipment.hasTelematics) ||
            (decimalPart.length > 2 && !currentEquipment.hasTelematics)) &&
          convertedEngineHours.length >
            (enteredEngineHours?.toString() || '').length
        ) {
          return;
        }
      }
      setEnteredEngineHours(inputValue);
      setEngineHoursError('');
    }
  };

  const setValidation = (isValid, errorMessage) => {
    setValidInput(isValid);
    setEngineHoursError(errorMessage);
  };

  const handleClick = () => {
    const dataToSend = {
      id: currentEquipment.id,
      engineHours: enteredEngineHours,
      nickName: currentEquipment.nickName,
    };

    (async () => {
      if (!validateEngineHours()) {
        return;
      }
      setIsSubmiting(true);
      ajax
        .put(
          `${ajax.appBaseUrl}/api/user/equipment/update`,
          JSON.stringify(dataToSend),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(() => {
          setEquipmentListData(oldData => ({
            ...oldData,
            selectedEquipment: {
              ...(oldData.selectedEquipment as IEquipmentEntry),
              userEnteredEngineHours: Number(enteredEngineHours),
            },
            equipmentList: oldData.equipmentList!.map((equip, index) => {
              if (index === oldData.selectedIndex) {
                return {
                  ...equip,
                  userEnteredEngineHours: Number(enteredEngineHours),
                };
              }
              return equip;
            }),
          }));
          toggleDialog();
        })
        .catch(err => {
          setErrorMessage(err.response.data.message as string);
          setShowErrorModal(true);
          console.log(err);
        })
        .finally(() => {
          setIsSubmiting(false);
        });
    })();
  };

  return (
    <>
      {showErrorModal && (
        <Dialog className="modal-center modal-error modal-remove-item modal-fulltext">
          <ErrorModal
            title={t('unexpected_error_title')}
            text={errorMessage || t('unexpected_error_message')}
            onClose={() => {
              setShowErrorModal(false);
              setErrorMessage('');
            }}
          />
        </Dialog>
      )}
      <Dialog className="modal-form">
        <div className="modal-container relative">
          <span className="back-button absolute" onClick={handleClose}>
            <BackIcon />
          </span>
          <div>
            <div className="ellipsis">
              {equipmentListData.selectedEquipment?.nickName ||
                equipmentListData.selectedEquipment?.model}
            </div>
          </div>
          <h1>
            {currentEquipment.userEnteredEngineHours === 0
              ? t('maintenance_add_engine_hours')
              : t('maintenance_edit_engine_hours')}
          </h1>
          <div className="form-caption">
            <div className="inline-label-text">
              <span className="label">{t('equipment_model')}</span>
              <span className="text">{currentEquipment.model}</span>
            </div>
            <div className="inline-label-text">
              <span className="label">
                {currentEquipment.identifierType === 'Pin'
                  ? t('equipment_pin')
                  : t('equipment_serial')}
              </span>
              <span className="text">{currentEquipment.pinOrSerial}</span>
            </div>
          </div>
          <div className="form-group">
            <div className="form-content">
              <div className="form-label">
                <label>{t('hours')}</label>
              </div>
              <div className="form-control">
                <Input
                  type="text"
                  onChange={e => handleChange(e.target.value)}
                  valid={validInput}
                  value={enteredEngineHours}
                  placeholder={t('maintenance_add_engine_hours_message')}
                  disabled={isSubmiting}
                />
              </div>
            </div>
            <div className="form-error">
              {engineHoursError && (
                <Error id={'engineHoursError'}>{engineHoursError}</Error>
              )}
            </div>
          </div>

          <Button
            className="btn-accent input-normal"
            onClick={handleClick}
            disabled={isSubmiting}
          >
            {isSubmiting ? (
              <div className="loading_icon loading_icon-btn">
                <div className="css-icon"></div>
              </div>
            ) : (
              t('continue_text')
            )}
          </Button>
        </div>
      </Dialog>
    </>
  );
}
