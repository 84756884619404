import { Dialog } from '@progress/kendo-react-dialogs';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import {
  ic_nav_back as BackIcon,
  ic_nav_close as CloseIcon,
} from 'app/widgets/SVGs';
import SuccessIcon from 'assets/images/ill_success.svg';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { tagEvent } from 'utils/ga';
import { UpdateHoursCard } from './UpdateHoursCard';

interface IUpdateHoursProps {
  updateIsUpdateHoursExpanded: (updatedIsUpdateHoursExpanded: boolean) => void;
}

export function UpdateHours(props: IUpdateHoursProps) {
  const { equipmentListData, setEquipmentListData } =
    React.useContext(EquipmentListContext);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(true);
  const [nonTelematicsEquipment, setNonTelematicsEquipment] = React.useState<
    IEquipmentEntry[]
  >([]);

  React.useEffect(() => {
    const nonTelematicsEq = equipmentListData.equipmentList?.filter(
      item => item.hasTelematics == false,
    );
    setNonTelematicsEquipment(nonTelematicsEq ?? []);
    setIsLoading(false);
  }, []);

  const updateHours = async (equipmentId, newValue) => {
    try {
      let selectedEquipment = equipmentListData.selectedEquipment;
      let newDate = new Date().toISOString();
      if (
        selectedEquipment &&
        equipmentId === equipmentListData.selectedEquipment?.id
      ) {
        selectedEquipment.userEnteredEngineHours = newValue;
        selectedEquipment.dateModified = newDate;
      }

      setEquipmentListData(oldData => ({
        ...oldData,
        equipmentList: oldData.equipmentList!.map(equip => {
          if (equipmentId === equip.id) {
            return {
              ...equip,
              userEnteredEngineHours: Number(newValue),
              dateModified: newDate,
            };
          }
          return equip;
        }),
        selectedEquipment: selectedEquipment,
      }));

      setNonTelematicsEquipment(oldData =>
        oldData.map(item => {
          if (item.id == equipmentId) {
            return {
              ...item,
              userEnteredEngineHours: Number(newValue),
              dateModified: newDate,
              updated: true,
            };
          }
          return item;
        }),
      );

      tagEvent('edit_engine_hours');
      return { success: true };
    } catch (error) {
      return { error: error as string };
    }
  };

  return (
    <>
      <Dialog className="modal-equipment modal-fixed-button modal-equipment-hours">
        <div className="row height-100">
          <div className="col-sm-12">
            <div className="modal-container relative">
              <span
                className={`back-button fixed-on-desktop ${
                  nonTelematicsEquipment.length > 0 ? '' : 'close-button'
                }`}
                onClick={() => props.updateIsUpdateHoursExpanded(false)}
              >
                {nonTelematicsEquipment.length > 0 ? (
                  <BackIcon />
                ) : (
                  <CloseIcon />
                )}
              </span>
              <h2>{t('edit_equipment_hours')}</h2>
              <div className="subtitle-caption">
                <span>{t('edit_equipment_hours_description')}</span>
              </div>
              {isLoading ? (
                <>
                  <div className="dealers-list-loading">
                    <div className="loading">
                      <div className="loading_text">{t('loading')}</div>
                      <div className="loading_icon">
                        <div className="css-icon"></div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {' '}
                  {nonTelematicsEquipment.filter(e => !e.updated).length > 0 ? (
                    <>
                      <div>
                        <span style={{ fontWeight: 600 }}>
                          {
                            nonTelematicsEquipment.filter(e => !e.updated)
                              .length
                          }
                        </span>{' '}
                        {t('edit_equipment_hours_count')}
                      </div>
                      <div>
                        {nonTelematicsEquipment.map(nonTelEq => {
                          if (!nonTelEq.updated)
                            return (
                              <UpdateHoursCard
                                key={nonTelEq.id}
                                equipment={nonTelEq}
                                updateHoursFc={updateHours}
                              />
                            );
                          return null;
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="height-100 equipment-hours-done">
                        <div className="success-image-container">
                          <img
                            className="success-image"
                            src={SuccessIcon}
                            alt=""
                          />
                        </div>
                        <h4>{t('all_equipment_updated')}</h4>
                        <div>{t('edit_equipment_hours_notify')}</div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
