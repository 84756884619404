import { useTranslation } from 'react-i18next';
import React from 'react';
import NoUsersWithAccess from '../../../../assets/images/ill_empty_access_sharing_operators.svg';
import { Input } from '@progress/kendo-react-inputs';
import { ic_close_small_black as BlackCloseIcon } from 'app/widgets/SVGs';
import SearchIcon from 'assets/images/ic_search.svg';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import SharedWithRow from './SharedWithRow';

interface ISharedWith {
  isMobile: boolean;
}

function SharedWith(props: ISharedWith) {
  const { t } = useTranslation();
  const { equipmentListData: data } = React.useContext(EquipmentListContext);
  const [searchValue, setSearchValue] = React.useState<string>('');

  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div className="access-sharing-subcontainer">
      <div className="access-sharing-subcontainer-header">
        {data.equipmentList?.length == 0 ? (
          <h3>{t('shared_with')}</h3>
        ) : (
          <div className="access-sharing-subcontainer-header-content no-filters">
            <h3>{t('shared_with')}</h3>
            <div className="site-search open">
              <span className="search-icon">
                <img alt="Search" src={SearchIcon}></img>
              </span>
              <div className="search-input">
                <Input
                  name="shared_with_users_search"
                  value={searchValue}
                  placeholder={t('shared_with_search_placeholder')}
                  onChange={newValue => {
                    setSearchValue(newValue.value);
                  }}
                  onClick={() => setIsExpanded(!isExpanded)}
                />
                {searchValue && (
                  <span
                    className="search-icon-close"
                    onClick={() => {
                      setSearchValue('');
                    }}
                  >
                    <BlackCloseIcon />
                  </span>
                )}
              </div>
            </div>
            <div></div>
          </div>
        )}
      </div>
      <div className="access-sharing-subcontainer-body">
        {data.equipmentList?.length == 0 ? (
          <div className="access-sharing-subcontainer-body-empty">
            <div className="access-sharing-body-picture">
              <img src={NoUsersWithAccess}></img>
            </div>
            <div className="access-sharing-body-text">
              <h4>{t('shared_with_no_users_title')}</h4>
              <p>{t('shared_with_no_users_text')}</p>
            </div>
          </div>
        ) : (
          <div className="access-sharing-subcontainer-body-table">
            <div className="access-sharing-subcontainer-body-table-header shared-with">
              <div>{t('user')}</div>
              <div>{t('access_to')}</div>
              <div></div>
              <div></div>
            </div>
            <div className="access-sharing-subcontainer-body-table-body shared-with">
              {data.equipmentList?.map(equipment => {
                return (
                  <SharedWithRow
                    user={equipment}
                    isMobile={props.isMobile}
                    key={equipment.id}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SharedWith;
