import { createContext } from 'react';

export interface IUserSettingsState {
  measurementUnit: 'US' | 'METRIC';
  subscribedToAlerts: boolean;
  subscribedToMessages: boolean;
  subscribedToNotifications: boolean;
}

export interface IUserSettingsContext {
  userSettings: IUserSettingsState;
  setUserSettings: (
    newValue:
      | IUserSettingsState
      | ((oldValue: IUserSettingsState) => IUserSettingsState),
  ) => void;
}

export const UserSettingsContext = createContext({} as IUserSettingsContext);
