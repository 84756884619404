import { ajax } from 'ajax/ajax';
import { UpdateHours } from 'app/pages/UpdateHours';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Notifications } from '../../pages/Notifications';
import { EquipmentListContext } from '../ReactContexts/equipmentListContext';
import { GlobalErrorContext } from '../ReactContexts/globalErrorContext';
import { InboxContext } from '../ReactContexts/inboxContext';
import { PushNotificationContext } from '../ReactContexts/pushNotificationContext';

interface INotificationsComponent {}

export function NotificationsComponent(props: INotificationsComponent) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isUpdateHoursExpanded, setIsUpdateHoursExpanded] =
    React.useState(false);
  const { equipmentListData } = React.useContext(EquipmentListContext);
  const [unreadNotificationsCount, setUnreadNotificationsCount] =
    React.useState(0);
  const { inbox, setInbox } = React.useContext(InboxContext);
  const { pushNotifications } = React.useContext(PushNotificationContext);
  const { addErrorModal } = React.useContext(GlobalErrorContext);

  React.useEffect(() => {
    if (inbox.length > 0) {
      setUnreadNotificationsCount(inbox.filter(n => !n.isRead).length);
      return;
    }
    (async () => {
      try {
        const inboxResponse = await ajax.get(
          `${ajax.appBaseUrl}/api/user/inbox`,
        );
        if (inboxResponse.status === 200) {
          if (inboxResponse.data.length > 0) {
            setUnreadNotificationsCount(
              inboxResponse.data.filter(n => !n.isRead).length,
            );
            setInbox(inboxResponse.data);
          }
        }
      } catch (ex) {
        addErrorModal();
      }
    })();
  }, [inbox]);

  React.useEffect(() => {
    if (pushNotifications.length === 0) {
      return;
    }
    (async () => {
      try {
        if (
          !inbox.some(
            n => n.id == pushNotifications[pushNotifications.length - 1],
          )
        ) {
          const inboxResponse = await ajax.get(
            `${ajax.appBaseUrl}/api/user/inbox`,
          );
          if (inboxResponse.status === 200) {
            const notifications = inboxResponse.data as any[];

            setUnreadNotificationsCount(
              notifications.filter(n => !n.isRead).length,
            );
            setInbox(notifications);
          }
        }
        setIsExpanded(true);
      } catch (ex) {
        addErrorModal();
      }
    })();
  }, [pushNotifications]);

  const updateNotificationsList = updatedList => {
    setInbox(updatedList);
    setUnreadNotificationsCount(updatedList.filter(n => !n.isRead).length);
  };

  const updateIsExpanded = updatedIsExpanded => {
    setIsExpanded(updatedIsExpanded);
  };

  const updateIsUpdateHoursExpanded = updatedIsUpdateHoursExpanded => {
    setIsUpdateHoursExpanded(updatedIsUpdateHoursExpanded);
  };

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        setIsUpdateHoursExpanded(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  if (
    equipmentListData.equipmentList == undefined &&
    window.location.hash.startsWith('#/equipment')
  ) {
    return null;
  }

  return (
    <>
      <span
        id="btn-notif"
        className="button-notifications"
        title={t('notifications')}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className="icon-notifications">
          <svg
            id="ic_menu_notif"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <rect width="32" height="32" fill="none" />
            <g transform="translate(0.002 -2.699)">
              <path
                id="bell-bottom"
                d="M8.917,0a6.658,6.658,0,0,0-6.66,6.66v2.1a5.192,5.192,0,0,1-.63,2.28L.357,13.16c-.9,1.281-1.068,3.373,1.2,3.25,7.04.125,8.741.02,14.73,0,2.269-.074,2.47-1.281,1.2-3.25l-1.27-2.12a5.23,5.23,0,0,1-.63-2.28V6.66A6.7,6.7,0,0,0,8.917,0Z"
                transform="translate(7.096 9.625)"
                fill="none"
                stroke="#1a1a1a"
                strokeLinecap="round"
                strokeWidth="1.5"
              />
              <path
                id="bell-bottom1"
                data-name="Vector"
                d="M6.66,0A3.342,3.342,0,0,1,3.33,3.33,3.336,3.336,0,0,1,.98,2.35,3.336,3.336,0,0,1,0,0"
                transform="translate(12.668 26.445)"
                fill="none"
                stroke="#1a1a1a"
                strokeWidth="1.5"
              />
            </g>
          </svg>
        </span>
        <span className="tab-number" id="tab-number-notif">
          {unreadNotificationsCount > 9
            ? '9+'
            : unreadNotificationsCount == 0
            ? ''
            : unreadNotificationsCount}
        </span>
      </span>
      {isUpdateHoursExpanded && (
        <UpdateHours
          updateIsUpdateHoursExpanded={updateIsUpdateHoursExpanded}
        />
      )}
      {isExpanded && (
        <Notifications
          isExpanded={isExpanded}
          notificationsList={inbox}
          onUpdateNotificationsList={updateNotificationsList}
          updateIsExpanded={updateIsExpanded}
          updateIsUpdateHoursExpanded={updateIsUpdateHoursExpanded}
        />
      )}
    </>
  );
}
