import * as React from 'react';
import * as _ from 'lodash';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PreviewPlayIcon } from 'assets/images/ic_preview_play.svg';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { CacheContext } from 'app/components/ReactContexts/cacheContext';
import { tagEvent } from 'utils/ga';
import { GlobalVideoContext } from 'app/components/ReactContexts/globalVideoContext';

export function VideoResourcesPage() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [videos, setVideos] = React.useState<any>();
  const { getApiModels } = React.useContext(CacheContext);
  const { equipmentListData } = React.useContext(EquipmentListContext);
  const { globalVideoStateData, setGlobalVideoStateData } =
    React.useContext(GlobalVideoContext);
  const model =
    equipmentListData.selectedEquipment && params.id != undefined
      ? equipmentListData.selectedEquipment.model
      : params.model;
  const equipmentFilter = equipmentListData.selectedEquipment
    ? equipmentListData.selectedEquipment.id
    : params.id;
  const [paramsExist, setParamsExist] = React.useState(true);
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      setParamsExist(true);
      const apiModel = await getApiModels();
      if (apiModel.models && !apiModel.models.some(c => c.model == model)) {
        setParamsExist(false);
      }
      if (equipmentFilter) {
        var videosFromEquipment =
          equipmentListData.selectedEquipment?.videoEntries;
        setVideos(videosFromEquipment);
      } else {
        var videosItem = apiModel.models.filter(c => c.model == model)[0]
          .videoEntries;
        setVideos(videosItem);
      }
      setIsLoading(false);
    })();
    if (params.id != undefined && params.id != equipmentFilter) {
      setParamsExist(false);
    }
  }, [i18n.language]);

  if (paramsExist && globalVideoStateData.video) {
    tagEvent('view_video', {
      model,
      name: globalVideoStateData.video.name,
    });
  }

  return (
    <>
      {isLoading ? (
        <div className="loading-full-page">
          <div className="loading">
            <div className="loading_text">{t('loading')}...</div>
            <div className="loading_icon">
              <div className="css-icon"></div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {' '}
          {paramsExist ? (
            <>
              <div className={equipmentFilter ? 'container' : ''}>
                <div className="relative">
                  <span
                    className="back-button back-button-on-layout fixed-on-desktop"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <BackIcon />
                  </span>
                  <div className="box-content box-content-full-height">
                    <div>{model}</div>
                    <h1>{t('instructional_videos_title')}</h1>
                    <div className="separator-l"></div>
                    {!isLoading && videos && videos.length > 0 ? (
                      _.map(videos, video => {
                        return (
                          <div key={video.name} className="preview-video">
                            <div
                              onClick={() => {
                                if (globalVideoStateData.isPiPEnabled) {
                                  document.exitPictureInPicture().then(() => {
                                    setGlobalVideoStateData({
                                      ...globalVideoStateData,
                                      isModalOpen: true,
                                      video: video,
                                      isPiPEnabled: false,
                                    });
                                  });
                                } else {
                                  setGlobalVideoStateData({
                                    ...globalVideoStateData,
                                    isModalOpen: true,
                                    video: video,
                                  });
                                }
                              }}
                            >
                              <span className="preview-play-icon">
                                <PreviewPlayIcon />
                              </span>

                              <span>{video.name}</span>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <>{t('no_results')}</>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NotFoundPage />
          )}
        </>
      )}
    </>
  );
}
