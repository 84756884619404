import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import { ajax } from 'ajax/ajax';
import {
  DealerListContext,
  DealerTabs,
} from 'app/components/ReactContexts/dealerListContext';
import * as _ from 'lodash';
import * as React from 'react';

import DealerIcon from 'assets/images/map_pin.svg';
import SelectedFavoriteDealerIcon from 'assets/images/map_pin_fav-selected.svg';
import FavoriteDealerIcon from 'assets/images/map_pin_fav.svg';
import SelectedDealerIcon from 'assets/images/map_pin_selected.svg';

import { librariesList } from 'app/common/librariesList';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';
import { UserLocationContext } from 'app/components/ReactContexts/userLocationContext';
import { UserCoordinates } from 'app/components/UserCoordinate';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { useTranslation } from 'react-i18next';

interface IDealerMap {}

export function DealerMap(props: IDealerMap) {
  const { dealerListData, setDealerListData } =
    React.useContext(DealerListContext);
  const { addErrorModal } = React.useContext(GlobalErrorContext);

  const { t, i18n } = useTranslation();

  // only use this if you are sure the map is not loaded yet, otherwise use getCenter and panTo
  const [center, setCenter] = React.useState({
    lat: 32.9792895,
    lng: -97.0315917,
  } as { lat: any; lng: any } | undefined);
  const [currentZoom, setCurrentZoom] = React.useState(9);

  const mapRef = React.useRef(undefined as google.maps.Map | undefined);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_API_KEY as string,
    libraries: librariesList,
  });

  const { userLocation } = React.useContext(UserLocationContext);

  React.useEffect(() => {
    if (userLocation?.location?.coords) {
      if (mapRef.current) {
        setDealerListData(oldValue => ({
          ...oldValue,
          panTo: {
            position: {
              lng: userLocation?.location?.coords.longitude,
              lat: userLocation?.location?.coords.latitude,
            },
          },
        }));
      } else {
        //map is not yet loaded
        setCenter({
          lng: userLocation?.location?.coords.longitude,
          lat: userLocation?.location?.coords.latitude,
        });
      }
    }
  }, [!!userLocation?.location?.coords]);

  const updateDealerData = React.useCallback(async () => {
    const metersPerPx =
      (156543.03392 *
        Math.cos(
          ((mapRef.current?.getCenter?.()?.lat() || 32.9792895) * Math.PI) /
            180,
        )) /
      Math.pow(2, mapRef.current?.getZoom?.() || 9);
    let radiusSize = mapRef.current?.getDiv
      ? Math.round(
          metersPerPx *
            Math.round(
              Math.sqrt(
                (mapRef.current.getDiv().offsetWidth / 2) *
                  (mapRef.current.getDiv().offsetWidth / 2) +
                  (mapRef.current.getDiv().offsetHeight / 2) *
                    (mapRef.current.getDiv().offsetHeight / 2),
              ),
            ),
        )
      : Math.round(metersPerPx * 9);

    if (radiusSize < 160000) {
      radiusSize = 160000;
    }

    setDealerListData(oldState => ({
      ...oldState,
      listIsLoading: true,
    }));

    try {
      const mapLng = mapRef.current?.getCenter?.()?.lng();
      let normalizedMapLng;
      if (mapLng) {
        normalizedMapLng =
          mapLng! < -180
            ? ((mapLng! + 180) % 360) + 180
            : ((mapLng! + 180) % 360) - 180;
      }
      const dealersNearby = await ajax.getAsAnonymous(
        `${ajax.appBaseUrl}/api/dealer/nearest?latitude=${
          mapRef.current?.getCenter?.()?.lat() || 32.9792895
        }&longitude=${
          normalizedMapLng || -97.0315917
        }&rangeMeters=${radiusSize}&limit=100`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      setDealerListData(oldState => ({
        ...oldState,
        dealerList: [...dealersNearby.data],
        listIsLoading: false,
      }));
    } catch (e) {
      setDealerListData(oldState => ({
        ...oldState,
        dealerList: [],
        listIsLoading: false,
      }));
      addErrorModal();
    }
  }, []);

  const debouncedLoadNewSet = React.useMemo(() => {
    return _.debounce(async () => {
      await updateDealerData();
    }, 750);
  }, []);

  React.useEffect(() => {
    debouncedLoadNewSet();
  }, [i18n.language]);

  React.useEffect(() => {
    if (ajax.isLoggedIn()) {
      ajax
        .get(`${ajax.appBaseUrl}/api/user/dealer`, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .catch(err => {
          addErrorModal();
        })
        .then(response => {
          setDealerListData(oldState => ({
            ...oldState,
            favoriteDealers: response.data,
          }));
        });
    } else {
      setDealerListData(oldState => ({
        ...oldState,
        favoriteDealers: [],
      }));
    }
  }, []);

  React.useEffect(() => {
    if (dealerListData.panTo) {
      let resetZoomSupression = true;
      mapRef.current?.panTo(dealerListData.panTo.position);
      if (dealerListData.panTo?.zoom) {
        if (mapRef.current?.getZoom() !== dealerListData.panTo?.zoom) {
          mapRef.current?.setZoom(dealerListData.panTo?.zoom);
          resetZoomSupression = false;
        }
      }
      if (!dealerListData.surpressZoomDataLoad) {
        debouncedLoadNewSet();
      }

      setDealerListData(oldState => ({
        ...oldState,
        panTo: undefined,
        surpressZoomDataLoad:
          dealerListData.surpressZoomDataLoad && !resetZoomSupression,
      }));
    }
  }, [dealerListData.panTo]);

  React.useEffect(() => {
    if (
      dealerListData.dealerTab === DealerTabs.Favorites &&
      !_.isEmpty(dealerListData.favoriteDealers)
    ) {
      let bounds = new google.maps.LatLngBounds();
      (dealerListData.favoriteDealers || []).map(favoriteDealer =>
        bounds.extend({
          lat: favoriteDealer.address.latitude,
          lng: favoriteDealer.address.longitude,
        }),
      );

      mapRef.current?.fitBounds(bounds);
      const zoomAferFitBound = mapRef.current?.getZoom() || 1;
      if (zoomAferFitBound > 11) {
        mapRef.current?.setZoom(11);
      }
    } else if (dealerListData.dealerTab === DealerTabs.Locations) {
      setDealerListData(oldState => ({
        ...oldState,
        panTo: {
          position: {
            lng: userLocation?.location?.coords?.longitude || -97.0315917,
            lat: userLocation?.location?.coords?.latitude || 32.9792895,
          },
          zoom: 9,
        },
      }));
    }
  }, [dealerListData.dealerTab]);

  React.useEffect(() => {
    return () => {
      setDealerListData(oldValue => ({
        ...oldValue,
        dealerTab: DealerTabs.Locations,
      }));
    };
  }, []);

  const isMobile = useIsMobile();

  const options = React.useMemo(
    () => ({
      mapTypeControlOptions: {
        position: isLoaded ? google.maps.ControlPosition.RIGHT_TOP : 9,
        style: isMobile
          ? isLoaded
            ? google.maps.MapTypeControlStyle.DROPDOWN_MENU
            : 1.0
          : isLoaded
          ? google.maps.MapTypeControlStyle.HORIZONTAL_BAR
          : 2.0,
      },
      fullscreenControl: false,
      minZoom: 2,
      scaleControl: true,
      streetViewControl: false,
      restriction: {
        latLngBounds: {
          west: -180,
          north: 85,
          south: -85,
          east: 180,
        },
        strictBounds: true,
      },
    }),
    [isLoaded, isMobile],
  );

  return (
    <div>
      {!isLoaded ? (
        <div className="equipment-content-empty-loading">
          <div className="loading">
            <div className="loading_text">{t('loading_map')}</div>
            <div className="loading_icon">
              <div className="css-icon"></div>
            </div>
          </div>
        </div>
      ) : (
        <GoogleMap
          mapContainerClassName="dealers-map-container"
          center={center}
          zoom={9}
          onLoad={map => {
            mapRef.current = map;
          }}
          onDragEnd={() => {
            debouncedLoadNewSet();
          }}
          onZoomChanged={() => {
            if (dealerListData.surpressZoomDataLoad) {
              setDealerListData(oldState => ({
                ...oldState,
                surpressZoomDataLoad: false,
              }));
            } else {
              debouncedLoadNewSet();
            }
          }}
          options={options}
          onClick={() => {
            setDealerListData(oldState => ({
              ...oldState,
              selectedDealer: undefined,
            }));
          }}
        >
          <UserCoordinates />

          {(dealerListData.dealerTab === DealerTabs.Favorites
            ? dealerListData.favoriteDealers
            : dealerListData.dealerList
          )?.map(dealer => {
            const isFavorited = !!dealerListData.favoriteDealers?.find(
              favoriteDealer =>
                favoriteDealer.dealerNumber === dealer.dealerNumber,
            );
            const isSelectedDealer =
              dealer.dealerNumber ===
              dealerListData.selectedDealer?.dealerNumber;
            return (
              <MarkerF
                key={`dealerMarker${dealer.dealerNumber}${isSelectedDealer}${isFavorited}`}
                onClick={() => {
                  if (
                    dealerListData.selectedDealer?.dealerNumber ===
                    dealer.dealerNumber
                  ) {
                    setDealerListData(oldState => ({
                      ...oldState,
                      selectedDealer: undefined,
                      panTo: {
                        position: {
                          lat:
                            mapRef.current?.getCenter?.()?.lat() ??
                            Number(dealer.address.latitude),
                          lng:
                            mapRef.current?.getCenter?.()?.lng() ??
                            Number(dealer.address.longitude),
                        },
                        zoom: currentZoom,
                      },
                      surpressZoomDataLoad: false,
                    }));
                  } else {
                    setCurrentZoom(mapRef.current?.getZoom() as number);
                    setDealerListData(oldState => ({
                      ...oldState,
                      selectedDealer: dealer,
                      panTo: {
                        position: {
                          lat: Number(dealer.address.latitude),
                          lng: Number(dealer.address.longitude),
                        },
                        zoom: 11,
                      },
                      surpressZoomDataLoad: true,
                    }));
                  }
                }}
                position={{
                  lat: Number(dealer.address.latitude),
                  lng: Number(dealer.address.longitude),
                }}
                icon={
                  isFavorited
                    ? isSelectedDealer
                      ? SelectedFavoriteDealerIcon
                      : FavoriteDealerIcon
                    : isSelectedDealer
                    ? SelectedDealerIcon
                    : DealerIcon
                }
              ></MarkerF>
            );
          })}
        </GoogleMap>
      )}
    </div>
  );
}
