import { Dialog } from '@progress/kendo-react-dialogs';
import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import './ConfirmationDialog.less';

export interface Props {
  open: boolean;
  // icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  children: React.ReactNode;
  confirmationLabel?: string;
  closeLabel?: string;
  onConfirm: () => void;
  onClose: () => void;
}

export default function ConfirmationDialog({
  open,
  // icon: Icon,
  title,
  children,
  confirmationLabel,
  closeLabel,
  onConfirm,
  onClose,
}: Props) {
  if (!open) {
    return null;
  }

  return (
    <Dialog className="modal-center modal-error modal-remove-item modal-fulltext period-settings-dialog">
      <div className="confirmation-dialog">
        <h2 className="confirmation-dialog__title">{title}</h2>

        {children}

        <div className="confirmation-dialog__actions-block">
          <Button themeColor="dark" onClick={onClose}>
            {closeLabel ?? 'CLOSE'}
          </Button>

          <Button themeColor="primary" onClick={onConfirm}>
            {confirmationLabel ?? 'CONFIRM'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
