import React from 'react';
import { IAdBannerData } from 'types/IAdBannerData';
import { tagEvent } from 'utils/ga';

interface ISmallAdBanner {
  bannerData: IAdBannerData | undefined;
}

function SmallAdBanner(props: ISmallAdBanner) {
  return (
    <>
      <div className="small-banner">
        <img src={props?.bannerData?.imageUrl}></img>
        <div className="small-banner-title">{props?.bannerData?.title}</div>
        <div className="small-banner-desc">
          {props?.bannerData?.description}
        </div>
        <div
          className="small-banner-button"
          onClick={() => {
            tagEvent('small_ad_click', { clicked: props?.bannerData });
          }}
        >
          <a
            href={props?.bannerData?.buttonUrl}
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            {props?.bannerData?.buttonLabel.toUpperCase()}
          </a>
        </div>
      </div>
    </>
  );
}

export default SmallAdBanner;
