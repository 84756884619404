import { createContext } from 'react';

export interface IUserInfoData {
  email_verified?: boolean;
}

export interface IUserInfoContext {
  setUserInfo: (newValue: IUserInfoData) => void;
  userInfo?: IUserInfoData;
}

export const UserInfoContext = createContext({} as IUserInfoContext);
