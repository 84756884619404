import { ajax } from 'ajax/ajax';
import { isCanada } from 'app/common/websiteCountry';

export const getRegion = () => {
  //default to the website location
  let locale = isCanada ? 'CA' : ('US' as 'CA' | 'US');
  let set = false;

  navigator?.languages?.forEach(l => {
    if (set) {
      return;
    }
    if (l.toLowerCase().includes('us')) {
      locale = 'US';
      set = true;
    }
    if (l.toLowerCase().includes('ca')) {
      locale = 'CA';
      set = true;
    }
  });
  return locale;
};

export const getLocale = () => {
  const region = getRegion();
  const language = localStorage.getItem('language');
  let locale = `en-${region}`;

  if (!language) {
    let set = false;
    navigator?.languages?.forEach(l => {
      if (set) {
        return;
      }

      if (l.includes('en')) {
        locale = `en-${region}`;
        set = true;
      } else if (l.includes('fr')) {
        locale = `fr-${region}`;
        set = true;
      }
    });
  } else {
    locale = `${language}-${region}`;
  }

  return locale;
};

export const setDefaultMeasurementUnit = () => {
  let defaultMeasurementUnit: 'US' | 'METRIC';

  window.navigator.language.includes('-US')
    ? (defaultMeasurementUnit = 'US')
    : (defaultMeasurementUnit = 'METRIC');

  const settings = {
    settings: {
      measurementUnit: defaultMeasurementUnit,
    },
  };

  (async () =>
    await ajax.post(`${ajax.appBaseUrl}/api/user/settings`, settings))();
};
