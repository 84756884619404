export enum IAccessSharingPermissionType {
  Viewer,
  Editor,
  Admin,
}

export enum IAccessSharingPeriodType {
  Continuous,
  Temporary,
}

export interface IAccessSharingUserData {
  email: string;
  permission: IAccessSharingPermissionType;
  accessPeriod: IAccessSharingPeriodType;
  startDate?: Date;
  endDate?: Date;
}
