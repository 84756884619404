import { Card } from 'app/components/Card';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccessSharingRole } from 'types/entities/AccessSharingRole';
import './CurrentUserAccessCard.less';

interface Props {
  name: string;
  email: string;
  role: AccessSharingRole;
  className?: string;
}

export default function CurrentUserAccessCard({
  name,
  email,
  role,
  className,
}: Props) {
  const { t } = useTranslation();

  return (
    <Card className={clsx('current_user_access_card__card', className)}>
      <div className="current_user_access_card__content">
        <div className="current_user_access_card__info">
          <b className="current_user_access_card__label">{name}</b>
          <span className="current_user_access_card__text">{email}</span>
        </div>

        <div className="current_user_access_card__info">
          <span className="current_user_access_card__label current_user_access_card__role-label">
            {t('current_role')}
          </span>
          <span className="current_user_access_card__text">
            {t(role.toLowerCase().toUpperCase())}
          </span>
        </div>
      </div>
    </Card>
  );
}
