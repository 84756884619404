import { isCanada } from 'app/common/websiteCountry';
import { getLocale } from 'locales/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AddEngineHoursDialog } from './Dialogs/ChangeEngineHours';

interface IProps {
  eqipmentDetails: any;
  openEngineHoursUpdateDialog: boolean;
  setOpenEngineHoursUpdateDialog: any;
  openNewMaintenanceDialog?: any;
}

export function EquipmentDetails(props: IProps) {
  const { t, i18n } = useTranslation();

  const [shoulRedirect, setSouldRedirect] = React.useState<boolean>(true);

  React.useEffect(() => {
    setSouldRedirect(true);
  }, []);

  const locale = getLocale();

  const formatFrHours = hours => {
    return hours.toString().replace('.', t('hours_separator'));
  };

  const getHours = () => {
    const currentEngineHours =
      props.eqipmentDetails.telematics?.cumulativeOperatingHours ||
      props.eqipmentDetails.userEnteredEngineHours;

    return isCanada ||
      locale === 'fr-CA' ||
      locale === 'en-CA' ||
      i18n.language === 'fr'
      ? formatFrHours(currentEngineHours)
      : currentEngineHours;
  };

  const handleEdit = () => {
    props.setOpenEngineHoursUpdateDialog(true);
    setSouldRedirect(false);
  };

  return (
    <div className="info-cards-row">
      <div className="info-card info-card-engine-hours">
        <div className="engine-hours">
          <div className="info-card-label">
            {t('select_maintenance_current_engine_hours')}
          </div>
          {!props.eqipmentDetails.hasTelematics && (
            <svg
              onClick={handleEdit}
              id="ic_maintenance_edit"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <rect id="Box" width="20" height="20" fill="none" />
              <g id="edit-2" transform="translate(4.528 4)">
                <path
                  id="Vector"
                  d="M5.633.875.64,6.159a1.854,1.854,0,0,0-.407.87L.008,9a.929.929,0,0,0,1.137,1.076L3.1,9.74a1.746,1.746,0,0,0,.845-.456L8.94,4A1.981,1.981,0,0,0,8.849.778,1.982,1.982,0,0,0,5.633.875Z"
                  transform="translate(0.606 0)"
                  fill="none"
                  stroke="#1a1a1a"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
                <path
                  id="Vector-2"
                  data-name="Vector"
                  d="M0,0A3.725,3.725,0,0,0,3.314,3.131"
                  transform="translate(5.406 1.757)"
                  fill="none"
                  stroke="#1a1a1a"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
                <path
                  id="Vector-3"
                  data-name="Vector"
                  d="M0,0H10.945"
                  transform="translate(0 12.063)"
                  fill="none"
                  stroke="#1a1a1a"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
              </g>
            </svg>
          )}
        </div>
        <div className="engine-hours">
          <span className="engine-hours-icon">
            <svg
              style={{ pointerEvents: 'none' }}
              id="ic_tele_hours"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <rect id="Box" width="24" height="24" fill="none" />
              <g
                id="Icon_feather-clock"
                data-name="Icon feather-clock"
                transform="translate(2.754 2.623)"
              >
                <path
                  id="Path_433"
                  data-name="Path 433"
                  d="M22.14,12.57A9.57,9.57,0,1,1,12.57,3a9.57,9.57,0,0,1,9.57,9.57Z"
                  transform="translate(-3 -3)"
                  fill="#1a1a1a"
                />
                <path
                  id="Path_434"
                  data-name="Path 434"
                  d="M18,9v5.748l3.832,1.916"
                  transform="translate(-8.433 -5.175)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.2"
                />
              </g>
            </svg>
          </span>
          <div className="ellipsis">{getHours()}</div>
        </div>
      </div>
      <div className="info-card row">
        <div className="col-md-12">
          <span className="info-col-label">{t('equipment_model')}</span>
          <span className="info-col-value">{props.eqipmentDetails.model}</span>
        </div>
        <div className="col-md-12">
          <span className="info-col-label">
            {props.eqipmentDetails.pin
              ? t('equipment_pin')
              : t('equipment_serial')}
          </span>
          <span className="info-col-value">
            {props.eqipmentDetails.pinOrSerial || '\u00A0'}{' '}
          </span>
        </div>
      </div>
      {props.openEngineHoursUpdateDialog && (
        <AddEngineHoursDialog
          isVisible={props.openEngineHoursUpdateDialog}
          setVisible={props.setOpenEngineHoursUpdateDialog}
          openNewMaintenanceDialog={props.openNewMaintenanceDialog}
          shouldRedirect={shoulRedirect}
          redirect={() => setSouldRedirect(true)}
        />
      )}
    </div>
  );
}
