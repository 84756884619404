import { useAccessSharingFlowContext } from 'app/hooks/access-sharing';
import { ACCESS_SHARING_FLEET_STEPS } from 'app/constants/access-sharing';
import React from 'react';
import { AccessSharingView } from 'app/components/access-sharing/flows';

export default function FleetAccessSharingStepController() {
  const {
    stepper,
    isEditing,
    isFleetCreated,
    selectedUsers,
    editableUserEmail,
    setIsEditing,
    setSelectedUsers,
  } = useAccessSharingFlowContext();

  const { setStep } = stepper;

  function handlePrevStep() {
    if (isFleetCreated && !isEditing) {
      setStep(ACCESS_SHARING_FLEET_STEPS.FLEET_CREATED);
    } else if (isEditing) {
      setStep(ACCESS_SHARING_FLEET_STEPS.OVERVIEW);
      setIsEditing(false);
    } else {
      setStep(ACCESS_SHARING_FLEET_STEPS.FLEET_SELECTION);
    }
  }

  function handleNextStep() {
    if (isEditing) {
      setIsEditing(false);
    }
    setStep(ACCESS_SHARING_FLEET_STEPS.OVERVIEW);
  }

  return (
    <AccessSharingView
      selectedUsers={selectedUsers}
      editableUserEmail={editableUserEmail}
      setSelectedUsers={setSelectedUsers}
      onBack={handlePrevStep}
      onNextStep={handleNextStep}
    />
  );
}
