import { createContext } from 'react';

export interface IPushNotificationContext {
  addPushNotification: any;
  removePushNotification: any;
  pushNotifications: any[];
}

export const PushNotificationContext = createContext(
  {} as IPushNotificationContext,
);
