import {
  AccessPeriodRadioGroup,
  AccessPermissionsRadioGroup,
} from 'app/components/access-sharing';
import React from 'react';
import {
  IAccessSharingPeriodType,
  IAccessSharingPermissionType,
} from 'types/IAccessSharingUser';
import './AccessConfiguration.less';

interface Props {
  initialPermission?: IAccessSharingPermissionType;
  initialPeriod?: IAccessSharingPeriodType;
  initialStartDate?: Date | null;
  initialEndDate?: Date | null;
  onChangePermission: (value: IAccessSharingPermissionType) => void;
  onChangePeriod: (value: IAccessSharingPeriodType) => void;
  onChangeStartDate: (value: Date | null) => void;
  onChangeEndDate: (value: Date | null) => void;
}

export default function AccessConfiguration({
  initialPermission,
  initialPeriod,
  initialStartDate,
  initialEndDate,
  onChangePermission,
  onChangePeriod,
  onChangeStartDate,
  onChangeEndDate,
}: Props) {
  return (
    <div className="access-configuration">
      <AccessPermissionsRadioGroup
        className="access-configuration__radio_group"
        initialValue={initialPermission}
        onChange={onChangePermission}
      />

      <AccessPeriodRadioGroup
        className="access-configuration__radio_group"
        initialValue={initialPeriod}
        initialStartDate={initialStartDate}
        initialEndDate={initialEndDate}
        onChange={onChangePeriod}
        onChangeStartDate={onChangeStartDate}
        onChangeEndDate={onChangeEndDate}
      />
    </div>
  );
}
