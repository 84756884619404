import * as React from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { librariesList } from 'app/common/librariesList';
import {
  EquipmentListContext,
  IPanTo,
} from 'app/components/ReactContexts/equipmentListContext';
import { EquipmentMarker } from './EquipmentMarker';
import { GeofencePolygon } from './GeofencePolygon';
import { DrawGeofence } from './DrawGeofence';
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from '@progress/kendo-react-layout';
import { BackButton } from 'app/components/BackButton';
import { useTranslation } from 'react-i18next';
import { InfoButton } from 'app/components/InfoButton';
import { UndoButton } from 'app/components/UndoButton';
import { IPointEntry } from 'types/IGeofenceEntry';
import _ from 'lodash';
import { UserCoordinates } from '../../../components/UserCoordinate';
import { UserLocationContext } from 'app/components/ReactContexts/userLocationContext';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { useMeassurementUnit } from 'app/hooks/useMeasurementUnit';
import { MapLegendInfoControl } from 'app/components/MapLegendInfoControl';
import MapControlsContainer from 'app/pages/Equipment/Geofence/MapControlsContainer';

type IGeofence = {};

export function Geofence(props: IGeofence) {
  const { equipmentListData, setEquipmentListData } =
    React.useContext(EquipmentListContext);
  const [filteredEquipmentList, setFilteredEquipmentList] = React.useState<any>(
    equipmentListData.equipmentList,
  );

  const { t } = useTranslation();

  // only use this if you are sure the map is not loaded yet, otherwise use getCenter and panTo
  const [center, setCenter] = React.useState({
    lat: 32.9792895,
    lng: -97.0315917,
  } as { lat: any; lng: any } | undefined);

  const [newZoom, setNewZoom] = React.useState<number>();
  const [currentZoom, setCurrentZoom] = React.useState<number>(9);

  const [negateUserLocationRecenter, setNegateUserLocationRecenter] =
    React.useState(false);

  const mapRef = React.useRef(undefined as google.maps.Map | undefined);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_API_KEY as string,
    libraries: librariesList,
  });

  const [drawnPolygon, setDrawnPolygon] = React.useState<IPointEntry[]>([]);
  const [infoBoxShown, setInfoBoxShown] = React.useState(true);

  const { userLocation } = React.useContext(UserLocationContext);

  const measurementUnit = useMeassurementUnit();

  React.useEffect(() => {
    var filteredList = equipmentListData.equipmentList;
    if (
      equipmentListData.filtersList &&
      equipmentListData.filtersList.includes('active_fault_codes')
    ) {
      filteredList = filteredList?.filter(
        f =>
          f.telematics &&
          f.telematics.faultCodes &&
          f.telematics?.faultCodes?.length > 0,
      );
    }
    if (
      equipmentListData.filtersList &&
      equipmentListData.filtersList.includes('machine_running')
    ) {
      filteredList = filteredList?.filter(
        f => f.telematics && f.telematics.engineRunning,
      );
    }
    if (
      equipmentListData.filtersList &&
      equipmentListData.filtersList.includes('machine_in_transport')
    ) {
      filteredList = filteredList?.filter(
        f => f.telematics && f.telematics.motionState == 'in-transport',
      );
    }
    if (
      equipmentListData.filtersList &&
      equipmentListData.filtersList.includes('restart_inhibited')
    ) {
      filteredList = filteredList?.filter(
        f =>
          f.telematics &&
          f.telematics.restartInhibitStatus != null &&
          f.telematics.restartInhibitStatus?.equipmentStatus ==
            'RestartDisabled',
      );
    }
    setFilteredEquipmentList(filteredList);
  }, [equipmentListData.filtersList]);

  React.useEffect(() => {
    if (userLocation?.location?.coords && !negateUserLocationRecenter) {
      if (
        equipmentListData.panTo?.position ||
        equipmentListData.panTo?.latLongBonds
      ) {
        return;
      }
      if (mapRef.current) {
        setEquipmentListData(oldValue => ({
          ...oldValue,
          panTo: {
            position: {
              lng: userLocation?.location?.coords.longitude,
              lat: userLocation?.location?.coords.latitude,
            },
          },
        }));
      } else {
        //map is not yet loaded
        setCenter({
          lng: userLocation?.location?.coords.longitude,
          lat: userLocation?.location?.coords.latitude,
        });
      }
    }
  }, [!!userLocation?.location?.coords]);

  const handlePanTo = (panTo: IPanTo | undefined, map) => {
    if (panTo && map) {
      if (panTo?.position) {
        map?.panTo(panTo.position);
        setNegateUserLocationRecenter(true);
      }
      if (panTo?.zoom && map?.getZoom() !== panTo?.zoom) {
        map?.setZoom(panTo?.zoom);
      }

      if (panTo?.latLongBonds && panTo?.latLongBonds.length > 0) {
        let bounds = new google.maps.LatLngBounds();
        (panTo?.latLongBonds || []).map(favoriteDealer =>
          bounds.extend({
            lat: favoriteDealer.latitude,
            lng: favoriteDealer.longitude,
          }),
        );
        map?.fitBounds(bounds);
      }
    }
  };

  React.useEffect(() => {
    handlePanTo(equipmentListData.panTo, mapRef.current);

    if ((equipmentListData.panTo, mapRef.current)) {
      setEquipmentListData(oldState => ({
        ...oldState,
        panTo: undefined,
      }));
    }
  }, [equipmentListData.panTo]);

  const isMobile = useIsMobile();

  const options = React.useMemo(
    () => ({
      mapTypeControlOptions: {
        position: isLoaded ? google.maps.ControlPosition.RIGHT_TOP : 8,
        style: isMobile
          ? isLoaded
            ? google.maps.MapTypeControlStyle.DROPDOWN_MENU
            : 1.0
          : isLoaded
          ? google.maps.MapTypeControlStyle.HORIZONTAL_BAR
          : 2.0,
      },
      fullscreenControl: false,
      minZoom: 2,
      scaleControl: true,
      streetViewControl: false,
      restriction: {
        latLngBounds: {
          west: -180,
          north: 85,
          south: -85,
          east: 180,
        },
        strictBounds: true,
      },
    }),
    [isLoaded, isMobile],
  );

  return (
    <div>
      {!isLoaded ? (
        <h1>{t('loading...')}</h1>
      ) : (
        <>
          {equipmentListData.isDrawing && (
            <div className="geofences-drawing-controls">
              <AppBar>
                <AppBarSection>
                  <BackButton
                    onClick={() => {
                      setEquipmentListData(oldvalue => ({
                        ...oldvalue,
                        isDrawing: false,
                      }));
                      setDrawnPolygon([]);
                    }}
                  />
                </AppBarSection>
                <AppBarSection>
                  <h3 className="geofences-create-geofence-text">
                    {t('create_geofences')}
                  </h3>
                </AppBarSection>
                <AppBarSpacer />
                {!_.isEmpty(drawnPolygon) && (
                  <AppBarSection>
                    <UndoButton
                      onClick={() => {
                        setDrawnPolygon([]);
                      }}
                    />
                  </AppBarSection>
                )}
                <AppBarSection>
                  <InfoButton
                    onClick={() => {
                      setInfoBoxShown(!infoBoxShown);
                    }}
                  />
                </AppBarSection>
              </AppBar>
            </div>
          )}
          <GoogleMap
            mapContainerClassName={`geofences-map-container ${
              equipmentListData.isDrawing && 'geofences-map-container-drawing'
            }`}
            center={center}
            zoom={newZoom ? newZoom : 9}
            onZoomChanged={() => {
              setNewZoom(undefined);
              if (mapRef.current?.getZoom()) {
                setCurrentZoom(mapRef.current.getZoom() || 9);
              }
            }}
            onLoad={map => {
              mapRef.current = map;
              handlePanTo(equipmentListData.panTo, map);
            }}
            options={options}
          >
            <MapControlsContainer>
              <MapLegendInfoControl />
              <UserCoordinates isEquipment />
            </MapControlsContainer>

            {!equipmentListData.isDrawing && (
              <>
                {equipmentListData.geofenceList?.map(geofence => {
                  return (
                    <div key={geofence.id}>
                      <GeofencePolygon
                        geofence={geofence}
                        currentZoom={currentZoom}
                      />
                    </div>
                  );
                })}
              </>
            )}
            {filteredEquipmentList?.map((equipment, index) => {
              return (
                <div key={index}>
                  <EquipmentMarker
                    equipment={equipment}
                    index={index}
                    key={`equipmentListMarker${index}`}
                    measurementUnit={measurementUnit}
                  />
                </div>
              );
            })}
            <DrawGeofence
              drawnPolygon={drawnPolygon}
              setDrawnPolygon={setDrawnPolygon}
              infoBoxShown={infoBoxShown}
              setInfoBoxShown={setInfoBoxShown}
            />
          </GoogleMap>
        </>
      )}
    </div>
  );
}
