import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { LoadingOverlay } from 'app/components/LoadingOverlay';
import { ill_delete as DeleteIcon } from 'app/widgets/SVGs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface IDeleteNotificationsDialog {
  isLoading: boolean;
  isGeneralSelection: boolean;
  isAlertsSelected: boolean;
  close: () => void;
  deleteNotifications: () => void;
}

export function DeleteNotificationsDialog(props: IDeleteNotificationsDialog) {
  const { t } = useTranslation();

  return (
    <Dialog className="modal-center modal-remove-item modal-fulltext">
      <div className="remove-item-dialog-content">
        <div className="image-to-remove">
          <DeleteIcon />
        </div>
        <h3 className="text-center">
          {props.isAlertsSelected
            ? props.isGeneralSelection
              ? t('delete_all_alerts_dialog_message')
              : t('delete_selected_alerts_dialog_message')
            : props.isGeneralSelection
            ? t('delete_all_messages_dialog_message')
            : t('delete_selected_messages_dialog_message')}
        </h3>
        <div className="row">
          <div className="col-xxs-6">
            <Button disabled={props.isLoading} onClick={props.close}>
              {t('cancel')}
            </Button>
          </div>
          <div className="col-xxs-6">
            <Button
              disabled={props.isLoading}
              onClick={props.deleteNotifications}
              themeColor="error"
            >
              {t('menu_delete')}
              <LoadingOverlay isOpen={props.isLoading} text="" />
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
