import { UserAvatarStack } from 'app/components/access-sharing';
import { Radio } from 'app/components/form-fields';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  fleet: any;
  checked: boolean;
  handleCheck: () => void;
};

const usernames = [
  'John Doe',
  'Jane Smith',
  'Michael Johnson',
  'Emily Davis',
  'William Brown',
  'Olivia Taylor',
  'John Doe',
  'Jane Smith',
];

export default function FleetRadioItem({ fleet, checked, handleCheck }: Props) {
  const { t } = useTranslation();
  const { id, name, pieces } = fleet;

  return (
    <li className="fleet-select__item">
      <Radio
        name="fleet-select"
        checked={checked}
        id={id}
        value={id}
        onChange={handleCheck}
      />

      <label htmlFor={id} className="fleet-info">
        <span
          className={clsx('fleet-select__title', {
            'fleet-select__title_checked': checked,
          })}
        >
          {name}
        </span>

        <span className="fleet-select__description">
          {t('number_of_equipment', {
            numberOfEquipment: pieces,
          })}
        </span>

        <UserAvatarStack usernames={usernames} />
      </label>
    </li>
  );
}
