import { useEffect } from 'react';

export default function useClickOutside(callback: () => void) {
  useEffect(() => {
    const handleClick = (event: MouseEvent | TouchEvent) => {
      const eventElement = event.target as HTMLElement;

      if (getClassName(eventElement).includes('k-overlay')) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [callback]);
}

function getClassName(element: HTMLElement) {
  return typeof element.className === 'string'
    ? element.className
    : element instanceof SVGElement && element.className.baseVal;
}
