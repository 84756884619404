import { BaseButton } from 'app/components/buttons/BaseButton';
import { AddIcon } from 'app/components/icons';
import FleetRadioItem from './components/FleetRadioItem';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './FleetSelect.less';
import clsx from 'clsx';

interface Props {
  fleets: any[];
  selectedFleetId?: string;
  className?: string;
  setSelectedFleetId: (id: string) => void;
  onCreateFleet?: () => void;
}

export default function FleetSelect({
  fleets,
  selectedFleetId,
  className,
  setSelectedFleetId,
  onCreateFleet,
}: Props) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!selectedFleetId) {
      setSelectedFleetId(fleets[0].id);
    }
  }, [selectedFleetId, setSelectedFleetId]);

  return (
    <ul className={clsx('fleet-select', className)}>
      {fleets.map(fleet => {
        const { id } = fleet;

        return (
          <FleetRadioItem
            key={id}
            fleet={fleet}
            checked={selectedFleetId === id}
            handleCheck={() => setSelectedFleetId(id)}
          />
        );
      })}

      {onCreateFleet && (
        <BaseButton
          onClick={onCreateFleet}
          className="fleet-select__create-new-btn"
        >
          <AddIcon width={24} height={24} />
          {t('create_new_fleet')}
        </BaseButton>
      )}
    </ul>
  );
}
