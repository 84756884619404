import { Label } from '@progress/kendo-react-labels';
import { Radio } from 'app/components/form-fields';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAccessSharingPermissionType } from 'types/IAccessSharingUser';
import './AccessPermissionsRadioGroup.less';

interface Props {
  className?: string;
  initialValue?: IAccessSharingPermissionType;
  onChange: (value: IAccessSharingPermissionType) => void;
}

export default function AccessPermissionsRadioGroup({
  className,
  initialValue = IAccessSharingPermissionType.Viewer,
  onChange,
}: Props) {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);

  const options = useMemo(
    () => [
      {
        label: t('viewer'),
        description: t('viewer_description'),
        value: IAccessSharingPermissionType.Viewer,
      },
      {
        label: t('editor'),
        description: t('editor_description'),
        value: IAccessSharingPermissionType.Editor,
      },
      {
        label: t('admin'),
        description: t('admin_description'),
        value: IAccessSharingPermissionType.Admin,
      },
    ],
    [t],
  );

  return (
    <div className={clsx('access-permissions-radio-group', className)}>
      <Label className="access-permissions-radio-group__label">
        {t('select_access_permissions')}
      </Label>

      <div className="access-permissions-radio-group__options">
        {options.map(option => {
          const isChecked = value === option.value;

          return (
            <label
              key={option.label}
              className="access-permissions-radio-group__option"
            >
              <Radio
                name={'access_permissions'}
                value={option.value}
                checked={isChecked}
                onChange={() => {
                  setValue(option.value);
                  onChange(option.value);
                }}
              />

              <div>
                <span className="access-permissions-radio-group__text">
                  {option.label}
                </span>

                {option.description && (
                  <p className="access-permissions-radio-group__description">
                    {option.description}
                  </p>
                )}
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
}
