import {
  FleetAccessSharingOverviewSection,
  UserAccessSharingOverviewSection,
} from 'app/components/access-sharing';
import { CloseIcon } from 'app/components/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IAccessSharingUserData } from 'types/IAccessSharingUser';
import './FleetSharingOverviewView.less';
import { SubmitButton } from 'app/components/form-fields';
import {
  BackActionContentLayout,
  CardContentLayout,
} from 'app/components/layouts';

type Props = {
  fleets: any[];
  selectedFleetId?: string;
  selectedUsers: Map<string, IAccessSharingUserData>;
  isLoading: boolean;
  setSelectedUsers: (users: Map<string, IAccessSharingUserData>) => void;
  onEditFleet: () => void;
  onEditUser: (email: string) => void;
  onAddUser: () => void;
  onBack: () => void;
  onSubmit: () => void;
};

export default function FleetSharingOverviewView({
  fleets,
  selectedFleetId,
  selectedUsers,
  isLoading,
  setSelectedUsers,
  onEditFleet,
  onEditUser,
  onAddUser,
  onBack,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const selectedFleet = useMemo(() => {
    // TODO For testing purposes. It takes the first fleet in case of absence of a selectedFleetId
    return fleets.find(fleet => fleet.id === selectedFleetId) ?? fleets[0];
  }, [fleets]);

  function handleDeleteUser(email: string) {
    const updatedUsers = new Map(selectedUsers);
    updatedUsers.delete(email);
    setSelectedUsers(updatedUsers);
  }

  return (
    <BackActionContentLayout
      isLoading={isLoading}
      backIcon={CloseIcon}
      onBack={onBack}
    >
      <CardContentLayout
        title={t('access_sharing_overview')}
        subtitle={t('information_correct_and_complete')}
      >
        <FleetAccessSharingOverviewSection
          fleet={selectedFleet}
          className="fleet-sharing-overview-view__fleet-access-sharing-overview-section"
          isLoading={isLoading}
          onEdit={onEditFleet}
        />

        <UserAccessSharingOverviewSection
          users={Array.from(selectedUsers.values())}
          className="fleet-sharing-overview-view__user-access-sharing-overview-section"
          isLoading={isLoading}
          onEdit={onEditUser}
          onDelete={handleDeleteUser}
          onAdd={onAddUser}
        />

        <SubmitButton disabled={isLoading} onClick={onSubmit}>
          {t('send_invitations')}
        </SubmitButton>
      </CardContentLayout>
    </BackActionContentLayout>
  );
}
