import clsx from 'clsx';
import React, { ButtonHTMLAttributes } from 'react';
import { IconButton } from '../IconButton';
import './NavigationIconButton.less';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export default function NavigationIconButton({ children, ...props }: Props) {
  return (
    <IconButton
      {...props}
      withShadow
      className={clsx('navigation-icon-button__icon-button', props.className)}
    >
      {children}
    </IconButton>
  );
}
