import { Dialog } from '@progress/kendo-react-dialogs';
import { EquipmentSelect } from 'app/components/access-sharing';
import { ErrorModal } from 'app/components/ErrorModal';
import { FleetNameTextField } from './components/FleetNameTextField';
import ill_warning from 'assets/images/ill_warning.svg';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import './CreateFleetView.less';
import { SubmitButton } from 'app/components/form-fields';
import {
  BackActionContentLayout,
  CardContentLayout,
} from 'app/components/layouts';

interface Props {
  equipment: IEquipmentEntry[];
  selectedFleetName?: string;
  selectedEquipmentIds: Set<string>;
  setSelectedFleetName: (name: string) => void;
  setSelectedEquipmentIds: (ids: Set<string>) => void;
  onBack: () => void;
  onNextStep: () => void;
}

// TODO Where do they come from? Is there a way to include it into IEquipmentEntry?
const sharedIds = new Set(['0650d2d7-840c-4f78-b26a-69a16af4263c']);
const inFleetIds = new Set(['0bc5a412-1b21-41f8-a849-761f5633d302']);

export default function CreateFleetView({
  equipment,
  selectedFleetName,
  selectedEquipmentIds,
  setSelectedFleetName,
  setSelectedEquipmentIds,
  onBack,
  onNextStep,
}: Props) {
  const { t } = useTranslation();

  const [
    isSelectAtLeastOneItemDialogOpen,
    setIsSelectAtLeastOneItemDialogOpen,
  ] = useState(false);
  // TODO Replace with RHF or rework the form validation approach
  const [fleetNameError, setFleetNameError] = React.useState('');

  const isAllEquipmentDisabled = useMemo(
    () =>
      equipment.every(
        equipment =>
          sharedIds.has(equipment.id) || inFleetIds.has(equipment.id),
      ),
    [equipment],
  );

  function handleNextStep() {
    if (!selectedFleetName) {
      setFleetNameError(t('field_is_required'));
      return;
    }

    if (!selectedEquipmentIds.size) {
      setIsSelectAtLeastOneItemDialogOpen(true);
      return;
    }

    onNextStep();
  }

  return (
    <>
      <BackActionContentLayout onBack={onBack}>
        <CardContentLayout
          title={t('create_fleet')}
          subtitle={t('create_fleet_subtitle')}
        >
          <FleetNameTextField
            selectedFleetName={selectedFleetName}
            isAllEquipmentDisabled={isAllEquipmentDisabled}
            fleetNameError={fleetNameError}
            className="create-fleet-view__fleet-name-text-field"
            setSelectedFleetName={setSelectedFleetName}
            clearFieldError={() => setFleetNameError('')}
          />

          <EquipmentSelect
            equipment={equipment}
            selectedEquipmentIds={selectedEquipmentIds}
            setSelectedEquipmentIds={setSelectedEquipmentIds}
          />

          {!isAllEquipmentDisabled && (
            <SubmitButton onClick={handleNextStep}>
              <div className="create-fleet-view__continue-btn-content">
                {!selectedEquipmentIds.size ? (
                  t('save_fleet')
                ) : (
                  <>
                    {t('save_fleet_and_continue')}

                    <span className="create-fleet-view__equipment-selected-text">
                      {t('no_equipment_selected', {
                        no: selectedEquipmentIds.size,
                      })}
                    </span>
                  </>
                )}
              </div>
            </SubmitButton>
          )}
        </CardContentLayout>
      </BackActionContentLayout>

      {isSelectAtLeastOneItemDialogOpen && (
        <Dialog className="modal-center modal-error modal-remove-item modal-fulltext">
          <ErrorModal
            icon={ill_warning}
            title={t('select_at_least_1_item')}
            class="select-items-modal"
            onClose={() => setIsSelectAtLeastOneItemDialogOpen(false)}
          />
        </Dialog>
      )}
    </>
  );
}
