import * as React from 'react';
import { useTranslation } from 'react-i18next';
import AboutHeaderImg from 'assets/images/img_header_about@2x.png';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';

export function AboutPage() {
  const { t } = useTranslation();
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  // mount
  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);
  return (
    <div>
      <div
        className="model-title-box"
        style={{ backgroundImage: `url(${AboutHeaderImg})` }}
      >
        <div className="model-title about-title">
          <div className="model-description">
            {t('webApp_version').replace(
              '{{app_version}}',
              process.env.VERSION || '1.0.0',
            )}
          </div>
          <h2>{t('app_name')}</h2>
        </div>
      </div>
      <div className="box-content">
        <p>{t('kubota_is_looking_for_ways')}</p>
        <div className="separator-m"></div>
        <p>{t('the_myKubota_app')}</p>
        <ul className="list list-square">
          <li>{t('keep_track_of')}</li>
          <li>{t('access_and_browse')}</li>
          <li>{t('conduct_general_upkeep')}</li>
          <li>{t('find_locate_and_save')}</li>
        </ul>
        {/* <p>
          <span className="k-icon k-i-stop" /> {t('keep_track_of')}
        </p>
        <p>
          <span className="k-icon k-i-stop" /> {t('access_and_browse')}
        </p>
        <p>
          <span className="k-icon k-i-stop" /> {t('conduct_general_upkeep')}
        </p>
        <p>
          <span className="k-icon k-i-stop" /> {t('find_locate_and_save')}
        </p> */}
      </div>
    </div>
  );
}
