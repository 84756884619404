import * as React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Menu,
  MenuItem,
} from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProfileClickableMenuItem } from './ProfileClickableMenuItem';
import ClickOutside from 'app/widgets/ClickOutside';
import { chevron_down as DownArrow } from 'app/widgets/SVGs';
import { ic_account_language as IconLanguage } from 'app/widgets/SVGs';
import { ic_account_support as IconSupport } from 'app/widgets/SVGs';
import { ic_account as AccountImg } from 'app/widgets/SVGs';

interface ISingedOutComponent {}

export function SingedOutComponent(props: ISingedOutComponent) {
  const { t, i18n } = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isLanguageExpanded, setIsLanguageExpanded] = React.useState(false);
  const [language, setLanguage] = React.useState('');
  const navigate = useNavigate();

  const onNavigateClicked = item => {
    setIsExpanded(false);
    setIsLanguageExpanded(false);
    switch (item.item.data) {
      case 'language':
        setIsExpanded(true);
        setIsLanguageExpanded(() => !isLanguageExpanded);
        break;
      case 'report':
        window.open(
          'https://app.smartsheet.com/b/form/a6e3eaf309c74aa7a4dc5375cc603baa',
          '_blank',
        );
        break;
    }
  };

  const changeLanguage = (selectedValue: string) => {
    const selectedLanguage = selectedValue;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage);
  };

  React.useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage != null) {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  return (
    <>
      <ClickOutside
        onClickOutside={() => {
          setIsExpanded(false);
          setIsLanguageExpanded(false);
        }}
      >
        <>
          <span onClick={() => setIsExpanded(!isExpanded)}>
            <span className="avatar-circle">
              <span className="avatar-icon">
                <span>
                  <AccountImg />
                </span>
              </span>
              <span className="avatar-down-arrow">
                <DownArrow />
              </span>
            </span>
          </span>
          {isExpanded && (
            <Card className="top-card top-card-account-settings top-card-account-settings-logged-out">
              <CardHeader>
                <CardTitle>{t('access_account')}</CardTitle>
                <CardSubtitle>{t('sign_in_header')}</CardSubtitle>
              </CardHeader>
              <CardBody>
                <Button onClick={() => navigate('/login')}>
                  {t('sign_in')}
                </Button>
                <Button
                  onClick={() => navigate('/createAccount')}
                  themeColor="primary"
                >
                  {t('create_account')}
                </Button>
                <Menu vertical onSelect={onNavigateClicked}>
                  <MenuItem
                    data="language"
                    render={() => (
                      <ProfileClickableMenuItem icon={<IconLanguage />}>
                        <div className="language-component language-menu-dropdown">
                          <span>
                            <span>{t('language')}</span>
                            <span className="selected-language">
                              {language.toLocaleUpperCase().slice(0, 2)}
                            </span>
                          </span>
                          {isLanguageExpanded && (
                            <Card className="top-card top-card-language-setting language-menu-dropdown-options">
                              <div className="">
                                <span
                                  onClick={() => {
                                    changeLanguage('en');
                                  }}
                                >
                                  {t('language_EN')}
                                </span>
                                <span
                                  onClick={() => {
                                    changeLanguage('fr');
                                  }}
                                >
                                  {t('language_FR')}
                                </span>
                              </div>
                            </Card>
                          )}
                        </div>
                      </ProfileClickableMenuItem>
                    )}
                  />
                  <MenuItem
                    data="report"
                    render={() => (
                      <ProfileClickableMenuItem icon={<IconSupport />}>
                        {t('kubota_report_problem')}
                      </ProfileClickableMenuItem>
                    )}
                  />
                </Menu>
              </CardBody>
            </Card>
          )}
        </>
      </ClickOutside>
    </>
  );
}
