import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { useLocation, useNavigate } from 'react-router';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Card, CardBody } from '@progress/kendo-react-layout';
import ClickOutside from 'app/widgets/ClickOutside';
import SearchIcon from 'assets/images/ic_search.svg';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import ChevronRightIcon from 'assets/images/chevron_right.svg';
import { ic_close_small_black as BlackCloseIcon } from 'app/widgets/SVGs';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import { ic_filter as FilterIcon } from 'app/widgets/SVGs';
import { ic_close_small as NormalCloseIcon } from 'app/widgets/SVGs';
import { getDefaultThumbnail } from '../Equipment/utils';
import { Button } from '@progress/kendo-react-buttons';
import { CacheContext } from 'app/components/ReactContexts/cacheContext';

export function ResourcesPage(props: any) {
  const [categoriesList, setCategoriesList] = React.useState<Array<any>>();
  const [allCategoriesList, setAllCategoriesList] =
    React.useState<Array<any>>();
  const [compatibleModels, setCompatibleModels] = React.useState<Array<any>>();
  const [allModelsList, setAllModelsList] = React.useState<Array<any>>();
  const [searchValue, setSearchValue] = React.useState('');
  const [isFiltered, setIsFiltered] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isFilterExpanded, setIsFilterExpanded] = React.useState(false);
  const [recentlyViewedList, setRecentlyViewedList] = React.useState<any>();

  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  const { getApiModels } = React.useContext(CacheContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const modelFilter = (location.state as { modelFilter: string })?.modelFilter;
  const commonParentCategories = (
    location.state as { commonParentCategories: number[] }
  )?.commonParentCategories;
  const isDefalutPage = !(
    commonParentCategories &&
    (location.state as { compatibleModels: any[] }).compatibleModels &&
    modelFilter
  );

  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  const getApiModelsRef = React.useRef(0);

  React.useEffect(() => {
    (async () => {
      setCategoriesList(undefined);
      setAllCategoriesList(undefined);
      setAllModelsList(undefined);
      setCompatibleModels(undefined);
      const newNumber = getApiModelsRef.current + 1;
      getApiModelsRef.current = newNumber;
      const apiModel = await getApiModels();
      if (getApiModelsRef.current !== newNumber) {
        return;
      }
      if (apiModel.categories) {
        const mainCategories = apiModel.categories.filter(
          c => c.parentId == undefined,
        );

        const sortedCategoriesData =
          mainCategories &&
          mainCategories.some(category => category.index === undefined)
            ? [...mainCategories].sort((a, b) => a.name.localeCompare(b.name))
            : [...mainCategories].sort((a, b) => a.index - b.index);
        const sortedAllCategoriesData = [...apiModel.categories].sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        setCategoriesList(sortedCategoriesData);
        setAllCategoriesList(sortedAllCategoriesData);
      }
      if (apiModel.models) {
        const sortedModelsData = [...apiModel.models].sort((a, b) =>
          a.model.localeCompare(b.model),
        );
        setAllModelsList(sortedModelsData);
      }

      if (!isDefalutPage) {
        setCategoriesList(
          apiModel.categories.filter(category =>
            commonParentCategories.includes(category.id),
          ),
        );
        setCompatibleModels(
          (location.state as { compatibleModels: any }).compatibleModels,
        );
      } else {
        addRecentlyViewedData();
      }
    })();
  }, [i18n.language]);

  React.useEffect(() => {
    if (location.state) {
      if (allModelsList && compatibleModels) {
        if (isFiltered) {
          setAllModelsList(
            allModelsList.filter(m => m.discontinuedDate == undefined),
          );
          setCompatibleModels(
            compatibleModels.filter(cm => cm.discontinuedDate == undefined),
          );
        }
      }
    }
  }, [isFiltered]);

  const addRecentlyViewedData = () => {
    const recentlyViewedData = window.sessionStorage.getItem(
      'recentlyViewedModels',
    );
    if (recentlyViewedData) {
      const recentlyViewedItems = JSON.parse(recentlyViewedData);
      setRecentlyViewedList(recentlyViewedItems);
    }
  };

  function getChildrenCount(categoryId) {
    let count = 0;
    if (
      compatibleModels &&
      compatibleModels?.some(m => m.categoryId == categoryId)
    ) {
      return compatibleModels.filter(m => m.categoryId == categoryId).length;
    } else {
      let childrenCategories = allCategoriesList?.filter(
        c => c.parentId == categoryId,
      );
      if (childrenCategories) {
        for (let i = 0; i < childrenCategories?.length; i++) {
          count += getChildrenCount(childrenCategories[i].id);
        }
      }
    }
    return count;
  }

  const handleFilterChange = event => {
    setIsFiltered(event.target.value);
  };

  const allResourcesContent = (
    <>
      <div className="title-with-search">
        <div className="title-box">
          <h1>{t('title_resources')}</h1>
          <div>{t('browse_machines_attachments')}</div>
        </div>
        <div className="search-box">
          <ClickOutside
            className="search-with-options"
            onClickOutside={() => {
              setIsExpanded(false);
            }}
          >
            <div className="site-search open">
              <span className="search-icon">
                <img alt="Search" src={SearchIcon}></img>
              </span>

              <div className="search-input">
                <Input
                  id="resourcesSearch"
                  name="resourcesSearch"
                  value={searchValue}
                  placeholder={t('search_for', { s: t('title_resources') })}
                  onChange={newValue => {
                    setSearchValue(newValue.value);
                  }}
                  onClick={() => setIsExpanded(!isExpanded)}
                />
                {searchValue && (
                  <span
                    className="search-icon-close"
                    onClick={() => {
                      setSearchValue('');
                    }}
                  >
                    <BlackCloseIcon />
                  </span>
                )}
              </div>
            </div>

            <div className="search-options">
              {isExpanded && (
                <Card>
                  <CardBody>
                    {allModelsList?.some(
                      c =>
                        c.model
                          .toLowerCase()
                          .includes(searchValue.toLocaleLowerCase()) ||
                        (c.modelDescription &&
                          c.modelDescription
                            .toLowerCase()
                            .includes(searchValue.toLocaleLowerCase())),
                    ) ? (
                      _.map(
                        allModelsList.filter(
                          c =>
                            c.model
                              .toLowerCase()
                              .includes(searchValue.toLocaleLowerCase()) ||
                            (c.modelDescription &&
                              c.modelDescription
                                .toLowerCase()
                                .includes(searchValue.toLocaleLowerCase())),
                        ),
                        model => {
                          return (
                            <div
                              key={model.model}
                              onClick={() => navigate('/models/' + model.model)}
                            >
                              {model.model}
                            </div>
                          );
                        },
                      )
                    ) : (
                      <div className="not-found-message">
                        {t('no_result_for', { s: searchValue })}
                      </div>
                    )}
                  </CardBody>
                </Card>
              )}
            </div>
          </ClickOutside>
        </div>
      </div>

      <div>
        <div className="row-0 resources-boxes">
          {categoriesList &&
            _.map(categoriesList, resource => {
              return (
                <div
                  className="col-sm-4 col-xxs-6 r-box-wrapper"
                  key={resource.id}
                  onClick={() => navigate('/resources/' + resource.id)}
                >
                  <div className="r-img">
                    <img src={resource.iconUrl} />
                  </div>

                  <h4>{resource.name}</h4>
                </div>
              );
            })}
        </div>
      </div>

      {recentlyViewedList != undefined && (
        <>
          <div className="separator-xl"></div>
          <h3>{t('recently_viewed')}</h3>
          <div className="recently-view">
            <div className="row">
              {_.isArray(recentlyViewedList) &&
              recentlyViewedList.length > 0 ? (
                _.map(recentlyViewedList, item => (
                  <div
                    className="col-sm-3 col-xs-6"
                    key={item.model}
                    onClick={() => navigate('/models/' + item.model)}
                  >
                    <div className="recently-view_item">
                      <img
                        src={
                          item.modelIconUrl || getDefaultThumbnail(item.type)
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = getDefaultThumbnail(item.type);
                        }}
                        alt=""
                      ></img>
                      <h4 title={item.model}>{item.model}</h4>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className="col-sm-3 col-xs-6"
                  key={recentlyViewedList.model}
                  onClick={() =>
                    navigate('/models/' + recentlyViewedList.model)
                  }
                >
                  <div className="recently-view_item">
                    <img
                      src={
                        recentlyViewedList.modelIconUrl ||
                        getDefaultThumbnail(recentlyViewedList.type)
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = getDefaultThumbnail(
                          recentlyViewedList.type,
                        );
                      }}
                      alt=""
                    ></img>
                    <h4 title={recentlyViewedList.model}>
                      {recentlyViewedList.model}
                    </h4>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );

  const comparibleMachinesContent = (
    <>
      <span
        className="back-button back-button-on-layout fixed-on-desktop"
        onClick={() => {
          navigate(-1);
        }}
      >
        <BackIcon />
      </span>

      <div className="title-with-search">
        <div className="title-box">
          <h1>{t('title_machines')}</h1>
        </div>
        <div className="search-box">
          <ClickOutside
            className="search-with-options"
            onClickOutside={() => {
              setIsExpanded(false);
            }}
          >
            <div className="site-search open">
              <span className="search-icon">
                <img alt="Search" src={SearchIcon}></img>
              </span>

              <div className="search-input">
                <Input
                  id="resourcesSearch"
                  name="search"
                  value={searchValue}
                  placeholder={t('search_for', { s: t('title_resources') })}
                  onChange={newValue => {
                    setSearchValue(newValue.value);
                  }}
                  onClick={() => setIsExpanded(!isExpanded)}
                />
                {searchValue && (
                  <span
                    className="search-icon-close"
                    onClick={() => {
                      setSearchValue('');
                    }}
                  >
                    <BlackCloseIcon />
                  </span>
                )}
              </div>
            </div>

            <div className="search-options">
              {isExpanded && (
                <Card>
                  <CardBody>
                    {compatibleModels?.some(c =>
                      c.model
                        .toLowerCase()
                        .includes(searchValue.toLocaleLowerCase()),
                    ) ? (
                      _.map(
                        compatibleModels.filter(c =>
                          c.model
                            .toLowerCase()
                            .includes(searchValue.toLocaleLowerCase()),
                        ),
                        model => {
                          return (
                            <div
                              key={model.model}
                              onClick={() => navigate('/models/' + model.model)}
                            >
                              {model.model}
                            </div>
                          );
                        },
                      )
                    ) : (
                      <div className="not-found-message">
                        {t('no_result_for', { s: searchValue })}
                      </div>
                    )}
                  </CardBody>
                </Card>
              )}
            </div>
          </ClickOutside>
        </div>
        <div className="filter-box">
          <ClickOutside
            className="filter"
            onClickOutside={() => {
              setIsFilterExpanded(false);
            }}
          >
            <Button
              className="button-filter"
              onClick={() => setIsFilterExpanded(!isFilterExpanded)}
            >
              <FilterIcon />
              {t('equipment_tree_btn_filter')}
            </Button>

            <div className="filter-options">
              {isFilterExpanded && (
                <Card>
                  <CardBody>
                    <div>{t('equipment_tree_filter_discontinued')}</div>
                    <Switch
                      checked={isFiltered}
                      onChange={handleFilterChange}
                      onLabel=""
                      offLabel=""
                    />
                  </CardBody>
                </Card>
              )}
            </div>
          </ClickOutside>
        </div>
      </div>

      <div>
        {modelFilter != null && (
          <Button className="button-tag-close without-close">
            {t('model_compatible_with', { s: modelFilter })}
          </Button>
        )}
        {isFiltered && (
          <Button
            className="button-tag-close"
            onClick={() => setIsFiltered(false)}
          >
            {t('equipment_tree_filter_discontinued')}
            <NormalCloseIcon />
          </Button>
        )}
      </div>

      <div>
        <div className="add-equipment-boxes resources-style">
          {categoriesList &&
            _.map(categoriesList, resource => {
              return (
                <span key={resource.id}>
                  <div className="equipment-content-actions section">
                    <div
                      className="add-equipment-item"
                      onClick={() => {
                        navigate('/resources/' + resource.id, {
                          state: {
                            lastCommonParent: resource.id,
                            modelFilter: modelFilter,
                          },
                        });
                      }}
                    >
                      <div className="product-length">
                        <span>{getChildrenCount(resource.id)}</span>
                      </div>

                      <div className="add-equipment-box-img">
                        <img
                          src={
                            resource.iconUrl ||
                            getDefaultThumbnail(
                              modelFilter != null
                                ? 'attachment'
                                : resource.name,
                              modelFilter != null
                                ? 'attachment'
                                : resource.name,
                            )
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = getDefaultThumbnail(
                              modelFilter != null
                                ? 'attachment'
                                : resource.name,
                              modelFilter != null
                                ? 'attachment'
                                : resource.name,
                            );
                          }}
                          alt=""
                        ></img>
                      </div>

                      <h3>{resource.name}</h3>
                      <img
                        className="arrow-links_icon arrow-links_icon-right"
                        src={ChevronRightIcon}
                      ></img>
                    </div>
                  </div>
                </span>
              );
            })}
        </div>
      </div>
    </>
  );

  if (!allModelsList || !categoriesList) {
    return (
      <div className="loading-full-page">
        <div className="loading">
          <div className="loading_text">{t('LoadingResources...')}</div>
          <div className="loading_icon">
            <div className="css-icon"></div>
          </div>
        </div>
      </div>
    );
  }

  return isDefalutPage ? allResourcesContent : comparibleMachinesContent;
}
