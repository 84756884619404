import { Dialog } from '@progress/kendo-react-dialogs';
import { ajax } from 'ajax/ajax';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';
import ClickOutside from 'app/widgets/ClickOutside';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import React from 'react';
import { useParams } from 'react-router-dom';
import { tagEvent } from 'utils/ga';
import { InhibitRestartLocallyDisabled } from './Content/InhibitRestartLocallyDisabled';
import { InhibitRestartProcessingCommand } from './Content/InhibitRestartProcessingCommand';
import { InhibitRestartStatus } from './Content/InhibitRestartStatus';
import { getInhibitRestartMetadata } from './utils';

interface InhibitRestartProps {
  onClose: Function;
}

export function InhibitRestart(props: InhibitRestartProps) {
  const params = useParams();

  const { equipmentListData: data, setEquipmentListData: setData } =
    React.useContext(EquipmentListContext);
  const { addErrorModal } = React.useContext(GlobalErrorContext);
  const [equipmentInhibitRestartMetadata, setEquipmentInhibitRestartMetadata] =
    React.useState(getInhibitRestartMetadata(data.selectedEquipment));
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    hasPendingCommand,
    isEnabled,
    isEnableing,
    commandStatusToSend,
    isLocallyDisabled,
  } = equipmentInhibitRestartMetadata;

  const refreshEquipmentData = () => {
    ajax
      .get(`${ajax.appBaseUrl}/api/user/equipment/${params.id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        setData(oldData => ({
          ...oldData,
          selectedEquipment: response.data,
          equipmentList: oldData.equipmentList?.map(eq => {
            if (eq.id === params.id) {
              return response.data;
            }
            return eq;
          }),
        }));
        setEquipmentInhibitRestartMetadata(
          getInhibitRestartMetadata(response.data),
        );
      })
      .catch(error => {
        addErrorModal();
      });
  };

  const onSendCommand = (isCancel: boolean) => {
    setIsLoading(true);
    const status = isCancel
      ? data.selectedEquipment?.telematics?.restartInhibitStatus
          ?.equipmentStatus
      : commandStatusToSend;
    ajax
      .put(
        `${ajax.appBaseUrl}/api/user/equipment/${params.id}/restartInhibit?status=${status}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(response => {
        setData(oldData => ({
          ...oldData,
          selectedEquipment: response.data[0],
          equipmentList: oldData.equipmentList?.map(eq => {
            if (eq.id === params.id) {
              return response.data[0];
            }
            return eq;
          }),
        }));
        setEquipmentInhibitRestartMetadata(
          getInhibitRestartMetadata(response.data[0]),
        );
        tagEvent(`inhibit_restart_${status}`);
        setIsConfirmDialogVisible(false);
        setIsLoading(false);
      })
      .catch(() => {
        setIsConfirmDialogVisible(false);
        setIsLoading(false);
        addErrorModal();
      });
  };

  return (
    <>
      <Dialog className="modal-equipment modal-fixed-button modal-equipment-hours">
        <ClickOutside
          onClickOutside={() => !isConfirmDialogVisible && props.onClose()}
        >
          <div className="row height-100">
            <div className="col-sm-12">
              <div className="modal-container relative">
                <span
                  className={
                    'back-button fixed-on-desktop inhibit-restart-back-button'
                  }
                  onClick={() => props.onClose()}
                >
                  <BackIcon />
                </span>
                {isLocallyDisabled ? (
                  <InhibitRestartLocallyDisabled
                    equipmentModel={data.selectedEquipment?.model}
                    equipmentIdentifierType={
                      data.selectedEquipment?.identifierType
                    }
                    equipmentPinOrSerial={data.selectedEquipment?.pinOrSerial}
                  />
                ) : hasPendingCommand ? (
                  <InhibitRestartProcessingCommand
                    equipmentNickName={
                      data.selectedEquipment?.nickName ||
                      data.selectedEquipment?.model
                    }
                    refreshEquipmentData={refreshEquipmentData}
                    isEnableing={isEnableing}
                    isLoading={isLoading}
                    onSendCommand={onSendCommand}
                    equipmentModel={data.selectedEquipment?.model}
                    equipmentIdentifierType={
                      data.selectedEquipment?.identifierType
                    }
                    equipmentPinOrSerial={data.selectedEquipment?.pinOrSerial}
                  />
                ) : (
                  <InhibitRestartStatus
                    isEnabled={isEnabled}
                    isLoading={isLoading}
                    isConfirmDialogVisible={isConfirmDialogVisible}
                    setIsConfirmDialogVisible={setIsConfirmDialogVisible}
                    equipmentModelIconUrl={data.selectedEquipment?.modelIconUrl}
                    equipmentNickName={
                      data.selectedEquipment?.nickName ||
                      data.selectedEquipment?.model
                    }
                    onSendCommand={onSendCommand}
                    equipmentModel={data.selectedEquipment?.model}
                    equipmentIdentifierType={
                      data.selectedEquipment?.identifierType
                    }
                    equipmentPinOrSerial={data.selectedEquipment?.pinOrSerial}
                  />
                )}
              </div>
            </div>
          </div>
        </ClickOutside>
      </Dialog>
    </>
  );
}
