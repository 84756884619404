import React from 'react';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import { Trans, useTranslation } from 'react-i18next';
import EmptyStatePicture from 'assets/images/ill_empty_equipment.png';
import { Button } from '@progress/kendo-react-buttons';
import { Link } from 'react-router-dom';

interface INoMachinesEmptyState {
  onClose: () => void;
}

function NoMachinesEmptyState(props: INoMachinesEmptyState) {
  const { t } = useTranslation();
  return (
    <div className="layout-info-panel access-sharing-no-machines">
      <div className="layout-info-panel_container">
        <span
          className="back-button back-button-on-layout fixed-on-desktop"
          onClick={() => props.onClose()}
        >
          <BackIcon />
        </span>
        <div className="box-content">
          <div className="content-container">
            <div className="layout-info-panel-header">
              <h1 className="no-machines-title">
                {t('no_machines_empty_state_title')}
              </h1>
            </div>
            <div>
              <div className="no-machines-text-container">
                <div className="no-machines-text1">
                  {t('no_machines_empty_state_text1')}
                </div>
                <div>{t('no_machines_empty_state_text2')}</div>
              </div>
              <div className="no-machines-image">
                <img src={EmptyStatePicture} alt="" />
              </div>
              <div>
                <div className="no-machines-bolded">
                  {t('steps_to_get_started')}
                </div>
                <div>
                  <ul className="list list-square">
                    <li>
                      <Trans
                        i18nKey={'add_your_equipment_no_machines'}
                        components={{
                          equipment_link: <Link to={'/equipments'} />,
                        }}
                      ></Trans>
                    </li>
                    <li>{t('request_access')}</li>
                    <li>{t('share_with_others')}</li>
                  </ul>
                </div>
              </div>
              <div>
                <Button
                  className="form-button"
                  themeColor="primary"
                  onClick={() => {
                    props.onClose();
                  }}
                  style={{ width: '100%' }}
                >
                  {t('ok')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoMachinesEmptyState;
