import React from 'react';
import './Loader.less';
import clsx from 'clsx';

interface Props {
  className?: string;
}

export default function Loader({ className }: Props) {
  return <div className={clsx('loader', className)} />;
}
