import { NotificationGroup } from '@progress/kendo-react-notification';
import { LargeBanner } from 'app/components/LargeBanner';
import { NotificationContext } from 'app/components/ReactContexts/notificationContext';
import * as React from 'react';

export const WelcomePopUp = () => {
  const anchor = React.useRef(null);

  const { notifications } = React.useContext(NotificationContext);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        localStorage.setItem('justLoggedOn', 'false');
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    <>
      {localStorage.getItem('justLoggedOn') === 'true' && <LargeBanner />}
      <NotificationGroup className="alert position" ref={anchor}>
        {notifications?.map(notification => notification.element)}
      </NotificationGroup>
    </>
  );
};
