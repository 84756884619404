import { Dialog } from '@progress/kendo-react-dialogs';
import { Success } from 'app/components/Notifications';
import { NotificationContext } from 'app/components/ReactContexts/notificationContext';
import { AccessConfiguration } from 'app/components/access-sharing';
import { CurrentUserAccessCard } from 'app/components/access-sharing/cards';
import { SharedUserAccess } from 'app/components/access-sharing/cards/SharedUserAccessCard/types';
import { SubmitButton } from 'app/components/form-fields';
import { BackActionDialogContentLayout } from 'app/components/layouts';
import useClickOutside from 'app/hooks/useClickOutside';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IAccessSharingPeriodType,
  IAccessSharingPermissionType,
} from 'types/IAccessSharingUser';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import './ChangeUserPermissionsDialog.less';

interface Props {
  equipment: IEquipmentEntry;
  userAccess: SharedUserAccess;
  onClose: () => void;
}

export default function ChangeUserPermissionsDialog({
  equipment,
  userAccess,
  onClose,
}: Props) {
  const { t } = useTranslation();
  // TODO needs to be replaces with useTryMutate when API is ready
  const { addNotification } = React.useContext(NotificationContext);

  // Note: there's a point in creating a wrapper around the Dialog and adding an onClickOutside prop
  useClickOutside(onClose);

  const [permission, setPermission] = useState(
    IAccessSharingPermissionType.Viewer,
  );
  const [period, setPeriod] = useState(IAccessSharingPeriodType.Continuous);

  // TODO for testing purposes. Need to be refactored when API is ready
  const initialStartDate = userAccess.startTime
    ? new Date(userAccess.startTime)
    : null;
  const initialEndDate = userAccess.endTime
    ? new Date(userAccess.endTime)
    : null;

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const { name, email, role } = userAccess;

  return (
    <Dialog className="modal-container">
      <BackActionDialogContentLayout
        className="change-user-permissions-dialog"
        onBack={onClose}
      >
        <h2 className="change-user-permissions-dialog__title">
          {t('access_sharing.machine_details.change_users_permissions')}
        </h2>

        <div className="change-user-permissions-dialog__model">
          <span className="change-user-permissions-dialog__model-label">
            {t('equipment_model')}
          </span>
          {equipment.model}
          <span className="change-user-permissions-dialog__model-label">
            {t('equipment_serial')}
          </span>
          {equipment.serial}
        </div>

        <CurrentUserAccessCard
          name={name}
          email={email}
          role={role}
          className="change-user-permissions-dialog__current-user-access-card"
        />

        <AccessConfiguration
          initialPermission={permission}
          initialPeriod={period}
          initialStartDate={startDate}
          initialEndDate={endDate}
          onChangePermission={setPermission}
          onChangePeriod={setPeriod}
          onChangeStartDate={setStartDate}
          onChangeEndDate={setEndDate}
        />

        <SubmitButton
          onClick={() => {
            addNotification(
              <Success text={'User permissions updated'} />,
              6000,
            );
            onClose();
          }}
        >
          {t('save')}
        </SubmitButton>
      </BackActionDialogContentLayout>
    </Dialog>
  );
}
