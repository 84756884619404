import * as React from 'react';

function checkImageURL(url, callback) {
  var img = new Image();
  img.onload = function () {
    callback(true);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src = url;
}

export function useImageUrlWithPlaceholder(
  url: string,
  placeHolderUrl: string,
) {
  const [isValidImage, setIsValidImage] = React.useState<boolean | undefined>(
    undefined,
  );
  React.useEffect(() => {
    if (url) {
      checkImageURL(url, valid => {
        if (!valid) {
          setIsValidImage(false);
        } else {
          setIsValidImage(true);
        }
      });
    }
  }, [url]);

  if (!url) {
    return placeHolderUrl;
  }

  return isValidImage === false ? placeHolderUrl : url;
}
