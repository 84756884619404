import { Button } from '@progress/kendo-react-buttons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ill_signout as SignOutIcon } from 'app/widgets/SVGs';

interface ISignOutDialogProps {
  close: () => void;
  signOut: () => void;
}

export function SignOutDialog(props: ISignOutDialogProps) {
  const { t } = useTranslation();

  return (
    <div className="remove-item-dialog-content">
      <SignOutIcon />
      <h3>{t('signout_dialog_message')}</h3>
      <div className="row">
        <div className="col-xxs-6">
          <Button onClick={props.close}>{t('cancel')}</Button>
        </div>
        <div className="col-xxs-6">
          <Button onClick={props.signOut} themeColor="error">
            {t('sign_out_title')}
          </Button>
        </div>
      </div>
    </div>
  );
}
