import { Button } from '@progress/kendo-react-buttons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@progress/kendo-react-dialogs';
import { ajax } from 'ajax/ajax';

interface IProps {
  closeRequestSubmittedDialog: () => void;
  closeConfirmationDialog: () => void;
  title: string;
  message: string;
}

export function RequestSubmitedDialog(props: IProps) {
  const { t } = useTranslation();

  const handleClick = () => {
    props.closeRequestSubmittedDialog();
    props.closeConfirmationDialog();

    ajax.logOut();
  };

  return (
    <>
      <Dialog className="modal-center modal-request-submitted modal-fulltext">
        <div className="remove-item-dialog-content">
          <h3>{props.title}</h3>
          <p>{props.message}</p>
          <div className="row centered">
            <div className="col-xxs-6">
              <Button onClick={() => handleClick()} themeColor="error">
                {t('ok')}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
