import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { ajax } from 'ajax/ajax';
import {
  EquipmentListContext,
  IEquipmentListData,
} from 'app/components/ReactContexts/equipmentListContext';
import { getLocale } from 'locales/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { tagEvent } from 'utils/ga';
import { formatNumberWithDecimals } from 'utils/general';

interface IEditEngineHours {
  closeDialog: () => void;
}

export const getEngineHours = (data: IEquipmentListData) => {
  if (
    data.selectedEquipment!.telematics?.cumulativeOperatingHours ||
    data.selectedEquipment!.telematics?.cumulativeOperatingHours == 0
  ) {
    return data.selectedEquipment!.telematics?.cumulativeOperatingHours;
  }
  if (
    data.selectedEquipment!.userEnteredEngineHours ||
    data.selectedEquipment!.userEnteredEngineHours == 0
  ) {
    return data.selectedEquipment!.userEnteredEngineHours;
  }
  return 0;
};

export function EditEngineHours(props: IEditEngineHours) {
  const { equipmentListData: data, setEquipmentListData: setData } =
    React.useContext(EquipmentListContext);
  const { t, i18n } = useTranslation();

  const [validInput, setValidInput] = React.useState(true);
  const [engineHours, setEngineHours] = React.useState<string>();
  const [engineHoursError, setEngineHoursError] = React.useState('');

  const validateEngineHours = () => {
    if (!engineHours) {
      setEngineHoursError(t('field_is_required'));
      return false;
    }
    return true;
  };

  const equipmentData = data.selectedEquipment as IEquipmentEntry;

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setEngineHours(
      data.selectedEquipment?.hasTelematics
        ? formatNumberWithDecimals(getEngineHours(data), 1).replace(
            '.',
            t('hours_separator'),
          )
        : formatNumberWithDecimals(getEngineHours(data), 2).replace(
            '.',
            t('hours_separator'),
          ),
    );
  }, [getEngineHours(data)]);

  React.useEffect(() => {
    const handlePopState = () => props.closeDialog();

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [getEngineHours(data)]);

  const saveChangesButtonClicked = () => {
    if (!validateEngineHours()) {
      return;
    }
    setIsLoading(true);
    ajax
      .put(
        `${ajax.appBaseUrl}/api/user/equipment/engineHours`,
        JSON.stringify({
          id: equipmentData.id,
          engineHours: engineHours?.replace(t('hours_separator'), '.'),
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        setData(oldData => ({
          ...oldData,
          selectedEquipment: {
            ...(oldData.selectedEquipment as IEquipmentEntry),
            userEnteredEngineHours: Number(
              engineHours?.replace(t('hours_separator'), '.'),
            ),
          },
          equipmentList: oldData.equipmentList!.map((equip, index) => {
            if (index === oldData.selectedIndex) {
              return {
                ...equip,
                userEnteredEngineHours: Number(
                  engineHours?.replace(t('hours_separator'), '.'),
                ),
              };
            }
            return equip;
          }),
        }));
        tagEvent('edit_engine_hours');
        props.closeDialog();
      })
      .catch(e => {
        setEngineHoursError(e?.response?.data?.errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleInputChange = inputValue => {
    var regex = /^[0-9.]*$/;
    var convertedEngineHours = inputValue;
    const locale = getLocale();
    if (locale === 'fr-CA' || locale === 'en-CA' || i18n.language === 'fr') {
      if (inputValue.includes('.')) return;
      convertedEngineHours = inputValue.replace(t('hours_separator'), '.');
    }

    if (!regex.test(convertedEngineHours)) {
      setValidation(false, t('engine_hours_incorrect'));
      return;
    }

    const hasDecimal = convertedEngineHours.includes('.');
    const maxLength = hasDecimal ? 10 : 7;
    if ((convertedEngineHours.length || 0) > maxLength) {
      return;
    }

    const numericValue = Number(convertedEngineHours);
    if (isNaN(numericValue) || (!hasDecimal && numericValue > 9999999)) {
      setValidation(false, t('engine_hours_incorrect'));
    } else {
      if (hasDecimal) {
        const [, decimalPart] = convertedEngineHours.split('.');
        if (
          decimalPart &&
          ((decimalPart.length > 1 && data.selectedEquipment?.hasTelematics) ||
            (decimalPart.length > 2 &&
              !data.selectedEquipment?.hasTelematics)) &&
          convertedEngineHours.length > (engineHours?.toString() || '').length
        ) {
          return;
        }
      }
      setEngineHours(inputValue);
      setEngineHoursError('');
    }
  };

  const setValidation = (isValid, errorMessage) => {
    setValidInput(isValid);
    setEngineHoursError(errorMessage);
  };

  return (
    <Dialog className="modal-form">
      <div className="modal-container relative">
        <span
          className="back-button absolute"
          onClick={() => {
            props.closeDialog();
          }}
        >
          <svg
            id="ic_nav_back"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <rect id="Box" width="48" height="48" fill="none" />
            <path
              id="ic_nav_up"
              d="M16.465,8.482H1.483"
              transform="translate(15.518 15.518)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <path
              id="Path_470"
              data-name="Path 470"
              d="M23.676,16.018,16.2,23.748l7.477,7.73"
              transform="translate(0.324 0.252)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </svg>
        </span>
        <>
          <h2>{t('maintenance_edit_engine_hours')}</h2>
          <div className="form-caption">
            <div className="inline-label-text">
              <span className="label">{t('equipment_model')}</span>
              <span className="text">{equipmentData.model}</span>
            </div>
            <div className="inline-label-text">
              <span className="label">
                {equipmentData.identifierType === 'Pin'
                  ? t('equipment_pin')
                  : t('equipment_serial')}
              </span>
              <span className="text">{equipmentData.pinOrSerial}</span>
            </div>
          </div>

          <div className={`form-group ${engineHoursError && 'error'}`}>
            <div className="form-content">
              <div className="form-label">
                <span>{t('hours')}</span>
              </div>
              <div className="form-control">
                <Input
                  id="engineHours"
                  name="engineHours"
                  valid={validInput}
                  placeholder={t('enter_the_new_equipment_hours')}
                  value={engineHours}
                  onChange={event => {
                    const inputValue = event.target.value?.toString();
                    handleInputChange(inputValue);
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className="form-error">
              {engineHoursError && (
                <Error id={'engineHoursError'}>{engineHoursError}</Error>
              )}
            </div>
          </div>
          <Button
            themeColor="primary"
            onClick={saveChangesButtonClicked}
            disabled={
              isLoading ||
              !!(
                !Number(engineHours?.replace(t('hours_separator'), '.')) &&
                Number(engineHours?.replace(t('hours_separator'), '.')) !== 0 &&
                engineHours
              )
            }
          >
            {isLoading ? (
              <div className="loading_icon loading_icon-btn">
                <div className="css-icon"></div>
              </div>
            ) : (
              t('save_changes')
            )}
          </Button>
        </>
      </div>
    </Dialog>
  );
}
