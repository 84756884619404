import { initializeApp } from '@firebase/app';
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
  isSupported,
} from '@firebase/messaging';

export const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const isFirebaseSupported = isSupported;

export const getFirebaseToken = (swReg, fcmReg) => {
  return getToken(messaging, {
    vapidKey: process.env.FIREBASE_VAPID_KEY,
    serviceWorkerRegistration: swReg,
  })
    .then(currentToken => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        fcmReg(currentToken);
      } else {
        console.log(
          'No registration token available. Request permission to generate one.',
        );
        // shows on the UI that permission is required
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export const deleteFirebaseToken = deleteTokenCallback => {
  deleteToken(messaging)
    .then(result => {
      if (result) {
        deleteTokenCallback();
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
