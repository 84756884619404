import axios, { AxiosPromise, AxiosRequestConfig, AxiosStatic } from 'axios';
import { getLocale } from 'locales/utils';
import { tagEvent } from 'utils/ga';

axios.defaults.headers!.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers!.common['Version'] = '2023_R03';
// axios.defaults.headers!.common[
//   'X-User-Agent'
// ] = `myKubotaWeb/${process.env.VERSION} - ${process.env.OAUTH_CLIENT_ID}`;

export class Http {
  private instance = axios;
  private access_token = null as null | string;
  private expires_in = null as null | string;
  private expires_at = null as null | number;
  private refresh_token = null as null | string;
  public appBaseUrl = process.env.APP_API_URL as string;
  public uiApiBaseUrl = process.env.UI_API_URL as string;
  public cancelToken = axios.CancelToken.source();

  constructor(callback?: (instance: AxiosStatic) => void) {
    this.access_token = window.localStorage.getItem('kubota_access_token');
    this.expires_in = window.localStorage.getItem('kubota_expires_in');
    this.expires_at =
      parseInt(window.localStorage.getItem('kubota_expires_at') as string) ||
      null;
    this.refresh_token = window.localStorage.getItem('kubota_refresh_token');

    if (callback) {
      callback(this.instance);
    }
  }

  logOut() {
    window.localStorage.removeItem('kubota_access_token');
    window.localStorage.removeItem('kubota_expires_in');
    window.localStorage.removeItem('kubota_expires_at');
    window.localStorage.removeItem('kubota_refresh_token');
    window.location.href = '/';
  }

  async assureLogIn(): Promise<boolean> {
    if (!this.access_token) {
      if (!window.location.hash.startsWith('#/login?returnUrl=')) {
        window.location.replace(`#/login?returnUrl=${window.location.href}`);
      }
      return false;
    }

    if (new Date().getTime() > (this.expires_at || 0)) {
      this.logOut();
    }
    return true;
  }

  addAuthentification(config) {
    return {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: `Bearer ${this.access_token}`,
      },
    };
  }

  addAcceptLanguage(config) {
    const locale = getLocale();
    return {
      ...config,
      headers: {
        ...config?.headers,
        'Accept-Language': locale,
      },
    };
  }

  setLocalStorageeAndProperties(
    access_token,
    expires_in,
    refresh_token,
    username = null as null | string,
  ) {
    this.access_token = access_token;
    this.expires_in = expires_in;
    this.expires_at = new Date().getTime() + parseInt(expires_in) * 1000 - 5000;

    this.refresh_token = refresh_token;

    window.localStorage.setItem('kubota_access_token', access_token);
    window.localStorage.setItem('kubota_expires_in', expires_in);
    window.localStorage.setItem(
      'kubota_expires_at',
      this.expires_at.toString(),
    );
    window.localStorage.setItem('kubota_refresh_token', refresh_token);
    if (username) {
      window.localStorage.setItem('kubota_user_name', username);
    }
  }

  async getLogin(
    username: string,
    password: string,
    returnUrl: string,
  ): Promise<void> {
    const params = new URLSearchParams();

    params.append('username', username);
    params.append('password', password);
    params.append('grant_type', 'password');

    //To do: write the ts type
    const {
      data: { access_token, expires_in, refresh_token },
    } = (await axios.post(`${this.uiApiBaseUrl}/oauth/token`, params)) as any;

    //To do: treat errors
    this.setLocalStorageeAndProperties(
      access_token,
      expires_in,
      refresh_token,
      username,
    );

    tagEvent('login');

    window.location.href = returnUrl;
  }

  isLoggedIn() {
    return !!this.access_token;
  }

  async getTokenWithRefreshToken(): Promise<void> {
    if (!this.refresh_token) {
      throw Error('How did you get here?');
    }
    const params = new URLSearchParams();

    params.append('refresh_token', this.refresh_token);
    params.append('grant_type', 'refresh_token');

    try {
      const {
        data: { access_token, expires_in, refresh_token },
      } = (await axios.post(`${this.uiApiBaseUrl}/oauth/token`, params)) as any;

      this.setLocalStorageeAndProperties(
        access_token,
        expires_in,
        refresh_token,
      );
    } catch {
      this.logOut();
    }
  }

  async get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const isLoggedIn = await this.assureLogIn();

    if (!isLoggedIn) {
      Promise.resolve();
    }

    return axios.get(
      url,
      this.addAcceptLanguage(this.addAuthentification(config)),
    ) as Promise<T>;
  }

  getAsAnonymous<T = any>(
    url: string,
    config?: AxiosRequestConfig,
  ): AxiosPromise<T> {
    return axios.get(url, this.addAcceptLanguage(config)) as AxiosPromise;
  }

  async delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const isLoggedIn = await this.assureLogIn();

    if (!isLoggedIn) {
      Promise.resolve();
    }
    return axios.delete(url, this.addAuthentification(config)) as Promise<T>;
  }

  async putAsAnonymous<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosPromise<T>> {
    return axios.put(url, data, config) as AxiosPromise<T>;
  }

  async postAsAnonymous<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosPromise<T>> {
    return axios.post(url, data, config) as AxiosPromise<T>;
  }

  async post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosPromise<T>> {
    const isLoggedIn = await this.assureLogIn();

    if (!isLoggedIn) {
      Promise.resolve();
    }
    return axios.post(
      url,
      data,
      this.addAuthentification(config),
    ) as AxiosPromise<T>;
  }

  async patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosPromise<T>> {
    const isLoggedIn = await this.assureLogIn();

    if (!isLoggedIn) {
      Promise.resolve();
    }
    return axios.patch(
      url,
      data,
      this.addAuthentification(config),
    ) as AxiosPromise<T>;
  }

  async put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    const isLoggedIn = await this.assureLogIn();

    if (!isLoggedIn) {
      Promise.resolve();
    }

    return axios.put(url, data, this.addAuthentification(config)) as Promise<T>;
  }
}

export const ajax = new Http();
