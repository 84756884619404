import { Button } from '@progress/kendo-react-buttons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IDeleteTelematicsEquipmentErrorDialogProps {
  onClose: () => void;
}

export function DeleteTelematicsEquipmentErrorDialog(
  props: IDeleteTelematicsEquipmentErrorDialogProps,
) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="remove-item-dialog-content">
      <h3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('equipment_delete_error_title'),
          }}
        />
      </h3>
      <p>
        <span
          dangerouslySetInnerHTML={{
            __html: t('equipment_cannot_delete_error_message'),
          }}
        />
      </p>
      <div className="row buttons-wrapper">
        <div className="col-xxs-6">
          <Button onClick={props.onClose}>{t('cancel')}</Button>
        </div>
        <div className="col-xxs-6">
          <Button onClick={() => navigate('/dealers')} themeColor="primary">
            {t('contact_local_dealer')}
          </Button>
        </div>
      </div>
    </div>
  );
}
