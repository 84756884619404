import React from 'react';
import './SharedUserAccessCard.less';
import { SharedUserAccess } from 'app/components/access-sharing/cards/SharedUserAccessCard/types';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'app/components/Avatar';
import { ActionsListItem, ActionsMenu } from 'app/components/menu';
import clsx from 'clsx';

interface Props {
  userAccess: SharedUserAccess;
  actions?: ActionsListItem[];
  className?: string;
  isExpired?: boolean;
}
export default function SharedUserAccessCard({
  userAccess,
  actions,
  className,
  isExpired,
}: Props) {
  const { t } = useTranslation();

  const { name, email, role, startTime, endTime } = userAccess;
  const isAnyPeriodSet = startTime || endTime;

  return (
    <div
      className={clsx('shared_user_access_card', className, {
        shared_user_access_card_expired: isExpired,
      })}
    >
      <div className="shared_user_access_card__title">
        <Avatar name={name} />
        <div className="shared_user_access_card__info">
          <b>{name}</b>
          <span>{email}</span>
        </div>

        {!!actions?.length && <ActionsMenu actions={actions} />}
      </div>

      <div className="shared_user_access_card__content">
        <div className="shared_user_access_card__user-role">
          <span className="shared_user_access_card__label">{t('role')}</span>
          <span>{t(role.toLowerCase() || '').toUpperCase()}</span>
        </div>

        {isAnyPeriodSet && (
          <div className="shared_user_access_card__period-wrapper">
            {startTime && (
              <div className="shared_user_access_card__period">
                <span className="shared_user_access_card__label">
                  {t('start_time')}
                </span>
                <span>{startTime}</span>
              </div>
            )}

            {endTime && (
              <div className="shared_user_access_card__period">
                <span className="shared_user_access_card__label">
                  {t('end_time')}
                </span>
                <span>{endTime}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
