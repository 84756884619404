import React from 'react';
import clsx from 'clsx';
import './Card.less';

export interface Props {
  children: React.ReactNode;
  className?: string;
  withShadow?: boolean;
  withBorder?: boolean;
  disabled?: boolean;
}

export default function Card({
  children,
  className,
  withShadow = true,
  withBorder = true,
  disabled,
}: Props) {
  return (
    <div
      className={clsx(
        'card',
        {
          'card_with-shadow': withShadow,
          'card_with-border': withBorder,
          card_disabled: disabled,
        },
        className,
      )}
    >
      {children}
    </div>
  );
}
