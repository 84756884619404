import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from '@progress/kendo-react-layout';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import _ from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface IFilterCard {
  updateFilteredList: (filteredList) => void;
}

export function FilterCard(props: IFilterCard) {
  const { equipmentListData: data, setEquipmentListData: setData } =
    React.useContext(EquipmentListContext);
  const { t } = useTranslation();

  const filterTypes = [
    {
      type: 'active_fault_codes',
      filterName: t('active_fault_codes'),
    },
    { type: 'machine_running', filterName: t('machine_running') },
    { type: 'machine_in_transport', filterName: t('machine_in_transport') },
    {
      type: 'restart_inhibited',
      filterName: t('restart_inhibited'),
    },
  ];

  const handleCheck = e => {
    const { id, checked } = e.target;
    const updatedFiltersList = checked
      ? [...(data.filtersList || []), id]
      : (data.filtersList || []).filter(item => item !== id);

    setData({
      ...data,
      filtersList: updatedFiltersList,
    });
  };

  React.useEffect(() => {
    var filteredList = data.equipmentList;
    if (data.filtersList && data.filtersList.includes('active_fault_codes')) {
      filteredList = filteredList?.filter(
        f =>
          f.telematics &&
          f.telematics.faultCodes &&
          f.telematics?.faultCodes?.length > 0,
      );
    }

    if (data.filtersList && data.filtersList.includes('machine_running')) {
      filteredList = filteredList?.filter(
        f => f.telematics && f.telematics.engineRunning,
      );
    }

    if (data.filtersList && data.filtersList.includes('machine_in_transport')) {
      filteredList = filteredList?.filter(
        f => f.telematics && f.telematics.motionState == 'in-transport',
      );
    }

    if (data.filtersList && data.filtersList.includes('restart_inhibited')) {
      filteredList = filteredList?.filter(
        f =>
          f.telematics &&
          f.telematics.restartInhibitStatus != null &&
          f.telematics.restartInhibitStatus?.equipmentStatus ==
            'RestartDisabled',
      );
    }

    props.updateFilteredList(filteredList);
  }, [data.filtersList]);
  return (
    <div>
      <Card className="equipment-filter-card">
        <CardHeader>
          <CardTitle>
            {t('equipment_tree_btn_filter')}
            {data.filtersList && data.filtersList.length > 0 && (
              <span
                onClick={() => {
                  setData({
                    ...data,
                    filtersList: [],
                  });
                }}
              >
                {t('clear_filters')}
              </span>
            )}
          </CardTitle>
        </CardHeader>
        <CardBody>
          {_.map(filterTypes, f => {
            return (
              <div className="maintenance-box-element" key={f.type}>
                <label
                  className={`input-check ${
                    data.filtersList?.includes(f.type) ? 'big-weight' : ''
                  }`}
                >
                  {f.filterName}
                </label>
                <input
                  id={f.type}
                  type="checkbox"
                  onChange={handleCheck}
                  checked={Boolean(
                    data.filtersList && data.filtersList.includes(f.type),
                  )}
                />
              </div>
            );
          })}
        </CardBody>
      </Card>
    </div>
  );
}
