import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import FaultCodeIcon from 'assets/images/ic_res_fault_code-2.svg';
import ChevronRightIcon from 'assets/images/chevron_right.svg';
import PlaceHolderModel from 'assets/images/ph_resources_model_header.png';
import ManualsIcon from 'assets/images/ic_res_manuals.svg';
import MaintenanceScheduleIcon from 'assets/images/ic_res_maint_schedule.svg';
import WarrantyInformationIcon from 'assets/images/ic_res_warranty_info.svg';
import VideoResourcesIcon from 'assets/images/ic_res_video.svg';
import CompatibleMachinesIcon from 'assets/images/ic_res_compatible.svg';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import GuidesIcon from 'assets/images/ic_res_maintenance_guides.svg';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { useImageUrlWithPlaceholder } from 'app/hooks/useImageUrlWithPlaceholder';
import { CacheContext } from 'app/components/ReactContexts/cacheContext';
import { tagEvent } from 'utils/ga';

interface IModelPage {}

export function ModelPage(props: IModelPage) {
  const [categoriesList, setCategoriesList] = React.useState<Array<any>>();
  const [modelsList, setModelsList] = React.useState<Array<any>>();
  const [compatibleAttachmentsCategories, setCompatibleAttachmentsCategories] =
    React.useState<Array<any>>([]);
  const [currentModel, setCurrentModel] = React.useState<any>();
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { getApiModels } = React.useContext(CacheContext);

  const scrolableRef = React.useRef(null as null | HTMLDivElement);
  const posRef = React.useRef({ top: 0, left: 0, x: 0, y: 0 });

  const posMovementRef = React.useRef<{
    maxDistanceFromPos: number;
  }>({ maxDistanceFromPos: 0 });

  const mouseMoveHandler = function (e) {
    // How far the mouse has been moved
    const dx = e.clientX - posRef.current.x;
    const dy = e.clientY - posRef.current.y;

    if (
      Math.abs(dx) + Math.abs(dy) >
      posMovementRef.current.maxDistanceFromPos
    ) {
      posMovementRef.current.maxDistanceFromPos = Math.abs(dx) + Math.abs(dy);
    }
    // Scroll the element
    scrolableRef.current!.scrollTop = posRef.current.top - dy;
    scrolableRef.current!.scrollLeft = posRef.current.left - dx;
  };

  const mouseUpHandler = function () {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    scrolableRef.current!.style.cursor = 'grab';
    scrolableRef.current!.style.removeProperty('user-select');
  };

  const mouseDownHandler = function (e) {
    posRef.current = {
      // The current scroll
      left: scrolableRef.current!.scrollLeft,
      top: scrolableRef.current!.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    posMovementRef.current = { maxDistanceFromPos: 0 };

    scrolableRef.current!.style.cursor = 'grabbing';
    scrolableRef.current!.style.userSelect = 'none';

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  function getChildrenCount(categoryId) {
    let count = 0;
    if (
      modelsList &&
      modelsList?.some(
        m =>
          m.categoryId == categoryId &&
          currentModel.compatibleAttachments.includes(m.model),
      )
    ) {
      return modelsList.filter(
        m =>
          m.categoryId == categoryId &&
          currentModel.compatibleAttachments.includes(m.model),
      ).length;
    } else {
      let childrenCategories = categoriesList?.filter(
        c => c.parentId == categoryId,
      );
      if (childrenCategories) {
        for (let i = 0; i < childrenCategories?.length; i++) {
          count += getChildrenCount(childrenCategories[i].id);
        }
      }
    }
    return count;
  }

  function findCommonSet(compatibleAttachmentsParents) {
    if (
      !compatibleAttachmentsParents ||
      compatibleAttachmentsParents.length === 0
    ) {
      return [];
    }
    const commonSet = Array.from(
      new Set(compatibleAttachmentsParents.map(item => item.parents[0])),
    );

    return commonSet;
  }

  function compatibleMachinesRedirect() {
    var compatibleModels = modelsList?.filter(
      m =>
        m.compatibleAttachments &&
        m.compatibleAttachments.includes(currentModel.model),
    );
    var compatibleAttachmentsParents: any[] = [];

    if (compatibleModels) {
      for (let i = 0; i < compatibleModels.length; i++) {
        const parents: any[] = [];
        var currentParentId = compatibleModels[i].categoryId;
        while (currentParentId !== undefined && currentParentId != null) {
          const currentParent = categoriesList?.filter(
            // eslint-disable-next-line no-loop-func
            c => c.id === currentParentId,
          )[0];
          currentParentId = currentParent?.parentId;
          parents.unshift(currentParent.id);
        }

        compatibleAttachmentsParents.push({
          model: compatibleModels[i],
          parents: parents,
        });
      }

      const commonSet = findCommonSet(compatibleAttachmentsParents);

      if (commonSet.length > 0) {
        if (commonSet.length == 1) {
          const redirectPath = `/resources/${commonSet[0]}`;
          navigate(redirectPath, {
            state: {
              lastCommonParent: null,
              modelFilter: currentModel.model,
            },
          });
        } else {
          navigate(`/resources/`, {
            state: {
              modelFilter: currentModel.model,
              compatibleModels: compatibleAttachmentsParents.map(
                item => item.model,
              ),
              commonParentCategories: commonSet,
            },
          });
        }
      } else {
        navigate('/resources/');
      }
    }
  }

  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  const getApiModelsRef = React.useRef(0);

  React.useEffect(() => {
    (async () => {
      setCategoriesList(undefined);
      setModelsList(undefined);
      const newNumber = getApiModelsRef.current + 1;
      getApiModelsRef.current = newNumber;
      const apiModel = await getApiModels();
      if (getApiModelsRef.current !== newNumber) {
        return;
      }
      const sortedModelsData = [...apiModel.models].sort((a, b) =>
        a.model.localeCompare(b.model),
      );
      const sortedCategoriesData = [...apiModel.categories].sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      setCategoriesList(sortedCategoriesData);
      setModelsList(sortedModelsData);
    })();
  }, [i18n.language]);

  React.useEffect(() => {
    const updatedCurrentModel = modelsList?.filter(
      m => m.model === params.model,
    )[0];
    setCurrentModel(updatedCurrentModel);

    const recentlyViewedData = window.sessionStorage.getItem(
      'recentlyViewedModels',
    );
    if (updatedCurrentModel) {
      if (recentlyViewedData != null && recentlyViewedData != undefined) {
        const recentlyViewedItems = JSON.parse(recentlyViewedData);
        const itemExists = _.isArray(recentlyViewedItems)
          ? recentlyViewedItems.some(
              item => item.model === updatedCurrentModel.model,
            )
          : recentlyViewedItems.model == updatedCurrentModel.model;
        if (!itemExists) {
          if (!_.isArray(recentlyViewedItems)) {
            var newList = [recentlyViewedItems];
            newList.unshift(updatedCurrentModel);
            window.sessionStorage.setItem(
              'recentlyViewedModels',
              JSON.stringify(newList),
            );
          } else {
            recentlyViewedItems.unshift(updatedCurrentModel);
            window.sessionStorage.setItem(
              'recentlyViewedModels',
              JSON.stringify(recentlyViewedItems),
            );
          }
        } else if (itemExists && _.isArray(recentlyViewedItems)) {
          const index = recentlyViewedItems.findIndex(
            item => item.model === updatedCurrentModel.model,
          );
          if (index !== -1) {
            const removedItem = recentlyViewedItems.splice(index, 1)[0];
            recentlyViewedItems.unshift(removedItem);
            window.sessionStorage.setItem(
              'recentlyViewedModels',
              JSON.stringify(recentlyViewedItems),
            );
          }
        }
      } else {
        window.sessionStorage.setItem(
          'recentlyViewedModels',
          JSON.stringify(updatedCurrentModel),
        );
      }
    }
  }, [location.pathname, modelsList]);

  React.useEffect(() => {
    if (modelsList && currentModel && currentModel.compatibleAttachments) {
      const filteredAttachments = modelsList.filter(m =>
        currentModel.compatibleAttachments.some(c => c === m.model),
      );

      for (let i = 0; i < filteredAttachments.length; i++) {
        let subcategoryId = filteredAttachments[i].categoryId;
        let currentSubcategory;
        while (!_.isNil(subcategoryId)) {
          currentSubcategory = categoriesList?.filter(
            // eslint-disable-next-line no-loop-func
            c => c.id === subcategoryId,
          )[0];
          let parentSubcategory = categoriesList?.filter(
            // eslint-disable-next-line no-loop-func
            c => c.id === currentSubcategory.parentId,
          )[0];

          if (_.isNil(parentSubcategory.parentId)) {
            subcategoryId = parentSubcategory.parentId;
          } else {
            subcategoryId = currentSubcategory.parentId;
          }
        }

        setCompatibleAttachmentsCategories(prevCategories => {
          const updatedCategories = [...prevCategories];
          if (!updatedCategories.some(c => c.id === currentSubcategory.id)) {
            updatedCategories.push(currentSubcategory);
            updatedCategories.sort((a, b) => {
              if (a.index !== b.index) {
                return a.index - b.index;
              }
              return a.name.localeCompare(b.name);
            });
          }
          return updatedCategories;
        });
      }
    }
  }, [currentModel, modelsList, categoriesList]);

  const modelUrl = useImageUrlWithPlaceholder(
    currentModel?.modelHeroUrl,
    PlaceHolderModel,
  );

  if (!modelsList) {
    return (
      <div className="loading-full-page">
        <div className="loading">
          <div className="loading_text">{t('loading...')}</div>
          <div className="loading_icon">
            <div className="css-icon"></div>
          </div>
        </div>
      </div>
    );
  } else if (!currentModel) {
    return (
      <>
        <NotFoundPage />
      </>
    );
  }

  tagEvent('view_model', { model: currentModel.model });

  return (
    <div className="relative">
      <span
        className="back-button back-button-on-layout fixed-on-desktop"
        onClick={() => {
          navigate(-1);
        }}
      >
        <BackIcon />
      </span>
      <div className="model-title-box contrast-enhanced-img">
        <span
          className="image-enh"
          style={{ backgroundImage: `url(${modelUrl})` }}
        ></span>
        <div className="model-title">
          <h2>{currentModel.model}</h2>
          {currentModel.modelDescription && (
            <div className="model-description">
              {currentModel.modelDescription}
            </div>
          )}
        </div>
      </div>

      {compatibleAttachmentsCategories &&
        compatibleAttachmentsCategories.length > 0 && (
          <div className="equipment-content-actions section-margin-top">
            <div className="row">
              <div className="col-xxs-8">
                <h4>{t('compatible_attachments')}</h4>
              </div>
              <div className="col-xxs-4">
                <Link
                  to={{ pathname: '/resources/39' }}
                  className="equipment-content-actions-viewAll"
                  onClick={e => {
                    e.preventDefault();
                    location.state = {
                      modelFilter: currentModel.model,
                    };
                    navigate('/resources/39', { state: location.state });
                  }}
                >
                  {t('attachment_slider_item_view_all')}
                </Link>
              </div>
            </div>
            <div className="attachments-slider-wrapper">
              <div className="box-for-border"></div>
              <div
                className="attachments-slider"
                ref={scrolableRef}
                onMouseDown={mouseDownHandler}
              >
                {compatibleAttachmentsCategories.map(category => (
                  <div className="attachments-image" key={category.name}>
                    {/* <img src={category.iconUrl} /> */}
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${category.iconUrl})` }}
                    ></div>
                    <div
                      className="attachments-hover-box"
                      onClick={() => {
                        if (posMovementRef.current.maxDistanceFromPos < 10) {
                          navigate('/resources/' + category.id, {
                            state: {
                              modelFilter: currentModel.model,
                            },
                          });
                        }
                        posMovementRef.current = { maxDistanceFromPos: 0 };
                      }}
                    >
                      <div
                        className="image"
                        style={{ backgroundImage: `url(${category.iconUrl})` }}
                      >
                        {/* <img src={category.iconUrl} /> */}
                      </div>
                      <div className="text">{category.name}</div>
                      <div className="number">
                        {getChildrenCount(category.id)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      <div className="row section-margin-top">
        <div className="col-sm-12">
          <div className="equipment-content-actions-title">
            <h4>{t('title_resources')}</h4>
          </div>
          <div className="arrow-links arrow-links-no-icon-border">
            {currentModel.hasFaultCodes && (
              <div
                className="arrow-links_item"
                onClick={() => {
                  navigate('/faultCodes/' + currentModel.model);
                }}
              >
                <img className="arrow-links_icon" src={FaultCodeIcon}></img>
                <span>{t('fault_code_lookup')}</span>
                <img
                  className="arrow-links_icon arrow-links_icon-right"
                  src={ChevronRightIcon}
                />
              </div>
            )}
            {currentModel.manualEntries &&
              currentModel.manualEntries.length > 0 && (
                <div
                  className="arrow-links_item"
                  onClick={() => navigate('/manual/' + currentModel.model)}
                >
                  <img className="arrow-links_icon" src={ManualsIcon}></img>
                  <span>{t('manuals')}</span>
                  <img
                    className="arrow-links_icon arrow-links_icon-right"
                    src={ChevronRightIcon}
                  />
                </div>
              )}
            {currentModel.guideUrl && (
              <div
                className="arrow-links_item"
                onClick={() => navigate('/guides/' + currentModel.model)}
              >
                <img className="arrow-links_icon" src={GuidesIcon}></img>
                <span>{t('maintenance_guides')}</span>
                <img
                  className="arrow-links_icon arrow-links_icon-right"
                  src={ChevronRightIcon}
                />
              </div>
            )}
            {currentModel.hasMaintenanceSchedules && (
              <div
                className="arrow-links_item"
                onClick={() =>
                  navigate(location.pathname + '/maintenanceSchedule')
                }
              >
                <img
                  className="arrow-links_icon"
                  src={MaintenanceScheduleIcon}
                />
                <span>{t('maintenance_schedules')}</span>
                <img
                  className="arrow-links_icon arrow-links_icon-right"
                  src={ChevronRightIcon}
                />
              </div>
            )}

            {currentModel.warrantyUrl && currentModel.type == 'machine' && (
              <div
                className="arrow-links_item"
                onClick={() => {
                  window.open(currentModel.warrantyUrl, '_blank');
                }}
              >
                <img
                  className="arrow-links_icon"
                  src={WarrantyInformationIcon}
                />
                <span>{t('warranty_info')}</span>
                <img
                  className="arrow-links_icon arrow-links_icon-right"
                  src={ChevronRightIcon}
                />
              </div>
            )}

            {modelsList &&
              modelsList.some(
                m =>
                  m.compatibleAttachments &&
                  m.compatibleAttachments.includes(currentModel.model),
              ) && (
                <div
                  className="arrow-links_item"
                  onClick={() => compatibleMachinesRedirect()}
                >
                  <img
                    className="arrow-links_icon"
                    src={CompatibleMachinesIcon}
                  />
                  <span>{t('compatible_machines')}</span>
                  <img
                    className="arrow-links_icon arrow-links_icon-right"
                    src={ChevronRightIcon}
                  />
                </div>
              )}

            {currentModel.videoEntries && currentModel.videoEntries.length > 0 && (
              <div
                className="arrow-links_item"
                onClick={() =>
                  navigate('/videoResources/' + currentModel.model)
                }
              >
                <img className="arrow-links_icon" src={VideoResourcesIcon} />
                <span>{t('instructional_videos_title')}</span>
                <img
                  className="arrow-links_icon arrow-links_icon-right"
                  src={ChevronRightIcon}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
