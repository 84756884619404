import { Button } from '@progress/kendo-react-buttons';
import { UserAccessSharingOverviewCard } from './components/UserAccessSharingOverviewCard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IAccessSharingUserData } from 'types/IAccessSharingUser';
import './UserAccessSharingOverviewSection.less';
import clsx from 'clsx';
import { NotificationContext } from 'app/components/ReactContexts/notificationContext';
import { USERS_TO_INVITE_LIMIT } from './constants';

interface Props {
  users: IAccessSharingUserData[];
  className?: string;
  isLoading?: boolean;
  usersToInviteLimit?: number;
  onEdit?: (email: string) => void;
  onDelete?: (email: string) => void;
  onAdd: () => void;
}

export default function UserAccessSharingOverviewSection({
  users,
  className,
  isLoading,
  usersToInviteLimit = USERS_TO_INVITE_LIMIT,
  onEdit,
  onDelete,
  onAdd,
}: Props) {
  const { t } = useTranslation();
  const { addSnackbarNotification } = React.useContext(NotificationContext);

  const hasReachedUsersInviteLimit = users.length >= usersToInviteLimit;

  function handleAddAnotherUser() {
    if (hasReachedUsersInviteLimit) {
      // TODO think about moving it into a separate component <Snackbar type='success' icon={} text="" children={} />
      addSnackbarNotification(
        <div className="snackbar-container">
          <span>{t('access_sharing.snackbars.users-limit-invite')}</span>
        </div>,
        6000,
      );
    } else {
      onAdd();
    }
  }

  return (
    <section
      className={clsx('user-access-sharing-overview-section', className)}
    >
      <h3 className="user-access-sharing-overview-section__title">
        {t('sharing_with')}
      </h3>

      <div className="user-access-sharing-overview-section__cards">
        {users.map(user => {
          const { email } = user;
          const isSingleUser = users.length <= 1;

          return (
            <UserAccessSharingOverviewCard
              key={email}
              user={user}
              isLoading={isLoading}
              onEdit={onEdit ? () => onEdit(email) : undefined}
              onDelete={
                !isSingleUser && onDelete ? () => onDelete(email) : undefined
              }
            />
          );
        })}
      </div>

      <div className="user-access-sharing-overview-section__add-user-btn-block">
        <Button
          disabled={isLoading}
          onClick={handleAddAnotherUser}
          className={clsx(
            'user-access-sharing-overview-section__add-user-btn',
            {
              'user-access-sharing-overview-section__add-user-btn_has-reached-limit':
                hasReachedUsersInviteLimit,
            },
          )}
        >
          {t('add_another_user')}
        </Button>
      </div>
    </section>
  );
}
