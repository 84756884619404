import NoMachinesEmptyState from 'app/components/access-sharing/EquipmentAndFleets/NoMachinesEmptyState';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import {
  AccessSharingFlowProvider,
  useAccessSharingFlowContext,
} from 'app/hooks/access-sharing';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CreateFleetStepController,
  FleetSelectionStepController,
} from './components';
import {
  ACCESS_SHARING_FLEET_STEPS,
  dummyEquipmentData,
  dummyFleetsData,
} from 'app/constants/access-sharing';
import { ConfirmNavigateAwayDialog } from 'app/components/dialogs';
import useNavigationPrompt from 'app/hooks/useNavigationPrompt';
import {
  FleetAccessSharingStepController,
  FleetCreatedStepController,
  FleetSharingOverviewStepController,
} from 'app/components/access-sharing/flows';
import { useIsMobile } from 'app/hooks/useIsMobile';

export default function ShareFleetPage() {
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  const isMobile = useIsMobile();

  // TODO throw it away as soon as possible
  // Refactor layout components in the future to be more flexible
  // and avoid hacks like this.
  React.useEffect(() => {
    let classNameIndex = -1;

    if (!isMobile) {
      classNameIndex = setClassNames('layout-grey');
    }
    return () => {
      if (classNameIndex >= 0) {
        removeClassNames(classNameIndex);
        classNameIndex = -1;
      }
    };
  }, [isMobile]);

  return (
    <AccessSharingFlowProvider
      stepCount={Object.keys(ACCESS_SHARING_FLEET_STEPS).length}
      // startStep={ACCESS_SHARING_FLEET_STEPS.OVERVIEW}
    >
      <StepRenderer />
      <UnsavedChangesWarning />
    </AccessSharingFlowProvider>
  );
}

function StepRenderer() {
  const navigate = useNavigate();
  const { stepper } = useAccessSharingFlowContext();
  const { step } = stepper;

  function handleBack() {
    navigate('/access');
  }

  if (!dummyEquipmentData.length) {
    return <NoMachinesEmptyState onClose={() => {}} />;
  }

  if (!dummyFleetsData.length) {
    // TODO redirect
  }

  //TODO redirect to FLEET_CREATION if !hasAtLeastOneFleet

  switch (step) {
    case ACCESS_SHARING_FLEET_STEPS.FLEET_SELECTION:
      return (
        <FleetSelectionStepController
          fleets={dummyFleetsData}
          onBack={handleBack}
        />
      );
    case ACCESS_SHARING_FLEET_STEPS.FLEET_CREATION:
      return (
        <CreateFleetStepController
          equipment={dummyEquipmentData}
          hasAtLeastOneFleet={dummyFleetsData.length >= 1}
          onBack={handleBack}
        />
      );
    case ACCESS_SHARING_FLEET_STEPS.FLEET_CREATED:
      return <FleetCreatedStepController onBack={handleBack} />;
    case ACCESS_SHARING_FLEET_STEPS.ACCESS_SHARING:
      return <FleetAccessSharingStepController />;
    case ACCESS_SHARING_FLEET_STEPS.OVERVIEW:
      return (
        <FleetSharingOverviewStepController
          fleets={dummyFleetsData}
          onBack={handleBack}
        />
      );
    default:
      return null;
  }
}

function UnsavedChangesWarning() {
  const { isSubmitted, isEditing, selectedUsers, selectedFleetId, stepper } =
    useAccessSharingFlowContext();
  const { step } = stepper;

  const isDirty = selectedUsers.size > 0 || !!selectedFleetId;
  const isStepWithPrompt =
    step === ACCESS_SHARING_FLEET_STEPS.FLEET_CREATED ||
    step === ACCESS_SHARING_FLEET_STEPS.OVERVIEW;

  const { showPrompt, confirmNavigation, cancelNavigation } =
    useNavigationPrompt(
      isDirty && (isStepWithPrompt || isEditing) && !isSubmitted,
    );

  return (
    <ConfirmNavigateAwayDialog
      open={showPrompt}
      onConfirm={confirmNavigation}
      onClose={cancelNavigation}
    />
  );
}
