import * as React from 'react';
import { ajax } from 'ajax/ajax';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import SearchIcon from 'assets/images/ic_search.svg';
import InfoIcon from 'assets/images/ic_nav_info.svg';
import InfoSpn from 'assets/images/ill_codes_spn.svg';
import InfoSpnFR from 'assets/images/ill_codes_spn_fr.svg';
import InfoFmi from 'assets/images/ill_code_fmi.svg';
import InfoFmiFR from 'assets/images/ill_code_fmi_fr.svg';
import ChevronRightIcon from 'assets/images/chevron_right.svg';
import EmptyFaultCodes from 'assets/images/ill_empty_faultcodes.svg';
import { useNavigate, useParams } from 'react-router';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import { ic_close_small_black as CloseIcon } from 'app/widgets/SVGs';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { CacheContext } from 'app/components/ReactContexts/cacheContext';
import i18next from 'i18next';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';

export function ResourcesFaultCodesPage() {
  const [searchValue, setSearchValue] = React.useState('');
  const [spnSearchValue, setSpnSearchValue] = React.useState('');
  const [fmiSearchValue, setFmiSearchValue] = React.useState('');
  const [selectedFaultCode, setSelectedFaultCode] = React.useState<any>();
  const [searchValues, setSearchValues] = React.useState<Array<any>>();
  const [selectedErrorType, setSelectedErrorType] = React.useState('all');
  const anchorSpn = React.useRef<HTMLImageElement | null>(null);
  const anchorFmi = React.useRef<HTMLImageElement | null>(null);
  const [debouncedSearchValue, setDebouncedSearchValue] = React.useState('');
  const [showCodeLookup, setShowCodeLookup] = React.useState(true);
  const [paramsExist, setParamsExist] = React.useState(true);
  const [language, setLanguage] = React.useState(i18next.resolvedLanguage);

  const navigate = useNavigate();
  const params = useParams();
  const { t, i18n } = useTranslation();
  const { getApiModels } = React.useContext(CacheContext);
  const { addErrorModal } = React.useContext(GlobalErrorContext);
  const { equipmentListData } = React.useContext(EquipmentListContext);

  const selectedEquipment = equipmentListData.selectedEquipment;
  const activeCodes = selectedEquipment?.telematics?.faultCodes;
  const model =
    selectedEquipment && params.id != undefined
      ? selectedEquipment.model
      : params.model;

  const equipmentFilter = equipmentListData
    ? equipmentListData.equipmentList?.find(
        equipment => equipment.id === params.id,
      )?.id
    : params.id;

  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // prettier-ignore
  const infoSpnMap = {
    "en": InfoSpn,
    "fr": InfoSpnFR,
  };

  // prettier-ignore
  const infoFmiMap = {
    "en": InfoFmi,
    "fr": InfoFmiFR,
  };

  React.useEffect(
    () => setLanguage(i18next.resolvedLanguage),
    [i18next.resolvedLanguage],
  );

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      setParamsExist(true);
      const apiModel = await getApiModels();
      if (apiModel.models && !apiModel.models.some(c => c.model == model)) {
        setParamsExist(false);
      }
      if (
        (params.id != undefined && params.id != equipmentFilter) ||
        (params.model && params.model != model)
      ) {
        setParamsExist(false);
      }
      setIsLoading(false);
    })();
  }, [i18n.language]);

  // mount
  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  React.useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchValue]);

  const [searchValueLoading, setSearchValueLoading] = React.useState(false);

  const [falutCodeMobileOpened, setfalutCodeMobileOpened] =
    React.useState(false);

  React.useEffect(() => {
    if (searchValue != '') {
      (async () => {
        setSearchValueLoading(true);
        setSearchValues([]);
        try {
          const response = await ajax.getAsAnonymous(
            `${ajax.appBaseUrl}/api/faultCode/${model}?errorType=${selectedErrorType}&code=${searchValue}`,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            },
          );

          if (response.status === 200) {
            setSearchValues(response.data?.faultCodes);
          }
        } catch (ex: any) {
          if (ex.response.status !== 404) {
            addErrorModal();
          }
        } finally {
          setSearchValueLoading(false);
        }
      })();
    } else {
      setSearchValues(undefined);
    }
  }, [debouncedSearchValue]);

  const formatDateTime = date => {
    const inputDate = new Date(date);

    const formattedDate = inputDate.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

    const formattedTime = inputDate.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return `${formattedDate} | ${formattedTime}`;
  };

  React.useEffect(() => {
    if (equipmentFilter) {
      setShowCodeLookup(false);
    }
  }, [equipmentFilter]);

  if (!paramsExist) {
    return <NotFoundPage />;
  }

  if (isLoading) {
    return (
      <div className="loading-full-page">
        <div className="loading">
          <div className="loading_text">{t('loading')}...</div>
          <div className="loading_icon">
            <div className="css-icon"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={equipmentFilter ? 'container' : ''}>
        <div
          className={`relative ${
            falutCodeMobileOpened ? 'fault-code-opened' : 'fault-code-closed'
          }`}
        >
          <span
            className="back-button back-button-on-layout fixed-on-desktop"
            onClick={() => {
              navigate(-1);
            }}
          >
            <BackIcon />
          </span>
          <div className="box-content fault-codes box-content-full-height">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="fault-code-name">
                  {selectedEquipment?.nickName || model}
                </h3>
                <h2>{t('fault_code_screen_title')}</h2>
                <div className="separator-m"></div>
                {equipmentFilter && (
                  <>
                    <Button
                      className="button-fault-codes-tab"
                      themeColor={!showCodeLookup ? 'primary' : null}
                      onClick={() => {
                        setShowCodeLookup(false);
                        setSelectedErrorType('all');
                        setSearchValue('');
                        setSelectedFaultCode(null);
                        setfalutCodeMobileOpened(false);
                        setSpnSearchValue('');
                        setFmiSearchValue('');
                      }}
                    >
                      {t('active_codes')}
                    </Button>
                    <Button
                      className="button-fault-codes-tab"
                      themeColor={showCodeLookup ? 'primary' : null}
                      onClick={() => {
                        setShowCodeLookup(true);
                        setSelectedErrorType('all');
                        setSearchValue('');
                        setSelectedFaultCode(null);
                        setfalutCodeMobileOpened(false);
                        setSpnSearchValue('');
                        setFmiSearchValue('');
                      }}
                    >
                      {t('code_lookup')}
                    </Button>
                    <div className="separator-l"></div>
                  </>
                )}
                {showCodeLookup ? (
                  <>
                    <div>
                      <Button
                        className="button-tabs"
                        themeColor={
                          selectedErrorType === 'all' ? 'primary' : null
                        }
                        onClick={() => {
                          setSelectedErrorType('all');
                          setSearchValue('');
                          setSelectedFaultCode(null);
                          setfalutCodeMobileOpened(false);
                          setSpnSearchValue('');
                          setFmiSearchValue('');
                        }}
                      >
                        {t('fault_mode_all')}
                      </Button>
                      <Button
                        className="button-tabs"
                        themeColor={
                          selectedErrorType === 'dtc' ? 'primary' : null
                        }
                        onClick={() => {
                          setSelectedErrorType('dtc');
                          setSearchValue('');
                          setSelectedFaultCode(null);
                          setfalutCodeMobileOpened(false);
                          setSpnSearchValue('');
                          setFmiSearchValue('');
                        }}
                      >
                        {t('fault_mode_e_code')}
                      </Button>
                      <Button
                        className="button-tabs"
                        themeColor={
                          selectedErrorType === 'j1939' ? 'primary' : null
                        }
                        onClick={() => {
                          setSelectedErrorType('j1939');
                          setSearchValue('');
                          setSelectedFaultCode(null);
                          setfalutCodeMobileOpened(false);
                          setSpnSearchValue('');
                          setFmiSearchValue('');
                        }}
                      >
                        {t('fault_mode_j1939')}
                      </Button>
                    </div>
                    {selectedErrorType == 'all' ? (
                      <div className="search-box">
                        <span className="search-with-options">
                          <div className="site-search search-with-before-text">
                            <span className="search-icon">
                              <img alt="Search" src={SearchIcon}></img>
                            </span>
                            <div className="search-input">
                              <Input
                                id="searchAll"
                                name="searchAll"
                                value={searchValue}
                                placeholder={`${t(
                                  'search_all_fault_codes',
                                )}...`}
                                onChange={newValue => {
                                  setSearchValue(newValue.value);
                                  setfalutCodeMobileOpened(false);
                                }}
                              />
                              {searchValue && (
                                <span
                                  className="search-icon-close"
                                  onClick={() => {
                                    setSearchValue('');
                                    setSelectedFaultCode(null);
                                    setfalutCodeMobileOpened(false);
                                  }}
                                >
                                  <CloseIcon />
                                </span>
                              )}
                            </div>
                          </div>
                        </span>
                      </div>
                    ) : selectedErrorType == 'dtc' ? (
                      <div className="search-box">
                        <span className="search-with-options">
                          <div className="site-search search-with-before-text">
                            <span className="search-icon">
                              <img alt="Search" src={SearchIcon}></img>
                            </span>
                            <div className="search-input">
                              <span className="before-text">
                                {t('fault_e')}
                              </span>
                              <Input
                                id="searchECode"
                                name="searchECode"
                                value={searchValue}
                                placeholder={`${t('search_e_fault_codes')}...`}
                                onChange={newValue => {
                                  setSearchValue(newValue.value);
                                  setfalutCodeMobileOpened(false);
                                }}
                              />
                              {searchValue && (
                                <span
                                  className="search-icon-close"
                                  onClick={() => {
                                    setSearchValue('');
                                    setSelectedFaultCode(null);
                                    setfalutCodeMobileOpened(false);
                                  }}
                                >
                                  <CloseIcon />
                                </span>
                              )}
                            </div>
                          </div>
                        </span>
                      </div>
                    ) : (
                      <div>
                        <div className="search-box search-box-with-popover">
                          <span className="search-with-options">
                            <div className="site-search search-with-before-text">
                              <span className="search-icon">
                                <img alt="Search" src={SearchIcon}></img>
                              </span>
                              <div className="search-input">
                                <span className="before-text">
                                  {t('fault_spn')}
                                </span>
                                <Input
                                  id="searchSPN"
                                  name="searchSPN"
                                  value={spnSearchValue}
                                  placeholder={`${t(
                                    'search_spn_fault_codes',
                                  )}...`}
                                  onChange={newValue => {
                                    setSpnSearchValue(newValue.value);
                                    setfalutCodeMobileOpened(false);
                                    newValue.value != ''
                                      ? setSearchValue(
                                          newValue.value +
                                            '-' +
                                            (fmiSearchValue != ''
                                              ? fmiSearchValue
                                              : '*'),
                                        )
                                      : fmiSearchValue == ''
                                      ? setSearchValue('')
                                      : setSearchValue(
                                          '*-' +
                                            (fmiSearchValue != ''
                                              ? fmiSearchValue
                                              : '*'),
                                        );
                                  }}
                                />

                                {spnSearchValue && (
                                  <span
                                    className="search-icon-close"
                                    onClick={() => {
                                      setSpnSearchValue('');
                                      setfalutCodeMobileOpened(false);
                                      fmiSearchValue != ''
                                        ? setSearchValue(`*-${fmiSearchValue}`)
                                        : setSearchValue('');
                                      setSelectedFaultCode(null);
                                    }}
                                  >
                                    <CloseIcon />
                                  </span>
                                )}
                              </div>
                            </div>
                          </span>

                          <div className="popover">
                            <img
                              id="spnInfo"
                              alt="InfoSpn"
                              src={InfoIcon}
                              ref={anchorSpn}
                              className="popover-toggle"
                            ></img>
                            <div className="popover-options">
                              <div className="popover-arrow-box">
                                <img
                                  alt="Spn"
                                  src={infoSpnMap[language] || InfoSpn}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="search-box search-box-with-popover">
                          <span className="search-with-options">
                            <div className="site-search search-with-before-text">
                              <span className="search-icon">
                                <img alt="Search" src={SearchIcon}></img>
                              </span>
                              <div className="search-input">
                                <span className="before-text">
                                  {t('fmi_fault')}
                                </span>
                                <Input
                                  id="searchFMI"
                                  name="searchFMI"
                                  value={fmiSearchValue}
                                  placeholder={`${t(
                                    'search_fmi_fault_codes',
                                  )}...`}
                                  onChange={newValue => {
                                    setFmiSearchValue(newValue.value);
                                    setfalutCodeMobileOpened(false);
                                    newValue.value != ''
                                      ? setSearchValue(
                                          (spnSearchValue != ''
                                            ? spnSearchValue
                                            : '*') +
                                            '-' +
                                            newValue.value,
                                        )
                                      : spnSearchValue == ''
                                      ? setSearchValue('')
                                      : setSearchValue(
                                          (spnSearchValue != ''
                                            ? spnSearchValue
                                            : '*') + '-*',
                                        );
                                  }}
                                />
                                {fmiSearchValue && (
                                  <span
                                    className="search-icon-close"
                                    onClick={() => {
                                      setFmiSearchValue('');
                                      setfalutCodeMobileOpened(false);
                                      spnSearchValue != ''
                                        ? setSearchValue(`${spnSearchValue}-*`)
                                        : setSearchValue('');
                                      setSelectedFaultCode(null);
                                    }}
                                  >
                                    <CloseIcon />
                                  </span>
                                )}
                              </div>
                            </div>
                          </span>
                          <div className="popover">
                            <img
                              id="fmiInfo"
                              alt="InfoFmi"
                              src={InfoIcon}
                              ref={anchorFmi}
                              className="popover-toggle"
                            ></img>
                            <div className="popover-options">
                              <div className="popover-arrow-box">
                                <img
                                  alt="Fmi"
                                  src={infoFmiMap[language] || InfoFmi}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {!searchValueLoading &&
                      searchValues &&
                      searchValues.length > 0 &&
                      searchValue && (
                        <div>
                          <h4 className="results-found-title">
                            {t('search_results')}:
                          </h4>
                          <div className="search-results-fault-codes">
                            {_.map(searchValues, faultCode => {
                              return (
                                <div
                                  className={`fault-code-item ${
                                    selectedFaultCode &&
                                    selectedFaultCode.code === faultCode.code &&
                                    selectedFaultCode.j1939Fmi ===
                                      faultCode.j1939Fmi &&
                                    selectedFaultCode.j1939Spn ===
                                      faultCode.j1939Spn
                                      ? 'active'
                                      : ''
                                  }`}
                                  key={
                                    faultCode.errorType == 'j1939'
                                      ? `${faultCode.j1939Spn}-${faultCode.j1939Fmi}`
                                      : faultCode.code
                                  }
                                  onClick={() => {
                                    setSelectedFaultCode(faultCode);
                                    setfalutCodeMobileOpened(true);
                                  }}
                                >
                                  {faultCode.errorType == 'j1939'
                                    ? `${t('fault_mode_j1939')}: ${
                                        faultCode.j1939Spn
                                      }-${faultCode.j1939Fmi}`
                                    : `${t('fault_mode_e_code')}: ${
                                        faultCode.code
                                      }`}
                                  <span>
                                    <img src={ChevronRightIcon}></img>
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}

                    {searchValueLoading && (
                      <div>
                        <div className="loading results-found-title">
                          <div className="loading_text">{t('loading...')}</div>
                          <div className="loading_icon">
                            <div className="css-icon"></div>
                          </div>
                        </div>
                      </div>
                    )}

                    {!searchValueLoading &&
                      searchValues &&
                      searchValues.length == 0 &&
                      searchValue &&
                      debouncedSearchValue == searchValue && (
                        <div>
                          <h4 className="results-found-title">
                            {t('search_results')}:
                          </h4>
                          <div className="no-results-fc">
                            {t('no_result_for', { s: searchValue })}
                          </div>
                        </div>
                      )}

                    {!searchValueLoading &&
                      (searchValue.length === 0 ||
                        debouncedSearchValue.length === 0) && (
                        <div>
                          <h4 className="results-found-title">
                            {t('type_in_searchbar_to_start')}
                          </h4>
                        </div>
                      )}
                  </>
                ) : (
                  <>
                    {activeCodes && activeCodes.length > 0 ? (
                      <div className="search-results-fault-codes search-results-fault-codes-active">
                        {_.map(activeCodes, faultCode => {
                          return (
                            <div
                              className={`fault-code-item ${
                                selectedFaultCode &&
                                selectedFaultCode.code === faultCode.code &&
                                selectedFaultCode.j1939Fmi ===
                                  faultCode.j1939Fmi &&
                                selectedFaultCode.j1939Spn ===
                                  faultCode.j1939Spn
                                  ? 'active'
                                  : ''
                              }`}
                              key={
                                faultCode.errorType == 'j1939'
                                  ? `${faultCode.j1939Spn}-${faultCode.j1939Fmi}`
                                  : faultCode.code
                              }
                              onClick={() => {
                                setSelectedFaultCode(faultCode);
                                setfalutCodeMobileOpened(true);
                              }}
                            >
                              {faultCode.errorType == 'j1939'
                                ? `${t('fault_mode_j1939')}: ${
                                    faultCode.j1939Spn
                                  }-${faultCode.j1939Fmi}`
                                : `${t('fault_mode_e_code')}: ${
                                    faultCode.code
                                  }`}
                              <div className="fault-code-list-date">
                                {formatDateTime(faultCode.timeReported)}
                              </div>
                              <span>
                                <img src={ChevronRightIcon}></img>
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div>
                        <img src={EmptyFaultCodes}></img>
                        <h4 className="results-found-title">
                          {t('no_active_fault_codes')}
                        </h4>
                      </div>
                    )}
                  </>
                )}
              </div>
              {selectedFaultCode && (
                <div className="col-sm-6">
                  <div className="selected-fault-code">
                    <span
                      className="back-button back-button-mobile-fault-code"
                      onClick={() => {
                        setfalutCodeMobileOpened(false);
                      }}
                    >
                      <BackIcon />
                    </span>
                    <h2>
                      {selectedFaultCode.errorType == 'j1939'
                        ? `${t('fault_mode_j1939')}: ${
                            selectedFaultCode.j1939Spn
                          }-${selectedFaultCode.j1939Fmi}`
                        : `${t('fault_mode_e_code')}: ${
                            selectedFaultCode.code
                          }`}
                    </h2>
                    <div className="separator-l"></div>
                    <div className="fault-code-texts">
                      <div>
                        <h5>{t('fault_code_description')}</h5>
                      </div>
                      <div>{selectedFaultCode.description}</div>
                      <div className="separator-l"></div>
                      <div>
                        <h5>{t('fault_code_action')}</h5>
                      </div>
                      <div>{selectedFaultCode.provisionalMeasure}</div>
                    </div>
                    <Button
                      themeColor="primary"
                      onClick={() => {
                        navigate('/dealers');
                      }}
                    >
                      {t('contact_dealer')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
