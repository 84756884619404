import React from 'react';
import './UserAvatarStack.less';
import { Avatar } from 'app/components/Avatar';

interface Props {
  usernames: string[];
  displayNameCount?: number;
}

export default function UserAvatarStack({
  usernames,
  displayNameCount = 6,
}: Props) {
  const displayedNames = usernames.slice(0, displayNameCount);
  const remainingNamesCount = usernames.length - displayedNames.length;

  return (
    <div className="user-avatar-stack">
      {displayedNames.map(username => (
        <Avatar key={username} name={username} />
      ))}

      {remainingNamesCount > 0 && <Avatar name={`+ ${remainingNamesCount}`} />}
    </div>
  );
}
