import { StepperOptions } from 'app/hooks/useStepper/types';
import { useState } from 'react';

export default function useStepper({ startStep, stepCount }: StepperOptions) {
  const [step, setStep] = useState(startStep ?? 0);

  const isFirstStep = step === 0;
  const isLastStep = step === stepCount - 1;

  return {
    step,
    isFirstStep,
    isLastStep,
    setStep,
  };
}
