import * as React from 'react';
import { chevron_right as IconRightImg } from 'app/widgets/SVGs';

interface IProfileClickableMenuItem {
  children: JSX.Element | string;
  icon: JSX.Element;
}

export function ProfileClickableMenuItem(props: IProfileClickableMenuItem) {
  return (
    <div>
      <span>
        <span className="link-icon">{props.icon}</span>
        <span className="link-text">{props.children}</span>
      </span>

      <span className="link-arrow">
        <IconRightImg />
      </span>
    </div>
  );
}
