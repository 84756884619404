import { createContext } from 'react';
import { IDealerEntry } from 'types/IDealerEntry';

export enum DealerTabs {
  Locations,
  Favorites,
}

export interface IDealerListData {
  selectedDealer?: IDealerEntry;
  dealerList?: IDealerEntry[];
  favoriteDealers?: IDealerEntry[];
  surpressZoomDataLoad?: boolean;
  panTo?: {
    position: { lat: any; lng: any };
    zoom?: number;
  };
  listIsLoading?: boolean;
  dealerTab?: DealerTabs;
}

export interface IDealerListContext {
  setDealerListData:
    | ((newValue: IDealerListData) => void)
    | ((callback: (oldValue: IDealerListData) => IDealerListData) => void);
  dealerListData: IDealerListData;
}

export const DealerListContext = createContext({} as IDealerListContext);
