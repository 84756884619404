import { ajax } from 'ajax/ajax';
import { CacheContext } from 'app/components/ReactContexts/cacheContext';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';
import _ from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { EquipmentContentEmpty } from '../EquipmentContentEmpty';

interface IEquipmentNotLoaded {}

export function EquipmentNotLoaded(props: IEquipmentNotLoaded) {
  const { equipmentListData: data, setEquipmentListData: setData } =
    React.useContext(EquipmentListContext);
  const { getApiModels } = React.useContext(CacheContext);
  const { addErrorModal } = React.useContext(GlobalErrorContext);

  const { t } = useTranslation();
  const location = useLocation();
  React.useEffect(() => {
    if (data.equipmentList) {
      return;
    }
    const abortController = new AbortController();
    (async () => {
      await getApiModels();
      try {
        if (ajax.isLoggedIn()) {
          const equipmentResponse = await ajax.get(
            `${ajax.appBaseUrl}/api/user/equipment`,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                version: 'V2021_R05',
              },
              signal: abortController.signal,
            },
          );

          const geofenceResponse = await ajax.get(
            `${ajax.appBaseUrl}/api/user/geofence`,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            },
          );

          const equipmentMachines = _.filter(
            equipmentResponse.data,
            e => e.type === 'machine',
          );
          const equipmentAttatchments = _.filter(
            equipmentResponse.data,
            e => e.type !== 'machine',
          );

          const sortedEquipmentMachines = _.orderBy(
            equipmentMachines,
            e => (e.nickName || e.model).toLowerCase() || !e.hasTelematics,
            ['asc'],
          );

          const sortedEquipmentAttatchments = _.orderBy(
            equipmentAttatchments,
            e => (e.nickName || e.model).toLowerCase(),
            ['asc'],
          );

          const orderedData = [
            ...sortedEquipmentMachines,
            ...sortedEquipmentAttatchments,
          ];

          const orderedGeofenceData = _.orderBy(
            geofenceResponse.data,
            e => e.description.toLowerCase(),
            ['asc'],
          );

          const equipmentIndex = orderedData.findIndex(
            e => e.id === location.pathname.substring(11, 47),
          );
          if (equipmentIndex >= 0) {
            const equipmentValue = orderedData[equipmentIndex];
            setData({
              ...data,
              equipmentList: orderedData,
              geofenceList: orderedGeofenceData,
              selectedIndex: equipmentIndex,
              selectedEquipment: equipmentValue,
            });
          } else {
            setData({
              ...data,
              geofenceList: orderedGeofenceData,
              equipmentList: orderedData,
              selectedIndex: 0,
              selectedEquipment: orderedData && orderedData[0],
            });
          }
        }
      } catch (ex: any) {
        if (ex.message !== 'canceled') {
          addErrorModal();
        }
      }
    })();

    return () => {
      abortController.abort();
    };
  }, []);

  if (!ajax.isLoggedIn()) {
    return <EquipmentContentEmpty />;
  } else {
    return (
      <>
        {data.equipmentList?.length === 0 ? (
          <EquipmentContentEmpty />
        ) : (
          <div className="equipment-content-empty-loading">
            <div className="loading">
              <div className="loading_text">{t('loading_equipment...')}</div>
              <div className="loading_icon">
                <div className="css-icon"></div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
