import React from 'react';
import { GlobalVideoContext } from '../ReactContexts/globalVideoContext';
import { Dialog } from '@progress/kendo-react-dialogs';
import { ReactComponent as ClosePlayerIcon } from 'assets/images/ic_player_video_close.svg';

export function GlobalVideo({ children }) {
  const videoRef = React.useRef<null | HTMLVideoElement>(null);
  const { globalVideoStateData, setGlobalVideoStateData } =
    React.useContext(GlobalVideoContext);

  React.useEffect(() => {
    const leavingPictureInPicture = () => {
      setGlobalVideoStateData({
        ...globalVideoStateData,
        isModalOpen: true,
        isPiPEnabled: false,
      });
    };

    const enteringPictureInPicture = () => {
      setGlobalVideoStateData({
        ...globalVideoStateData,
        isPiPEnabled: true,
      });
    };

    if (videoRef.current) {
      videoRef.current.addEventListener(
        'enterpictureinpicture',
        enteringPictureInPicture,
      );

      videoRef.current.addEventListener(
        'leavepictureinpicture',
        leavingPictureInPicture,
      );
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener(
          'leavepictureinpicture',
          leavingPictureInPicture,
        );
        videoRef.current.removeEventListener(
          'enterpictureinpicture',
          enteringPictureInPicture,
        );
      }
    };
  }, [videoRef.current, globalVideoStateData.video]);

  const closeVideoModal = () => {
    if (!globalVideoStateData.isPiPEnabled) {
      setGlobalVideoStateData({
        ...globalVideoStateData,
        isModalOpen: false,
        video: { url: '', name: '' },
      });
    } else {
      setGlobalVideoStateData({ ...globalVideoStateData, isModalOpen: false });
    }
  };

  return (
    <div>
      {globalVideoStateData.video.url !== '' && (
        <Dialog
          className="modal-video modal-center modal-fulltext"
          style={globalVideoStateData.isModalOpen ? {} : { display: 'none' }}
        >
          <div className="video-wrapper">
            <video
              controls
              autoPlay
              ref={videoRef}
              key={globalVideoStateData.video.url}
            >
              <span>{globalVideoStateData.video.name}</span>
              <source
                src={`${
                  globalVideoStateData.video.url
                }?v=${new Date().getTime()}`}
              ></source>
            </video>
          </div>
          <div className="close-icon" onClick={closeVideoModal}>
            <ClosePlayerIcon />
          </div>
        </Dialog>
      )}
      {children}
    </div>
  );
}
