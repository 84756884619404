import { createContext } from 'react';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { IGeofenceEntry } from 'types/IGeofenceEntry';

export enum EquipmentTabs {
  Equipment,
  Geofences,
}

export interface IPanTo {
  position?: { lat: any; lng: any };
  latLongBonds?: { latitude: any; longitude: any }[];
  zoom?: number;
}

export interface IEquipmentListData {
  selectedEquipment?: IEquipmentEntry;
  selectedGeofence?: IGeofenceEntry;
  geofenceList?: IGeofenceEntry[];
  equipmentList?: IEquipmentEntry[];
  selectedIndex?: number;
  geoAddress?: string | null;
  panTo?: IPanTo;
  isDrawing?: boolean;
  searchValue: string;
  equipmentTab?: EquipmentTabs;
  openContent?: boolean;
  filtersList?: string[];
}

export interface IEquipmentListContext {
  setEquipmentListData: (
    newValue:
      | IEquipmentListData
      | ((oldValue: IEquipmentListData) => IEquipmentListData),
  ) => void;
  equipmentListData: IEquipmentListData;
}

export const EquipmentListContext = createContext({} as IEquipmentListContext);
