import React from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import './EquipmentMenuItem.less';
import clsx from 'clsx';
import { getDefaultThumbnail } from 'app/pages/Equipment/utils';
import EvEquipmentIcon from 'assets/images/ic_equipment_ev.svg';

type Props = {
  equipment: IEquipmentEntry;
  isChecked: boolean;
  isShared: boolean;
  isInFleet: boolean;
  handleCheck: () => void;
};

// TODO I see common features between this and EquipmentAccessSharingOverviewCard components.
// Analyze all similarities and bring them into a common component.
export default function EquipmentMenuItem({
  equipment,
  isChecked,
  isShared,
  isInFleet,
  handleCheck,
}: Props) {
  const { t } = useTranslation();

  const isElectric = !!equipment.telematics?.electric;

  const defaultThumbnail = getDefaultThumbnail(
    equipment.type,
    equipment.subcategory,
  );
  const modelIconUrl = equipment.modelIconUrl || defaultThumbnail;
  const equipmentTitle = equipment.nickName || equipment.model;

  const isDisabled = isShared || isInFleet;

  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    event.currentTarget.onerror = null;
    event.currentTarget.src = defaultThumbnail;
  };

  return (
    <li
      className={clsx('equipment-menu-item', {
        'equipment-menu-item_disabled': isDisabled,
      })}
    >
      <div className="equipment-menu-item__card">
        <img
          src={modelIconUrl}
          alt="Equipment"
          className="equipment-menu-item__image"
          onError={handleImageError}
        />

        <div className="equipment-menu-item__equipment_info_block">
          <div className="equipment-menu-item__header">
            {isElectric && <img src={EvEquipmentIcon} alt="Electric model" />}

            <span className="equipment-menu-item__name" title={equipmentTitle}>
              {equipmentTitle}
            </span>

            {isDisabled && (
              <span className="equipment-menu-item__disable-reason">
                {isInFleet
                  ? t('disabled_reason_in_fleet')
                  : t('disabled_reason_shared')}
              </span>
            )}
          </div>

          <div className="equipment-menu-item__details">
            <span className="equipment-menu-item__details-label">
              {t('equipment_model')}
            </span>
            <span className="equipment-menu-item__details-value">
              {equipment.model}
            </span>

            <span className="equipment-menu-item__details-label">
              {equipment.identifierType === 'Pin'
                ? t('equipment_pin')
                : t('equipment_serial')}
            </span>
            <span
              className="equipment-menu-item__details-value"
              title={equipment.pinOrSerial}
            >
              {equipment.pinOrSerial}
            </span>
          </div>
        </div>
      </div>

      <input
        type="checkbox"
        className="equipment-menu-item__checkbox"
        checked={isChecked}
        onChange={handleCheck}
      />
    </li>
  );
}
