import React from 'react';
import clsx from 'clsx';
import './BaseDataGrid.less';

export interface DataGridColumn<T> {
  field: keyof T | 'actions';
  headerName?: string;
  renderCell?: (row: T) => React.ReactNode;
  flex?: number;
  width?: number;
}

export interface DataGridProps<T> {
  columns: DataGridColumn<T>[];
  rows: T[];
  isContentScrollable?: boolean;
  className?: string;
  headerProps?: {
    className: string;
  };
  bodyProps?: {
    className: string;
  };
}

export default function DataGrid<T>({
  columns,
  rows,
  isContentScrollable,
  className,
  headerProps,
  bodyProps,
}: DataGridProps<T>) {
  function renderCellValue(row: T, column: DataGridColumn<T>) {
    const isActionsColumn = column.field === 'actions';

    if (isActionsColumn && column.renderCell) {
      return column.renderCell(row);
    }

    const value = row[column.field as keyof T];

    if (!value) {
      return '';
    }

    return column.renderCell ? column.renderCell(row) : String(value);
  }

  /**
   * According to the design the table border must go out of the component content.
   * To handle this case we need to pass:
   * 
   * <BaseDataGrid headerProps={{ className: 'parent-component__base-data-grid' }}
   * 
   *  &__base-data-grid::after {
        content: '';
        position: absolute;
        left: -24px;
        right: -24px;
        bottom: 0;
        height: 1px;
        background: @color-g87;
      }
   */

  return (
    <div className={clsx('base-data-grid', className)}>
      <div
        className={clsx('base-data-grid__header', headerProps?.className, {
          'base-data-grid__header_with-border': !isContentScrollable,
        })}
      >
        {columns.map(({ flex, width, ...column }) => {
          const style = {
            ...(flex && { flex }),
            ...(width && { width }),
          };

          return (
            <div
              key={String(column.field)}
              className="base-data-grid__header-cell"
              style={style}
            >
              {column.headerName}
            </div>
          );
        })}
      </div>

      <div
        className={clsx('base-data-grid__body', bodyProps?.className, {
          'base-data-grid__body_scrollable': isContentScrollable,
        })}
      >
        {rows.map((row, rowIndex) => (
          <div key={rowIndex} className="base-data-grid__row">
            {columns.map(({ flex, width, ...column }) => {
              const style = {
                ...(flex && { flex }),
                ...(width && { width }),
              };

              return (
                <div
                  key={String(column.field)}
                  className="base-data-grid__body-cell"
                  style={style}
                >
                  {renderCellValue(row, column)}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
}
