import React from 'react';

export default function EditIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="ic_edit"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={props.width ?? '32'}
      height={props.height ?? '32'}
      {...props}
    >
      <rect id="Box" width="32" height="32" fill="none" />
      <g id="edit-2" transform="translate(5.641 5.484)">
        <path
          id="Vector"
          d="M8.386,1.3.954,9.17a2.761,2.761,0,0,0-.607,1.295L.012,13.4A1.383,1.383,0,0,0,1.705,15l2.915-.5a2.6,2.6,0,0,0,1.258-.679l7.433-7.867c1.286-1.358,1.865-2.906-.136-4.8C11.184-.716,9.672-.055,8.386,1.3Z"
          transform="translate(3.114 1.593)"
          fill="none"
          stroke={props.stroke ?? '#1a1a1a'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={props.strokeWidth ?? '1.5'}
        />
        <path
          id="Vector-2"
          d="M0,0A5.546,5.546,0,0,0,4.934,4.663"
          transform="translate(10.261 4.209)"
          fill="none"
          stroke={props.stroke ?? '#1a1a1a'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={props.strokeWidth ?? '1.5'}
        />
        <path
          id="Vector-3"
          d="M0,0H16.3"
          transform="translate(2.212 19.555)"
          fill="none"
          stroke={props.stroke ?? '#1a1a1a'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={props.strokeWidth ?? '1.5'}
        />
      </g>
    </svg>
  );
}
