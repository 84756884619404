import { Dialog } from '@progress/kendo-react-dialogs';
import MapLegendEquipment from 'assets/images/ill_map_legend_equipment.png';
import MapLegendEquipmentError from 'assets/images/ill_map_legend_equipment_error.png';
import MapLegendEquipmentOut from 'assets/images/ill_map_legend_equipment_out.png';
import MapLegendEquipmentOutError from 'assets/images/ill_map_legend_equipment_out_error.png';
import MapLegendEv from 'assets/images/ill_map_legend_ev.png';
import MapLegendPaired from 'assets/images/ill_map_legend_paired.png';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
interface Props {
  open: boolean;
  onClose: () => void;
}

export function LegendDialog({ open, onClose }: Props) {
  const { t } = useTranslation();

  if (!open) {
    return null;
  }

  return (
    <Dialog className="modal-form">
      <div className="map-legend-model">
        <div className="title">
          <span className="back-button fixed-on-desktop" onClick={onClose}>
            <svg
              id="ic_nav_back"
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
            >
              <rect id="Box" width="48" height="48" fill="none" />
              <path
                id="ic_nav_up"
                d="M16.465,8.482H1.483"
                transform="translate(15.518 15.518)"
                fill="none"
                stroke="#1a1a1a"
                strokeLinecap="round"
                strokeWidth="2"
              />
              <path
                id="Path_470"
                data-name="Path 470"
                d="M23.676,16.018,16.2,23.748l7.477,7.73"
                transform="translate(0.324 0.252)"
                fill="none"
                stroke="#1a1a1a"
                strokeLinecap="round"
                strokeWidth="2"
              />
            </svg>
          </span>
          <h2>{t('map_legend_title')}</h2>
        </div>
        <div className="subtitle-caption">
          <span>{t('map_legend_subtitle')}</span>
        </div>
        <div className="map-legend-container">
          <div className="item">
            <img src={MapLegendEquipment} alt="Not found" />
            <span
              dangerouslySetInnerHTML={{
                __html: t('map_legend_equipment'),
              }}
            />
          </div>
          <div className="item">
            <img src={MapLegendEquipmentError} alt="Not found" />
            <span
              dangerouslySetInnerHTML={{
                __html: t('map_legend_equipment_error'),
              }}
            />
          </div>
          <div className="item">
            <img src={MapLegendEquipmentOut} alt="Not found" />
            <span
              dangerouslySetInnerHTML={{
                __html: t('map_legend_equipment_out'),
              }}
            />
          </div>
          <div className="item">
            <img src={MapLegendEquipmentOutError} alt="Not found" />
            <span
              dangerouslySetInnerHTML={{
                __html: t('map_legend_equipment_error_out'),
              }}
            />
          </div>
          <div className="item">
            <img src={MapLegendPaired} alt="Not found" />
            <span
              dangerouslySetInnerHTML={{
                __html: t('map_legend_equipment_paired'),
              }}
            />
          </div>
          <div className="item">
            <img src={MapLegendEv} alt="Not found" />
            <span
              dangerouslySetInnerHTML={{
                __html: t('map_legend_ev'),
              }}
            />
          </div>
        </div>
        <button onClick={onClose}>{t('ok')}</button>
      </div>
    </Dialog>
  );
}
