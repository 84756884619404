import React from 'react';
import './FullscreenLoader.less';
import { Loader } from 'app/components/Loader';

export default function FullscreenLoader() {
  return (
    <div className="fullscreen-loader">
      <Loader />
    </div>
  );
}
