import { Chip } from '@progress/kendo-react-buttons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export function PasswordDecorators(props: { password: string }) {
  const { t } = useTranslation();
  return (
    <>
      <Chip
        text={t('password_rule_character_limit')}
        icon={
          props.password?.length >= 8
            ? 'k-i-marker-pin-target password-card-met'
            : 'k-i-marker-pin-target password-card-unmet'
        }
      />

      <Chip
        text={t('password_rule_alpha_character')}
        icon={
          props.password.match(/^.*[a-zA-Z]+.*$/)
            ? 'k-i-marker-pin-target password-card-met'
            : 'k-i-marker-pin-target password-card-unmet'
        }
      />

      <Chip
        text={t('password_rule_numeric_character')}
        icon={
          props.password.match(/^.*[0-9]+.*$/)
            ? 'k-i-marker-pin-target password-card-met'
            : 'k-i-marker-pin-target password-card-unmet'
        }
      />

      <Chip
        text={t('password_rule_symbol_character')}
        icon={
          props.password.match(/[^\w\s]/g)
            ? 'k-i-marker-pin-target password-card-met'
            : 'k-i-marker-pin-target password-card-unmet'
        }
      />
    </>
  );
}
