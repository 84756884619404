import React from 'react';
import './Divider.less';
import clsx from 'clsx';

interface Props {
  orientation?: 'vertical' | 'horizontal';
}

export default function Divider({ orientation = 'vertical' }: Props) {
  const isVertical = orientation === 'vertical';
  return (
    <span
      className={clsx('divider', {
        divider_vertical: isVertical,
        divider_horizontal: !isVertical,
      })}
    />
  );
}
