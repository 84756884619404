import { BaseButton } from 'app/components/buttons/BaseButton';
import { EditIcon } from 'app/components/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './FleetAccessSharingOverviewSection.less';
import clsx from 'clsx';
import { Card } from 'app/components/Card';

interface Props {
  fleet: any;
  className?: string;
  isLoading?: boolean;
  onEdit?: () => void;
}

export default function FleetAccessSharingOverviewSection({
  fleet,
  className,
  isLoading,
  onEdit,
}: Props) {
  const { t } = useTranslation();

  return (
    <section
      className={clsx('fleet-access-sharing-overview-section', className)}
    >
      <div className="fleet-access-sharing-overview-section__header">
        <h3 className="fleet-access-sharing-overview-section__title">
          {t('fleet')}
        </h3>

        <BaseButton onClick={onEdit} disabled={isLoading}>
          <EditIcon width={20} height={20} />
          <b>{t('edit').toUpperCase()}</b>
        </BaseButton>
      </div>

      <Card className="fleet-access-sharing-overview-section__card">
        <h4 className="fleet-access-sharing-overview-section__card-title">
          {fleet.name}
        </h4>

        <p className="fleet-access-sharing-overview-section__card-text">
          {t('number_of_equipment', {
            numberOfEquipment: fleet.pieces,
          })}
        </p>
      </Card>
    </section>
  );
}
