import React from 'react';
import './CounterBadge.less';

interface Props {
  badgeContent: number;
  text: string;
}

export default function CounterBadge({ badgeContent, text }: Props) {
  return (
    <span className="counter-badge">
      <span className="counter-badge__text">{text}</span>
      <span className="counter-badge__content">{badgeContent}</span>
    </span>
  );
}
