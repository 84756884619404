import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { ajax } from 'ajax/ajax';
import { emailRegex } from 'app/common/utils';
import {
  ic_nav_back as BackIcon,
  ill_banner_error as ErrorIcon,
} from 'app/widgets/SVGs';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import HidePassIcon from '../../../assets/images/ic_field_hidepass.svg';
import ShowPassIcon from '../../../assets/images/ic_field_showpass.svg';

export function LoginPage() {
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [globalError, setGlobalError] = React.useState<any>(undefined);
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024);
  const [isLandingPage, setIsLandingPage] = React.useState(true);

  let [searchParams] = useSearchParams();
  const location = useLocation();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const validateEmail = () => {
    if (!email) {
      setEmailError(t('field_is_required'));
      return false;
    }

    if (!email.toLowerCase().match(emailRegex)) {
      setEmailError(t('incorrect_email_format'));
      return false;
    }
    return true;
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError(t('field_is_required'));
      return false;
    }

    return true;
  };

  const validateForm = () => {
    let isValid = true;
    isValid = validateEmail() && isValid;
    isValid = validatePassword() && isValid;
    return isValid;
  };

  const [isSubmiting, setIsSubmiting] = React.useState(false);

  const loginButtonClicked = () => {
    if (!validateForm()) {
      return;
    }
    setIsSubmiting(true);

    let returnUrl = '#/equipment/?askNotificationPermission';

    ajax
      .getLogin(email, password, returnUrl)
      .catch(e => {
        if (
          e?.response?.data?.error === 'invalid_grant' ||
          e?.response?.data?.error_code === 'invalid_grant'
        ) {
          setGlobalError({
            title: t('invalid_credentials'),
            text: t('invalid_credentials_message'),
          });
        } else {
          setGlobalError({
            title: t('unexpected_error_title'),
            text: t('unexpected_error_message'),
          });
        }
      })
      .finally(() => {
        localStorage.setItem('justLoggedOn', 'true');
        setIsSubmiting(false);
      });
  };

  React.useEffect(() => {
    const showLandingPage = (location.state as { showLandingPage: boolean })
      ?.showLandingPage;

    if (showLandingPage != undefined) {
      setIsLandingPage(showLandingPage);
    }
  }, [location.state as { showLandingPage: boolean }]);

  React.useEffect(() => {
    window.addEventListener('resize', e => {
      window.innerWidth < 1024 ? setIsMobile(true) : setIsMobile(false);
    });
  }, [window.innerWidth]);

  React.useEffect(() => {}, [isMobile, isLandingPage]);

  const defaultLoginPage = (
    <div id="defaultLogin">
      {isMobile && (
        <span
          className="back-button back-button-on-layout fixed-on-desktop"
          onClick={() => setIsLandingPage(true)}
        >
          <BackIcon />
        </span>
      )}
      <h1>{isMobile ? t('sign_in') : t('welcome_message')}</h1>
      <div className="form-caption">
        <div>
          <Trans i18nKey="sign_in_with_existing_account" />
        </div>
        <div>
          <b>{t('required_fields_warning')}</b>
        </div>
      </div>
      <div className={`form-group ${emailError && 'error'}`}>
        <div className="form-content">
          <div className="form-label">
            <span>{t('email_address')} *</span>
          </div>
          <div className="form-control">
            <Input
              id="email"
              name="email"
              placeholder={t('enter_email_address')}
              value={email}
              onChange={event => {
                setEmail(event.target.value as string);
                setEmailError('');
              }}
              disabled={isSubmiting}
            />
          </div>
        </div>
        <div className="form-error">
          {emailError && <Error id={'emailError'}>{emailError}</Error>}
        </div>
      </div>

      <div className={`form-group ${passwordError && 'error'}`}>
        <div className="form-content">
          <div className="form-label">
            <span>{t('password')} *</span>
          </div>
          <div className="form-control form-control-password">
            <Input
              id="password"
              name="password"
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder={t('enter_password')}
              value={password}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  loginButtonClicked();
                }
              }}
              onChange={event => {
                setPassword(event.target.value as string);
                setPasswordError('');
              }}
              disabled={isSubmiting}
            />
            <span
              className={`show-password ${
                isPasswordVisible ? 'pass-visible' : ''
              }`}
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              <img
                alt="Hide Password"
                src={HidePassIcon}
                className="hide-pass-icon"
              ></img>
              <img
                alt="Show Password"
                src={ShowPassIcon}
                className="show-pass-icon"
              ></img>
            </span>
          </div>
        </div>
        <div className="form-error">
          {passwordError && <Error id={'passwordError'}>{passwordError}</Error>}
        </div>
      </div>
      <div className="form-bottom-link text-right">
        <Link
          to={
            '/forgotPassword?returnUrl=' +
            (searchParams.get('returnUrl') || '/')
          }
        >
          {t('forgot_your_password')}
        </Link>
      </div>

      <Button
        className="form-button landing-page"
        themeColor="primary"
        onClick={loginButtonClicked}
        disabled={isSubmiting}
      >
        {isSubmiting ? (
          <div className="loading_icon loading_icon-btn">
            <div className="css-icon"></div>
          </div>
        ) : (
          t('sign_in')
        )}
      </Button>
      {globalError && (
        <div className="errors-box form-global-errors sign-in-global-errors">
          <span className="error-icon">
            <ErrorIcon />
          </span>
          <div className="error-list">
            <span>
              <b>{globalError.title}</b>
            </span>
            <span>
              <Error id={'globalError'}>{globalError.text}</Error>
            </span>
          </div>
        </div>
      )}
      <div className="form-create-area">
        <div className="text-create">{t('dont_have_an_account_yet')}</div>
        <Button
          className="form-button"
          themeColor="tertiary"
          onClick={() => navigate('/createAccount')}
        >
          {t('create_account')}
        </Button>
      </div>
    </div>
  );

  const presentationLoginPage = (
    <div id="mobileLandingPage">
      <h1>{t('welcome_message')}</h1>
      <div className="form-caption">
        <div>
          <span
            dangerouslySetInnerHTML={{ __html: t('AddEquipment.SignInToAcc') }}
          />
        </div>
      </div>

      <Button
        className="form-button"
        themeColor="primary"
        onClick={() => {
          setIsLandingPage(false);
        }}
        disabled={isSubmiting}
      >
        {t('sign_in')}
      </Button>
      <Button
        className="form-button landing-page_mobile"
        themeColor="tertiary"
        onClick={() => navigate('/createAccount')}
      >
        {t('create_account')}
      </Button>
    </div>
  );

  return isMobile && isLandingPage ? presentationLoginPage : defaultLoginPage;
}
