import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import ClickOutside from 'app/widgets/ClickOutside';
import { BaseButton } from 'app/components/buttons/BaseButton';
import { MoreIcon } from 'app/components/icons';
import { ActionsListItem } from './types';
import './ActionsMenu.less';
import clsx from 'clsx';

interface Props {
  actions: ActionsListItem[];
}

export default function ActionsMenu({ actions }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
  const menuOpen = Boolean(anchorEl);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  const handleMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const element = event.currentTarget;
      setAnchorEl(element);
    },
    [],
  );

  const activeActions = useMemo(
    () => actions.filter(({ exclude }) => !exclude),
    [actions],
  );

  useEffect(() => {
    const handleScrollClose = () => {
      if (menuOpen) {
        handleMenuClose();
      }
    };

    const layoutElement = document.querySelector('.layout');
    const layoutContainerElement = document.querySelector('.layout_container');

    layoutElement?.addEventListener('scroll', handleScrollClose);
    layoutContainerElement?.addEventListener('scroll', handleScrollClose);

    return () => {
      layoutElement?.removeEventListener('scroll', handleScrollClose);
      layoutContainerElement?.removeEventListener('scroll', handleScrollClose);
    };
  }, [menuOpen, handleMenuClose]);

  return (
    <>
      <BaseButton onClick={handleMenuOpen} disabled={!activeActions.length}>
        <MoreIcon />
      </BaseButton>

      <Popup
        // Note: React doesn't render this popup correctly when we close it onScroll. The key prop says that it needs to be unmounted.
        key={menuOpen ? 'open' : 'closed'}
        anchor={anchorEl}
        show={menuOpen}
        popupAlign={{ horizontal: 'right', vertical: 'top' }}
      >
        <ClickOutside onClickOutside={handleMenuClose}>
          <ul className="actions-menu">
            {activeActions.map(({ label, action, disabled }, index) => (
              <li
                key={`${label}-${index}`}
                className={clsx('actions-menu__item', {
                  'actions-menu__item_disabled': disabled,
                })}
                onClick={() => {
                  if (!disabled) {
                    action();
                    handleMenuClose();
                  }
                }}
              >
                {label}
              </li>
            ))}
          </ul>
        </ClickOutside>
      </Popup>
    </>
  );
}
