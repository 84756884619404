import { Button } from '@progress/kendo-react-buttons';
import {
  ill_inhibit_disabled as StarterDisabledIcon,
  ill_inhibit_enabled as StarterEnabledIcon,
} from 'app/widgets/SVGs';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface InhibitRestartConfirmDialogProps {
  isEnable: boolean;
  isLoading: boolean;
  modelIconUrl?: string;
  nickName?: string;
  close: () => void;
  onSendCommand: (isCancel: boolean) => void;
}
export function InhibitRestartConfirmDialog(
  props: InhibitRestartConfirmDialogProps,
) {
  const { t } = useTranslation();

  const { isEnable, isLoading, modelIconUrl, nickName, close, onSendCommand } =
    props;

  return (
    <div className="remove-item-dialog-content">
      <div className="image-to-remove">
        <img src={modelIconUrl} alt={nickName} />
        <span className="delete-image-icon">
          {isEnable ? <StarterEnabledIcon /> : <StarterDisabledIcon />}
        </span>
      </div>
      <h3 className="text-center">
        {t(
          `${
            isEnable
              ? 'inhibit_restart_confirm_enable_message'
              : 'inhibit_restart_confirm_disable_message'
          }`,
          {
            nickname: nickName,
          },
        )}
      </h3>
      <div className="row">
        <div className="col-xxs-6">
          <Button disabled={isLoading} onClick={close}>
            {t('cancel')}
          </Button>
        </div>
        <div className="col-xxs-6">
          <Button
            disabled={isLoading}
            onClick={() => onSendCommand(false)}
            themeColor={`${isEnable ? 'success' : 'error'}`}
          >
            {isLoading ? (
              <div className="loading_icon loading_icon-btn">
                <div className="css-icon"></div>
              </div>
            ) : (
              t(
                `${
                  isEnable
                    ? 'inhibit_restart_confirm_enable_btn'
                    : 'inhibit_restart_confirm_disable_btn'
                }`,
              )
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
