export const InhibitRestartStatus = {
  RestartEnabled: 'RestartEnabled',
  RestartDisabled: 'RestartDisabled',
};

export interface IInhibitRestartMetadata {
  hasPendingCommand: boolean;
  isEnabled: boolean;
  isEnableing: boolean;
  commandStatusToSend?: string;
  isLocallyDisabled: boolean;
}
