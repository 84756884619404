import { CacheContext } from 'app/components/ReactContexts/cacheContext';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IEquipmentEntry } from 'types/IEquipmentEntry';

export function CompatibleAttachments(props) {
  const [categoriesList, setCategoriesList] = React.useState<Array<any>>();
  const [modelsList, setModelsList] = React.useState<Array<any>>();
  const [currentModel, setCurrentModel] = React.useState<any>();
  const [compatibleAttachmentsCategories, setCompatibleAttachmentsCategories] =
    React.useState<Array<any>>([]);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const selectedEquipment = props.currentEquipment as IEquipmentEntry;
  const { getApiModels } = React.useContext(CacheContext);

  const scrolableRef = React.useRef(null as null | HTMLDivElement);
  const posRef = React.useRef({ top: 0, left: 0, x: 0, y: 0 });
  const posMovementRef = React.useRef<{
    maxDistanceFromPos: number;
  }>({ maxDistanceFromPos: 0 });

  const mouseMoveHandler = function (e) {
    // How far the mouse has been moved
    const dx = e.clientX - posRef.current.x;
    const dy = e.clientY - posRef.current.y;

    if (
      Math.abs(dx) + Math.abs(dy) >
      posMovementRef.current.maxDistanceFromPos
    ) {
      posMovementRef.current.maxDistanceFromPos = Math.abs(dx) + Math.abs(dy);
    }

    // Scroll the element
    scrolableRef.current!.scrollTop = posRef.current.top - dy;
    scrolableRef.current!.scrollLeft = posRef.current.left - dx;
  };

  const mouseUpHandler = function () {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    scrolableRef.current!.style.cursor = 'grab';
    scrolableRef.current!.style.removeProperty('user-select');
  };

  const mouseDownHandler = function (e) {
    posRef.current = {
      // The current scroll
      left: scrolableRef.current!.scrollLeft,
      top: scrolableRef.current!.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    scrolableRef.current!.style.cursor = 'grabbing';
    scrolableRef.current!.style.userSelect = 'none';

    posMovementRef.current = { maxDistanceFromPos: 0 };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const getSubcategory = subcategoryId =>
    categoriesList?.filter(c => c.id === subcategoryId)[0];

  const getParentSubcategory = currentSubcategory =>
    categoriesList?.filter(c => c.id === currentSubcategory.parentId)[0];

  function getChildrenCount(categoryId) {
    let count = 0;
    if (
      modelsList &&
      modelsList?.some(
        m =>
          m.categoryId == categoryId &&
          currentModel.compatibleAttachments.includes(m.model),
      )
    ) {
      return modelsList.filter(
        m =>
          m.categoryId == categoryId &&
          currentModel.compatibleAttachments.includes(m.model),
      ).length;
    } else {
      let childrenCategories = categoriesList?.filter(
        c => c.parentId == categoryId,
      );
      if (childrenCategories) {
        for (let i = 0; i < childrenCategories?.length; i++) {
          count += getChildrenCount(childrenCategories[i].id);
        }
      }
    }
    return count;
  }

  const getApiModelsRef = React.useRef(0);

  React.useEffect(() => {
    (async () => {
      setCategoriesList(undefined);
      setModelsList(undefined);
      setCurrentModel(undefined);
      const newNumber = getApiModelsRef.current + 1;
      getApiModelsRef.current = newNumber;
      const apiModel = await getApiModels();
      if (getApiModelsRef.current !== newNumber) {
        return;
      }
      setCategoriesList(apiModel.categories);
      setModelsList(apiModel.models);
      setCurrentModel(
        apiModel.models.filter(
          model => model.model == selectedEquipment.model,
        )[0],
      );
    })();
  }, [selectedEquipment.model, i18n.language]);

  React.useEffect(() => {
    if (modelsList && currentModel && currentModel.compatibleAttachments) {
      const filteredAttachments = modelsList.filter(m =>
        currentModel.compatibleAttachments.some(c => c === m.model),
      );

      for (let i = 0; i < filteredAttachments.length; i++) {
        let subcategoryId = filteredAttachments[i].categoryId;
        let currentSubcategory;

        while (subcategoryId !== undefined) {
          currentSubcategory = getSubcategory(subcategoryId);
          let parentSubcategory = getParentSubcategory(currentSubcategory);

          parentSubcategory.parentId === undefined
            ? (subcategoryId = parentSubcategory.parentId)
            : (subcategoryId = currentSubcategory.parentId);
        }

        setCompatibleAttachmentsCategories(prevCategories => {
          let updatedCategories;
          if (!prevCategories.some(c => c.id === currentSubcategory.id)) {
            updatedCategories = [...prevCategories, currentSubcategory];
          } else {
            updatedCategories = prevCategories;
          }
          updatedCategories.sort((a, b) => {
            if (a.index !== b.index) {
              return a.index - b.index;
            }
            return a.name.localeCompare(b.name);
          });
          return updatedCategories;
        });
      }
    }
  }, [currentModel]);

  return (
    <div className="m-b-40">
      {currentModel?.hasOwnProperty('compatibleAttachments') &&
        compatibleAttachmentsCategories &&
        compatibleAttachmentsCategories.length > 0 && (
          <div className="equipment-content-actions">
            <div className="row">
              <div className="col-xxs-8">
                <h4>{t('compatible_attachments')}</h4>
              </div>
              <div className="col-xxs-4">
                <Link
                  to={{ pathname: '/resources/39' }}
                  className="equipment-content-actions-viewAll"
                  onClick={e => {
                    e.preventDefault();
                    location.state = { modelFilter: currentModel.model };
                    navigate('/resources/39', { state: location.state });
                  }}
                >
                  {t('attachment_slider_item_view_all')}
                </Link>
              </div>
            </div>
            <div className="attachments-slider-wrapper">
              <div className="box-for-border"></div>
              <div
                className="attachments-slider"
                ref={scrolableRef}
                onMouseDown={mouseDownHandler}
              >
                {compatibleAttachmentsCategories.map(category => (
                  <div className="attachments-image" key={category.name}>
                    {/* <img src={category.iconUrl} /> */}
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${category.iconUrl})` }}
                    ></div>
                    <div
                      className="attachments-hover-box"
                      onClick={() => {
                        if (posMovementRef.current.maxDistanceFromPos < 10) {
                          navigate('/resources/' + category.id, {
                            state: { modelFilter: currentModel.model },
                          });
                        }
                        posMovementRef.current = { maxDistanceFromPos: 0 };
                      }}
                    >
                      <div
                        className="image"
                        style={{ backgroundImage: `url(${category.iconUrl})` }}
                      >
                        {/* <img src={category.iconUrl} /> */}
                      </div>
                      <div className="text">{category.name}</div>
                      <div className="number">
                        {getChildrenCount(category.id)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="separator-l"></div>
          </div>
        )}
    </div>
  );
}
